<div class="feature-room-section pt-5 pb-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-8 col-sm-7">
        <div class="section-title">
          <h1>{{'home.rooms' | translate}}</h1>
        </div>
      </div>
    </div>
    <ngx-slick-carousel class="row room-gird-loop mt-1 false"
                        [config]="featureroomsliderConfig">
      <app-carousel-room style="margin-left: 5px; margin-right: 5px"
                         ngxSlickItem *ngFor="let room of rooms; index as i;"[index]="i" [room]="room">
      </app-carousel-room>
    </ngx-slick-carousel>
  </div>
</div>

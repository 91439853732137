<mat-card class="example-card">
  <mat-card-header>
        <div style="float: left">
          <mat-card-subtitle>
            <mat-icon style="font-size: 32px; color: #bead8e; cursor: pointer">assignment</mat-icon>
            <span style="position: relative; bottom: 10px; margin-left: 20px">{{post?.postType}}</span>
          </mat-card-subtitle>
        </div>
  </mat-card-header>
  <hr style="margin-bottom: 5px; margin-top: 2px">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <mat-card-content style="text-align: left">
          <p> <b>Title:</b> {{post?.title}}</p>
          <p [hidden]="true" class="post-content"><b matTooltip={{post?.content}} matTooltipClass="tooltip-black" >Content:</b> {{post?.content}}</p>
          <p> <b>Link Title:</b> {{post?.linkTitle}}</p>
          <p class="post-link"><b matTooltip={{post?.link}} matTooltipClass="tooltip-black" >Link:</b> {{post?.link}}</p>
          <p><b>created:</b> {{post?.createdDate | date:'dd-MM-yyyy'}}</p>
          <p><b>changed:</b> {{post?.editedDate | date:'dd-MM-yyyy'}}</p>
          <p><b>Public:</b> {{post?.public}}</p>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <img mat-card-image [src]=post?.photoUrl
             style="
             width: 300px;
             height: 200px;
             margin-top: 10px;
             overflow: hidden">
      </div>
    </div>
  </div>

  <mat-card-actions>
    <button (click)="UpdatePost()" mat-stroked-button color="primary">Edit</button>
    <button (click)="deletePost()" mat-stroked-button color="warn">Delete</button>
    <app-content-status [isOnline]="post.public"></app-content-status>
  </mat-card-actions>

</mat-card>

<div class="container">
  <h3>
    Es wurde eine E-mail an <b>{{data}}</b> gesendet,
    Bitte beachten sie die Angaben in der
    E-mail um die Reservierung Abzuschließen.
    Achtung, diese E-Mail könnte sich in ihrem Spam Ordner befinden!
  </h3>
  <button style="margin: 10px" mat-raised-button color="primary" mat-button [mat-dialog-close]="true">OK</button>
</div>


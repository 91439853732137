import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import {Observable} from 'rxjs';
import firebase from 'firebase/compat';
import User = firebase.User;
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  user: User;
  currentUserUid: string;

  private AppUserDocument: AngularFirestoreDocument<any>;
  AppUser: Observable<any>;

  constructor(private angularFireStore: AngularFirestore,
              private authService: AuthService) {
    this.authService.getCurrentUserUid().subscribe(userUid => {
      if (userUid) {
        this.currentUserUid = userUid;
      }
    });
  }

  getUser(): Observable<any> {
    if (this.currentUserUid) {
      this.AppUserDocument = this.angularFireStore.doc<any>('users/' + this.currentUserUid);
      this.AppUser = this.AppUserDocument.valueChanges();
      return this.AppUser;
    } else {
      return null;
    }
  }

}

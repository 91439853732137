import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Room} from '../../../../models/room.model';
import {RoomService} from '../../../../services/room.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-updateroom',
  templateUrl: './updateroom.component.html',
  styleUrls: ['./updateroom.component.css']
})
export class UpdateroomComponent implements OnInit {
  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  bulletPointCtrl = new UntypedFormControl();
  filteredBulletPoints: Observable<string[]>;
  bulletPoints: string[] = [];
  allBulletPoints: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

////////////////////////////////////////////////////// Check List ////////////////////////////////////////////////////////
  visible2 = true;
  selectable2 = true;
  removable2 = true;
  separatorKeysCodes2: number[] = [ENTER, COMMA];
  bulletPointCtrl2 = new UntypedFormControl();
  filteredBulletPoints2: Observable<string[]>;
  checkList: string[] = [];
  allBulletPoints2: string[] = ['Balcony', 'More stuff'];

  @ViewChild('fruitInput2') fruitInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2') matAutocomplete2: MatAutocomplete;

  uploadEvent1: Event;
  uploadEvent2: Event;
  uploadEvent3: Event;
  uploadEvent4: Event;
  uploadEvent5: Event;
  uploadEvent6: Event;
  uploadEvent7: Event;
  uploadEvent8: Event;
  uploadEvent9: Event;
  uploadEvent10: Event;
  roomForm: UntypedFormGroup;
  room: Room;
  hasPhoto1: boolean;
  hasPhoto2: boolean;
  hasPhoto3: boolean;
  hasPhoto4: boolean;
  hasPhoto5: boolean;
  hasPhoto6: boolean;
  hasPhoto7: boolean;
  hasPhoto8: boolean;
  hasPhoto9: boolean;
  hasPhoto10: boolean;
  roomFromServer: any;
  private startSave: Boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private roomService: RoomService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<UpdateroomComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Room) {
    this.roomForm = this.formBuilder.group({
      content: ['', [Validators.required]],
      title: ['', [Validators.required]],
      price: [''],
      roomType: ['', [Validators.required]],
      public: [false, ''],
      extras1: [''],
      extras2: [''],
      extras3: [''],
      extras4: [''],
      extras5: [''],
      extras6: [''],
      extras7: [''],
      extras8: [''],
      extras9: [''],
      extras10: [''],
    });
    this.roomService.getRoomById(this.data.roomId).subscribe(room => {
      this.roomFromServer = room;
    });

    this.filteredBulletPoints = this.bulletPointCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allBulletPoints.slice()));

    this.filteredBulletPoints2 = this.bulletPointCtrl2.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter2(fruit) : this.allBulletPoints2.slice()));
  }

  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.bulletPoints.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.bulletPointCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.bulletPoints.indexOf(fruit);

    if (index >= 0) {
      this.bulletPoints.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.bulletPointCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBulletPoints.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points //////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////// Check List ////////////////////////////////////////////////////////
  add2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.checkList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.bulletPointCtrl2.setValue(null);
  }

  remove2(fruit: string): void {
    const index = this.checkList.indexOf(fruit);

    if (index >= 0) {
      this.checkList.splice(index, 1);
    }
  }

  selected2(event: MatAutocompleteSelectedEvent): void {
    this.checkList.push(event.option.viewValue);
    this.fruitInput2.nativeElement.value = '';
    this.bulletPointCtrl2.setValue(null);
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBulletPoints2.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////// End Check List ///////////////////////////////////////////////


  ngOnInit(): void {
    if (this.data != null) {
      this.roomForm.controls.title.setValue(this.data.title);
      this.roomForm.controls.content.setValue(this.data.content);
      this.roomForm.controls.price.setValue(this.data.price);
      this.roomForm.controls.roomType.setValue(this.data.roomType);
      this.roomForm.controls.public.setValue(this.data.public);
      this.roomForm.controls.extras1.setValue(this.data.extras1);
      this.roomForm.controls.extras2.setValue(this.data.extras2);
      this.roomForm.controls.extras3.setValue(this.data.extras3);
      this.roomForm.controls.extras4.setValue(this.data.extras4);
      this.roomForm.controls.extras5.setValue(this.data.extras5);
      this.roomForm.controls.extras6.setValue(this.data.extras6);
      this.roomForm.controls.extras7.setValue(this.data.extras7);
      this.roomForm.controls.extras8.setValue(this.data.extras8);
      this.roomForm.controls.extras9.setValue(this.data.extras9);
      this.roomForm.controls.extras10.setValue(this.data.extras10);
      this.bulletPoints = this.data.bulletPoints;
      this.checkList = this.data.checkList;
    }
    if (this.data.photoUrl1 !== '') {
      this.hasPhoto1 = true;
    } else {
      this.hasPhoto1 = false;
    }

    if (this.data.photoUrl2 !== '') {
      this.hasPhoto2 = true;
    } else {
      this.hasPhoto2 = false;
    }

    if (this.data.photoUrl3 !== '') {
      this.hasPhoto3 = true;
    } else {
      this.hasPhoto3 = false;
    }

    if (this.data.photoUrl4 !== '') {
      this.hasPhoto4 = true;
    } else {
      this.hasPhoto4 = false;
    }
    if (this.data.photoUrl5 !== '') {
      this.hasPhoto5 = true;
    } else {
      this.hasPhoto5 = false;
    }

    if (this.data.photoUrl6 !== '') {
      this.hasPhoto6 = true;
    } else {
      this.hasPhoto6 = false;
    }

    if (this.data.photoUrl7 !== '') {
      this.hasPhoto7 = true;
    } else {
      this.hasPhoto7 = false;
    }

    if (this.data.photoUrl8 !== '') {
      this.hasPhoto8 = true;
    } else {
      this.hasPhoto8 = false;
    }

    if (this.data.photoUrl9 !== '') {
      this.hasPhoto9 = true;
    } else {
      this.hasPhoto9 = false;
    }

    if (this.data.photoUrl10 !== '') {
      this.hasPhoto10 = true;
    } else {
      this.hasPhoto10 = false;
    }
  }

  startSaving() {
    this.startSave = true;
  }

  updateRoom() {
    if (this.roomForm.invalid !== true && this.startSave) {
      this.room = new Room(this.roomForm.value);
      this.room.bulletPoints = this.bulletPoints;
      this.room.checkList = this.checkList;
      this.room.roomId = this.data.roomId;
      this.roomService.updateRoom(
        this.uploadEvent1,
        this.uploadEvent2,
        this.uploadEvent3,
        this.uploadEvent4,
        this.uploadEvent5,
        this.uploadEvent6,
        this.uploadEvent7,
        this.uploadEvent8,
        this.uploadEvent9,
        this.uploadEvent10,
        this.room);
      this.dialogRef.close(true);
      this.uploadEvent1 = null;
      this.uploadEvent1 = null;
    }
  }

  pictureToUpload1($event: Event) {
    this.uploadEvent1 = $event;
    this.hasPhoto1 = true;
  }
  pictureToUpload2($event: Event) {
    this.uploadEvent2 = $event;
    this.hasPhoto2 = true;
  }
  pictureToUpload3($event: Event) {
    this.uploadEvent3 = $event;
    this.hasPhoto3 = true;
  }
  pictureToUpload4($event: Event) {
    this.uploadEvent4 = $event;
    this.hasPhoto4 = true;
  }
  pictureToUpload5($event: Event) {
    this.uploadEvent5 = $event;
    this.hasPhoto5 = true;
  }
  pictureToUpload6($event: Event) {
    this.uploadEvent6 = $event;
    this.hasPhoto6 = true;
  }
  pictureToUpload7($event: Event) {
    this.uploadEvent7 = $event;
    this.hasPhoto7 = true;
  }
  pictureToUpload8($event: Event) {
    this.uploadEvent8 = $event;
    this.hasPhoto8 = true;
  }
  pictureToUpload9($event: Event) {
    this.uploadEvent9 = $event;
    this.hasPhoto9 = true;
  }
  pictureToUpload10($event: Event) {
    this.hasPhoto10 = true;
    this.uploadEvent10 = $event;
  }

  deleteRoomPhoto1() {
    if (this.roomFromServer.photoUrl1) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl1, this.roomFromServer.roomId, 'photoUrl1');
          this.hasPhoto1 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto2() {
    if (this.roomFromServer.photoUrl2) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl2, this.roomFromServer.roomId, 'photoUrl2');
          this.hasPhoto2 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto3() {
    if (this.roomFromServer.photoUrl3) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl3, this.roomFromServer.roomId, 'photoUrl3');
          this.hasPhoto3 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto4() {
    if (this.roomFromServer.photoUrl4) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl4, this.roomFromServer.roomId, 'photoUrl4');
          this.hasPhoto4 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto5() {
    if (this.roomFromServer.photoUrl5) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl5, this.roomFromServer.roomId, 'photoUrl5');
          this.hasPhoto5 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto6() {
    if (this.roomFromServer.photoUrl6) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl6, this.roomFromServer.roomId, 'photoUrl6');
          this.hasPhoto6 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto7() {
    if (this.roomFromServer.photoUrl7) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl7, this.roomFromServer.roomId, 'photoUrl7');
          this.hasPhoto7 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto8() {
    if (this.roomFromServer.photoUrl8) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl8, this.roomFromServer.roomId, 'photoUrl8');
          this.hasPhoto8 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto9() {
    if (this.roomFromServer.photoUrl9) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl9, this.roomFromServer.roomId, 'photoUrl9');
          this.hasPhoto9 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto10() {
    if (this.roomFromServer.photoUrl10) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomService.deleteRoomPhoto(this.roomFromServer.photoUrl10, this.roomFromServer.roomId, 'photoUrl10');
          this.hasPhoto10 = false;
          this.roomService.getRoomById(this.data.roomId).subscribe(room => {
            this.roomFromServer = room;
          });
        }
      });
    }
  }

}

<app-header4></app-header4>
<!--====== BREADCRUMB PART START ======-->
<section class="breadcrumb-area banner-img">
  <div class="container">
    <div class="breadcrumb-text">
      <!-- <span>The ultimate luxury</span> -->
      <h2 class="page-title">{{'seminars.seminars' | translate}}</h2>


      <ul class="breadcrumb-nav">
        <li><a routerLink='/'>Home</a></li>
        <li class="active">{{'seminars.seminars' | translate}}</li>
      </ul>
    </div>
  </div>
</section>

<!--====== Title Start ======-->
<section class="about-section pt-30 pb-20">
  <div class="container">
    <div class="section-title about-title text-center">
<!--      <span class="title-tag">Seminare im Hotel-Restaurant Schepers</span>-->
      <span class="title-tag"> {{'seminars.head' | translate}}
      </span>
    </div>
  </div>
</section>
<!--====== Title End ======-->

<app-offers-seminar id="{{'offer' + i}}" *ngFor="let offer of offers; index as i;" [offer]="offer"></app-offers-seminar>

<app-footer2></app-footer2>

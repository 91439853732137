<mat-card class="example-card" style="padding: 20px">
  <mat-card-header>
    <div class="container">
      <div class="row">
        <div class="col">
          <mat-card-title><mat-icon style="font-size: 32px; color: #bead8e; margin: 10px; cursor: pointer">location_on</mat-icon>{{place?.placeType}}</mat-card-title>
        </div>
      </div>
    </div>
  </mat-card-header>
  <hr style="
      margin-bottom: 5px;
      margin-top: 2px">
  <div class="container">
    <div class="row">
      <div class="col">
        <mat-card-content>
          <p><b>Title:</b> {{place?.title}}</p>
          <p><b>created:</b> {{place?.createdDate | date:'d-MM-y'}}</p>
          <p><b>changed:</b> {{place?.editedDate | date:'d-MM-y'}}</p>
          <p [hidden]="true"  ><b>website:</b> {{place?.website}}</p>
          <p><b>Public:</b> {{place?.public}}</p>
          <p><b>Lat:</b> {{place?.destinationLat}}</p>
          <p><b>Lng:</b> {{place?.destinationLng}}</p>
          <p [hidden]="true" class="place-content"><b matTooltip={{place?.content}} matTooltipClass="tooltip-black" >Content:<br></b> {{place?.content}}</p>
        </mat-card-content>
      </div>
      <div class="col">
        <img mat-card-image [src]=place?.photoUrl1
             style="
             overflow: hidden;
             width: 300px;
             height: 200px;
             margin-top: 10px;
             background-size: cover;
             background-position: center;">
      </div>
    </div>
  </div>

  <mat-card-actions>
      <button (click)="updateplace()" mat-stroked-button color="primary">Edit</button>
      <button (click)="deleteplace()" mat-stroked-button color="warn">Delete</button>
      <app-content-status-old [isOnline]="place?.public"></app-content-status-old>
  </mat-card-actions>

</mat-card>


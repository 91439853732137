<!--====== BREADCRUMB PART START ======-->
<section style="margin-top: 0"
         class="breadcrumb-area about-banner-frame"
         title="Picture show's feet in the Background Image of the about Page">
  <div class="container">
    <div class="breadcrumb-text">
      <h2 class="page-title">Datenschutzbestimmungen</h2>
    </div>
  </div>
</section>
<!--====== BREADCRUMB PART END ======-->

<!--====== ABOUT PART START ======-->
<div style="margin: 10px 10px 10px 10px" class="policy-box">
  <h1 style="margin-top: 20px">Datenschutzbestimmungen</h1>
  Zuletzt aktualisiert: Januar 05, 2022
  <div>
    Cookies oder Browser-Cookies. Ein Cookie ist eine kleine Datei, die auf Ihrem Gerät gespeichert wird. Sie können Ihren Browser anweisen, alle Cookies abzulehnen oder anzuzeigen,
    wenn ein Cookie gesendet wird. Wenn Sie jedoch keine Cookies akzeptieren, können Sie möglicherweise einige Teile unseres Dienstes nicht nutzen. Sofern Sie Ihren Browser nicht so
    eingestellt haben, dass er Cookies ablehnt, kann unser Dienst Cookies verwenden.
    Flash-Cookies. Bestimmte Funktionen unseres Dienstes können lokal gespeicherte Objekte (oder Flash Cookies) verwenden, um Informationen über Ihre Präferenzen oder Ihre Aktivitäten
    auf unserem Dienst zu sammeln und zu speichern. Flash-Cookies werden nicht über dieselben Browser-Einstellungen verwaltet wie die Browser-Cookies.
  </div>

  <div style="margin-top: 10px; margin-bottom: 10px">
    <mat-slide-toggle
      (change)="changeCookieSetting($event)"
      [checked]="checked">
      Cookies EIN und AUS schalten.
    </mat-slide-toggle>
    <p>Einstellungen werden sofort Automatisch gespeichert</p>
  </div>

  <p>
    Diese Datenschutzrichtlinie beschreibt unsere Richtlinien und Verfahren zur Erfassung, Verwendung und Offenlegung Ihrer Daten, wenn Sie den Dienst nutzen, und informiert Sie über Ihre Datenschutzrechte und wie das Gesetz Sie schützt.
    Wir verwenden Ihre persönlichen Daten zur Bereitstellung und Verbesserung des Dienstes. Durch die Nutzung des Dienstes erklären Sie sich mit der Erfassung und Nutzung von Informationen in Übereinstimmung
    mit dieser Datenschutzrichtlinie einverstanden.
    Auslegung und Definitionen Auslegung Die Wörter, deren Anfangsbuchstaben groß geschrieben sind, haben die unter den folgenden Bedingungen definierte Bedeutung. Die folgenden Definitionen haben dieselbe Bedeutung,
    unabhängig davon, ob sie im Singular oder im Plural stehen.
  </p>

  <h2 style="margin-top: 20px">Begriffsbestimmungen</h2>
  <p>Für die Zwecke dieser Datenschutzrichtlinie:
    Konto bezeichnet ein eindeutiges Konto, das für Sie erstellt wurde, um auf unseren Dienst oder Teile unseres Dienstes zuzugreifen.
    Unternehmen (in dieser Vereinbarung entweder als "das Unternehmen", "wir", "uns" oder "unser" bezeichnet) bezieht sich auf Hotel-Schepers GmbH.
    Cookies sind kleine Dateien, die von einer Website auf Ihrem Computer, Ihrem Mobilgerät oder einem anderen Gerät platziert werden und die unter anderem die Details Ihres Surfverhaltens auf dieser Website enthalten.
    Land bezieht sich auf: Baden-Württemberg, Deutschland
    Gerät ist jedes Gerät, das auf den Dienst zugreifen kann, wie z. B. ein Computer, ein Mobiltelefon oder ein digitales Tablet.
    Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare Person beziehen.
    Dienst bezieht sich auf die Website.
    Dienstanbieter ist jede natürliche oder juristische Person, die die Daten im Auftrag des Unternehmens verarbeitet. Dies bezieht sich auf Drittunternehmen oder Einzelpersonen, die vom Unternehmen angestellt sind,
    um den Dienst zu erleichtern, den Dienst im Namen des Unternehmens bereitzustellen, Dienstleistungen im Zusammenhang mit dem Dienst zu erbringen oder das Unternehmen bei der Analyse der Nutzung des Dienstes zu unterstützen.
    Nutzungsdaten beziehen sich auf automatisch erfasste Daten, die entweder durch die Nutzung des Dienstes oder durch die Infrastruktur des Dienstes selbst erzeugt werden (z.B. die Dauer eines Seitenbesuchs).
    Website bezieht sich auf Hotel-Schepers GmbH, die von Hotel-Schepers GmbH aus zugänglich ist.
    Sie bezeichnet die natürliche Person, die auf den Dienst zugreift oder ihn nutzt, bzw. das Unternehmen oder eine andere juristische Person, in deren Namen die natürliche Person auf den Dienst zugreift oder ihn nutzt, je nachdem.
    Erhebung und Verwendung Ihrer persönlichen Daten
    Arten von gesammelten Daten
    Persönliche Daten
    Während der Nutzung unseres Dienstes bitten wir Sie möglicherweise, uns bestimmte personenbezogene Daten mitzuteilen, die dazu verwendet werden können, Sie zu kontaktieren oder zu identifizieren.
    Persönlich identifizierbare Informationen können unter anderem sein Nutzungsdaten
  </p>


  <h2 style="margin-top: 20px">Nutzungsdaten</h2>
  <p>
    Nutzungsdaten werden automatisch bei der Nutzung des Dienstes erfasst.
    Zu den Nutzungsdaten können Informationen wie die Internetprotokolladresse Ihres Geräts (z. B. IP-Adresse), der Browsertyp, die Browserversion, die von Ihnen besuchten Seiten unseres Dienstes,
    die Uhrzeit und das Datum Ihres Besuchs, die auf diesen Seiten verbrachte Zeit, eindeutige Gerätekennungen und andere Diagnosedaten gehören.
    Wenn Sie mit oder über ein mobiles Gerät auf den Dienst zugreifen, können wir bestimmte Informationen automatisch erfassen, einschließlich, aber nicht beschränkt auf die Art des von Ihnen verwendeten mobilen Geräts,
    die eindeutige ID Ihres mobilen Geräts, die IP-Adresse Ihres mobilen Geräts, Ihr mobiles Betriebssystem, die Art des von Ihnen verwendeten mobilen Internetbrowsers, eindeutige Gerätekennungen und andere Diagnosedaten.
    Wir können auch Informationen erfassen, die Ihr Browser sendet, wenn Sie unseren Dienst besuchen oder wenn Sie über ein mobiles Gerät auf den Dienst zugreifen.
    Tracking-Technologien und Cookies
    Wir verwenden Cookies und ähnliche Tracking-Technologien, um die Aktivitäten in unserem Dienst zu verfolgen und bestimmte Informationen zu speichern. Bei den verwendeten Tracking-Technologien handelt es sich um Beacons,
    Tags und Skripte zur Erfassung und Verfolgung von Informationen und zur Verbesserung und Analyse unseres Dienstes. Zu den von uns verwendeten Technologien können gehören:
    Bestimmte Bereiche unseres Dienstes und unserer E-Mails können kleine elektronische Dateien enthalten, die als Web Beacons (auch als Clear Gifs, Pixel Tags und Single-Pixel Gifs bezeichnet) bekannt sind und die es dem
    Unternehmen beispielsweise ermöglichen, die Benutzer zu zählen, die diese Seiten besucht oder eine E-Mail geöffnet haben, sowie andere damit verbundene Website-Statistiken zu erstellen (z. B. Aufzeichnung der
    Beliebtheit eines bestimmten Bereichs und Überprüfung der System- und Serverintegrität).
    Cookies können "dauerhafte" oder "Sitzungs"-Cookies sein. Dauerhafte Cookies verbleiben auf Ihrem Computer oder Mobilgerät, wenn Sie offline gehen, während Sitzungscookies gelöscht werden, sobald Sie Ihren Webbrowser schließen.
  </p>

  <h2 style="margin-top: 20px"> Wir verwenden sowohl Sitzungscookies als auch dauerhafte Cookies für die unten genannten Zwecke:</h2>
  Notwendige / wesentliche Cookies
  <div>
    <ul>
      <li>Typ: Sitzungs-Cookies</li>
      <li>Verwaltet von: Uns</li>
      <li>
        Zweck: Diese Cookies sind unerlässlich, um Ihnen die über die Website verfügbaren Dienste zur Verfügung zu stellen und Ihnen die Nutzung einiger ihrer Funktionen zu ermöglichen. Sie helfen bei der
        Authentifizierung von Benutzern und verhindern die betrügerische Nutzung von Benutzerkonten. Ohne diese Cookies können die von Ihnen angeforderten Dienste nicht bereitgestellt werden, und wir
        verwenden diese Cookies nur, um Ihnen diese Dienste zur Verfügung zu stellen.
      </li>
      <li> Cookies Politik / Hinweis Akzeptanz Cookies</li>
      <li>Typ: Dauerhafte Cookies</li>
      <li>Verwaltet von: Uns</li>
      <li>Zweck: Diese Cookies identifizieren, ob die Benutzer die Verwendung von Cookies auf der Website akzeptiert haben.</li>
      <li>Typ: Dauerhafte Cookies</li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>

  <div>
    Funktionelle Cookies
    <ul>
      <li>Verwaltet von: Uns</li>
      <li>
        Zweck: Diese Cookies ermöglichen es uns, die von Ihnen bei der Nutzung der Website getroffenen Entscheidungen zu speichern, wie z. B. Ihre Anmeldedaten oder Ihre Spracheinstellung. Der Zweck dieser Cookies ist es,
        Ihnen eine persönlichere Erfahrung zu bieten und zu vermeiden, dass Sie Ihre Präferenzen jedes Mal neu eingeben müssen, wenn Sie die Website nutzen.
        Weitere Informationen über die von uns verwendeten Cookies und Ihre Wahlmöglichkeiten in Bezug auf Cookies finden Sie in unserer Cookie-Richtlinie oder im Abschnitt Cookies unserer Datenschutzrichtlinie.
      </li>
      <li>
        Zweck: Diese Cookies ermöglichen es uns, die von Ihnen bei der Nutzung der Website getroffenen Entscheidungen zu speichern, wie z. B. Ihre Anmeldedaten oder Ihre Spracheinstellung. Der Zweck dieser Cookies ist es,
        Ihnen eine persönlichere Erfahrung zu bieten und zu vermeiden, dass Sie Ihre Präferenzen jedes Mal neu eingeben müssen, wenn Sie die Website nutzen.
        Weitere Informationen über die von uns verwendeten Cookies und Ihre Wahlmöglichkeiten in Bezug auf Cookies finden Sie in unserer Cookie-Richtlinie oder im Abschnitt Cookies unserer Datenschutzrichtlinie.
      </li>
    </ul>
  </div>

  <h2 style="margin-top: 20px">Verwendung Ihrer persönlichen Daten</h2>
  <p>
    Das Unternehmen kann personenbezogene Daten für die folgenden Zwecke verwenden:
    Zur Bereitstellung und Aufrechterhaltung unseres Dienstes, einschließlich zur Überwachung der Nutzung unseres Dienstes.
    Zur Verwaltung Ihres Kontos: zur Verwaltung Ihrer Registrierung als Nutzer des Dienstes. Die von Ihnen bereitgestellten
    persönlichen Daten können Ihnen Zugang zu verschiedenen Funktionen des Dienstes geben, die Ihnen als registriertem Nutzer zur Verfügung stehen.
    Zur Vertragserfüllung: zur Entwicklung, Einhaltung und Durchführung des Kaufvertrags für die von Ihnen erworbenen Produkte,
    Artikel oder Dienstleistungen oder eines anderen Vertrags mit uns über den Dienst.
    Um Sie zu kontaktieren: Um Sie per E-Mail, Telefonanrufe, SMS oder andere gleichwertige Formen der elektronischen Kommunikation
    zu kontaktieren, wie z. B. Push-Benachrichtigungen einer mobilen Anwendung in Bezug auf Aktualisierungen oder informative Mitteilungen im Zusammenhang mit den Funktionalitäten,
    Produkten oder vertraglich vereinbarten Dienstleistungen, einschließlich der Sicherheitsaktualisierungen, wenn dies für ihre Umsetzung notwendig oder angemessen ist.
    Um Ihnen Neuigkeiten, Sonderangebote und allgemeine Informationen über andere von uns angebotene Waren, Dienstleistungen und Veranstaltungen
    zukommen zu lassen, die denen ähneln, die Sie bereits gekauft oder angefragt haben, es sei denn, Sie haben sich dagegen entschieden, solche Informationen zu erhalten.
    Um Ihre Anfragen zu verwalten: Um Ihre Anfragen an uns zu bearbeiten und zu verwalten.
    Für Geschäftsübertragungen: Wir können Ihre Daten verwenden, um eine Fusion, Veräußerung, Umstrukturierung, Reorganisation, Auflösung oder
    einen anderen Verkauf oder eine Übertragung einiger oder aller unserer Vermögenswerte zu bewerten oder durchzuführen, sei es als laufender Betrieb oder als Teil eines Konkurses,
    einer Liquidation oder eines ähnlichen Verfahrens, bei dem persönliche Daten, die wir über unsere Dienstnutzer besitzen, zu den übertragenen Vermögenswerten gehören.
    Für andere Zwecke: Wir können Ihre Daten für andere Zwecke verwenden, wie z. B. zur Datenanalyse, zur Ermittlung von Nutzungstrends, zur
    Bestimmung der Wirksamkeit unserer Werbekampagnen und zur Bewertung und Verbesserung unseres Dienstes, unserer Produkte, Dienstleistungen, unseres Marketings und Ihrer Erfahrungen.
  </p>

  <h2 style="margin-top: 20px"> Wir können Ihre persönlichen Daten in den folgenden Situationen weitergeben:</h2>
  <p>
    Mit Dienstanbietern: Wir können Ihre persönlichen Daten mit Dienstanbietern teilen, um die Nutzung unseres Dienstes zu überwachen und zu analysieren und um Sie zu kontaktieren.
    Für Geschäftsübertragungen: Wir können Ihre personenbezogenen Daten in Verbindung mit oder während der Verhandlungen über eine Fusion, einen Verkauf von Unternehmensvermögen,
    eine Finanzierung oder eine Übernahme unseres gesamten oder eines Teils unseres Unternehmens durch ein anderes Unternehmen weitergeben oder übertragen.
    Mit verbundenen Unternehmen: Wir können Ihre Daten an unsere verbundenen Unternehmen weitergeben; in diesem Fall verpflichten wir diese verbundenen Unternehmen zur Einhaltung
    dieser Datenschutzrichtlinie. Zu den verbundenen Unternehmen gehören unsere Muttergesellschaft und alle anderen Tochtergesellschaften, Joint-Venture-Partner oder andere
    Unternehmen, die wir kontrollieren oder die unter gemeinsamer Kontrolle mit uns stehen.
    Mit Geschäftspartnern: Wir können Ihre Informationen an unsere Geschäftspartner weitergeben, um Ihnen bestimmte Produkte, Dienstleistungen oder Werbeaktionen anzubieten.
    Mit anderen Nutzern: Wenn Sie persönliche Informationen weitergeben oder auf andere Weise in den öffentlichen Bereichen mit anderen Nutzern interagieren, können diese
    Informationen von allen Nutzern eingesehen und öffentlich nach außen verbreitet werden.
    Mit Ihrem Einverständnis: Mit Ihrem Einverständnis können wir Ihre persönlichen Daten für jeden anderen Zweck offenlegen.
    Aufbewahrung Ihrer persönlichen Daten Das Unternehmen wird Ihre persönlichen Daten nur so lange aufbewahren, wie es für die in dieser Datenschutzrichtlinie genannten
    Zwecke erforderlich ist. Wir werden Ihre personenbezogenen Daten aufbewahren und verwenden, soweit dies erforderlich ist, um unseren rechtlichen Verpflichtungen
    nachzukommen (z. B. wenn wir Ihre Daten aufbewahren müssen, um geltende Gesetze einzuhalten), Streitigkeiten beizulegen und unsere rechtlichen Vereinbarungen und Richtlinien durchzusetzen.
    Das Unternehmen speichert auch Nutzungsdaten für interne Analysezwecke. Nutzungsdaten werden im Allgemeinen für einen kürzeren Zeitraum aufbewahrt, es sei denn, diese
    Daten werden verwendet, um die Sicherheit oder die Funktionalität unseres Dienstes zu verbessern, oder wir sind gesetzlich verpflichtet, diese Daten für längere Zeiträume aufzubewahren.
  </p>

  <h2 style="margin-top: 20px"> Weitergabe Ihrer persönlichen Daten</h2>
  <p>
    Ihre Informationen, einschließlich personenbezogener Daten, werden in den Betriebsbüros des Unternehmens und an allen anderen Orten verarbeitet, an denen die an der
    Verarbeitung beteiligten Parteien ansässig sind. Das bedeutet, dass diese Daten an Computer außerhalb Ihres Staates,
    Ihrer Provinz, Ihres Landes oder einer anderen staatlichen Gerichtsbarkeit, in der andere Datenschutzgesetze als in Ihrer Gerichtsbarkeit gelten, übertragen und dort gespeichert werden können.
    Mit Ihrer Zustimmung zu dieser Datenschutzrichtlinie und der anschließenden Übermittlung dieser Daten erklären Sie sich mit dieser Übertragung einverstanden.
    Das Unternehmen wird alle angemessenen Maßnahmen ergreifen, um sicherzustellen, dass Ihre Daten sicher und in Übereinstimmung mit dieser Datenschutzrichtlinie
    behandelt werden, und es wird keine Übermittlung Ihrer persönlichen Daten an eine Organisation oder ein Land stattfinden, wenn nicht angemessene Kontrollen vorhanden sind,
    die die Sicherheit Ihrer Daten und anderer persönlicher Informationen einschließen.
  </p>

  Datenschutz für Kinder
  Unser Service richtet sich nicht an Personen unter 13 Jahren. Wir sammeln nicht wissentlich personenbezogene Daten von Personen unter 13 Jahren. Wenn Sie ein Elternteil oder Erziehungsberechtigter sind und wissen, dass Ihr Kind uns personenbezogene Daten zur Verfügung gestellt hat, kontaktieren Sie uns bitte. Wenn wir feststellen, dass wir personenbezogene Daten von Personen unter 13 Jahren ohne Überprüfung der elterlichen Zustimmung erfasst haben, ergreifen wir Maßnahmen, um diese Daten von unseren Servern zu entfernen.

  Wenn wir uns auf die Zustimmung als Rechtsgrundlage für die Verarbeitung Ihrer Daten verlassen müssen und Ihr Land die Zustimmung eines Elternteils erfordert, können wir die Zustimmung Ihres Elternteils verlangen, bevor wir diese Daten erfassen und verwenden.
  <h2 style="margin-top: 20px">  Offenlegung Ihrer persönlichen Daten</h2>
  <p>
    Wenn das Unternehmen an einer Fusion, einer Übernahme oder einem Verkauf von Vermögenswerten beteiligt ist, können Ihre persönlichen Daten übertragen werden.
    Wir werden Sie informieren, bevor Ihre persönlichen Daten übertragen werden und einer anderen Datenschutzrichtlinie unterliegen.
    Strafverfolgung
    Unter bestimmten Umständen kann das Unternehmen verpflichtet sein, Ihre persönlichen Daten offenzulegen, wenn dies gesetzlich vorgeschrieben ist oder als Antwort auf berechtigte Anfragen
    von Behörden (z. B. einem Gericht oder einer Regierungsbehörde).

    Andere gesetzliche Anforderungen
    Das Unternehmen kann Ihre persönlichen Daten offenlegen, wenn es in gutem Glauben davon ausgeht, dass eine solche Maßnahme notwendig ist, um
    einer gesetzlichen Verpflichtung nachzukommen die Rechte oder das Eigentum des Unternehmens zu schützen und zu verteidigen
    Verhinderung oder Untersuchung von möglichem Fehlverhalten im Zusammenhang mit dem Dienst
    die persönliche Sicherheit der Nutzer des Dienstes oder der Öffentlichkeit zu schützen
    Schutz vor rechtlicher Haftung
    Sicherheit Ihrer persönlichen Daten
    Die Sicherheit Ihrer persönlichen Daten ist uns wichtig, aber denken Sie daran, dass keine Methode der Übertragung über das Internet
    oder der elektronischen Speicherung 100% sicher ist. Obwohl wir uns bemühen, Ihre persönlichen Daten mit kommerziell akzeptablen Mitteln zu schützen, können wir keine absolute Sicherheit garantieren.
  </p>

  <h2 style="margin-top: 20px">Links zu anderen Websites</h2>
  <p>
    Unser Service kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Wenn Sie auf einen Link einer dritten Partei klicken,
    werden Sie auf die Seite dieser dritten Partei weitergeleitet. Wir empfehlen Ihnen dringend, die Datenschutzrichtlinien jeder von Ihnen besuchten Website zu lesen.
    Wir haben keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt, die Datenschutzrichtlinien oder Praktiken von Websites oder Diensten Dritter.

    Änderungen an dieser Datenschutzrichtlinie
    Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über alle Änderungen informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.
    Wir informieren Sie per E-Mail und/oder durch einen auffälligen Hinweis in unserem Service, bevor die Änderung in Kraft tritt, und aktualisieren das Datum der letzten Aktualisierung" oben in dieser Datenschutzrichtlinie.
    Wir empfehlen Ihnen, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu überprüfen. Änderungen an dieser Datenschutzrichtlinie treten in Kraft, wenn sie auf dieser Seite veröffentlicht werden.
  </p>

  <h2 style="margin-top: 20px">Kontaktieren Sie uns</h2>
  <p>
    Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie uns kontaktieren:
    Per E-Mail: info@hotel-schepers.de
  </p>

</div>

<!--====== ABOUT PART END ======-->



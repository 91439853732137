<div class="container" style="margin-top: 10px; margin-bottom: 10px">
  <mat-accordion style="margin-top: 10px; margin-bottom: 10px" class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Email: {{config?.reservation_email}}
        </mat-panel-title>
        <mat-panel-description>
          <span *ngIf="config?.reservation_email">Email for Reservations</span>
          <span *ngIf="!config?.reservation_email">Email is not Configured</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
        <mat-form-field style="font-size: 16px;" appearance="fill">
          <mat-label>Email:</mat-label>
          <input matInput type="email" [value]="config?.reservation_email" [(ngModel)]="email">
        </mat-form-field>
        <button (click)="saveEmail()" style="float: right" mat-raised-button color="primary">Set Email</button>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="container" style="margin-top: 10px">
  <mat-list-item>
    <mat-icon>access_time</mat-icon><span> Access Token is valid for more: {{timeToAutoLogout | number}} Minutes</span>
  </mat-list-item>
  <div style="margin-top: 10px; margin-bottom: 10px">
    <button [disabled]="true" (click)="sendTestEmail()" mat-raised-button color="primary">Send test Email for Offers</button>
  </div>
  <div style="margin-top: 10px; margin-bottom: 10px">
    <button mat-raised-button color="primary" routerLink="/adminpanel/testing">Go To testing Page</button>
  </div>
  <div style="margin-top: 10px; margin-bottom: 10px">
    <button mat-raised-button color="primary" routerLink="/adminpanel/layoutsettings">Go to Layout Settings</button>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import {NewroomComponent} from '../newroom/newroom.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-allrooms',
  templateUrl: './allrooms.component.html',
  styleUrls: ['./allrooms.component.css']
})
export class AllroomsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialogAddRoom() {
    const dialogRef = this.dialog.open(NewroomComponent, {
      width: '600px',
      data: ''
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Room Saved Success');
      }
    });
  }
}

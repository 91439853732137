<mat-card class="example-card" style="padding: 20px">
  <mat-card-header>
    <div class="container">
      <div class="row">
        <div class="col" style="float: left">
          <mat-card-title>
            <mat-icon style="float: left; margin-top: 25px;margin-right: 25px; font-size: 32px; color: #bead8e; cursor: pointer">airline_seat_individual_suite</mat-icon>
            <span style="float: left; margin-top: 25px">{{offer?.title}}</span>
            <span style="float: right; margin-top: 25px">Type: {{offer?.offerType}}</span>
          </mat-card-title>
        </div>
        <!-- <div class="col" style="float: right">
          <mat-card-subtitle>Title: {{ offer?.title}}</mat-card-subtitle>
        </div> -->
      </div>
    </div>
  </mat-card-header>
  <hr style="
      margin-bottom: 5px;
      margin-top: 2px">
  <div class="container">
    <div class="row">
      <div class="col">
        <mat-card-content>
          <p [hidden]="true" class="room-content"><b matTooltip={{offer?.content}} >Content:<br></b> {{offer?.content}}</p>
          <p><b>Offer title:</b> {{offer?.title}}</p>
          <p><b>Offer Type:</b> {{offer?.offerType}}</p>
          <p><b>Price:</b> {{offer?.price}}</p>
          <p><b>created:</b> {{offer?.createdDate | date:'d-MM-y'}}</p>
          <p><b>changed:</b> {{offer?.editedDate | date:'d-MM-y'}}</p>
          <p><b>Public:</b> {{offer?.public}}</p>
        </mat-card-content>
      </div>
      <div class="col">
        <img alt="Picture of Offer Object" mat-card-image [src]=offer?.photoUrl1
             style="
             overflow: hidden;
             width: 300px;
             height: 150px;
             margin-top: 10px;
             background-size: cover;
             background-position: center;">

        <div class="row">
          <div class="col">
            <ul>
              <li *ngIf="offer?.extras1"><i class="fal fa-check"></i>WiFi</li>
              <li *ngIf="offer?.extras2"><i class="fal fa-check"></i>Frühstuck</li>
              <li *ngIf="offer?.extras3"><i class="fal fa-check"></i>Dusche</li>
              <li *ngIf="offer?.extras4"><i class="fal fa-check"></i>Badewanne</li>
              <li *ngIf="offer?.extras5"><i class="fal fa-check"></i>Balkon</li>
            </ul>
          </div>

          <div class="col">
            <ul>
              <li *ngIf="offer?.extras6"><i class="fal fa-check"></i>Doppel</li>
              <li *ngIf="offer?.extras7"><i class="fal fa-check"></i>Schlafsofa</li>
              <li *ngIf="offer?.extras8"><i class="fal fa-check"></i>Schreibtisch</li>
              <li *ngIf="offer?.extras9"><i class="fal fa-check"></i>Kaffem.</li>
              <li *ngIf="offer?.extras10"><i class="fal fa-check"></i>Spare</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>

  <mat-card-actions>
    <button (click)="updateRoom()" mat-stroked-button color="primary">Edit</button>
    <button (click)="deleteRoom()" mat-stroked-button color="warn">Delete</button>
    <app-content-status-old [isOnline]="offer.public"></app-content-status-old>
  </mat-card-actions>

</mat-card>

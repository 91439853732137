<app-header4></app-header4>
<!--====== BREADCRUMB PART START ======-->
<section class="breadcrumb-area" style="background-image: url('{{ place?.photoUrl1 }}');">
    <div class="container">
      <!-- ///////////////////////// German Start ///////////////////////// -->
        <div *ngIf="currentLang == 'de'" class="breadcrumb-text">
            <span>{{place?.placeType}}</span>
            <h2 class="page-title">{{place?.title}}</h2>

            <ul class="breadcrumb-nav">
                <li><a routerLink='/'>Home</a></li>
                <li class="active">Sehenswürdigkeiten</li>
            </ul>
        </div>
      <!-- ///////////////////////// Englsih Start ///////////////////////// -->
      <div *ngIf="currentLang == 'en'" class="breadcrumb-text">
        <span>{{place?.placeTypeEN}}</span>
        <h2 class="page-title">{{place?.titleEN}}</h2>

        <ul class="breadcrumb-nav">
          <li><a routerLink='/'>Home</a></li>
          <li class="active">Sights</li>
        </ul>
      </div>

    </div>
</section>
<!--====== BREADCRUMB PART END ======-->
<section >
  <div class="container" *ngIf="place">
    <!-- Places Maps -->

  </div>
</section>
<!--====== PLACES CONTENT START ======-->
<section class="places-wrapper pt-50 pb-50">
  <!-- //////////////////////////////////////////////// German Start //////////////////////////////////// -->
    <div *ngIf="currentLang == 'de'" class="container">
        <div class="places-details">

            <div class="title-wrap mb-50 d-flex align-items-center justify-content-between">
                <div class="title">
                    <span class="place-cat">Ort</span>
                    <h2>{{place?.title}}</h2>
                </div>
                <!-- <div class="link">
                    <a href="#">direction</a>
                </div> -->
            </div>

            <p class="mb-25">{{place?.content}}</p>
            <div class="gallery mt-30 mb-60">
                <div class="row">
                    <div class="col-sm-6 mt-30">
                        <img [src]="place?.photoUrl2" alt="Img">
                    </div>
                    <div class="col-sm-6 mt-30">
                        <img [src]="place?.photoUrl3" alt="Img">
                    </div>
                </div>
            </div>
            <p class="mb-25"> </p>
        </div>
      <span *ngIf="place?.website != ''" class="main-btn btn-filled mt-40" (click)="openWesiteInTab()" >Zur Website</span>
    </div>
    <!-- //////////////////////////////////////////////// English Dtart //////////////////////////////////// -->
  <div *ngIf="currentLang == 'en'" class="container">
    <div class="places-details">

      <div class="title-wrap mb-50 d-flex align-items-center justify-content-between">
        <div class="title">
          <span class="place-cat">Place</span>
          <h2>{{place?.titleEN}}</h2>
        </div>
        <!-- <div class="link">
            <a href="#">direction</a>
        </div> -->
      </div>

      <p class="mb-25">{{place?.contentEN}}</p>
      <div class="gallery mt-30 mb-60">
        <div class="row">
          <div class="col-sm-6 mt-30">
            <img [src]="place?.photoUrl2" alt="Img">
          </div>
          <div class="col-sm-6 mt-30">
            <img [src]="place?.photoUrl3" alt="Img">
          </div>
        </div>
      </div>
      <p class="mb-25"> </p>
    </div>
    <span *ngIf="place?.website != ''" class="main-btn btn-filled mt-40" (click)="openWesiteInTab()" >To The Website</span>
  </div>

</section>
<!--====== PLACES CONTENT END ======-->
<app-footer2></app-footer2>

<!--====== Back to Top ======-->
<a class="back-to-top"
   id="backToTop"
   *ngIf="isShow"
   (click)="gotoTop()"
   (window:scroll)="ScrollTop()">
    <i class="fal fa-angle-double-up"></i>
</a>
<!--====== FOOTER START ======-->
<footer class="footer-two">
    <div class="footer-widget-area pt-100 pb-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6 order-1">
                    <!-- Site Info Widget -->
                    <div class="widget site-info-widget mb-50">
                        <div class="footer-logo mb-50">
                            <img src="../../../../../assets/img/logo/logo-white.png" alt="Hotel Schepers Logo" title="Picture show's Logo of Hotel restaurant Schepers">
                        </div>
                        <p>{{'footer.greetings' | translate}}</p>
                        <div class="social-links mt-40">
                            <a href="https://de-de.facebook.com/hotel.schepers/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="https://www.instagram.com/hotel_restaurant_schepers/" target="_blank"><i class="fab fa-instagram"></i></a>
                            <a href="https://www.youtube.com/watch?v=z9nT_a8HGI8" target="_blank"><i class="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 order-3 order-lg-2">
                    <!-- Nav Widget -->
                    <div class="widget nav-widget mb-50">
                        <div>
                            <!-- <h4 class="widget-title">Services</h4> -->
                            <ul style="cursor: pointer">
                              <li><a routerLink='/offers-seminars'>{{'footer.seminars' | translate}}</a></li>
                              <li><a routerLink='/places' >{{'footer.tourist-attractions' | translate}}</a></li>
                              <li><a routerLink='/about' >{{'footer.about-us' | translate}}</a></li>
                              <li><a routerLink='/gallery' >{{'footer.gallery' | translate}}</a></li>
                              <li><a routerLink='/restaurant' >{{'footer.restaurant' | translate}}</a></li>
                              <li><a routerLink='/offers-festivals' >{{'footer.festivals' | translate}}</a></li>
                              <li><a routerLink='/offers' >{{'footer.Arrangements' | translate}}</a></li>
                              <li><a routerLink='/wellness' >{{'footer.wellness' | translate}}</a></li>
                          </ul>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3 col-sm-6 order-2 order-lg-3">
                  <!-- Contact Widget -->
                    <div class="widget contact-widget mb-50">
                        <!-- <h4 class="widget-title">Kontaktieren sie uns</h4> -->
                        <div class="contact-lists">
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <div class="desc">
                                  <h6 class="title">{{'footer.phone' | translate}}</h6>
                                  +49 (0) 2565 933 20
                                </div>
                            </div>
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-message"></i>
                                </div>
                                <div class="desc">
                                    <h6 class="title">{{'footer.mail' | translate}}</h6>
                                    <a>info@hotel-schepers.de</a>
                                </div>
                            </div>
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-location-pin"></i>
                                </div>
                                <div class="desc">
                                    <h6 class="title">{{'footer.address' | translate}}</h6>
                                  Schepers Hotel-Restaurant
                                  Ahauser Str. 1
                                  D - 48599 Gronau-Epe
                                </div>
                            </div>
                          <div class="contact-box">
                            <mat-icon style="color: #bead8e; transform: scale(1.5); margin-left: 10px">restaurant</mat-icon>
                            <div class="desc">
                              <h6 class="title">{{'restaurant.opening-hours' | translate}}</h6>
                              <p>{{'restaurant.monday' | translate}}: <span *ngIf="hotelConfig?.openinghours?.mondayStart !== ''" >{{hotelConfig?.openinghours?.mondayStart}} : {{hotelConfig?.openinghours?.mondayEnd}}</span> <span *ngIf="hotelConfig?.openinghours?.mondayStart == ''">{{'restaurant.closed' | translate}}</span></p>
                              <p>{{'restaurant.tuesday' | translate}}: <span *ngIf="hotelConfig?.openinghours?.tuesdayStart !== ''" >{{hotelConfig?.openinghours?.tuesdayStart}} : {{hotelConfig?.openinghours?.tuesdayEnd}}</span> <span *ngIf="hotelConfig?.openinghours?.tuesdayStart == ''">{{'restaurant.closed' | translate}}</span></p>
                              <p>{{'restaurant.wednesday' | translate}}: <span *ngIf="hotelConfig?.openinghours?.wednesdayStart !== ''"  >{{hotelConfig?.openinghours?.wednesdayStart}} : {{hotelConfig?.openinghours?.wednesdayEnd}} </span> <span *ngIf="hotelConfig?.openinghours?.wednesdayStart == ''">{{'restaurant.closed' | translate}}</span></p>
                              <p>{{'restaurant.thursday' | translate}}: <span *ngIf="hotelConfig?.openinghours?.thursdayStart !== ''"  >{{hotelConfig?.openinghours?.thursdayStart}} : {{hotelConfig?.openinghours?.thursdayEnd}} </span> <span *ngIf="hotelConfig?.openinghours?.thursdayStart == ''">{{'restaurant.closed' | translate}}</span></p>
                              <p>{{'restaurant.friday' | translate}}: <span *ngIf="hotelConfig?.openinghours?.fridayStart !== ''"  >{{hotelConfig?.openinghours?.fridayStart}} : {{hotelConfig?.openinghours?.fridayEnd}} </span><span *ngIf="hotelConfig?.openinghours?.fridayStart == ''">{{'restaurant.closed' | translate}}</span></p>
                              <p>{{'restaurant.saturday' | translate}}: <span *ngIf="hotelConfig?.openinghours?.saturdayStart !== ''" >{{hotelConfig?.openinghours?.saturdayStart}} : {{hotelConfig?.openinghours?.saturdayEnd}} </span> <span *ngIf="hotelConfig?.openinghours?.saturdayStart == ''">{{'restaurant.closed' | translate}}</span></p>
                              <p>{{'restaurant.sunday' | translate}}: <span *ngIf="hotelConfig?.openinghours?.sundayStart !== ''" >{{hotelConfig?.openinghours?.sundayStart}} : {{hotelConfig?.openinghours?.sundayEnd}} </span> <span *ngIf="hotelConfig?.openinghours?.sundayStart == ''">{{'restaurant.closed' | translate}}</span></p>
                            </div>
                          </div>
                        </div>
                    </div>
              </div>
            </div>
        </div>
    </div>
    <div class="copyright-area pt-30 pb-30">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-5 order-2 order-md-1">
                    <p (click)="openDeveloperSiteInTap()" style="cursor: pointer" class="copyright-text copyright-two">{{'footer.copyright' | translate}}<a>Linuxpoint.net</a> - 2020</p>
                </div>
                <div class="col-lg-6 col-md-7 order-1 order-md-2">
                    <div class="footer-menu text-center text-md-right">
                        <ul>
                          <li><a (click)="openImpressum()" style="cursor: pointer; margin-right: 5px" >Impressum</a></li>
                          <li><a style="cursor: pointer; margin-right: 5px" (click)="openTermsOfUseDialog()">{{'footer.terms' | translate}}</a></li>
                          <li><a style="cursor: pointer; margin-right: 20px" (click)="openPrivacyDialog()" target="_blank">{{'footer.cookies' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--====== FOOTER END ======-->

import { Component, OnInit } from '@angular/core';
import {RoomService} from '../../../../services/room.service';
import {Room} from '../../../../models/room.model';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class RoomsComponent implements OnInit {
  rooms: Room;

  constructor(private roomService: RoomService) { }

  ngOnInit(): void {
    this.roomService.getRooms().subscribe(res => {
      this.rooms = res;
    });
  }

}

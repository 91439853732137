import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {Menu} from '../../../../models/menu.model';
import {RestaurantService} from '../../../../services/restaurant.service';

@Component({
  selector: 'app-newmenu',
  templateUrl: './newmenu.component.html',
  styleUrls: ['./newmenu.component.css']
})
export class NewmenuComponent implements OnInit {
  uploadEvent: Event;
  uploadEvent1: Event;
  menuForm: UntypedFormGroup;
  menu: Menu;
  currentDate: any;
  hasMenu: boolean;
  hasPicture: any;

  constructor(private authService: AuthService,
              private restaurantService: RestaurantService,
              private formBuilder: UntypedFormBuilder) {
    this.menuForm = this.formBuilder.group({
      headerDescription: ['', Validators.required],
      content: ['', Validators.required],
      title: ['', Validators.required],
      public: [false, '']
    });
  }

  ngOnInit(): void {
  }

  addPost() {
    this.menu = new Menu(this.menuForm.value);
    this.menu.menuType = 'Karte';
    this.restaurantService.addMenu(this.uploadEvent, this.uploadEvent1, this.menu);
  }

  pictureToUpload($event: Event) {
    this.uploadEvent = $event;
    this.hasMenu = true;
  }

  pictureToUpload2($event: Event) {
    this.uploadEvent1 = $event;
    this.hasPicture = true;
  }
}

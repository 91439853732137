import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TermsOfUseDialogComponent} from '../../../shared/terms-of-use-dialog/terms-of-use-dialog.component';
import {LanguageService} from '../../../../services/language.service';
import {Router} from '@angular/router';
import {HotelConfig} from '../../../../models/hotel-config/hotel-config';
import {HotelconfigService} from '../../../../services/hotelconfig.service';
import {ImpressumComponent} from '../../pages/impressum/impressum.component';

@Component({
  selector: 'app-footer2',
  templateUrl: './footer2.component.html',
  styleUrls: ['./footer2.component.css']
})
export class Footer2Component implements OnInit, AfterViewInit{
  isShow: boolean;
  topPosToStartShowing = 300;
  windowHeight: any;
  concurrentlang: any;
  hotelConfig: HotelConfig;
  constructor(public dialog: MatDialog,
              private languageService: LanguageService,
              private router: Router,
              private hotelConfigService: HotelconfigService) {
    this.hotelConfigService.getConfig().subscribe(config => {
      if (config) {
        this.hotelConfig = config;
        console.log(this.hotelConfig.openinghours.thursdayStart);
      }
    });
    this.languageService.getCurrentLanguage().subscribe(lang => {
      this.concurrentlang = lang;
    });
  }

  ngAfterViewInit(): void {
    const body = document.body;
    const html = document.documentElement;
    this.windowHeight = Math.max( body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight );
    }



  ngOnInit(): void {

  }

  ScrollTop() {
    const navbar = document.getElementById('backToTop');
    if (document.body.scrollTop >= 300 || document.documentElement.scrollTop > 300) {
      navbar.classList.add('active');
    } else {
      navbar.classList.remove('active');
    }
  }


  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // this.isShow = scrollPosition >= this.topPosToStartShowing;
    if (scrollPosition >= this.topPosToStartShowing && scrollPosition <= this.windowHeight + 1100) {
      this.isShow = true;
      // console.log(this.topPosToStartShowing, scrollPosition , this.windowHeight);
    } else {
      this.isShow = false;
    }

  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  openTermsOfUseDialog() {
    const dialogRef = this.dialog.open(TermsOfUseDialogComponent, {
      width: '600px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDeveloperSiteInTap() {
    window.open('https://flucht.me/home', '_blank');
  }

  openPrivacyDialog() {
    if (this.concurrentlang === 'de') {
      this.router.navigate(['privatsphaere']);
    } else {
      this.router.navigate(['private-policy']);
    }
  }

  openImpressum() {
    this.dialog.open(ImpressumComponent);
  }

}

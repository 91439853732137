import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {JobService} from '../../../../services/job.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {JobModel} from '../../../../models/job.model';
import {JobEnum} from '../../../../enums/job.enum';
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.css']
})
export class NewJobComponent implements OnInit {
  uploadEvent: Event;
  jobForm: UntypedFormGroup;
  job: JobModel;
  currentDate: any;
  hasPhoto1: boolean;
  jobTypes: JobEnum;

  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  bulletPointCtrl = new UntypedFormControl();
  filteredBulletPoints: Observable<string[]>;
  bulletPoints: string[] = [];
  allBulletPoints: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////// Bullet Points2 //////////////////////////////////////////////////////////
  visible2 = true;
  selectable2 = true;
  removable2 = true;
  separatorKeysCodes2: number[] = [ENTER, COMMA];
  bulletPointCtrl2 = new UntypedFormControl();
  filteredBulletPoints2: Observable<string[]>;
  bulletPoints2: string[] = [];
  allBulletPoints2: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];
  @ViewChild('fruitInput2') fruitInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2') matAutocomplete2: MatAutocomplete;
  ////////////////////////////////////////////////////// Bullet Points2 //////////////////////////////////////////////////////////
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'},
  ];
  constructor(private jobService: JobService) {
    this.jobForm = new UntypedFormGroup({
      content: new UntypedFormControl(null, {validators: [Validators.required]}),
      title: new UntypedFormControl(null, {validators: [Validators.required, Validators.maxLength(60)]}),
      public: new UntypedFormControl(false, {validators: [Validators.required]}),
      jobType: new UntypedFormControl('Vollzeit', {validators: [Validators.required]})
    });
  }

  ngOnInit(): void {
  }

  addJob() {
    this.job = new JobModel(this.jobForm.value);
    console.log(this.job);
    this.job.bulletPoints = this.bulletPoints;
    this.job.bulletPoints2 = this.bulletPoints2;
    this.jobService.addJob(this.uploadEvent, this.job);
  }

  pictureToUpload($event: Event) {
    this.uploadEvent = $event;
    this.hasPhoto1 = true;
  }

  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.bulletPoints.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.bulletPointCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.bulletPoints.indexOf(fruit);
    if (index >= 0) {
      this.bulletPoints.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.bulletPointCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBulletPoints.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points //////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////// Bullet Points 2//////////////////////////////////////////////////////////
  add2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.bulletPoints2.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.bulletPointCtrl2.setValue(null);
  }

  remove2(fruit: string): void {
    const index = this.bulletPoints2.indexOf(fruit);
    if (index >= 0) {
      this.bulletPoints2.splice(index, 1);
    }
  }

  selected2(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints2.push(event.option.viewValue);
    this.fruitInput2.nativeElement.value = '';
    this.bulletPointCtrl2.setValue(null);
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBulletPoints2.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points 2//////////////////////////////////////////////////////////

}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {JobService} from '../../../../../services/job.service';
import {JobModel} from '../../../../../models/job.model';
import {LanguageService} from '../../../../../services/language.service';

@Component({
  selector: 'app-jobs-details',
  templateUrl: './jobs-details.component.html',
  styleUrls: ['./jobs-details.component.css']
})
export class JobsDetailsComponent implements OnInit {
  job: JobModel;
  currentLang: string;
  constructor(private router: ActivatedRoute,
              private jobService: JobService,
              private langService: LanguageService) {
    this.langService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
    this.router.params.subscribe(jobId => {
      console.log(jobId);
      this.jobService.getPostById(jobId.id).subscribe(job => {
        this.job = job;
      });
    });
  }

  ngOnInit(): void {
    console.log(this.job);
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Place} from '../../../../models/place.model';
import {MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {PlaceService} from '../../../../services/place.service';
import {AdminUpdateplaceDialogComponent} from '../admin-updateplace-dialog/admin-updateplace-dialog.component';

@Component({
  selector: 'app-admin-singleplace',
  templateUrl: './admin-singleplace.component.html',
  styleUrls: ['./admin-singleplace.component.css']
})
export class AdminSingleplaceComponent implements OnInit {
  @Input() place: Place;
  constructor(private placeService: PlaceService,
              public dialog: MatDialog) { }

  ngOnInit(): void {}

  deleteplace() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.placeService.deletePlace(this.place.placeId);
      }
    });
  }

  updateplace() {
    const dialogRef = this.dialog.open(AdminUpdateplaceDialogComponent, {
      width: '600px',
      data: this.place
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

}

import {Component, OnInit, AfterViewInit} from '@angular/core';
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, AfterViewInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
  }
}

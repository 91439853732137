import { Component, OnInit} from '@angular/core';
import {RoomService} from '../../../../../../services/room.service';
import {Room} from '../../../../../../models/room.model';
import {ViewportScroller} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {SEOServiceService} from '../../../../../../services/seoservice.service';

@Component({
  selector: 'app-view-rooms',
  templateUrl: './view-rooms.component.html',
  styleUrls: ['./view-rooms.component.css']
})
export class ViewRoomsComponent implements OnInit  {
  rooms: Room;
  initPassed = false;
  constructor(private roomService: RoomService,
              private route: ActivatedRoute,
              private viewController: ViewportScroller,
              private router: Router,
              private seoService: SEOServiceService) {
    // this.seoService.updateMetadata(this.router);
    this.roomService.getPublicRooms().subscribe(res => {
      this.rooms = res;
    });
    this.route.fragment.subscribe(fragment => {
      this.delay(300).then(() => this.viewController.scrollToAnchor(fragment));
    });
  }

  ngOnInit(): void {
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Room} from '../../../../models/room.model';
import {RoomService} from '../../../../services/room.service';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {UpdateroomComponent} from '../updateroom/updateroom.component';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class RoomComponent implements OnInit {
  @Input() room: Room;
  constructor(private roomService: RoomService,
              public dialog: MatDialog) { }

  ngOnInit(): void {}

  deleteRoom() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.roomService.deleteRoom(this.room.roomId);
      }
    });
  }

  updateRoom() {
    const dialogRef = this.dialog.open(UpdateroomComponent, {
      width: '600px',
      data: this.room
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})

// https://developers.google.com/maps/documentation/javascript/adding-a-google-map#maps_add_map-html
// https://timdeschryver.dev/blog/google-maps-as-an-angular-component#map-marker-output-properties
// https://stackblitz.com/github/googlemaps/js-samples/tree/sample-directions-waypoints?file=index.html


export class TestingComponent implements OnInit {
  directionsService2 = new google.maps.DirectionsService();
  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    maxZoom: 15,
    disableDoubleClickZoom: false,
  };
  markers: any[] = [];
  directions: google.maps.DirectionsResult;
  routes: google.maps.DirectionsRoute;

  constructor() {}

  ngOnInit(): void {
    this.calculateAndDisplayRoute(this.directionsService2);
    this.center = {
      lat: 49.00,
      lng: 9.00,
    };
    // this.markers.push({
    //   position: {
    //     lat: 49,
    //     lng: 9,
    //   },
    //   label: {
    //     color: 'blue',
    //     text: '',
    //   },
    //   title: '',
    //   options: { animation: google.maps.Animation.DROP},
    // });
 }

  calculateAndDisplayRoute(directionsService2: google.maps.DirectionsService) {
    directionsService2.route({
        origin: '50 ,50',
        destination: '51, 51',
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        this.routes = response.routes[0];
        this.directions = response;
      })
      .catch((e) => console.log('Could not load route'));
  }
}

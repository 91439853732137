import { Component, OnInit } from '@angular/core';
import {Config} from '../../../models/config.model';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {EmailService} from '../../../services/email.service';
import {ReservationModel} from '../../../models/reservation.model';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  config: Config;
  email: any;
  timeToAutoLogout: number;

  constructor(private configService: ConfigService,
              private authService: AuthService, private emailService: EmailService) {
    this.configService.getConfig().subscribe(config => {
      if (config) {
        this.config = config;
      }
    });
  }

  ngOnInit(): void {
    this.timeToAutoLogout = this.authService.getTimeToAutoLogout() / 1000 / 60;
  }

  saveEmail() {
  this.configService.updateConfigReservationEmail(this.email);
  }

  sendTestEmail() {
    const reservationData = new ReservationModel({
      customerName: 'Lukas',
      customerSureName: 'Flucht',
      customerEmail: 'lukasfluchtfpv@gmail.com',
      amountCustomers: '1',
      customerTelephone: '01752507994',
      type: 'Massage',
      title: 'Hot Stone',
      dateIn: new Date().toString(),
      dateOut: new Date().toString(),
      replied: false,
      confirmed: false,
      confirmedByUserId: '',
      reservationId: '',
      created: new Date().toString()

    });
    this.emailService.sendTestEmail(reservationData);
  }
}

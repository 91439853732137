export class Menu{
  menuId: string;
  CreatorId: string;
  menuType: string;
  menuTypeEN: string;
  public: boolean;
  headerDescription: string;
  headerDescriptionEN: string;
  title: string;
  titleEN: string;
  content: string;
  contentEN: string;
  views: string;
  likes: string;
  createdDate: any;
  editedDate: any;
  menuUrl: string;
  photoUrl: string;

  constructor(menuData: any){
    if (menuData.menuId != null) {
      this.menuId = menuData.menuId;
    } else {
      this.menuId = '';
    }
    if (menuData.CreatorId != null) {
      this.CreatorId = menuData.CreatorId;
    } else {
      this.CreatorId = '';
    }
    if (menuData.menuType != null) {
      this.menuType = menuData.menuType;
    } else {
      this.menuType = '';
    }
    if (menuData.public != null) {
      this.public = menuData.public;
    } else {
      this.public = false;
    }
    if (menuData.headerDescription != null) {
      this.headerDescription = menuData.headerDescription;
    } else {
      this.headerDescription = '';
    }
    if (menuData.title != null) {
      this.title = menuData.title;
    } else {
      this.title = '';
    }
    if (menuData.content != null) {
      this.content = menuData.content;
    } else {
      this.content = '';
    }
    if (menuData.views != null) {
      this.views = menuData.views;
    } else {
      this.views = '0';
    }
    if (menuData.likes) {
      this.likes  = menuData.likes;
    } else {
      this.likes  = '';
    }
    if (menuData.createdDate != null) {
      this.createdDate = menuData.createdDate;
    } else {
      this.createdDate = Date.now();
    }
    if (menuData.editedDate != null) {
      this.editedDate = menuData.editedDate;
    } else {
      this.editedDate = Date.now();
    }
    if (menuData.menuUrl != null) {
      this.menuUrl = menuData.menuUrl;
    } else {
      this.menuUrl = '';
    }
    if (menuData.photoUrl != null) {
      this.photoUrl = menuData.photoUrl;
    } else {
      this.photoUrl = '';
    }
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EmailService} from '../../../../services/email.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReservationModel} from '../../../../models/reservation.model';
import {ReservationService} from '../../../../services/reservation.service';

export interface DialogData {
  offerTitle: string;
  offerType: string;
  onlyDateTime: boolean;
}

@Component({
  selector: 'app-offer-booking-dialog',
  templateUrl: './offer-booking-dialog.component.html',
  styleUrls: ['./offer-booking-dialog.component.css']
})

export class OfferBookingDialogComponent implements OnInit {
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  minDate: any;

  constructor(private formBuilder: UntypedFormBuilder,
              private  emailService: EmailService,
              private reservationService: ReservationService,
              public dialogRef: MatDialogRef<OfferBookingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.minDate = Date.now();
  }

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      sureName: ['', Validators.required],
      amountCustomers: ['']
    });
    this.secondFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      dateIn: ['', Validators.required],
      dateOut: ['']
    });
  }

  reservation() {
    const reservationData = new ReservationModel({
      customerName: this.firstFormGroup.value.name,
      customerSureName: this.firstFormGroup.value.sureName,
      customerEmail: this.secondFormGroup.value.email,
      amountCustomers: this.firstFormGroup.value.amountCustomers,
      customerTelephone: this.secondFormGroup.value.phone,
      type: this.data.offerType,
      title: this.data.offerTitle,
      dateIn: this.thirdFormGroup.value.dateIn,
      dateOut: this.thirdFormGroup.value.dateOut,
      replied: false,
      confirmed: false,
      confirmedByUserId: '',
      reservationId: '',

    });
    this.reservationService.addReservation(reservationData);
  }
}

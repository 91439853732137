import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MustMatchService} from '../../../services/must-match.service';
import {AppUser} from '../../../models/AppUser.model';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  register: UntypedFormGroup;
  user: AppUser;
  @Output() registered: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.register = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.minLength]],
      username: [''],
      usersurename: [''],
      userzipcode: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
      // recaptcha: ['', Validators.required]
    }, {
      validator: MustMatchService('password', 'confirmPassword')
    });
  }

  onSubmit() {
    this.authService.register(this.register.value.email,
                    this.register.value.password,
                    this.register.value.username,
                    this.register.value.usersurename,
                    this.register.value.userzipcode).then( res => {
                      if (res){
                        this.registered.emit(true);
                      }
                  });
  }

}


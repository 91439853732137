import { Component, OnInit } from '@angular/core';
import {NewmenuComponent} from '../newmenu/newmenu.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-allmenu',
  templateUrl: './allmenu.component.html',
  styleUrls: ['./allmenu.component.css']
})
export class AllmenuComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialogAddMenu() {
    const dialogRef = this.dialog.open(NewmenuComponent, {
      width: '600px',
      data: {}
    });

    dialogRef.afterClosed().subscribe();
  }

}

<div class="video-container">
  <video #video class="video" title="Video of Hotel Schepers uutside"
         poster="../../../../../assets/img/home/HotelBackView.jpg"
         playsinline
         autoplay
         muted
         loop
         style="width: 100%; min-height: 310px; max-height: 800px"
         (change)="video.play()"
         (canplay)="video.play()"
         (loadedmetadata)="video.muted = true">
    <source type="video/mp4"
            src="../../../../../assets/vid/schepers-image-vid-1.m4v"
            title="Video Shows the Hotel and the River Dinkel in Epe Gronau 48599">
  </video>
</div>

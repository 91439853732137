import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import { filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Route, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SEOServiceService {
  private updateSuccess = false;
  private route: Route;
  private sanitizedCurrentUrl: string;
  constructor(private title: Title,
              private meta: Meta,
              private activatedRoute: ActivatedRoute) { }

  updateMetadata(router: Router) {
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this.updateSuccess = true;
        this.updateTitle(event.title);
       // this.updateOgUrl(event.ogUrl);
        // Updating Description tag dynamically with title
        this.updateDescription(event.title + event.description);
      });
    if (false) {
      this.sanitizedCurrentUrl = router.routerState.snapshot.url.replace('/', '');
      this.route = router.config.filter(config => config.path === this.sanitizedCurrentUrl)[0];
      this.updateTitle(this.route.data.title);
      this.updateDescription(this.route.data.title + this.route.data.description);
      // this.updateOgUrl(this.route.data.ogUrl); Not working
    }
  }

  private updateTitle(title: string) {
    this.title.setTitle(title);
  }

  private updateOgUrl(url: string) {
    if (this.meta.getTag('og:url') != null) {
      this.meta.updateTag({ name: 'og:url', content: url });
    } else {
      this.meta.addTag({ name: 'og:url', content: url });
    }
  }

  private updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

}

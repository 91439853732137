export class Offer{
  offerId: string;
  CreatorId: string;
  offerType: string;
  offerTypeEN: string;
  price: string;
  public: boolean;
  title: string;
  titleEN: string;
  content: string;
  contentEN: string;
  views: string;
  likes: string;
  altText: string;
  thumbnailUrl: string;
  createdDate: any;
  editedDate: any;
  photoUrl1: string;
  photoUrl2: string;
  photoUrl3: string;
  photoUrl4: string;
  photoUrl5: string;
  photoUrl6: string;
  photoUrl7: string;
  photoUrl8: string;
  photoUrl9: string;
  photoUrl10: string;
  extras1: boolean;
  extras2: boolean;
  extras3: boolean;
  extras4: boolean;
  extras5: boolean;
  extras6: boolean;
  extras7: boolean;
  extras8: boolean;
  extras9: boolean;
  extras10: boolean;
  bulletPoints: string[];
  bulletPointsEN: string[];
  checkList: string[];
  checkListEN: string[];

  constructor(offerData: any){
    if (offerData.postId != null) {
      this.offerId = offerData.postId;
    } else {
      this.offerId = '';
    }
    if (offerData.CreatorId != null) {
      this.CreatorId = offerData.CreatorId;
    } else {
      this.CreatorId = '';
    }
    if (offerData.offerType != null) {
      this.offerType = offerData.offerType;
    } else {
      this.offerType = '';
    }
    if (offerData.offerTypeEN != null) {
      this.offerTypeEN = offerData.offerTypeEN;
    } else {
      this.offerTypeEN = '';
    }
    if (offerData.price != null) {
      this.price = offerData.price;
    } else {
      this.price = '';
    }
    if (offerData.public != null) {
      this.public = offerData.public;
    } else {
      this.public = false;
    }
    if (offerData.title != null) {
      this.title = offerData.title;
    } else {
      this.title = '';
    }
    if (offerData.titleEN != null) {
      this.titleEN = offerData.titleEN;
    } else {
      this.titleEN = '';
    }
    if (offerData.content != null) {
      this.content = offerData.content;
    } else {
      this.content = '';
    }
    if (offerData.contentEN != null) {
      this.contentEN = offerData.contentEN;
    } else {
      this.contentEN = '';
    }
    if (offerData.views != null) {
      this.views = offerData.views;
    } else {
      this.views = '';
    }
    if (offerData.altText != null) {
      this.altText = offerData.altText;
    } else {
      this.altText = '';
    }
    if (offerData.thumbnailUrl != null) {
      this.thumbnailUrl = offerData.thumbnailUrl;
    } else {
      this.thumbnailUrl = '';
    }
    if (offerData.likes) {
      this.likes  = offerData.likes;
    } else {
      this.likes  = '';
    }
    if (offerData.createdDate != null) {
      this.createdDate = offerData.createdDate;
    } else {
      this.createdDate = Date.now();
    }
    if (offerData.editedDate != null) {
      this.editedDate = offerData.editedDate;
    } else {
      this.editedDate = Date.now();
    }
    if (offerData.photoUrl1 != null) {
      this.photoUrl1 = offerData.photoUrl1;
    } else {
      this.photoUrl1 = '';
    }
    if (offerData.photoUrl2 != null) {
      this.photoUrl2 = offerData.photoUrl2;
    } else {
      this.photoUrl2 = '';
    }
    if (offerData.photoUrl3 != null) {
      this.photoUrl3 = offerData.photoUrl3;
    } else {
      this.photoUrl3 = '';
    }
    if (offerData.photoUrl4 != null) {
      this.photoUrl4 = offerData.photoUrl4;
    } else {
      this.photoUrl4 = '';
    }
    if (offerData.photoUrl5 != null) {
      this.photoUrl5 = offerData.photoUrl5;
    } else {
      this.photoUrl5 = '';
    }
    if (offerData.photoUrl6 != null) {
      this.photoUrl6 = offerData.photoUrl6;
    } else {
      this.photoUrl6 = '';
    }
    if (offerData.photoUrl7 != null) {
      this.photoUrl7 = offerData.photoUrl7;
    } else {
      this.photoUrl7 = '';
    }
    if (offerData.photoUrl8 != null) {
      this.photoUrl8 = offerData.photoUrl8;
    } else {
      this.photoUrl8 = '';
    }
    if (offerData.photoUrl9 != null) {
      this.photoUrl9 = offerData.photoUrl9;
    } else {
      this.photoUrl9 = '';
    }
    if (offerData.photoUrl10 != null) {
      this.photoUrl10 = offerData.photoUrl10;
    } else {
      this.photoUrl10 = '';
    }

    if (offerData.extras1 != null) {
      this.extras1 = offerData.extras1;
    } else {
      this.extras1 = false;
    }

    if (offerData.extras2 != null) {
      this.extras2 = offerData.extras2;
    } else {
      this.extras2 = false;
    }

    if (offerData.extras3 != null) {
      this.extras3 = offerData.extras3;
    } else {
      this.extras3 = false;
    }

    if (offerData.extras4 != null) {
      this.extras4 = offerData.extras4;
    } else {
      this.extras4 = false;
    }

    if (offerData.extras5 != null) {
      this.extras5 = offerData.extras5;
    } else {
      this.extras5 = false;
    }

    if (offerData.extras6 != null) {
      this.extras6 = offerData.extras6;
    } else {
      this.extras6 = false;
    }

    if (offerData.extras7 != null) {
      this.extras7 = offerData.extras7;
    } else {
      this.extras7 = false;
    }

    if (offerData.extras8 != null) {
      this.extras8 = offerData.extras8;
    } else {
      this.extras8 = false;
    }

    if (offerData.extras9 != null) {
      this.extras9 = offerData.extras9;
    } else {
      this.extras9 = false;
    }

    if (offerData.extras10 != null) {
      this.extras10 = offerData.extras10;
    } else {
      this.extras10 = false;
    }

    if (offerData.bulletPoints != null) {
      this.bulletPoints = offerData.bulletPoints;
    } else {
      this.bulletPoints = [];
    }
    if (offerData.bulletPointsEN != null) {
      this.bulletPointsEN = offerData.bulletPointsEN;
    } else {
      this.bulletPointsEN = [];
    }

    if (offerData.checkList != null) {
      this.checkList = offerData.checkList;
    } else {
      this.checkList = [];
    }
    if (offerData.checkListEN != null) {
      this.checkListEN = offerData.checkListEN;
    } else {
      this.checkListEN = [];
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {PlaceService} from '../../../../services/place.service';
import {Place} from '../../../../models/place.model';
import {LanguageService} from '../../../../services/language.service';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css']
})

export class PlacesComponent implements OnInit {
  public origin: any;
  public destination: any;
  places: Place[];
  currentlang: string;


  directionsService2 = new google.maps.DirectionsService();
  zoom = 12;
  routeResponse: google.maps.DirectionsResult;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: false,
  };
  markers: any[] = [];
  directions: google.maps.DirectionsResult;
  routes: google.maps.DirectionsRoute;

  constructor(private placeService: PlaceService,
              private langService: LanguageService) {
    this.origin = {lat: 52.177581, lng: 7.0387851};
    this.langService.getCurrentLanguage().subscribe(lang => this.currentlang = lang);
  }

  ngOnInit(): void {
    this.markers.push({
      position: {
        lat: 52.177581,
        lng: 7.0387851,
      },
      label: {
        color: 'blue',
        text: '',
      },
      title: '',
      options: { animation: google.maps.Animation.DROP},
    });
    this.placeService.getPublicPlaces().subscribe(places => {
      this.places = places;
    });
    this.center = {
      lat: 52.177581,
      lng: 7.0387851,
    };
  }

  showonmaps(lat: string, lng: string) {
    this.options = {
      zoomControl: true,
      scrollwheel: false,
      disableDoubleClickZoom: false,
    };
    const numLat = Number(lat);
    const numLng = Number(lng);
    this.destination = {lat: numLat, lng: numLng};
    this.calculateAndDisplayRoute(this.directionsService2, this.origin, this.destination);
  }

  calculateAndDisplayRoute(directionsService2: google.maps.DirectionsService,
                           origin: {lat: string, lng: string},
                           destination: {lat: string, lng: string}) {
    directionsService2.route({
      origin: origin.lat + ',' + origin.lng,
      destination: destination.lat + ',' + destination.lng,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.BICYCLING,
    })
      .then((response) => {
        this.routeResponse = response;
        this.routes = response.routes[0];
        this.directions = response;
      })
      .catch((e) => console.log('Could not load route'));
  }

  openPdf() {
    window.open('../../../../../assets/pdf/GronauFlyer.pdf', '_blank');
  }
}

<app-header4></app-header4>
<!--====== BREADCRUMB PART START ======-->
<section class="breadcrumb-area about-banner-frame" title="Picture show's feet in the Background Image of the about Page">
    <div class="container">
        <div class="breadcrumb-text">
            <!-- <h1>{{'about.banner-h1' | translate}}</h1> -->
            <h2 class="page-title">{{'about.banner-h2' | translate}}</h2>
            <ul class="breadcrumb-nav">
                <li><a routerLink='/'>{{'about.banner-router-link1' | translate}}</a></li>
                <li class="active">{{'about.banner-router-link2' | translate}}</li>
            </ul>
        </div>
    </div>
</section>
<!--====== BREADCRUMB PART END ======-->
<!--====== ABOUT PART START ======-->
<section class="about-section pt-25 pb-25">
    <div class="container">
        <div class="section-title about-title text-center">
            <span class="title-tag">{{'about.section1-title-span' | translate}}<span>1898</span></span>
            <h2>{{'about.section1-title-h2' | translate}}</h2>
        </div>
        <div class="about-text-box">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-img">
                        <img src="../../../../../assets/img/about/MuehleBackyard.jpg" alt="Picture show's the Backyard of the Building Muehle" title="Picture show's the Backyard of the Building Muehle">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-text">
                        <span>{{'about.section1-text-span' | translate}}</span>
                        <h3>{{'about.section1-text-h3' | translate}}</h3>
                        <p>{{'about.section1-text-p' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>

      <div class="about-text-box">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-text">
              <span>{{'about.section2-text-span' | translate}}</span>
              <h3>{{'about.section2-text-h3' | translate}}</h3>
              <p>{{'about.section2-text-p' | translate}}</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-img">
              <img src="../../../../../assets/img/about/WomanMassage.jpg" alt="Picture show's a wellness massage" title="Picture show's  a wellness massage">
            </div>
          </div>
        </div>
      </div>

      <div class="about-text-box">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img">
              <img src="../../../../../assets/img/about/RiverandHouse.jpg" alt="Picture show's the River Dinkel in Epe with a House in the Back" title="Picture show's the River Dinkel in Epe with a House in the Back">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-text">
              <span>{{'about.section3-text-span' | translate}}</span>
              <h3>{{'about.section3-text-h3' | translate}}</h3>
              <p>{{'about.section3-text-p' | translate}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
</section>
<!--====== ABOUT PART END ======-->

<!--====== LATEST NEWS END ======-->
<app-footer2></app-footer2>

import {Component, Input, OnInit} from '@angular/core';
import {ReservationModel} from '../../../../models/reservation.model';
import {ReservationService} from '../../../../services/reservation.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../shared/confirm-dialog/confirm-dialog.component';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {
  @Input() reservation: ReservationModel;

  constructor(private reservationService: ReservationService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  setReplied() {
    this.reservationService.setReplied(this.reservation);

  }

  setConfirmed() {
    this.reservationService.serConfirmed(this.reservation);
  }

  delete(reservation: ReservationModel) {
    this.dialog.open(DeleteDialogComponent).afterClosed().subscribe(res => {
      console.log(res);
      if (res) {
        this.reservationService.delete(reservation);
      }
    });


  }
}

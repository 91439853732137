import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {SeminarReservation} from '../models/seminar-booking.model';
import {DatePipe} from '@angular/common';
import {ReservationModel} from '../models/reservation.model';
import {ConfigService} from './config.service';
import {Config} from '../models/config.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  config: Config;

  constructor(private afs: AngularFirestore,
              private datePipe: DatePipe,
              private configService: ConfigService) {
    this.configService.getConfig().subscribe(config => {
      if (config) {
        this.config = config;
      }
    });
  }

  sendSeminarReservation(seminarReservations: SeminarReservation) {
    // Email to Customer
    console.log('send seminar reservation email to customer');
    this.afs.collection('mail').add({
      to: [seminarReservations.customerEmail],
      message: {
        subject: 'Reservierung Seminar Raum',
        html: 'Guten Tag ' + seminarReservations.customerName + ' ' + seminarReservations.customerSureName + ',' +
          ' vielen Dank für ihre Reservierungsanfrage vom: ' + this.datePipe.transform(seminarReservations.dateIn, 'dd-MM-yyyy') +
          ' bis zum: ' + this.datePipe.transform(seminarReservations.dateOut, 'dd-MM-yyyy') + '. ' + 'für die Seminarraum Pauschale: ' +
          seminarReservations.seminarTitle + '. ' +
          'Unser Team wird die Reservierungsanfrage so schnell wie möglich bearbeiten und ihnen eine Rückmeldung geben.' +  ' Anzahl Gäste: ' +
          seminarReservations.amountCustomers,
      }
    }).then ();
    // Email to Hotel
    this.afs.collection('mail').add({
      to: [this.config.reservation_email],
      message: {
        subject: 'Reservierung Seminar Raum',
        html: 'Eine Reservierung von, ' + seminarReservations.customerName + ' ' + seminarReservations.customerSureName +
          ' für die Zeit vom: ' + this.datePipe.transform(seminarReservations.dateIn, 'dd-MM-yyyy, hh:mm') +
          ' bis zum: ' + this.datePipe.transform(seminarReservations.dateOut, 'dd-MM-yyyy') + '. ' + 'für die Seminarraum Pauschale: ' +
          seminarReservations.seminarTitle + ', wurde aufgenommen. ' + '.' +
          ' Anzahl Gäste: ' + seminarReservations.amountCustomers + '. ' +
          'Bitte Prüfen sie die Reservierungsanfrage und antworten sie schnellstmöglich dem Kunden unter: ' +
          seminarReservations.customerEmail + ' Telefonnummer des Kunden: ' + seminarReservations.customerTelephone + '.',
      }
    }).then ();
  }

  sendReservationMail(reservation: ReservationModel) {
    // Email to Customer
    this.afs.collection('mail').add({
      to: [reservation.customerEmail],
      message: {
        subject: 'Reservierung: ' + reservation.type,
        html: 'Guten Tag, ' + reservation.customerName + ' ' + reservation.customerSureName + ',' +
          ' vielen Dank für ihre Reservierungsanfrage vom: ' + this.datePipe.transform(reservation.dateIn, 'dd-MM-yyyy') + ' um: ' +
          this.datePipe.transform(reservation.dateIn, 'hh:mm a', 'de-DE') +
          ' bis zum: ' + this.datePipe.transform(reservation.dateOut, 'dd-MM-yyyy') + ' um: ' +
            this.datePipe.transform(reservation.dateOut, 'hh:mm a', 'de-DE') +  '. ' + 'für: ' +
          reservation.type + ' - ' + reservation.title + '. ' +
          'Unser Team wird die Reservierungsanfrage so schnell wie möglich bearbeiten und ihnen eine Rückmeldung geben. ' + ' Anzahl Gäste: '
          + reservation.amountCustomers,
      }
    }).then ();
    // Email to Hotel
    this.afs.collection('mail').add({
      to: [this.config.reservation_email],
      message: {
        subject: 'Reservierung: ' + reservation.type,
        html: 'Eine Reservierung von, ' + reservation.customerName + ' ' + reservation.customerSureName +
          ' für die Zeit vom: ' + this.datePipe.transform(reservation.dateIn, 'dd-MM-yyyy') + ' um: ' +
          this.datePipe.transform(reservation.dateIn, 'hh:mm a', 'de-DE') +
          ' bis zum: ' + this.datePipe.transform(reservation.dateOut, 'dd-MM-yyyy') + ' um: ' +
          this.datePipe.transform(reservation.dateOut, 'hh:mm a', 'de-DE') + '. ' + 'für: ' +
          reservation.type + ' - ' + reservation.title + '. ' + ', wurde aufgenommen. ' +
          'Bitte Prüfen sie die Reservierungsanfrage und antworten sie schnellstmöglich dem Kunden unter: '
          + reservation.customerEmail + '.' + ' Anzahl Gäste: '
          + reservation.amountCustomers + ' Telefonnummer des Kunden: ' + reservation.customerTelephone + '.',
      }
    }).then ();
  }

  sendTestEmail(reservation: ReservationModel) {
    ///////////////////////////////////////////////// Email to Customer ////////////////////////////////////////////////////////////////////
    this.afs.collection('mail').add({
      to: [reservation.customerEmail],
      message: {
        subject: 'Ihre Reservierungs Anfrage: ' + reservation.type,
        // tslint:disable-next-line:max-line-length
        html: '<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n' +
          '<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">\n' +
          '<head>\n' +
          '  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">\n' +
          '  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
          '  <meta name="x-apple-disable-message-reformatting">\n' +
          '  <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->\n' +
          '  <title></title>\n' +
          '  \n' +
          '    <style type="text/css">\n' +
          '      table, td { color: #000000; } a { color: #0000ee; text-decoration: underline; }\n' +
          '@media only screen and (min-width: 620px) {\n' +
          '  .u-row {\n' +
          '    width: 600px !important;\n' +
          '  }\n' +
          '  .u-row .u-col {\n' +
          '    vertical-align: top;\n' +
          '  }\n' +
          '\n' +
          '  .u-row .u-col-100 {\n' +
          '    width: 600px !important;\n' +
          '  }\n' +
          '\n' +
          '}\n' +
          '\n' +
          '@media (max-width: 620px) {\n' +
          '  .u-row-container {\n' +
          '    max-width: 100% !important;\n' +
          '    padding-left: 0px !important;\n' +
          '    padding-right: 0px !important;\n' +
          '  }\n' +
          '  .u-row .u-col {\n' +
          '    min-width: 320px !important;\n' +
          '    max-width: 100% !important;\n' +
          '    display: block !important;\n' +
          '  }\n' +
          '  .u-row {\n' +
          '    width: calc(100% - 40px) !important;\n' +
          '  }\n' +
          '  .u-col {\n' +
          '    width: 100% !important;\n' +
          '  }\n' +
          '  .u-col > div {\n' +
          '    margin: 0 auto;\n' +
          '  }\n' +
          '}\n' +
          'body {\n' +
          '  margin: 0;\n' +
          '  padding: 0;\n' +
          '}\n' +
          '\n' +
          'table,\n' +
          'tr,\n' +
          'td {\n' +
          '  vertical-align: top;\n' +
          '  border-collapse: collapse;\n' +
          '}\n' +
          '\n' +
          'p {\n' +
          '  margin: 0;\n' +
          '}\n' +
          '\n' +
          '.ie-container table,\n' +
          '.mso-container table {\n' +
          '  table-layout: fixed;\n' +
          '}\n' +
          '\n' +
          '* {\n' +
          '  line-height: inherit;\n' +
          '}\n' +
          '\n' +
          'a[x-apple-data-detectors=\'true\'] {\n' +
          '  color: inherit !important;\n' +
          '  text-decoration: none !important;\n' +
          '}\n' +
          '\n' +
          '</style>\n' +
          '  \n' +
          '  \n' +
          '\n' +
          '<!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Cabin:400,700&display=swap" rel="stylesheet" type="text/css"><!--<![endif]-->\n' +
          '\n' +
          '</head>\n' +
          '\n' +
          '<body class="clean-body u_body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #ffffff;color: #000000">\n' +
          '  <table style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #ffffff;width:100%" cellpadding="0" cellspacing="0">\n' +
          '  <tbody>\n' +
          '  <tr style="vertical-align: top">\n' +
          '    <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #dcdcdc;">\n' +
          '    <div ' +
          'style="border-collapse: collapse;' +
          'display: table;' +
          'width: 100%;' +
          'background-image: url(\'https://firebasestorage.googleapis.com/v0/b/hotelshepers.appspot.com/o/email-pics%2FUntitled-3.jpg?alt=media&token=ab745cf9-ba0f-4355-b76b-e8755c905d8f\');' +
          'background-repeat: no-repeat;' +
          'background-position: center top;' +
          'background-color: transparent;' +
          'background-size: cover;">\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '<div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">\n' +
          '  \n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:67px 11px 11px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<table width="100%" cellpadding="0" cellspacing="0" border="0">\n' +
          '  <tr>\n' +
          '    <td style="padding-right: 0px;padding-left: 0px;" align="center">\n' +
          '      \n' +
          '      <img ' +
          'align="center" ' +
          'border="0" ' +
          'src="https://firebasestorage.googleapis.com/v0/b/hotelshepers.appspot.com/o/email-pics%2Flogo-schwarz.png?alt=media&token=96586b90-e33a-42b8-96c0-349e63515814" ' +
          'alt="Image" title="Image" style="outline: ' +
          'none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 50%;max-width: 250px;" width="150.28"/>\n' +
          '      \n' +
          '    </td>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 225px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="line-height: 140%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; color: white; line-height: 140%;"><span style="font-size: 30px; line-height: 42px; font-family: Cabin, sans-serif;">' +
          '<strong style="margin-top: 200px">' +
          '</strong>' +
          '</span>' +
          '</p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #fcf9f8;">\n' +
          '    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->\n' +
          '  \n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:44px 10px 14px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="line-height: 140%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; line-height: 140%;"><span ' +
          'style="font-family: Cabin, sans-serif; font-size: 14px; line-height: 19.6px;">' +
          '<span style="font-size: 26px; line-height: 36.4px;"><strong><span style="line-height: 36.4px; font-size: 26px;">Ihre Anfrage wird bearbeitet!</span></strong></span></span></p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:4px 55px 10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="line-height: 170%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; line-height: 170%;"><span style="font-family: Cabin, sans-serif; font-size: 18px; line-height: 30.6px;">Sie haben das Angebot </span></p>\n' + reservation.type + ' angefragt ' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:4px 55px 10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<div style="line-height: 170%; text-align: center; word-wrap: break-word;">\n' +
          '<p style="font-size: 14px; line-height: 170%;"><span style="font-family: Cabin, sans-serif; font-size: 16px; line-height: 27.2px;"><span style="font-size: 18px; line-height: 30.6px;">Das Team von Hotel Schepers bearbeitet zurzeit ihre Anfrage und wird sich in Kürze zurückmelden</span></span></p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 55px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<div align="center">\n' +
          '  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:\'Cabin\',sans-serif;"><tr><td style="font-family:\'Cabin\',sans-serif;" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:45px; v-text-anchor:middle; width:212px;" arcsize="9%" stroke="f" fillcolor="#34495e"><w:anchorlock/><center style="color:#e3e0f0;font-family:\'Cabin\',sans-serif;"><![endif]-->\n' +
          '    <a href="https://hotel-schepers.de/offers" target="_blank" style="box-sizing: border-box;display: inline-block;font-family:\'Cabin\',sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #e3e0f0; background-color: #34495e; border-radius: 4px;-webkit-border-radius: 4px; -moz-border-radius: 4px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;">\n' +
          '      <span style="display:block;padding:13px 55px;line-height:120%;"><span style="font-family: Cabin, sans-serif; font-size: 16px; line-height: 19.2px;"><span style="line-height: 19.2px; font-size: 16px;">zu den Arragements</span></span></span>\n' +
          '    </a>\n' +
          '  <!--[if mso]></center></v:roundrect></td></tr></table><![endif]-->\n' +
          '</div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #fff5ee;">\n' +
          '    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->\n' +
          '  \n' +
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:36px 10px 10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<table width="100%" cellpadding="0" cellspacing="0" border="0">\n' +
          '  <tr>\n' +
          '    <td style="padding-right: 0px;padding-left: 0px;" align="center">\n' +
          '      \n' +
          '      <img align="center" border="0" src="https://firebasestorage.googleapis.com/v0/b/hotelshepers.appspot.com/o/email-pics%2Fengin-akyurt-ZbzYDboN7fg-unsplash.png?alt=media&token=f9fb0533-1de1-4aae-9c58-3f9f6db1efcc" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;" width="475.6"/>\n' +
          '      \n' +
          '    </td>\n' +
          '  </tr>\n' +
          '</table>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 55px 21px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="line-height: 170%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; line-height: 170%;"><span style="font-family: ' +
          'Cabin, sans-serif; font-size: 18px; line-height: 30.6px;">Hier geht es zum Wellnes bereich</span></p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 55px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<div align="center">\n' +
          '  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:\'Cabin\',sans-serif;"><tr><td style="font-family:\'Cabin\',sans-serif;" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:45px; v-text-anchor:middle; width:224px;" arcsize="9%" stroke="f" fillcolor="#34495e"><w:anchorlock/><center style="color:#e3e0f0;font-family:\'Cabin\',sans-serif;"><![endif]-->\n' +
          '    <a href="https://hotel-schepers.de/wellness" target="_blank" style="box-sizing: border-box;display: inline-block;font-family:\'Cabin\',sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #e3e0f0; background-color: #34495e; border-radius: 4px;-webkit-border-radius: 4px; -moz-border-radius: 4px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;">\n' +
          '      <span style="display:block;padding:13px 55px;line-height:120%;"><span style="font-family: Cabin, sans-serif; font-size: 16px; line-height: 19.2px;"><span style="line-height: 19.2px; font-size: 16px;">Angebote</span></span></span>\n' +
          '    </a>\n' +
          '</div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #184e5a;">\n' +
          '    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">\n' +
          '      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #184e5a;"><![endif]-->\n' +
          '      \n' +
          '<!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->\n' +
          '  \n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:44px 55px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="color: #ffffff; line-height: 230%; text-align: center; word-wrap: break-word;">\n' +
          '    <pstyle="font-size: 14px; line-height: 230%;"><strong><span style="font-family: Cabin, sans-serif; font-size: 18px; line-height: 41.4px;"> ' +
          '<a style="color: #eec57e" href="https://g.page/hotel-schepers?share">★ ★ ★ ★ ★</a></span></strong></p>\n' +
          '<p style="font-size: 14px; line-height: 230%;"><em><span style="font-family: Cabin, sans-serif; font-size: 18px; line-height: 41.4px;">"Besuchen sie uns sehr gerne auch auf Google Maps, wir würden uns sehr über eine Bewertung Freuen"</span></em></p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px 0px 17px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">\n' +
          '    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">\n' +
          '      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px 0px 17px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->\n' +
          '      \n' +
          '<!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->\n' +
          '  \n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:23px 10px 10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<div align="center">\n' +
          '  <div style="display: table; max-width:31px;">\n' +
          '  <!--[if (mso)|(IE)]><table width="31" cellpadding="0" cellspacing="0" border="0"><tr><td style="border-collapse:collapse;" align="center"><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse; mso-table-lspace: 0pt;mso-table-rspace: 0pt; width:31px;"><tr><![endif]-->\n' +
          '  \n' +
          '    \n' +
          '    <!--[if (mso)|(IE)]><td width="32" style="width:32px; padding-right: 0px;" valign="top"><![endif]-->\n' +
          '    <table align="left" border="0" cellspacing="0" cellpadding="0" width="32" height="32" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 0px">\n' +
          '      <tbody><tr style="vertical-align: top"><td align="left" valign="middle" style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">\n' +
          '        <a href="https://www.instagram.com/hotel_restaurant_schepers/" title="Instagram" target="_blank">\n' +
          '          <img src="https://firebasestorage.googleapis.com/v0/b/hotelshepers.appspot.com/o/email-pics%2Fimage-3.png?alt=media&token=7afa51f9-df61-4931-8832-3ddd898c266f" alt="Instagram" title="Instagram" width="32" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important">\n' +
          '        </a>\n' +
          '      </td></tr>\n' +
          '    </tbody></table>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          // tslint:disable-next-line:max-line-length
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="color: #575758; line-height: 170%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; line-height: 170%;">Schaut gerne mal auf unserem Instagram Profil vorbei um weitere Aktuelle eindrücke von uns zu bekommen</p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '<!--[if (mso)|(IE)]></td><![endif]-->\n' +
          '      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->\n' +
          '    </td>\n' +
          '  </tr>\n' +
          '  </tbody>\n' +
          '  </table>\n' +
          '  <!--[if mso]></div><![endif]-->\n' +
          '  <!--[if IE]></div><![endif]-->\n' +
          '</body>\n' +
          '\n' +
          '</html>\n'
      }
    }).then( () => console.log('Email was Send'))
      .catch(error => console.log('Error', error));

     ///////////////////////////////////////////// Email to Host ///////////////////////////////////////////////////////////
    this.afs.collection('mail').add({
      to: [this.config.reservation_email],
      message: {
        subject: 'Reservierung' + reservation.type + ' von:' + reservation.customerName + ' ' + reservation.customerSureName,
        // tslint:disable-next-line:max-line-length
        html: '<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n' +
          '<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">\n' +
          '<head>\n' +
          '  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">\n' +
          '  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
          '  <meta name="x-apple-disable-message-reformatting">\n' +
          '  <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->\n' +
          '  <title></title>\n' +
          '  \n' +
          '    <style type="text/css">\n' +
          '      table, td { color: #000000; } a { color: #0000ee; text-decoration: underline; }\n' +
          '@media only screen and (min-width: 620px) {\n' +
          '  .u-row {\n' +
          '    width: 600px !important;\n' +
          '  }\n' +
          '  .u-row .u-col {\n' +
          '    vertical-align: top;\n' +
          '  }\n' +
          '\n' +
          '  .u-row .u-col-100 {\n' +
          '    width: 600px !important;\n' +
          '  }\n' +
          '\n' +
          '}\n' +
          '\n' +
          '@media (max-width: 620px) {\n' +
          '  .u-row-container {\n' +
          '    max-width: 100% !important;\n' +
          '    padding-left: 0px !important;\n' +
          '    padding-right: 0px !important;\n' +
          '  }\n' +
          '  .u-row .u-col {\n' +
          '    min-width: 320px !important;\n' +
          '    max-width: 100% !important;\n' +
          '    display: block !important;\n' +
          '  }\n' +
          '  .u-row {\n' +
          '    width: calc(100% - 40px) !important;\n' +
          '  }\n' +
          '  .u-col {\n' +
          '    width: 100% !important;\n' +
          '  }\n' +
          '  .u-col > div {\n' +
          '    margin: 0 auto;\n' +
          '  }\n' +
          '}\n' +
          'body {\n' +
          '  margin: 0;\n' +
          '  padding: 0;\n' +
          '}\n' +
          '\n' +
          'table,\n' +
          'tr,\n' +
          'td {\n' +
          '  vertical-align: top;\n' +
          '  border-collapse: collapse;\n' +
          '}\n' +
          '\n' +
          'p {\n' +
          '  margin: 0;\n' +
          '}\n' +
          '\n' +
          '.ie-container table,\n' +
          '.mso-container table {\n' +
          '  table-layout: fixed;\n' +
          '}\n' +
          '\n' +
          '* {\n' +
          '  line-height: inherit;\n' +
          '}\n' +
          '\n' +
          'a[x-apple-data-detectors=\'true\'] {\n' +
          '  color: inherit !important;\n' +
          '  text-decoration: none !important;\n' +
          '}\n' +
          '\n' +
          '</style>\n' +
          '  \n' +
          '  \n' +
          '\n' +
          '<!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Cabin:400,700&display=swap" rel="stylesheet" type="text/css"><!--<![endif]-->\n' +
          '\n' +
          '</head>\n' +
          '\n' +
          '<body class="clean-body u_body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #ffffff;color: #000000">\n' +
          '  <table style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #ffffff;width:100%" cellpadding="0" cellspacing="0">\n' +
          '  <tbody>\n' +
          '  <tr style="vertical-align: top">\n' +
          '    <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #dcdcdc;">\n' +
          '    <div ' +
          'style="border-collapse: collapse;' +
          'display: table;' +
          'width: 100%;' +
          'background-image: url(\'https://firebasestorage.googleapis.com/v0/b/hotelshepers.appspot.com/o/email-pics%2FUntitled-3.jpg?alt=media&token=ab745cf9-ba0f-4355-b76b-e8755c905d8f\');' +
          'background-repeat: no-repeat;' +
          'background-position: center top;' +
          'background-color: transparent;' +
          'background-size: cover;">\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '<div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">\n' +
          '  \n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:67px 11px 11px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<table width="100%" cellpadding="0" cellspacing="0" border="0">\n' +
          '  <tr>\n' +
          '    <td style="padding-right: 0px;padding-left: 0px;" align="center">\n' +
          '      \n' +
          '      <img ' +
          'align="center" ' +
          'border="0" ' +
          'src="https://firebasestorage.googleapis.com/v0/b/hotelshepers.appspot.com/o/email-pics%2Flogo-schwarz.png?alt=media&token=96586b90-e33a-42b8-96c0-349e63515814" ' +
          'alt="Image" title="Image" style="outline: ' +
          'none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 50%;max-width: 250px;" width="150.28"/>\n' +
          '      \n' +
          '    </td>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 225px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="line-height: 140%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; color: white; line-height: 140%;"><span style="font-size: 30px; line-height: 42px; font-family: Cabin, sans-serif;">' +
          '<strong style="margin-top: 200px">' +
          '</strong>' +
          '</span>' +
          '</p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #fcf9f8;">\n' +
          '    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->\n' +
          '  \n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:44px 10px 14px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="line-height: 140%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; line-height: 140%;"><span ' +
          'style="font-family: Cabin, sans-serif; font-size: 14px; line-height: 19.6px;">' +
          '<span style="font-size: 26px; line-height: 36.4px;"><strong><span style="line-height: 36.4px; font-size: 26px;">Neu Anfrage von einem Kunden, über Hotel-Schepers.de</span></strong></span></span></p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:4px 55px 10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="line-height: 170%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; line-height: 170%;"><span style="font-family: Cabin, sans-serif; font-size: 18px; line-height: 30.6px;">Das Angebot </span></p>\n' + ' ' + reservation.type + ' ' + 'wurde angefragt ' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:4px 55px 10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<div style="line-height: 170%; text-align: center; word-wrap: break-word;">\n' +
          '<p style="font-size: 14px; line-height: 170%;"><span style="font-family: Cabin, sans-serif; font-size: 16px; line-height: 27.2px;"><span style="font-size: 18px; line-height: 30.6px;">Bitte geben sie den Kunden Schnellstmöglich eine Rückmeldung</span></span></p>\n' +
          '<p></p>' +
          '<span style="font-weight: bold">Angebotstyp</span>' + ': ' + reservation.type + '<p></p>' +
          '<span style="font-weight: bold">Angebotsname</span>' + ': ' + reservation.title + '<p></p>' +
          '<span style="font-weight: bold">Anzahl Personen</span>' + ': ' + reservation.amountCustomers + '<p></p>' +
          '<span style="font-weight: bold">Kunden Name</span>' + ': ' + reservation.customerName + ' ' + reservation.customerSureName + '<p></p>' +
          '<span style="font-weight: bold">Kunde Email</span>' + ': ' + reservation.customerEmail + '<p></p>' +
          '<span style="font-weight: bold">Angefragt am</span>' + ': ' + this.datePipe.transform(reservation.created, 'dd-MM-yyyy') + ' um ' + this.datePipe.transform(reservation.created, 'hh:mm a', 'de-DE') + '<p></p>' +
          '<span style="font-weight: bold">Von</span>' + ': ' + this.datePipe.transform(reservation.dateIn, 'dd-MM-yyyy') + ' um ' + this.datePipe.transform(reservation.dateIn, 'hh:mm a', 'de-DE') + '<p></p>' +
          '<span style="font-weight: bold">Bis</span>' + ': ' + this.datePipe.transform(reservation.dateOut, 'dd-MM-yyyy') + ' um ' + this.datePipe.transform(reservation.dateOut, 'hh:mm a', 'de-DE') + '<p></p>' +
          '<span style="font-weight: bold">Kunde Telefonnummer</span>' + ': ' + reservation.customerTelephone + '<p></p>' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 55px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<div align="center">\n' +
          '  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:\'Cabin\',sans-serif;"><tr><td style="font-family:\'Cabin\',sans-serif;" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:45px; v-text-anchor:middle; width:212px;" arcsize="9%" stroke="f" fillcolor="#34495e"><w:anchorlock/><center style="color:#e3e0f0;font-family:\'Cabin\',sans-serif;"><![endif]-->\n' +
          '    <a href="https://hotel-schepers.de/adminpanel/reservations" target="_blank" style="box-sizing: border-box;display: inline-block;font-family:\'Cabin\',sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #e3e0f0; background-color: #34495e; border-radius: 4px;-webkit-border-radius: 4px; -moz-border-radius: 4px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;">\n' +
          '      <span style="display:block;padding:13px 55px;line-height:120%;"><span style="font-family: Cabin, sans-serif; font-size: 16px; line-height: 19.2px;"><span style="line-height: 19.2px; font-size: 16px;">Alle Buchungen Anzeigen</span></span></span>\n' +
          '    </a>\n' +
          '  <!--[if mso]></center></v:roundrect></td></tr></table><![endif]-->\n' +
          '</div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #fff5ee;">\n' +
          '    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->\n' +
          '  \n' +
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:36px 10px 10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<table width="100%" cellpadding="0" cellspacing="0" border="0">\n' +
          '  <tr>\n' +
          '    <td style="padding-right: 0px;padding-left: 0px;" align="center">\n' +
          '      \n' +
          '      <img align="center" border="0" src="https://firebasestorage.googleapis.com/v0/b/hotelshepers.appspot.com/o/email-pics%2Fengin-akyurt-ZbzYDboN7fg-unsplash.png?alt=media&token=f9fb0533-1de1-4aae-9c58-3f9f6db1efcc" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;" width="475.6"/>\n' +
          '      \n' +
          '    </td>\n' +
          '  </tr>\n' +
          '</table>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 55px 21px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="line-height: 170%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; line-height: 170%;"><span style="font-family: ' +
          'Cabin, sans-serif; font-size: 18px; line-height: 30.6px;">Hier geht es zum Wellnes bereich</span></p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 55px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<div align="center">\n' +
          '  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:\'Cabin\',sans-serif;"><tr><td style="font-family:\'Cabin\',sans-serif;" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:45px; v-text-anchor:middle; width:224px;" arcsize="9%" stroke="f" fillcolor="#34495e"><w:anchorlock/><center style="color:#e3e0f0;font-family:\'Cabin\',sans-serif;"><![endif]-->\n' +
          '    <a href="https://hotel-schepers.de/wellness" target="_blank" style="box-sizing: border-box;display: inline-block;font-family:\'Cabin\',sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #e3e0f0; background-color: #34495e; border-radius: 4px;-webkit-border-radius: 4px; -moz-border-radius: 4px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;">\n' +
          '      <span style="display:block;padding:13px 55px;line-height:120%;"><span style="font-family: Cabin, sans-serif; font-size: 16px; line-height: 19.2px;"><span style="line-height: 19.2px; font-size: 16px;">Angebote</span></span></span>\n' +
          '    </a>\n' +
          '</div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #184e5a;">\n' +
          '    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">\n' +
          '      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #184e5a;"><![endif]-->\n' +
          '      \n' +
          '<!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->\n' +
          '  \n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:44px 55px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="color: #ffffff; line-height: 230%; text-align: center; word-wrap: break-word;">\n' +
          '    <pstyle="font-size: 14px; line-height: 230%;"><strong><span style="font-family: Cabin, sans-serif; font-size: 18px; line-height: 41.4px;"> ' +
          '<a style="color: #eec57e" href="https://g.page/hotel-schepers?share">★ ★ ★ ★ ★</a></span></strong></p>\n' +
          '<p style="font-size: 14px; line-height: 230%;"><em><span style="font-family: Cabin, sans-serif; font-size: 18px; line-height: 41.4px;">"Besuchen sie uns sehr gerne auch auf Google Maps, wir würden uns sehr über eine Bewertung Freuen"</span></em></p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '\n' +
          '<div class="u-row-container" style="padding: 0px 0px 17px;background-color: transparent">\n' +
          '  <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">\n' +
          '    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">\n' +
          '      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px 0px 17px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->\n' +
          '      \n' +
          '<!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->\n' +
          '<div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">\n' +
          '  <div style="width: 100% !important;">\n' +
          '  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->\n' +
          '  \n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:23px 10px 10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '<div align="center">\n' +
          '  <div style="display: table; max-width:31px;">\n' +
          '  <!--[if (mso)|(IE)]><table width="31" cellpadding="0" cellspacing="0" border="0"><tr><td style="border-collapse:collapse;" align="center"><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse; mso-table-lspace: 0pt;mso-table-rspace: 0pt; width:31px;"><tr><![endif]-->\n' +
          '  \n' +
          '    \n' +
          '    <!--[if (mso)|(IE)]><td width="32" style="width:32px; padding-right: 0px;" valign="top"><![endif]-->\n' +
          '    <table align="left" border="0" cellspacing="0" cellpadding="0" width="32" height="32" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 0px">\n' +
          '      <tbody><tr style="vertical-align: top"><td align="left" valign="middle" style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">\n' +
          '        <a href="https://www.instagram.com/hotel_restaurant_schepers/" title="Instagram" target="_blank">\n' +
          '          <img src="https://firebasestorage.googleapis.com/v0/b/hotelshepers.appspot.com/o/email-pics%2Fimage-3.png?alt=media&token=7afa51f9-df61-4931-8832-3ddd898c266f" alt="Instagram" title="Instagram" width="32" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important">\n' +
          '        </a>\n' +
          '      </td></tr>\n' +
          '    </tbody></table>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          // tslint:disable-next-line:max-line-length
          '<table style="font-family:\'Cabin\',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">\n' +
          '  <tbody>\n' +
          '    <tr>\n' +
          // tslint:disable-next-line:max-line-length
          '      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:\'Cabin\',sans-serif;" align="left">\n' +
          '        \n' +
          '  <div style="color: #575758; line-height: 170%; text-align: center; word-wrap: break-word;">\n' +
          '    <p style="font-size: 14px; line-height: 170%;">Schaut gerne mal auf unserem Instagram Profil vorbei um weitere Aktuelle eindrücke von uns zu bekommen</p>\n' +
          '  </div>\n' +
          '\n' +
          '      </td>\n' +
          '    </tr>\n' +
          '  </tbody>\n' +
          '</table>\n' +
          '\n' +
          '  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->\n' +
          '  </div>\n' +
          '</div>\n' +
          '<!--[if (mso)|(IE)]></td><![endif]-->\n' +
          '      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->\n' +
          '    </div>\n' +
          '  </div>\n' +
          '</div>\n' +
          '\n' +
          '\n' +
          '    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->\n' +
          '    </td>\n' +
          '  </tr>\n' +
          '  </tbody>\n' +
          '  </table>\n' +
          '  <!--[if mso]></div><![endif]-->\n' +
          '  <!--[if IE]></div><![endif]-->\n' +
          '</body>\n' +
          '\n' +
          '</html>\n'
      }
    }).then( () => console.log('Email was Send'))
      .catch(error => console.log('Error', error));

  }

}

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCv7JsE0YZKukH3dTuhz7gQIEOSUG3EFho',
    authDomain: 'hotelshepers.firebaseapp.com',
    databaseURL: 'https://hotelshepers.firebaseio.com',
    projectId: 'hotelshepers',
    storageBucket: 'hotelshepers.appspot.com',
    messagingSenderId: '743682180612',
    appId: '1:743682180612:web:e3855ac908946affa11751',
    measurementId: 'G-M6JRP34G6Q'
  }
};

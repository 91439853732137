import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GALLERY_CONF, GALLERY_IMAGE, NgxImageGalleryComponent} from 'ngx-image-gallery';
import {MatDialog} from '@angular/material/dialog';
import {OfferBookingDialogComponent} from '../../../../shared/offer-booking/offer-booking-dialog/offer-booking-dialog.component';
import {OfferBookingConfiramtionDialogComponent} from '../../../../shared/offer-booking/offer-booking-confiramtion-dialog/offer-booking-confiramtion-dialog.component';
import {Offer} from '../../../../../models/offer.model';
import {LanguageService} from "../../../../../services/language.service";

@Component({
  selector: 'app-offers-festival',
  templateUrl: './offers-festival.component.html',
  styleUrls: ['./offers-festival.component.css']
})
export class OffersFestivalComponent implements OnInit{
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;
  @Input() offer: Offer;

  @ViewChild('galleryimg') galleryImg: ElementRef;
  imgHeight: any;
  currentLang: string;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  images: GALLERY_IMAGE[] = [];
  constructor(private dialog: MatDialog,
              private langService: LanguageService) { }

  openOfferBookingDialog(reservationType: string, reservationTitle: string, onlyDatetime: boolean) {
    this.dialog.open(OfferBookingDialogComponent, {data: {
          offerType: reservationType,
          offerTitle: reservationTitle,
          onlyDateTime: onlyDatetime}
    })
      .afterClosed().subscribe(res => {
      if (res) {
        this.dialog.open(OfferBookingConfiramtionDialogComponent, {data: res});
      }
    });
  }

  ngOnInit(): void {
    console.log(this.offer);
    this.langService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
    if (this.offer.photoUrl1 !== '') {
      this.images.push({url: this.offer.photoUrl1, thumbnailUrl: this.offer.photoUrl1});
    }
    if (this.offer.photoUrl2 !== '') {
      this.images.push({url: this.offer.photoUrl2, thumbnailUrl: this.offer.photoUrl2});
    }
    if (this.offer.photoUrl3 !== '') {
      this.images.push({url: this.offer.photoUrl3, thumbnailUrl: this.offer.photoUrl3});
    }
    if (this.offer.photoUrl4 !== '') {
      this.images.push({url: this.offer.photoUrl4, thumbnailUrl: this.offer.photoUrl4});
    }
    if (this.offer.photoUrl5 !== '') {
      this.images.push({url: this.offer.photoUrl5, thumbnailUrl: this.offer.photoUrl5});
    }
    if (this.offer.photoUrl6 !== '') {
      this.images.push({url: this.offer.photoUrl6, thumbnailUrl: this.offer.photoUrl6});
    }
    if (this.offer.photoUrl7 !== '') {
      this.images.push({url: this.offer.photoUrl7, thumbnailUrl: this.offer.photoUrl7});
    }
    if (this.offer.photoUrl8 !== '') {
      this.images.push({url: this.offer.photoUrl8, thumbnailUrl: this.offer.photoUrl8});
    }
    if (this.offer.photoUrl9 !== '') {
      this.images.push({url: this.offer.photoUrl9, thumbnailUrl: this.offer.photoUrl9});
    }
    if (this.offer.photoUrl10 !== '') {
      this.images.push({url: this.offer.photoUrl10, thumbnailUrl: this.offer.photoUrl10});
    }
  }

  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

  /**************************************************/

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {
    console.info('Gallery opened at index ', index);
  }

  // callback on gallery closed
  galleryClosed() {
    console.info('Gallery closed.');
  }

  // callback on gallery image clicked
  galleryImageClicked(index) {
    console.info('Gallery image clicked with index ', index);
  }

  // callback on gallery image changed
  galleryImageChanged(index) {
    console.info('Gallery image changed to index ', index);
  }

  // callback on user clicked delete button
  deleteImage(index) {
    console.info('Delete image at index ', index);
  }
  dosomething() {
    this.imgHeight = this.galleryImg.nativeElement.offsetHeight;
    this.imgHeight = this.imgHeight / 100 * 60;
  }
}

<app-header4></app-header4>

<section class="breadcrumb-area"
         style="background-image: url(../../../../../assets/img/rooms/roomsbanner-crop.jpg);">
  <div class="container">
    <div class="breadcrumb-text">
      <!--<span>The ultimate luxury</span>-->
      <h2 class="page-title">{{'rooms.rooms' | translate}}</h2>

      <ul class="breadcrumb-nav">
        <li><a routerLink='/'>Home</a></li>
        <li class="active">{{'rooms.rooms' | translate}}</li>
      </ul>
    </div>
  </div>
</section>

<app-view-room id="{{'room' + i}}" *ngFor="let room of rooms; index as i;" [room]="room"></app-view-room>

<app-footer2></app-footer2>

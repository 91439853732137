<div style="text-align: center">
  <h2>Impressum</h2>
  <br>
  <p style="font-weight: bold">Hotel Schepers GmbH</p>
  <p>Ahauser Str. 1</p>
  <p>D-48599 Gronau- Epe</p>
  <br>
  <p style="font-weight: bold">Kontakt:</p>
  <P>Telefon: <span>+49- 2565- 933 20</span></P>
  <P>Fax: <span>+49- 2565- 933 25</span></P>
  <P>E-mail: <span>info@hotel-schepers.de</span></P>
  <br>
  <p style="font-weight: bold">Geschäftsführer:</p>
  <p>John Schepers, Petra Schepers</p>
  <br>
  <p style="font-weight: bold">Handelsregister:</p>
  <p>AG Coesfeld</p>
  <p style="font-weight: bold">Registernummer:</p>
  <p>HR B 5758</p>
  <br>
  <p style="font-weight: bold">Umsatzsteuer-Identifikationsnummer:</p>
  <p>DE123766696</p>
</div>


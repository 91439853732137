import {OpeningHours} from './openingHours';

export class HotelConfig {
  public openinghours: OpeningHours;

  constructor(hours: OpeningHours, param?: any) {
    this.openinghours = hours;
    if (param != null) {
      console.log('contructor with optional value');
    }
  }
}

import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configDocument: AngularFirestoreDocument<any>;
  config: Observable<any>;

  constructor(private afs: AngularFirestore) {
    this.configDocument = this.afs.doc<any>('config/' + 1);
    this.config = this.configDocument.valueChanges();
  }

  getConfig() {
    return this.config;
  }

  updateConfigReservationEmail(email: string) {
    this.afs.collection('config').doc('1').update({reservation_email: email});
  }

}


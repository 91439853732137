import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GALLERY_CONF, GALLERY_IMAGE, NgxImageGalleryComponent} from 'ngx-image-gallery';
import {BookingDialogComponent} from '../../../../../shared/bookingdialog/booking-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LanguageService} from '../../../../../../services/language.service';

@Component({
  selector: 'app-view-room',
  templateUrl: './view-room.component.html',
  styleUrls: ['./view-room.component.css']
})
export class ViewRoomComponent implements OnInit {

  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;
  @Input() room: any;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  images: GALLERY_IMAGE[] = [];
  currentLang: string;

  constructor(private dialog: MatDialog, private langService: LanguageService) {
    this.langService.getCurrentLanguage().subscribe(lang => {
      this.currentLang = lang;
    });
  }

  ngOnInit(): void {
    if (this.room.photoUrl1 !== '') {
      this.images.push({url: this.room.photoUrl1, thumbnailUrl: this.room.photoUrl1});
    }
    if (this.room.photoUrl2 !== '') {
      this.images.push({url: this.room.photoUrl2, thumbnailUrl: this.room.photoUrl2});
    }
    if (this.room.photoUrl3 !== '') {
      this.images.push({url: this.room.photoUrl3, thumbnailUrl: this.room.photoUrl3});
    }
    if (this.room.photoUrl4 !== '') {
      this.images.push({url: this.room.photoUrl4, thumbnailUrl: this.room.photoUrl4});
    }
    if (this.room.photoUrl5 !== '') {
      this.images.push({url: this.room.photoUrl5, thumbnailUrl: this.room.photoUrl5});
    }
    if (this.room.photoUrl6 !== '') {
      this.images.push({url: this.room.photoUrl6, thumbnailUrl: this.room.photoUrl6});
    }
    if (this.room.photoUrl7 !== '') {
      this.images.push({url: this.room.photoUrl7, thumbnailUrl: this.room.photoUrl7});
    }
    if (this.room.photoUrl8 !== '') {
      this.images.push({url: this.room.photoUrl8, thumbnailUrl: this.room.photoUrl8});
    }
    if (this.room.photoUrl9 !== '') {
      this.images.push({url: this.room.photoUrl9, thumbnailUrl: this.room.photoUrl9});
    }
    if (this.room.photoUrl10 !== '') {
      this.images.push({url: this.room.photoUrl10, thumbnailUrl: this.room.photoUrl10});
    }
  }

  openBookingDialog() {
    // this.dialog.open(BookingDialogComponent);
    window.open('https://onepagebooking.com/hotel-schepers', '_blank');
  }

  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

  /**************************************************/

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {
    console.info('Gallery opened at index ', index);
  }

  // callback on gallery closed
  galleryClosed() {
    console.info('Gallery closed.');
  }

  // callback on gallery image clicked
  galleryImageClicked(index) {
    console.info('Gallery image clicked with index ', index);
  }

  // callback on gallery image changed
  galleryImageChanged(index) {
    console.info('Gallery image changed to index ', index);
  }

  // callback on user clicked delete button
  deleteImage(index) {
    console.info('Delete image at index ', index);
  }
}

export class JobModel {
  postId: string;
  CreatorId: string;
  jobType: string;
  public: boolean;
  title: string;
  titleEN: string;
  link: string;
  linkTitle: string;
  linkTitleEN: string;
  content: string;
  contentEN: string;
  views: string;
  likes: string;
  createdDate: any;
  editedDate: any;
  photoUrl: string;
  bulletPoints: string[];
  bulletPointsEN: string[];
  bulletPoints2: string[];
  bulletPointsEN2: string[];

  constructor(jobData: any){
    if (jobData.postId != null) {
      this.postId = jobData.postId;
    } else {
      this.postId = '';
    }
    if (jobData.CreatorId != null) {
      this.CreatorId = jobData.CreatorId;
    } else {
      this.CreatorId = '';
    }
    if (jobData.jobType != null) {
      this.jobType = jobData.jobType;
    } else {
      this.jobType = '';
    }
    if (jobData.public != null) {
      this.public = jobData.public;
    } else {
      this.public = false;
    }
    if (jobData.title != null) {
      this.title = jobData.title;
    } else {
      this.title = '';
    }
    if (jobData.titleEN != null) {
      this.titleEN = jobData.titleEN;
    } else {
      this.titleEN = '';
    }
    if (jobData.link != null) {
      this.link = jobData.link;
    } else {
      this.link = '';
    }
    if (jobData.linkTitle != null) {
      this.linkTitle = jobData.linkTitle;
    } else {
      this.linkTitle = '';
    }
    if (jobData.linkTitleEN != null) {
      this.linkTitleEN = jobData.linkTitleEN;
    } else {
      this.linkTitleEN = '';
    }
    if (jobData.content != null) {
      this.content = jobData.content;
    } else {
      this.content = '';
    }
    if (jobData.contentEN != null) {
      this.contentEN = jobData.contentEN;
    } else {
      this.contentEN = '';
    }
    if (jobData.views != null) {
      this.views = jobData.views;
    } else {
      this.views = '0';
    }
    if (jobData.likes) {
      this.likes  = jobData.likes;
    } else {
      this.likes  = '';
    }
    if (jobData.createdDate != null) {
      this.createdDate = jobData.createdDate;
    } else {
      this.createdDate = Date.now();
    }
    if (jobData.editedDate != null) {
      this.editedDate = jobData.editedDate;
    } else {
      this.editedDate = Date.now();
    }
    if (jobData.photoUrl != null) {
      this.photoUrl = jobData.photoUrl;
    } else {
      this.photoUrl = '';
    }
    if (jobData.bulletPoints != null) {
      this.bulletPoints = jobData.bulletPoints;
    } else {
      this.bulletPoints = [];
    }
    if (jobData.bulletPointsEN != null) {
      this.bulletPointsEN = jobData.bulletPointsEN;
    } else {
      this.bulletPointsEN = [];
    }
    if (jobData.bulletPoints2 != null) {
      this.bulletPoints2 = jobData.bulletPoints2;
    } else {
      this.bulletPoints2 = [];
    }
    if (jobData.bulletPointsEN2 != null) {
      this.bulletPointsEN2 = jobData.bulletPointsEN2;
    } else {
      this.bulletPointsEN2 = [];
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {CookieService} from "../../../../services/cookie.service";

@Component({
  selector: 'app-privatsphaere',
  templateUrl: './privatsphaere.component.html',
  styleUrls: ['./privatsphaere.component.css']
})
export class PrivatsphaereComponent implements OnInit {
  checked: any;

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    this.checked = this.cookieService.getCookieConfig();
  }

  changeCookieSetting($event) {
    this.checked = $event.checked;
    this.cookieService.setCookieConfig($event.checked);
  }
}

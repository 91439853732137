import {Component, Input, OnInit, Sanitizer, ViewEncapsulation} from '@angular/core';
import {PostService} from '../../../../services/post.service';
import {Post} from '../../../../models/post.model';
import {MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {UpdatepostComponent} from '../updatepost/updatepost.component';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

  @Input() post: Post;

  constructor(private postService: PostService,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  deletePost() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postService.deletePost(this.post.postId, this.post.photoUrl);
      }
    });
  }

  UpdatePost() {
    const dialogRef = this.dialog.open(UpdatepostComponent, {
      width: '600px',
      data: this.post
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  test() {
    console.log('Test');
  }
}

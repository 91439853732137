
<div class="accordion">
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          Email: {{user.email}}
        </mat-panel-title>
        <mat-panel-description>
          <span *ngIf="user.emailVerified">Email is Verified</span>
          <span *ngIf="!user.emailVerified">Email is NOT Verified</span>
          <!-- <mat-icon>account_circle</mat-icon> -->
        </mat-panel-description>
      </mat-expansion-panel-header>

      <button [disabled]="user.emailVerified"
              style="margin: 10px" mat-raised-button color="primary"
              (click)="SendVerifyEmail()">
        Send Email Verification
      </button>

    </mat-expansion-panel>
  </mat-accordion>
</div>

<section class="text-block bg-black with-pattern pt-50 pb-50 ">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-6 col-md-10 order-2 order-lg-1">
        <div class="block-text">
          <div class="section-title mb-20">
            <span class="title-tag">intro video</span>
            <h2>Meet With Our
              Luxury Place.</h2>
          </div>
          <p class="pr-50">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat you have to understand this.
          </p>
          <a routerLink="/room-details/1" class="main-btn btn-filled mt-40">Book now</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-10 order-1 order-lg-2">
        <div class="video-wrap video-wrap-two mb-small"
             [style.background-image]="'url(assets/img/video/banner-video-3.jpg)'">
<!--          <a href="https://www.youtube.com/watch?v=TKnufs85hXk" class="popup-video"><i class="fas fa-play"></i></a>-->
          <a (click)="openVideo()" class="popup-video"><i class="fas fa-play"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="pattern-wrap">
    <div class="pattern"></div>
  </div>
</section>

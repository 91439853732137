import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {Offer} from '../../../../models/offer.model';
import {AdminOfferUpdateComponent} from '../admin-offer-update/admin-offer-update.component';
import {OfferService} from '../../../../services/offer.service';

@Component({
  selector: 'app-admin-offer',
  templateUrl: './admin-offer.component.html',
  styleUrls: ['./admin-offer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminOfferComponent implements OnInit {
  @Input() offer: Offer;
  constructor(private offerService: OfferService,
              public dialog: MatDialog) { }

  ngOnInit(): void {}

  deleteRoom() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.offerService.deleteOfferAndPhotos(this.offer);
      }
    });
  }

  updateRoom() {
    const dialogRef = this.dialog.open(AdminOfferUpdateComponent, {
      width: '600px',
      data: this.offer
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

}

<section class="room-slider bg-white pb-10 pt-10">
  <div class="container-fluid p-0">
    <div class="section-title mb-10 text-center">
      <span class="title-tag">Zimmer</span>
      <h2>Unsere Lieblingszimmer</h2>
    </div>
    <ngx-slick-carousel #slickModal="slick-carousel" [config]="settings" class="row rooms-slider-two justify-content-center">
      <div ngxSlickItem class="col-lg-12" *ngFor="let roomOrg of roomsOrg; let index = index">
        <div class="single-rooms-box">
          <div class="room-img">
            <div (click)="openDialog(roomOrg)" class="img" [style.background-image]="'url('+roomOrg.photoUrl1+')'">
            </div>
          </div>
<!--          <ul class="icons">-->
<!--            <li *ngFor="let item of roomOrg | slice:0:7"><i [class]="item.icon"></i></li>-->
<!--          </ul>-->
          <!-- ////////////////////////////////////// German Start ////////////////////////////////////// -->
          <div *ngIf="currentLang == 'de'" class="room-desc" [routerLink]="['/room-list']" [fragment]="'room'+ index">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <h3><a [routerLink]="['/room-list']" [fragment]="'room'+ index">{{roomOrg.title}}</a></h3>
                <p>{{roomOrg.content | slice:0:200}}...</p>
              </div>
              <div class="col-sm-4">
                <div class="price">
                  ${{roomOrg.price | number:'1.0-0'}}<span>/nacht</span>
                </div>
              </div>
            </div>
          </div>
          <!-- ////////////////////////////////////// English Start ////////////////////////////////////// -->
          <div *ngIf="currentLang == 'en'" class="room-desc" [routerLink]="['/room-list']" [fragment]="'room'+ index">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <h3><a [routerLink]="['/room-list']" [fragment]="'room'+ index">{{roomOrg.titleEN}}</a></h3>
                <p>{{roomOrg.contentEN | slice:0:200}}...</p>
              </div>
              <div class="col-sm-4">
                <div class="price">
                  ${{roomOrg.price | number:'1.0-0'}}<span>/Night</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</section>

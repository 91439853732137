import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  email: string;
}

@Component({
  selector: 'app-seminar-booking-confiramtion',
  templateUrl: './seminar-booking-confiramtion.component.html',
  styleUrls: ['./seminar-booking-confiramtion.component.css']
})
export class SeminarBookingConfiramtionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SeminarBookingConfiramtionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

}

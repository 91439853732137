import { Component, OnInit } from '@angular/core';
import {NewpostComponent} from '../newpost/newpost.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-allposts',
  templateUrl: './allposts.component.html',
  styleUrls: ['./allposts.component.css']
})
export class AllpostsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialogAddRoom() {
    const dialogRef = this.dialog.open(NewpostComponent, {
      width: '600px',
      data: {}
    });

    dialogRef.afterClosed().subscribe();
  }
}

<div style="margin-left: 40%">
  <h3>Home Design</h3>
  <form [formGroup]="form" (ngSubmit)="submit()">

    <label>Choose Home Banner Design</label>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      formControlName="banner">
      <mat-radio-button class="example-radio-button" *ngFor="let banner of homeBannerDesign" [value]="banner">
        {{banner}}
      </mat-radio-button>
    </mat-radio-group>

    <label>Choose Room-Slider Design</label>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      formControlName="roomSlider">
      <mat-radio-button class="example-radio-button" *ngFor="let roomSlider of roomSliderDesign" [value]="roomSlider">
        {{roomSlider}}
      </mat-radio-button>
    </mat-radio-group>

    <label >Choose Home Video Design</label>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      formControlName="videoDesign">
      <mat-radio-button class="example-radio-button" *ngFor="let videoDesign of homeVideoDesign" [value]="videoDesign">
        {{videoDesign}}
      </mat-radio-button>
    </mat-radio-group>

    <button style="margin-bottom: 10px" mat-raised-button color="primary" type="submit">Save</button>
  </form>
</div>


import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {RestaurantService} from '../../../../services/restaurant.service';
import {BiketourService} from "../../../../services/biketour.service";

@Component({
  selector: 'app-biketours',
  templateUrl: './bike-tours.component.html',
  styleUrls: ['./bike-tours.component.css']
})
export class BikeToursComponent implements OnInit {
  menus: any[];
  // Todo add accordion and drag drop
  // Todo add Booking button for arragements
  constructor(private authService: AuthService,
              private biketourService: BiketourService) { }

  ngOnInit(): void {
    this.biketourService.getbiketours().subscribe(posts => {
      this.menus = posts;
    });
  }

}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements OnInit {
  baseUrl: SafeResourceUrl;

  @ViewChild('monitor') test: ElementRef;

  constructor(private dom: DomSanitizer) { }

  ngOnInit(): void {
    this.baseUrl = this.dom.bypassSecurityTrustResourceUrl('https://interfaces.cbooking.de/RateMonitor/RM.aspx?arrival=&departure=&adults=1&rooms=1&client=9fcaf120-c2ec-4fe7-9e2a-e227deb02284&open=1&lang=1&showButton=1&update=1#');
  }


}

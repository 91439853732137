import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {forkJoin, Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {GoogleTranslatorService} from './google-translator.service';
import {finalize} from 'rxjs/operators';
import {JobModel} from '../models/job.model';

enum jobType {
  Ausbildung,
  VollZeit,
  Teilzeit
}

@Injectable({
  providedIn: 'root'
})

export class JobService {
  // start project 27.06.2022
  // 27.06.2022 15:00 -> 16:30 1.5
  // 27.06.2022 18:00 -> 19:30 1.5
  // 30.06.2022 18:30 -> 21:00 2.5
  // 01.06.2022 16:00 -> 18:00 2.0
  // 02.07.2022 9:00 -> 12:00 3.0
  // Total = 10.5 * 50

  private jobCollection: AngularFirestoreCollection<any>;
  private jobDocument: AngularFirestoreDocument<any>;
  jobs: Observable<any>;
  userId: string;
  uploadPercent: Observable<number>;
  job: Observable<any>;

  constructor(private authService: AuthService,
              private afs: AngularFirestore,
              private afStorage: AngularFireStorage,
              private translator: GoogleTranslatorService) {
    this.jobCollection = this.afs.collection<any>('jobs', ref => ref
      .orderBy('editedDate', 'desc'));

    this.jobs = this.jobCollection.valueChanges();

    this.authService.afAuth.authState.subscribe(authState => {
      if (authState) {
        this.userId = authState.uid;
      }
    });
  }

  getAllJobs() {
    return this.jobs;
  }

  getPostById(postId: string) {
    this.jobDocument = this.afs.doc<any>('jobs/' + postId);
    this.job = this.jobDocument.valueChanges();
    return this.job;
  }

  getPublicPosts() {
    this.jobCollection = this.afs.collection<any>('jobs', ref => ref
      .where('public', '==', true).orderBy('editedDate', 'desc'));
    this.jobs = this.jobCollection.valueChanges();
    return this.jobs;
  }

  addJob(uploadEvent, job: JobModel) {
    this.afs.collection('jobs').add(Object.assign({}, job)).then(res => {
      this.afs.collection('jobs').doc(res.id).update({postId: res.id, CreatorId: this.userId});
      this.uploadPostPictures(uploadEvent, res.id);
    });
  }

  updatePost(uploadEvent, job: JobModel) {
    const newBulletsEN: any = [];
    const newBulletsEN2: any = [];
    const joinedRequest: any[] = [];
    joinedRequest.push(this.translator.translate(job.content));
    joinedRequest.push(this.translator.translate(job.title));
    if (job.bulletPoints.length > 0) {
      joinedRequest.push(this.translator.translateList(job.bulletPoints));
    }
    if (job.bulletPoints2.length > 0) {
      joinedRequest.push(this.translator.translateList(job.bulletPoints2));
    }

    forkJoin(joinedRequest).subscribe((res: any) => {
      console.log(res);
      if (res[0] != null) {
        job.contentEN = res[0].data.translations[0].translatedText;
      }
      if (res[1] != null) {
        job.titleEN = res[1].data.translations[0].translatedText;
      }
      if (res[2] != null) {
        res[2].data.translations.map(trans => {
          newBulletsEN.push(trans.translatedText);
        });
      }
      if (res[3] != null) {
        res[3].data.translations.map(trans => {
          newBulletsEN2.push(trans.translatedText);
        });
      }
      this.afs.collection('jobs').doc(job.postId)
        .update({
          title: job.title,
          titleEN: job.titleEN,
          content: job.content,
          contentEN: job.contentEN,
          bulletPoints: job.bulletPoints,
          bulletPointsEN: newBulletsEN,
          bulletPoints2: job.bulletPoints2,
          bulletPointsEN2: newBulletsEN2,
          public: job.public,
          editedDate: Date.now()
        });
      if (uploadEvent != null) {
        this.uploadPostPictures(uploadEvent, job.postId);
      }
    });
  }

  deletePost(postId: string, photoUrl: string) {
    if (photoUrl) {
      this.deletePostPhoto(photoUrl);
    }
    this.afs.collection('jobs').doc(postId).delete().then();
  }

  deletePostPhoto(photoUrl: string) {
    this.afStorage.refFromURL(photoUrl).delete();
  }

  uploadPostPictures(event, postId: string) {
    const file = event.target.files[0];
    const filePath = 'JobPictures/' + this.userId + '/' + Date.now().toString();
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          this.afs.collection('jobs').doc(postId).update({photoUrl: res});
        });
      } )
    ).subscribe();
  }

  deleteRoomPhoto(postPhotoUrl: string, postId: string, photoNumber: string) {
    this.afStorage.refFromURL(postPhotoUrl).delete();
    this.afs.collection('jobs').doc(postId).update({[photoNumber]: ''});
  }
}

import { Component, OnInit } from '@angular/core';
import {Offer} from '../../../../models/offer.model';
import {OfferService} from '../../../../services/offer.service';

interface FilterTypes {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-admin-offers',
  templateUrl: './admin-offers.component.html',
  styleUrls: ['./admin-offers.component.css']
})
export class AdminOffersComponent implements OnInit {
  offers: Offer;
  selectedValue: string;

  offertypes: FilterTypes[] = [
    {value: 'Arrangement', viewValue: 'Arrangements'},
    {value: 'Seminar', viewValue: 'Seminars'},
    {value: 'Fest', viewValue: 'Feste'},
    {value: 'Wellness', viewValue: 'Wellness'}
  ];

  constructor(private offerService: OfferService) { }

  ngOnInit(): void {
    this.loadAllOffers();
  }

  loadAllOffers() {
    this.offerService.getOffers().subscribe(offers => {
      this.offers = offers;
    });
  }

  filterOffers() {
    if (this.selectedValue === 'all') {
      this.loadAllOffers();
    } else {
      this.offerService.getOffersByType(this.selectedValue).subscribe(offers => {
        this.offers = offers;
      });
    }
  }
}

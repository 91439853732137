<mat-vertical-stepper [linear]="isLinear" #stepper>

  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Bitte Namen eingeben</ng-template>
      <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input matInput formControlName="name" placeholder="Vorname"  required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nachname</mat-label>
        <input matInput formControlName="sureName" placeholder="Nachname"  required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Anzahl Personen</mat-label>
        <input matInput formControlName="amountCustomers" placeholder="Anzahl Personen" >
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>Weiter</button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Bitte Email eingeben</ng-template>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" placeholder="Email" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Telefonnummer</mat-label>
        <input matInput formControlName="phone" placeholder="Telefonnummer" required>
      </mat-form-field>
      <div>
        <button mat-button matStepperPrevious>Zurück</button>
        <button mat-button matStepperNext>Weiter</button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Bitte Datum eingeben</ng-template>
      <mat-form-field>
        <mat-card-title style="font-size: 15px">Begin</mat-card-title>
        <input formControlName="dateIn" matInput [matDatepicker]="pickerIn">
        <mat-datepicker-toggle matSuffix [for]="pickerIn"></mat-datepicker-toggle>
        <mat-datepicker #pickerIn></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-card-title style="font-size: 15px">Ende</mat-card-title>
        <input formControlName="dateOut" matInput [matDatepicker]="pickerOut">
        <mat-datepicker-toggle matSuffix [for]="pickerOut"></mat-datepicker-toggle>
        <mat-datepicker #pickerOut></mat-datepicker>
      </mat-form-field>
      <div>
        <button mat-button matStepperPrevious>Zurück</button>
        <button mat-button matStepperNext>Weiter</button>
      </div>
    </form>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <div style="margin-top: 20px">
      <button [mat-dialog-close]="secondFormGroup.value.email"  class="main-btn btn-filled" mat-button mat-dialog-close (click)="seminarReservation()">Anfrage abschicken</button>
    </div>
  </mat-step>

</mat-vertical-stepper>

<section class="offers-area pt-40 pb-20">
  <div class="container">
    <div class="offer-boxes-loop">
      <div class="offer-box">
        <div (click)="openGallery()" class="thumb container" style="cursor: pointer">
          <img #galleryimg  src="{{ offer.photoUrl1 }}" alt="images" (load)="dosomething()">
          <mat-icon [ngStyle]="{'bottom.px': imgHeight}" class="gallery-arrows-left">keyboard_arrow_left</mat-icon>
          <mat-icon [ngStyle]="{'bottom.px': imgHeight}" class="gallery-arrows-right">keyboard_arrow_right</mat-icon>
        </div>
        <div class="offer-desc">
          <!-- //////////////////////////////////////////////////// German Start /////////////////////////////////////////// -->
          <div *ngIf="currentLang == 'de'">
            <div  class="title-wrap">
              <div class="title">
                <span class="room-cat">{{offer?.offerType}}</span>
                <h2>{{ offer.title }}</h2>
              </div>
              <div class="price">
                <span>ab </span> {{ offer.price }}<span> €</span>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-lg-6">
                <div class="offer-text">
                  <p>
                    {{ offer.content }}
                  </p>
                  <hr>
                  <p *ngFor="let bullet of offer?.bulletPoints">
                    <span *ngIf="bullet != ''"><b>-</b> {{bullet}}</span>
                  </p>
                </div>
              </div>
              <div class="col-lg-6" >
                <div class="offer-feature">
                  <ul *ngFor="let extra of offer?.checkList">
                    <li *ngIf="extra != ''"><i class="fal fa-check"></i>{{extra}}</li>
                  </ul>
                </div>
                <a (click)="openOfferBookingDialog(offer.offerType, offer.title, false)" class="main-btn btn-filled">Anfragen</a>
              </div>
            </div>
          </div>

          <!-- //////////////////////////////////////////////////// SEnglish Start /////////////////////////////////////////// -->
          <div  *ngIf="currentLang == 'en'">
            <div class="title-wrap">
              <div class="title">
                <span class="room-cat">{{offer?.offerTypeEN}}</span>
                <h2>{{ offer.titleEN }}</h2>
              </div>
              <div class="price">
                <span>from </span> {{ offer.price }}<span> €</span>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-lg-6">
                <div class="offer-text">
                  <p>
                    {{ offer.contentEN }}
                  </p>
                  <hr>
                  <p *ngFor="let bullet of offer?.bulletPointsEN">
                    <span *ngIf="bullet != ''"><b>-</b> {{bullet}}</span>
                  </p>
                </div>
              </div>
              <div class="col-lg-6" >
                <div class="offer-feature">
                  <ul *ngFor="let extra of offer?.checkListEN">
                    <li *ngIf="extra != ''"><i class="fal fa-check"></i>{{extra}}</li>
                  </ul>
                </div>
                <a (click)="openOfferBookingDialog(offer.offerType, offer.title, false)" class="main-btn btn-filled">Request</a>
              </div>
            </div>
          </div>

          <!-- ///////////////////////////////////// End /////////////////////// -->
        </div>
      </div>
    </div>
  </div>
</section>

<ngx-image-gallery
  [images]="images"
  [conf]="conf"
  (onOpen)="galleryOpened($event)"
  (onClose)="galleryClosed()"
  (onImageClicked)="galleryImageClicked($event)"
  (onImageChange)="galleryImageChanged($event)"
  (onDelete)="deleteImage($event)"
></ngx-image-gallery>


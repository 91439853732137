import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {UpdatepostComponent} from '../../admin-posts/updatepost/updatepost.component';
import {JobService} from '../../../../services/job.service';
import {UpdateJobComponent} from '../update-job/update-job.component';
import {JobModel} from '../../../../models/job.model';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {
  @Input() job: JobModel;

  constructor(private jobService: JobService,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  deletePost() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.jobService.deletePost(this.job.postId, this.job.photoUrl);
      }
    });
  }

  UpdatePost() {
    const dialogRef = this.dialog.open(UpdateJobComponent, {
      width: '600px',
      data: this.job
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  test() {
    console.log('Test');
  }
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {OfferBookingDialogComponent} from '../../../shared/offer-booking/offer-booking-dialog/offer-booking-dialog.component';
import {OfferBookingConfiramtionDialogComponent} from '../../../shared/offer-booking/offer-booking-confiramtion-dialog/offer-booking-confiramtion-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {OfferService} from '../../../../services/offer.service';
import {Offer} from '../../../../models/offer.model';
import {LanguageService} from '../../../../services/language.service';

@Component({
  selector: 'app-wellness',
  templateUrl: './wellness.component.html',
  styleUrls: ['./wellness.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WellnessComponent implements OnInit {
  offers: Offer[];
  currentLang: string;

  constructor(private dialog: MatDialog,
              private offerService: OfferService,
              private langService: LanguageService) {
    this.langService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
  }

  ngOnInit(): void {
    this.offerService.getPublicOffersByType('Wellness').subscribe(offers => {
      this.offers = offers;
    });
  }

  openOfferBookingDialog(reservationType: string, title: string, onlyDatetime: boolean) {
    this.dialog.open(OfferBookingDialogComponent, {data: {
      offerType: reservationType,
        offerTitle: title,
        onlyDateTime: onlyDatetime}})
      .afterClosed().subscribe(res => {
      if (res) {
        this.dialog.open(OfferBookingConfiramtionDialogComponent, {data: res});
      }
    });
  }
}

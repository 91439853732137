import { Component, OnInit } from '@angular/core';
import {JobService} from '../../../../../services/job.service';
import {LanguageService} from '../../../../../services/language.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  jobs: any;
  currentLang: any;

  constructor(private jobService: JobService,
              private langService: LanguageService) { }

  ngOnInit(): void {
    this.jobService.getPublicPosts().subscribe(jobs => {
      this.jobs = jobs;
    });
    this.langService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
  }

}

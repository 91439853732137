import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {RestaurantService} from '../../../../services/restaurant.service';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})
export class MenusComponent implements OnInit {
  menus: any[];
  constructor(private authService: AuthService,
              private menuService: RestaurantService) { }

  ngOnInit(): void {
    this.menuService.getMenus().subscribe(posts => {
      this.menus = posts;
    });
  }

}

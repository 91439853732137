import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EmailService} from '../../../../../services/email.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReservationModel} from '../../../../../models/reservation.model';
import {ReservationService} from '../../../../../services/reservation.service';

@Component({
  selector: 'app-seminar-booking-dialog',
  templateUrl: './seminar-booking-dialog.component.html',
  styleUrls: ['./seminar-booking-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SeminarBookingDialogComponent implements OnInit {
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              public dialogRef: MatDialogRef<SeminarBookingDialogComponent>,
              private reservationService: ReservationService,
              @Inject(MAT_DIALOG_DATA) public data: ReservationModel) { }

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      sureName: ['', Validators.required],
      amountCustomers: ['']
    });
    this.secondFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]]
    });
    this.thirdFormGroup = this.formBuilder.group({
      dateIn: ['', Validators.required],
      dateOut: ['', Validators.required]
    });
  }

  seminarReservation() {
    const reservationData = new ReservationModel({
      customerName: this.firstFormGroup.value.name,
      customerSureName: this.firstFormGroup.value.sureName,
      customerEmail: this.secondFormGroup.value.email,
      amountCustomers: this.firstFormGroup.value.amountCustomers,
      customerTelephone: this.secondFormGroup.value.phone,
      type: 'Seminar',
      title: this.data.title,
      dateIn: this.thirdFormGroup.value.dateIn,
      dateOut: this.thirdFormGroup.value.dateOut,
      replied: false,
      confirmed: false,
      confirmedByUserId: '',
      reservationId: '',
      created: new Date().toString()
    });
    this.reservationService.addReservation(reservationData);
  }
}

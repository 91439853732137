import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {PlaceService} from '../../../../services/place.service';
import {Place} from '../../../../models/place.model';

@Component({
  selector: 'app-admin-updateplace-dialog',
  templateUrl: './admin-updateplace-dialog.component.html',
  styleUrls: ['./admin-updateplace-dialog.component.css']
})
export class AdminUpdateplaceDialogComponent implements OnInit {
  uploadEvent1: Event;
  uploadEvent2: Event;
  uploadEvent3: Event;
  uploadEvent4: Event;
  placeForm: UntypedFormGroup;
  place: Place;
  hasPhoto1: boolean;
  hasPhoto2: boolean;
  hasPhoto3: boolean;
  hasPhoto4: boolean;
  placeFromServer: any;

  constructor(private formBuilder: UntypedFormBuilder,
              private placeService: PlaceService,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: Place) {
    this.placeForm = this.formBuilder.group({
      placeType: [''],
      content: [''],
      title: [''],
      destinationLat: [''],
      destinationLng: [''],
      website: [''],
      public: [false, ''],
    });
    this.placeService.getPlaceById(this.data.placeId).subscribe(place => {
      this.placeFromServer = place;
    });
  }

  ngOnInit(): void {
    if (this.data != null) {
      this.placeForm.controls.title.setValue(this.data.title);
      this.placeForm.controls.content.setValue(this.data.content);
      this.placeForm.controls.placeType.setValue(this.data.placeType);
      this.placeForm.controls.public.setValue(this.data.public);
      this.placeForm.controls.website.setValue(this.data.website);
      this.placeForm.controls.destinationLat.setValue(this.data.destinationLat);
      this.placeForm.controls.destinationLng.setValue(this.data.destinationLng);
    }
    if (this.data.photoUrl1 !== '') {
      this.hasPhoto1 = true;
    } else {
      this.hasPhoto1 = false;
    }

    if (this.data.photoUrl2 !== '') {
      this.hasPhoto2 = true;
    } else {
      this.hasPhoto2 = false;
    }

    if (this.data.photoUrl3 !== '') {
      this.hasPhoto3 = true;
    } else {
      this.hasPhoto3 = false;
    }

    if (this.data.photoUrl4 !== '') {
      this.hasPhoto4 = true;
    } else {
      this.hasPhoto4 = false;
    }
  }

  updateRoom() {
    this.place = new Place(this.placeForm.value);
    this.place.placeId = this.data.placeId;
    this.placeService.updatePlace(this.uploadEvent1,
              this.uploadEvent2,
              this.uploadEvent3,
              this.uploadEvent4,
              this.place);
  }

  pictureToUpload1($event: Event) {
    this.uploadEvent1 = $event;
    this.hasPhoto1 = true;
    this.updateRoom();
    this.uploadEvent1 = null;
  }
  pictureToUpload2($event: Event) {
    this.uploadEvent2 = $event;
    this.hasPhoto2 = true;
    this.updateRoom();
    this.uploadEvent2 = null;
  }
  pictureToUpload3($event: Event) {
    this.uploadEvent3 = $event;
    this.hasPhoto3 = true;
    this.updateRoom();
    this.uploadEvent3 = null;
  }
  pictureToUpload4($event: Event) {
    this.uploadEvent4 = $event;
    this.hasPhoto4 = true;
    this.updateRoom();
    this.uploadEvent4 = null;
  }

  deleteRoomPhoto1() {
    if (this.placeFromServer.photoUrl1) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.placeService.deletePlacePhoto(this.placeFromServer.photoUrl1, this.placeFromServer.placeId, 'photoUrl1');
          this.hasPhoto1 = false;
          this.placeService.getPlaceById(this.data.placeId).subscribe(room => {
            this.placeFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto2() {
    if (this.placeFromServer.photoUrl2) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.placeService.deletePlacePhoto(this.placeFromServer.photoUrl2, this.placeFromServer.placeId, 'photoUrl2');
          this.hasPhoto2 = false;
          this.placeService.getPlaceById(this.data.placeId).subscribe(room => {
            this.placeFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto3() {
    if (this.placeFromServer.photoUrl3) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.placeService.deletePlacePhoto(this.placeFromServer.photoUrl3, this.placeFromServer.placeId, 'photoUrl3');
          this.hasPhoto3 = false;
          this.placeService.getPlaceById(this.data.placeId).subscribe(room => {
            this.placeFromServer = room;
          });
        }
      });
    }
  }

  deleteRoomPhoto4() {
    if (this.placeFromServer.photoUrl4) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.placeService.deletePlacePhoto(this.placeFromServer.photoUrl4, this.placeFromServer.placeId, 'photoUrl4');
          this.hasPhoto4 = false;
          this.placeService.getPlaceById(this.data.placeId).subscribe(room => {
            this.placeFromServer = room;
          });
        }
      });
    }
  }

}

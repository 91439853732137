import {Component, Input, OnInit} from '@angular/core';
import {Menu} from '../../../../models/menu.model';
import {RestaurantService} from '../../../../services/restaurant.service';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {UpdatepostComponent} from '../../admin-posts/updatepost/updatepost.component';
import {UpdatemenuComponent} from '../updatemenu/updatemenu.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  @Input() menu: Menu;
  url: SafeResourceUrl;

  constructor(private menuService: RestaurantService,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // this.url = this.sanitize(this.menu.menuUrl);
  }

  deleteMenu() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.menuService.deleteMenu(this.menu.menuId, this.menu.menuUrl, this.menu.photoUrl);
      }
    });
  }

  UpdateMenu() {
    const dialogRef = this.dialog.open(UpdatemenuComponent, {
      width: '600px',
      data: this.menu
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  sanitize(url: string) {
   // return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {Menu} from '../../../../models/menu.model';
import {RestaurantService} from '../../../../services/restaurant.service';
import {BiketourService} from "../../../../services/biketour.service";
import {BikeTour} from "../../../../models/biketour.model";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-newbiketour',
  templateUrl: './new-biketour.component.html',
  styleUrls: ['./new-biketour.component.css']
})
export class NewBiketourComponent implements OnInit {
  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  bulletPointCtrl = new UntypedFormControl();
  filteredBulletPoints: Observable<string[]>;
  bulletPoints: string[] = [];
  allBulletPoints: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  uploadEvent: Event;
  uploadEvent1: Event;
  menuForm: UntypedFormGroup;
  menu: BikeTour;
  currentDate: any;
  hasMenu: boolean;
  hasPicture: any;

  pdfEvent: Event;
  hasPdf: boolean;


  constructor(private authService: AuthService,
              private restaurantService: BiketourService,
              private formBuilder: UntypedFormBuilder) {
    this.menuForm = this.formBuilder.group({
      headerDescription: ['', Validators.required],
      content: ['', Validators.required],
      title: ['', Validators.required],
      public: [false, '']
    });
    this.filteredBulletPoints = this.bulletPointCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allBulletPoints.slice()));
  }

  ngOnInit(): void {
  }

  addPost() {
    this.menu = new BikeTour(this.menuForm.value);
    this.menu.bulletPoints = this.bulletPoints;
    this.menu.bikeTourType = 'tour';
    this.restaurantService.addbiketour(this.uploadEvent, this.uploadEvent1, this.pdfEvent, this.menu);
  }

  gpxFileToUpload($event: Event) {
    this.uploadEvent = $event;
    this.hasMenu = true;
  }

  pdfFileToUpload($event: Event) {
    this.pdfEvent = $event;
    this.hasPdf = true;
  }

  pictureToUpload2($event: Event) {
    this.uploadEvent1 = $event;
    this.hasPicture = true;
  }

  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.bulletPoints.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.bulletPointCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.bulletPoints.indexOf(fruit);

    if (index >= 0) {
      this.bulletPoints.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.bulletPointCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBulletPoints.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points //////////////////////////////////////////////////////////



}

export class Booking{
  checkInDate: string;
  checkOutDate: string;
  durationInDays: string;
  persons: string;


  constructor(bookingData: any){
    this.checkInDate = bookingData.checkInDate;
    this.checkOutDate = bookingData.checkOutDate;
    this.durationInDays = bookingData.durationInDays;
    this.persons = bookingData.persons;
  }
}

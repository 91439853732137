<form [formGroup]="roomForm" class="example-form" (ngSubmit)="addRoom()">

  <div class="container">
    <mat-dialog-content>
      <div class="row">
        <mat-form-field style="width: 100%">
          <mat-label>Room Type</mat-label>
          <mat-select formControlName="roomType">
            <mat-option value="Einzelzimmmer">Einzelzimmmer</mat-option>
            <mat-option value="Doppelzimmer">Doppelzimmer</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" placeholder="Title">
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Content</mat-label>
          <textarea style="max-height: 120px" matInput formControlName="content" placeholder="Content"></textarea>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Price</mat-label>
          <input matInput formControlName="price" placeholder="Price">
        </mat-form-field>
      </div>


      <div class="row">
        <mat-slide-toggle style="margin: 15px" formControlName="public">Public</mat-slide-toggle>
      </div>
      <!--Size 1170x570-->
      <div class="row">
        <div class="col">
          <input #newPostUpload1 hidden="true" type="file" (change)="pictureToUpload1($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload1.click()">Upload 1<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
          <span>Upload 1 = max-height 600px</span>
          <a target="_blank" href="https://www.photopea.com/">Change height here</a>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto1">check</mat-icon>
          <img [src]=room?.photoUrl1 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload2 hidden="true" type="file" (change)="pictureToUpload2($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload2.click()">Upload 2<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto2">check</mat-icon>
          <img [src]=room?.photoUrl2 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload3 hidden="true" type="file" (change)="pictureToUpload3($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload3.click()">Upload 3<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto3">check</mat-icon>
          <img [src]=room?.photoUrl3 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload4 hidden="true" type="file" (change)="pictureToUpload4($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload4.click()">Upload 4<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto4">check</mat-icon>
          <img [src]=room?.photoUrl4 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload5 hidden="true" type="file" (change)="pictureToUpload5($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload5.click()">Upload 5<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto5">check</mat-icon>
          <img [src]=room?.photoUrl5 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload6 hidden="true" type="file" (change)="pictureToUpload6($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload6.click()">Upload 6<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto6">check</mat-icon>
          <img [src]=room?.photoUrl6 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload7 hidden="true" type="file" (change)="pictureToUpload7($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload7.click()">Upload 7<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto7">check</mat-icon>
          <img [src]=room?.photoUrl7 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload8 hidden="true" type="file" (change)="pictureToUpload8($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload8.click()">Upload 8<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto8">check</mat-icon>
          <img [src]=room?.photoUrl8 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload9 hidden="true" type="file" (change)="pictureToUpload9($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload9.click()">Upload 9<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto9">check</mat-icon>
          <img [src]=room?.photoUrl9 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload10 hidden="true" type="file" (change)="pictureToUpload10($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload10.click()">Upload 10<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto10">check</mat-icon>
          <img [src]=room?.photoUrl10 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>


      <div class="row">
        <div class="col-5">
          <mat-checkbox formControlName="extras1" class="example-margin">Wifi 200Mbit</mat-checkbox>
          <mat-checkbox formControlName="extras2" class="example-margin">Frühstuck</mat-checkbox>
          <mat-checkbox formControlName="extras3" class="example-margin">Dusche</mat-checkbox>
          <mat-checkbox formControlName="extras4" class="example-margin">Badewanne</mat-checkbox>
          <mat-checkbox formControlName="extras5" class="example-margin">Balkon</mat-checkbox>
        </div>
        <div class="col-5">
          <mat-checkbox formControlName="extras6" class="example-margin">Doppel Bett</mat-checkbox>
          <mat-checkbox formControlName="extras7" class="example-margin">Schlafsofa</mat-checkbox>
          <mat-checkbox formControlName="extras8" class="example-margin">Schreibtisch</mat-checkbox>
          <mat-checkbox formControlName="extras9" class="example-margin">Kaffeemaschine</mat-checkbox>
        </div>

        <!-----------------------Start Bullet Points ------------------>
        <mat-form-field class="example-chip-list" style="width: 100%; margin-top: 10px; margin-bottom: 10px">
          <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip
              *ngFor="let bulletPoint of bulletPoints"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(bulletPoint)">
              {{bulletPoint}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Bulletpoint List for Text"
              #fruitInput
              [formControl]="bulletPointCtrl"
              [matAutocomplete]=""
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let fruit of filteredBulletPoints | async" [value]="fruit">
              {{fruit}}
            </mat-option>
          </mat-autocomplete> -->
        </mat-form-field>

        <!-----------------------Start Check List ------------------>
        <mat-form-field *ngIf="false" class="example-chip-list" style="width: 100%; margin-top: 10px; margin-bottom: 10px">
          <mat-chip-list #chipList2 aria-label="Fruit selection2">
            <mat-chip
              *ngFor="let bulletPoint2 of checkList"
              [selectable]="selectable2"
              [removable]="removable2"
              (removed)="remove2(bulletPoint2)">
              {{bulletPoint2}}
              <mat-icon matChipRemove *ngIf="removable2">cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Checkbox List for key words"
              #fruitInput2
              [formControl]="bulletPointCtrl2"
              [matAutocomplete]=""
              [matChipInputFor]="chipList2"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes2"
              (matChipInputTokenEnd)="add2($event)">
          </mat-chip-list>
          <!-- <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selected2($event)">
             <mat-option *ngFor="let CheckPoint of filteredBulletPoints2 | async" [value]="CheckPoint">
               {{CheckPoint}}
             </mat-option>
           </mat-autocomplete> -->
        </mat-form-field>

      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="submit" style="margin: 10px" mat-raised-button color="primary" mat-button (click)="startSaveing()">Speichern</button>
      <button style="margin: 10px" mat-raised-button color="warn" mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>

  </div>
</form>

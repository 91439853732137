import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {PostService} from '../../../services/post.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Post} from '../../../models/post.model';

@Component({
  selector: 'app-adminpanel',
  templateUrl: './adminpanel.component.html',
  styleUrls: ['./adminpanel.component.css']
})
export class AdminpanelComponent implements OnInit {

  postForm: UntypedFormGroup;
  post: Post;
  currentDate: any;
  constructor(private authService: AuthService,
              private postService: PostService,
              private formBuilder: UntypedFormBuilder) {
    this.postForm = this.formBuilder.group({
      content: [''],
      title: [''],
      public: [false, '']
    });
  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
  }

}

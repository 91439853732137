import { Component, OnInit } from '@angular/core';
import {HotelConfig} from '../../../../models/hotel-config/hotel-config';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {OpeningHours} from "../../../../models/hotel-config/openingHours";
import {HotelconfigService} from "../../../../services/hotelconfig.service";

@Component({
  selector: 'app-admin-hotel-config-home',
  templateUrl: './admin-hotel-config-home.component.html',
  styleUrls: ['./admin-hotel-config-home.component.css']
})
export class AdminHotelConfigHomeComponent implements OnInit {
  hotelConfig: HotelConfig;
  formGroup: UntypedFormGroup;
  disabled: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private hotelConfigService: HotelconfigService) {
    this.formGroup = this.formBuilder.group({
      mondayStart: [''],
      mondayEnd: [''],
      tuesdayStart: [''],
      tuesdayEnd: [''],
      wednesdayStart: [''],
      wednesdayEnd: [''],
      thursdayStart: [''],
      thursdayEnd: [''],
      fridayStart: [''],
      fridayEnd: [''],
      saturdayStart: [''],
      saturdayEnd: [''],
      sundayStart: [''],
      sundayEnd: ['']
    });
  }

  ngOnInit(): void {
    this.hotelConfigService.getConfig().subscribe((hotelConfig: HotelConfig) => {
      this.hotelConfig = hotelConfig;
      console.log(hotelConfig);
      this.formGroup.setValue(hotelConfig.openinghours);
    });
  }


  saveOpeningHours(hours: OpeningHours) {
    this.hotelConfig = new HotelConfig(hours);
    this.hotelConfigService.updateHotelConfig(this.hotelConfig);
  }
}

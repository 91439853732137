import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {forkJoin, Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {finalize} from 'rxjs/operators';
import {Offer} from '../models/offer.model';
import {GoogleTranslatorService} from './google-translator.service';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  private OffersCollection: AngularFirestoreCollection<any>;
  private OfferCollection: AngularFirestoreDocument<any>;
  offers: Observable<any>;
  offer: Observable<any>;
  userId: string;
  uploadPercent: Observable<number>;

  constructor(private authService: AuthService,
              private afs: AngularFirestore,
              private afStorage: AngularFireStorage,
              private translator: GoogleTranslatorService) {
    this.OffersCollection = this.afs.collection<any>('offers', ref => ref
      .orderBy('editedDate', 'desc'));
    this.offers = this.OffersCollection.valueChanges();
    this.authService.afAuth.authState.subscribe(authState => {
      if (authState) {
        this.userId = authState.uid;
      }
    });
  }

  getOffers() {
    return this.offers;
  }

  getRoomById(offerId: string) {
    this.OfferCollection = this.afs.doc<any>('offers/' + offerId);
    this.offer = this.OfferCollection.valueChanges();
    return this.offer;
  }

  getOffersByType(offerType: string) {
    this.OffersCollection = this.afs.collection<any>('offers', ref => ref
      .where('offerType', '==', offerType).orderBy('editedDate', 'desc'));
    this.offers = this.OffersCollection.valueChanges();
    return this.offers;
  }

  getPublicOffersByType(offerType: string) {
    this.OffersCollection = this.afs.collection<any>('offers', ref => ref
      .where('offerType', '==', offerType).where('public', '==', true).orderBy('editedDate', 'desc'));
    this.offers = this.OffersCollection.valueChanges();
    return this.offers;
  }

  getPublicOffers() {
    this.OffersCollection = this.afs.collection<any>('offers', ref => ref
      .where('public', '==', true).orderBy('editedDate', 'desc'));
    this.offers = this.OffersCollection.valueChanges();
    return this.offers;
  }

  addOffer(uploadEvent1,
           uploadEvent2,
           uploadEvent3,
           uploadEvent4,
           uploadEvent5,
           uploadEvent6,
           uploadEvent7,
           uploadEvent8,
           uploadEvent9,
           uploadEvent10,
           offer: Offer) {
    const obsList = [];
    const bulletPointsEN = [];
    const checkListEN = [];
    const obsofferTypeEN = this.translator.translate(offer.offerType);
    obsList.push(obsofferTypeEN);
    const obsofferTitleEN = this.translator.translate(offer.title);
    obsList.push(obsofferTitleEN);
    const obsofferContentEN = this.translator.translate(offer.content);
    obsList.push(obsofferContentEN);
    if (offer.bulletPoints.length >= 1) {
      const obsofferBulletPointsEN = this.translator.translateList(offer.bulletPoints);
      obsList.push(obsofferBulletPointsEN);
    }
    if (offer.checkList.length >= 1) {
      const obsofferCheckListEN = this.translator.translateList(offer.checkList);
      obsList.push(obsofferCheckListEN);
    }

    forkJoin(obsList).subscribe((translations: any) => {
      console.log(translations);
      offer.offerTypeEN = translations[0].data.translations[0].translatedText;
      offer.titleEN = translations[1].data.translations[0].translatedText;
      offer.contentEN = translations[2].data.translations[0].translatedText;
      if (translations[3] != null) {
        translations[3].data.translations.forEach(translated => {
          bulletPointsEN.push(translated.translatedText);
        });
        offer.bulletPointsEN = bulletPointsEN;
      }
      if (translations[4] != null) {
        translations[4].data.translations.forEach(translated => {
          checkListEN.push(translated.translatedText);
        });
        offer.checkListEN = checkListEN;
      }
      this.afs.collection('offers').add(Object.assign({}, offer)).then(res => {
        this.afs.collection('offers').doc(res.id).update({offerId: res.id, CreatorId: this.userId});
        this.uploadOfferPictures(uploadEvent1, 'photoUrl1' , res.id);
        this.uploadOfferPictures(uploadEvent2, 'photoUrl2', res.id);
        this.uploadOfferPictures(uploadEvent3, 'photoUrl3', res.id);
        this.uploadOfferPictures(uploadEvent4, 'photoUrl4', res.id);
        this.uploadOfferPictures(uploadEvent5, 'photoUrl5', res.id);
        this.uploadOfferPictures(uploadEvent6, 'photoUrl6', res.id);
        this.uploadOfferPictures(uploadEvent7, 'photoUrl7', res.id);
        this.uploadOfferPictures(uploadEvent8, 'photoUrl8', res.id);
        this.uploadOfferPictures(uploadEvent9, 'photoUrl9', res.id);
        this.uploadOfferPictures(uploadEvent10, 'photoUrl10', res.id);
      });
    });
  }

  updateOffer(uploadEvent1,
              uploadEvent2,
              uploadEvent3,
              uploadEvent4,
              uploadEvent5,
              uploadEvent6,
              uploadEvent7,
              uploadEvent8,
              uploadEvent9,
              uploadEvent10,
              offer: Offer) {
    let hasBullets = false;
    let hasChecklist = false;
    const obsList = [];
    const bulletPointsEN = [];
    const checkListEN = [];
    const obsofferTypeEN = this.translator.translate(offer.offerType);
    obsList.push(obsofferTypeEN);
    const obsofferTitleEN = this.translator.translate(offer.title);
    obsList.push(obsofferTitleEN);
    const obsofferContentEN = this.translator.translate(offer.content);
    obsList.push(obsofferContentEN);
    if (offer.bulletPoints.length >= 1) {
      hasBullets = true;
      console.log('Bulletpoints more than one');
      const obsofferBulletPointsEN = this.translator.translateList(offer.bulletPoints);
      obsList.push(obsofferBulletPointsEN);
    }
    if (offer.checkList.length >= 1) {
      hasChecklist = true;
      console.log('Checklist more than one');
      const obsofferCheckListEN = this.translator.translateList(offer.checkList);
      obsList.push(obsofferCheckListEN);
    }
    forkJoin(obsList).subscribe((translations: any) => {
      console.log(translations);
      offer.offerTypeEN = translations[0].data.translations[0].translatedText;
      offer.titleEN = translations[1].data.translations[0].translatedText;
      offer.contentEN = translations[2].data.translations[0].translatedText;
      if (translations[3] != null && hasBullets) {
        translations[3].data.translations.forEach(translated => {
          bulletPointsEN.push(translated.translatedText);
        });
      } else if (translations[3] != null && !hasBullets && hasChecklist) {
        translations[3].data.translations.forEach(translated => {
          checkListEN.push(translated.translatedText);
        });
      }
      if (translations[4] != null && hasChecklist) {
        translations[4].data.translations.forEach(translated => {
          checkListEN.push(translated.translatedText);
        });
      }
      console.log('Bulletpoints', bulletPointsEN);
      console.log('checklist', checkListEN);
      offer.bulletPointsEN = bulletPointsEN;
      offer.checkListEN = checkListEN;

      this.afs.collection('offers').doc(offer.offerId)
        .update({
          title: offer.title,
          titleEN: offer.titleEN,
          content: offer.content,
          contentEN: offer.contentEN,
          public: offer.public,
          offerType: offer.offerType,
          offerTypeEN: offer.offerTypeEN,
          price: offer.price,
          extras1: offer.extras1,
          extras2: offer.extras2,
          extras3: offer.extras3,
          extras4: offer.extras4,
          extras5: offer.extras5,
          extras6: offer.extras6,
          extras7: offer.extras7,
          extras8: offer.extras8,
          extras9: offer.extras9,
          extras10: offer.extras10,
          bulletPoints: offer.bulletPoints,
          bulletPointsEN: offer.bulletPointsEN,
          checkList: offer.checkList,
          checkListEN: offer.checkListEN,
          editedDate: Date.now()
        });
    });
    if (uploadEvent1 != null) {
      this.uploadOfferPictures(uploadEvent1, 'photoUrl1', offer.offerId);
    }
    if (uploadEvent2 != null) {
      this.uploadOfferPictures(uploadEvent2, 'photoUrl2', offer.offerId);
    }
    if (uploadEvent3 != null) {
      this.uploadOfferPictures(uploadEvent3, 'photoUrl3', offer.offerId);
    }
    if (uploadEvent4 != null) {
      this.uploadOfferPictures(uploadEvent4, 'photoUrl4', offer.offerId);
    }
    if (uploadEvent5 != null) {
      this.uploadOfferPictures(uploadEvent5, 'photoUrl5', offer.offerId);
    }
    if (uploadEvent6 != null) {
      this.uploadOfferPictures(uploadEvent6, 'photoUrl6', offer.offerId);
    }
    if (uploadEvent7 != null) {
      this.uploadOfferPictures(uploadEvent7, 'photoUrl7', offer.offerId);
    }
    if (uploadEvent8 != null) {
      this.uploadOfferPictures(uploadEvent8, 'photoUrl8', offer.offerId);
    }
    if (uploadEvent9 != null) {
      this.uploadOfferPictures(uploadEvent9, 'photoUrl9', offer.offerId);
    }
    if (uploadEvent10 != null) {
      this.uploadOfferPictures(uploadEvent10, 'photoUrl10', offer.offerId);
    }
  }

  deleteOffer(offerId: string) {
    this.afs.collection('offers').doc(offerId).delete().then(res => {
    });
  }

  deleteOfferAndPhotos(offer: Offer) {
    if (offer.photoUrl1 !== '') {
      this.deleteOfferPhoto(offer.photoUrl1, offer.offerId, 'photoUrl1');
    }
    if (offer.photoUrl2 !== '') {
      this.deleteOfferPhoto(offer.photoUrl2, offer.offerId, 'photoUrl2');
    }
    if (offer.photoUrl3 !== '') {
      this.deleteOfferPhoto(offer.photoUrl3, offer.offerId, 'photoUrl3');
    }
    if (offer.photoUrl4 !== '') {
      this.deleteOfferPhoto(offer.photoUrl4, offer.offerId, 'photoUrl4');
    }
    if (offer.photoUrl5 !== '') {
      this.deleteOfferPhoto(offer.photoUrl5, offer.offerId, 'photoUrl5');
    }
    if (offer.photoUrl6 !== '') {
      this.deleteOfferPhoto(offer.photoUrl6, offer.offerId, 'photoUrl6');
    }
    if (offer.photoUrl7 !== '') {
      this.deleteOfferPhoto(offer.photoUrl7, offer.offerId, 'photoUrl7');
    }
    if (offer.photoUrl8 !== '') {
      this.deleteOfferPhoto(offer.photoUrl8, offer.offerId, 'photoUrl8');
    }
    if (offer.photoUrl9 !== '') {
      this.deleteOfferPhoto(offer.photoUrl9, offer.offerId, 'photoUrl9');
    }
    if (offer.photoUrl10 !== '') {
      this.deleteOfferPhoto(offer.photoUrl10, offer.offerId, 'photoUrl10');
    }
    this.afs.collection('offers').doc(offer.offerId).delete().then(res => {
    });
  }

  uploadOfferPictures(event, field: string, offerId: string) {
    const file = event.target.files[0];
    const filePath = 'OfferPictures/' + this.userId + '/' + Date.now().toString();
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          this.afs.collection('offers').doc(offerId).update({[field]: res});
        });
      })
    ).subscribe();
  }

  deleteOfferPhoto(offerPhotoUrl: string, offerId: string, photoNumber: string) {
    this.afStorage.refFromURL(offerPhotoUrl).delete();
    this.afs.collection('offers').doc(offerId).update({[photoNumber]: ''});
  }
}

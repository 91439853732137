import { Component, OnInit} from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import {SEOServiceService} from '../../../../services/seoservice.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  galleryposts = [
    { img: 'assets/img/gallery/011small.jpg' },
    { img: 'assets/img/gallery/022small.jpg' },
    { img: 'assets/img/gallery/033big.jpg' },
    { img: 'assets/img/gallery/044small.jpg' },
    { img: 'assets/img/gallery/055small.jpg' },
    { img: 'assets/img/gallery/066big.jpg' },
    { img: 'assets/img/gallery/077small.jpg' },
    { img: 'assets/img/gallery/088small.jpg' },
  ];

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {NewBiketourComponent} from "../newbiketour/new-biketour.component";

@Component({
  selector: 'app-allbiketours',
  templateUrl: './all-bike-tours.component.html',
  styleUrls: ['./all-bike-tours.component.css']
})
export class AllBikeToursComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialogAddMenu() {
    const dialogRef = this.dialog.open(NewBiketourComponent, {
      width: '600px',
      height: '80%',
      data: {}
    });

    dialogRef.afterClosed().subscribe();
  }

}

<mat-card class="example-card" style="padding: 20px">
  <mat-card-header>
    <div class="container">
      <div class="row">
        <div class="col" style="float: left">
          <mat-card-title><mat-icon style="margin: 20px; font-size: 32px; color: #bead8e; cursor: pointer">airline_seat_individual_suite</mat-icon>{{room?.title}}</mat-card-title>
        </div>
        <!-- <div class="col" style="float: right">
          <mat-card-subtitle>Title: {{ room?.title}}</mat-card-subtitle>
        </div> -->
      </div>
    </div>
  </mat-card-header>
  <hr style="
      margin-bottom: 5px;
      margin-top: 2px">
  <div class="container">
    <div class="row">
      <div class="col">
        <mat-card-content>
          <p [hidden]="true" class="room-content"><b matTooltip={{room?.content}} matTooltipClass="tooltip-black" >Content:<br></b> {{room?.content}}</p>
          <p><b>Room Title:</b> {{room?.title}}</p>
          <p><b>Room Type:</b> {{room?.roomType}}</p>
          <p><b>Price:</b> {{room?.price}}</p>
          <p><b>created:</b> {{room?.createdDate | date:'d-MM-y'}}</p>
          <p><b>changed:</b> {{room?.editedDate | date:'d-MM-y'}}</p>
          <p><b>Public:</b> {{room?.public}}</p>
        </mat-card-content>
      </div>
      <div class="col">
        <img mat-card-image [src]=room?.photoUrl1
             style="
             overflow: hidden;
             width: 300px;
             height: 200px;
             margin-top: 10px;
             background-size: cover;
             background-position: center;">

          <div [hidden]="true"  class="row">
            <div class="col">
              <ul>
                <li *ngIf="room?.extras1"><i class="fal fa-check"></i>WiFi</li>
                <li *ngIf="room?.extras2"><i class="fal fa-check"></i>Frühstuck</li>
                <li *ngIf="room?.extras3"><i class="fal fa-check"></i>Dusche</li>
                <li *ngIf="room?.extras4"><i class="fal fa-check"></i>Badewanne</li>
                <li *ngIf="room?.extras5"><i class="fal fa-check"></i>Balkon</li>
              </ul>
            </div>

            <div class="col">
              <ul>
                <li *ngIf="room?.extras6"><i class="fal fa-check"></i>Doppel</li>
                <li *ngIf="room?.extras7"><i class="fal fa-check"></i>Schlafsofa</li>
                <li *ngIf="room?.extras8"><i class="fal fa-check"></i>Schreibtisch</li>
                <li *ngIf="room?.extras9"><i class="fal fa-check"></i>Kaffem.</li>
                <li *ngIf="room?.extras10"><i class="fal fa-check"></i>Spare</li>
              </ul>
            </div>
          </div>

      </div>
    </div>
  </div>

  <mat-card-actions>
    <button (click)="updateRoom()" mat-stroked-button color="primary">Edit</button>
    <button (click)="deleteRoom()" mat-stroked-button color="warn">Delete</button>
    <app-content-status-old [isOnline]="room?.public"></app-content-status-old>
  </mat-card-actions>

</mat-card>

<form [formGroup]="postForm" class="example-form">
  <mat-dialog-content>
    <mat-form-field class="example-full-width">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" placeholder="Title">
    </mat-form-field>
    <mat-form-field class="example-full-width" >
      <mat-label>Content</mat-label>
      <textarea style="max-height: 120px" matInput formControlName="content" placeholder="Content"></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Link Title</mat-label>
      <input matInput formControlName="linkTitle" placeholder="Link Title">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Link</mat-label>
      <input matInput formControlName="link" placeholder="Link">
    </mat-form-field>

    <mat-slide-toggle style="margin: 15px" formControlName="public">Public</mat-slide-toggle>
    <div class="row">
      <div class="col">
        <input #newPostUpload hidden="true" type="file" (change)="pictureToUpload($event)" accept=".jpg,.png"/>
        <button mat-flat-button color="primary" (click)="newPostUpload.click()">Upload 1<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
      </div>
      <div class="col">
        <mat-icon *ngIf="hasPhoto1">check</mat-icon>
        <img [src]=post?.photoUrl style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button (click)="addPost()" [disabled]="postForm.invalid" style="margin: 10px" mat-raised-button color="primary" mat-button mat-dialog-close type="button">Speichern</button>
    <button style="margin: 10px" mat-raised-button color="warn" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

</form>

<div class="latest-post-box pointer box zoom"
     (click)="openNews()"
     (mousedown)="mouseDown($event)"
     (mouseup)="mouseUp($event)">
  <div class="post-img"
       style="background-image: url('{{ post.photoUrl }}');">
  </div>
  <!--/////////////////////////////// German Start ///////////////////// -->
  <div *ngIf="currentLang == 'de'" class="post-desc">
    <h4 style="margin-bottom: 5px "><a routerLink='/'>{{ post.title }}</a></h4>
    <p style="overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;">{{ post.content}}<span></span></p>
<!--    <ul class="post-meta" style="margin-top: 10px">-->
<!--     <li style="float: left">-->
<!--        <a routerLink=''><i class="fal fa-user"></i>By {{ username}}</a>-->
<!--      </li>-->
<!--      <li style="float: right">-->
<!--         <a>-->
<!--           <i class="fal fa-calendar-alt"></i>-->
<!--           {{ post.createdDate | date:'d-MM-y'}}-->
<!--         </a>-->
<!--       </li>-->
<!--     </ul>-->
   </div>
  <!--/////////////////////////////// English Start ///////////////////// -->
  <div *ngIf="currentLang == 'en'" class="post-desc">
    <h4 style="margin-bottom: 5px "><a routerLink='/'>{{ post.titleEN }}</a></h4>
    <p style="overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;">{{ post.contentEN}}<span></span></p>
<!--    <ul class="post-meta" style="margin-top: 10px">-->
<!--      <li style="float: left">-->
<!--         <a routerLink=''><i class="fal fa-user"></i>By {{ username}}</a>-->
<!--       </li>-->
<!--      <li style="float: right">-->
<!--         <a>-->
<!--           <i class="fal fa-calendar-alt"></i>-->
<!--           {{ post.createdDate | date:'d-MM-y'}}-->
<!--         </a>-->
<!--       </li>-->
<!--    </ul>-->
  </div>
 </div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MoviesService {
  lorem = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna ' +
    'aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea ' +
    'takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr';
  movies = [
    {index: 1, name: 'Episode 1 - The Phantom Menace', description: this.lorem},
    {index: 2, name: 'Episode 2 - The Phantom Menace', description: this.lorem},
    {index: 3, name: 'Episode 3 - The Phantom Menace', description: this.lorem},
    {index: 4, name: 'Episode 4 - The Phantom Menace', description: this.lorem},
    {index: 5, name: 'Episode 5 - The Phantom Menace', description: this.lorem},
    {index: 6, name: 'Episode 6 - The Phantom Menace', description: this.lorem},
  ];

  constructor() { }

  getAllMovies() {
    return this.movies;
  }

  setMovies(movies: any) {
    this.movies = movies;
  }
}

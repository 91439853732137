import { Injectable } from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  cookies: boolean;

  constructor(private anal: AngularFireAnalytics, private router: Router) {
    if (localStorage.getItem('cookies') === 'true') {
      this.setCookieConfig(true);
    } else if (localStorage.getItem('cookies') === 'false') {
      this.setCookieConfig(false);
    } else {
      localStorage.setItem('cookies', 'null');
      this.anal.setAnalyticsCollectionEnabled(false).then(() => {console.log('Analytics Disabled due no settings by user'); });
    }
  }

  getCookiesAreSet() {
    return localStorage.getItem('cookies') !== 'null';
  }

  getCookieConfig(): boolean {
    return this.cookies;
  }

  setCookieConfig(allowCookies: boolean) {
    if (allowCookies) {
      this.cookies = true;
      localStorage.setItem('cookies', 'true');
      this.anal.setAnalyticsCollectionEnabled(true).then(() => {console.log('Analytics Enabled'); });
    } else {
      this.cookies = false;
      this.anal.setAnalyticsCollectionEnabled(false).then(() => {console.log('Analytics Disabled'); });
      localStorage.setItem('cookies', 'false');
    }
  }


  deleteSetting() {
    localStorage.setItem('cookies', 'null');
    this.cookies = false;
    setTimeout(() => {this.router.navigate(['/']).then(); }, 1000);
  }
}

import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Router, Scroll} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy, ViewportScroller} from '@angular/common';
import {filter} from 'rxjs/operators';
import {SEOServiceService} from './services/seoservice.service';
import {CookieService} from './services/cookie.service';
import {LanguageService} from './services/language.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, AfterViewInit {
  location: any;
  routerSubscription: any;
  cookiesSettingsSet: boolean;
  concurrentlang: any;

  constructor(private loc: Location,
              private router: Router,
              private viewportScroller: ViewportScroller,
              private seoService: SEOServiceService,
              private cookieService: CookieService,
              private languageService: LanguageService) {
    this.languageService.getCurrentLanguage().subscribe(lang => {
      this.concurrentlang = lang;
    });
    this.cookiesSettingsSet = this.cookieService.getCookiesAreSet();
    console.log(this.cookiesSettingsSet);
    this.router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
    this.router.events.subscribe( () => {
      this.seoService.updateMetadata(this.router);
    });
  }

  ngOnInit(){
    this.cookiesSettingsSet = this.cookieService.getCookiesAreSet();
    console.log(this.cookiesSettingsSet);
  }

  setCookieConfig(allowCookies: boolean) {
    this.cookiesSettingsSet = true;
    this.cookieService.setCookieConfig(allowCookies);
  }

  ngAfterViewInit(): void {
    this.cookiesSettingsSet = this.cookieService.getCookiesAreSet();
    console.log(this.cookiesSettingsSet);
  }

  openPrivacyDialog() {
    if (this.concurrentlang === 'de') {
        this.router.navigate(['privatsphaere']);
      } else {
        this.router.navigate(['private-policy']);
      }
  }
}

import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-packages-hotel-net-solutions',
  templateUrl: './packages-hotel-net-solutions.component.html',
  styleUrls: ['./packages-hotel-net-solutions.component.css']
})
export class PackagesHotelNetSolutionsComponent implements OnInit {
  baseUrl: SafeResourceUrl;
  constructor(private dom: DomSanitizer) { }

  ngOnInit(): void {
    this.baseUrl = this.dom.bypassSecurityTrustResourceUrl('https://onepagebooking.com/hotel-schepers/packages');
  }

}

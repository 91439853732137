<form [formGroup]="menuForm" class="example-form">
  <mat-dialog-content>
    <mat-form-field class="example-full-width">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" placeholder="Title">
    </mat-form-field>
    <mat-form-field class="example-full-width" >
      <mat-label>HeaderDescription</mat-label>
      <textarea style="max-height: 100px" matInput formControlName="headerDescription" placeholder="Header Description"></textarea>
    </mat-form-field>
    <mat-form-field class="example-full-width" >
      <mat-label>Content</mat-label>
      <textarea style="max-height: 300px" matInput formControlName="content" placeholder="Content"></textarea>
    </mat-form-field>

    <!-----------------------Start Bullet Points ------------------>
    <mat-form-field class="example-chip-list" style="width: 100%; margin-top: 10px; margin-bottom: 10px">
      <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip
          *ngFor="let bulletPoint of bulletPoints"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(bulletPoint)">
          {{bulletPoint}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Bulletpoint List for Text"
          #fruitInput
          [formControl]="bulletPointCtrl"
          [matAutocomplete]=""
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let fruit of filteredBulletPoints | async" [value]="fruit">
          {{fruit}}
        </mat-option>
      </mat-autocomplete> -->
    </mat-form-field>

    <mat-slide-toggle style="margin: 15px" formControlName="public">Public</mat-slide-toggle>

    <div class="row" style="margin-bottom: 20px">
      <div class="col">
        <input #newMenuUpload hidden="true" type="file" (change)="gpxFileToUpload($event)" accept=".gpx"/>
        <button mat-flat-button color="primary" (click)="newMenuUpload.click()">GPX Updload<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
      </div>
      <div class="col">
        <mat-icon *ngIf="hasMenu">check</mat-icon>
        <img src="assets/icons/gpx.png" style="width: 64px; height: 64px">
      </div>
    </div>

    <div class="row" style="margin-bottom: 20px">
      <div class="col">
        <input #newMenuUpload2 hidden="true" type="file" (change)="pdfFileToUpload($event)" accept=".pdf"/>
        <button mat-flat-button color="primary" (click)="newMenuUpload2.click()">PDF Updload<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
      </div>
      <div class="col">
        <mat-icon *ngIf="hasPdf">check</mat-icon>
        <img src="assets/icons/pdf.png" style="width: 64px; height: 64px">
      </div>
    </div>


    <div class="row" style="margin-bottom: 20px">
      <div class="col">
        <input #newMenuUploadPicture hidden="true" type="file" (change)="pictureToUpload2($event)" accept=".jpg,.png"/>
        <button mat-flat-button color="primary" (click)="newMenuUploadPicture.click()">Picture Updload<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
      </div>
      <div class="col">
        <mat-icon *ngIf="hasPicture">check</mat-icon>
        <img [src]=menu?.photoUrl style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button style="margin: 10px" mat-raised-button color="primary" mat-button mat-dialog-close (click)="addPost()">Speichern</button>
    <button style="margin: 10px" mat-raised-button color="warn" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

</form>

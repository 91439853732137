<mat-toolbar color="primary">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>

  <!-- <button mat-icon-button (click)="openSideNav()" class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button> -->

  <span class="app-name">Dashboard</span>
  <span class="example-spacer"></span>

  <button [matMenuTriggerFor]="logout" mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>settings</mat-icon>
  </button>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/">
    <mat-icon>arrow_back</mat-icon>
    <span>Homepage</span>
  </button>
  <button mat-menu-item routerLink="allposts">
    <mat-icon>assignment</mat-icon>
    <span>Posts</span>
  </button>
  <button mat-menu-item routerLink="allrooms">
    <mat-icon>airline_seat_individual_suite</mat-icon>
    <span>Rooms</span>
  </button>
  <button mat-menu-item routerLink="allplaces">
    <mat-icon>location_on</mat-icon>
    <span>Places</span>
  </button>
  <button mat-menu-item routerLink="allmenu">
    <mat-icon>restaurant</mat-icon>
    <span>Menus</span>
  </button>
  <button mat-menu-item routerLink="allbiketours">
    <mat-icon>directions_bike</mat-icon>
    <span>Bike-Tours</span>
  </button>
  <button mat-menu-item routerLink="alloffers">
    <mat-icon>local_offer</mat-icon>
    <span>Offers</span>
  </button>
  <button mat-menu-item routerLink="alljobs">
    <mat-icon>work</mat-icon>
    <span>Jobs</span>
  </button>
  <button mat-menu-item routerLink="hotel-config">
    <mat-icon>perm_data_setting</mat-icon>
    <span>Hotel Config</span>
  </button>
</mat-menu>

<mat-menu #logout="matMenu">
  <button  [routerLink]="'settings'" mat-menu-item><mat-icon>power_settings_new</mat-icon>Settings</button>
  <button [hidden]="!appUser?.userisadmin" [routerLink]="'config'" mat-menu-item><mat-icon>phonelink_setup</mat-icon>Config</button>
  <button  [routerLink]="'reservations'" mat-menu-item><mat-icon>calendar_today</mat-icon>Reservations</button>
  <button  [routerLink]="'register'" mat-menu-item><mat-icon>supervisor_account</mat-icon>Add New User</button>
  <button mat-menu-item (click)="logoutUser()"><mat-icon>exit_to_app</mat-icon>Logout</button>
</mat-menu>


<form [formGroup]="register" (ngSubmit)="onSubmit()">
  <div class="login-container">
    <mat-card class="login-card">
      <mat-card-title>Register Admin-Dashboard</mat-card-title>

      <mat-form-field>
        <input matInput placeholder="Email" formControlName="email">
      </mat-form-field>
      <mat-error *ngIf="register.controls.email.hasError('email') && register.controls.email.touched" style="font-size: 12px">
        Email has wrong format
      </mat-error>
      <mat-error *ngIf="register.controls.email.hasError('minlength')" style="font-size: 12px">
        Email is already in use
      </mat-error>

      <mat-form-field>
        <input matInput placeholder="name" formControlName="username" type="text">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="surename" formControlName="usersurename" type="text">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="zipcode" formControlName="userzipcode" type="text">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Password" formControlName="password" type="password">
      </mat-form-field>
      <mat-error *ngIf="register.controls.password.hasError('minlength') && register.controls.password.touched" style="font-size: 12px">
        password needs at least 6 characters
      </mat-error>
      <mat-error *ngIf="register.controls.password.hasError('required') && register.controls.password.touched" style="font-size: 12px">
        password is required
      </mat-error>

      <mat-form-field>
        <input matInput placeholder="Confirm Password" formControlName="confirmPassword" type="password">
      </mat-form-field>
      <mat-error *ngIf="register.controls.confirmPassword.hasError('mustMatch') && register.controls.confirmPassword.touched" style="font-size: 12px">
        passwords needs to match
      </mat-error>

      <mat-card-actions>
        <button type="submit" mat-stroked-button color="primary">Register</button>
      </mat-card-actions>
    </mat-card>
    <!-- <ngx-recaptcha2 #captchaElem
                     [siteKey]=siteKey
                     useGlobalDomain="false"
                     size='normal'
                     hl='en'
                     theme='light'
                     type='image'
                     formControlName="recaptcha"
                     (reset)="handleReset()"
                     (expire)="handleExpire()"
                     (error)="handleError()"
                     (load)="handleLoad()"
                     (success)="handleSuccess($event)">
     </ngx-recaptcha2>
     <mat-error *ngIf="register.controls.recaptcha.hasError('required')" style="font-size: 12px">
       Are you a Robot ?
     </mat-error> -->
  </div>
</form>

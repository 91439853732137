import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private angularFireStore: AngularFirestore) { }

  getLayoutsConfig() {
    return this.angularFireStore.collection('layouts').doc('1').valueChanges();
  }

  setLayoutConfig(layoutConfig: any) {
    return this.angularFireStore.collection('layouts').doc('1').update(layoutConfig);
  }
}

import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {HotelConfig} from '../models/hotel-config/hotel-config';

@Injectable({
  providedIn: 'root'
})
export class HotelconfigService {
  private configDocument: AngularFirestoreDocument<any>;
  hotelConfig: Observable<any>;

  constructor(private afs: AngularFirestore) {
    this.configDocument = this.afs.doc<any>('hotelconfig/' + 1);
    this.hotelConfig = this.configDocument.valueChanges();
  }

  getConfig() {
    return this.hotelConfig;
  }

  updateHotelConfig(hotelConfig: HotelConfig) {
    console.log(hotelConfig);
    this.afs.collection('hotelconfig').doc('1').update({openinghours: hotelConfig.openinghours});
  }
}


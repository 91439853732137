import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {LayoutConfigModel} from '../../../models/LayoutConfig.model';
import {LayoutService} from '../../../services/layout.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../shared/confirm-dialog/confirm-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-config-layout',
  templateUrl: './admin-config-layout.component.html',
  styleUrls: ['./admin-config-layout.component.css']
})
export class AdminConfigLayoutComponent implements OnInit {
  form: UntypedFormGroup;
  homeBannerDesign: string[] = ['None', 'Home-banner-1', 'Home-banner-2', 'Home-banner-3'];
  roomSliderDesign: string[] = ['None', 'Room-Slider-1', 'Room-Slider-2', 'Room-Slider-3'];
  homeVideoDesign: string[] = ['None', 'video-1', 'video-2', 'video-3'];

  layouts: LayoutConfigModel;

  constructor(private angularFireStore: AngularFirestore,
              private layoutService: LayoutService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) {
    // Form
    this.form = new UntypedFormGroup({
      banner: new UntypedFormControl(''),
      roomSlider: new UntypedFormControl(''),
      videoDesign: new UntypedFormControl('')
    });
    // Get Current LayoutConfig
    this.layoutService.getLayoutsConfig().subscribe((layouts: any) => {
        this.layouts = layouts;
        console.log(this.layouts);
        this.form.controls.banner.setValue(this.layouts.banner);
        this.form.controls.roomSlider.setValue(this.layouts.roomSlider);
        this.form.controls.videoDesign.setValue(this.layouts.videoDesign);
    });
  }

  ngOnInit(): void {}

  submit(): any {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(res => {
      if (res) {
        this.layoutService.setLayoutConfig(this.form.value).then();
        this.snackBar.open('Settings Saved');
      }
    });
  }
}

<h3 style="text-align: center">{{data?.offerType}}
  <button style="margin-bottom: 20px; float: right" mat-raised-button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h3>

<mat-vertical-stepper [linear]="isLinear" #stepper>

  <mat-step [stepControl]="firstFormGroup" class="matstepper">
    <form [formGroup]="firstFormGroup" style=" flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:100%;">
      <ng-template matStepLabel>{{'offer-reservation-dialog.info1' | translate}}</ng-template>
      <mat-form-field class="form-field">
        <mat-label>{{'offer-reservation-dialog.name' | translate}}</mat-label>
        <input matInput formControlName="name" placeholder="Vorname"  required>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>{{'offer-reservation-dialog.sure-name' | translate}}</mat-label>
        <input matInput formControlName="sureName" placeholder="Nachname"  required>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>{{'offer-reservation-dialog.number-persons' | translate}}</mat-label>
        <input matInput formControlName="amountCustomers" placeholder="Anzahl Personen" >
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>{{'offer-reservation-dialog.next' | translate}}</button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>{{'offer-reservation-dialog.info2' | translate}}</ng-template>
      <mat-form-field class="form-field">
        <mat-label>{{'offer-reservation-dialog.email' | translate}}</mat-label>
        <input matInput formControlName="email" placeholder="Email" required>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>{{'offer-reservation-dialog.phone' | translate}}</mat-label>
        <input matInput formControlName="phone" placeholder="Telefonnummer" required>
      </mat-form-field>
      <div>
        <button mat-button matStepperPrevious>{{'offer-reservation-dialog.back' | translate}}</button>
        <button mat-button matStepperNext>{{'offer-reservation-dialog.next' | translate}}</button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>{{'offer-reservation-dialog.info3' | translate}}</ng-template>

      <mat-form-field appearance="fill" style="margin-top: 10px"  class="form-field">
        <mat-label>{{'offer-reservation-dialog.begin' | translate}}</mat-label>
        <input (click)="picker1.open()"  formControlName="dateIn" matInput [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill"  class="form-field">
        <mat-label>{{'offer-reservation-dialog.end' | translate}}</mat-label>
        <input (click)="picker2.open()"  formControlName="dateOut" matInput [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>

      <div>
        <button mat-button matStepperPrevious>{{'offer-reservation-dialog.back' | translate}}</button>
        <button mat-button matStepperNext>{{'offer-reservation-dialog.next' | translate}}</button>
      </div>
    </form>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <div style="margin-top: 20px">
      <button [mat-dialog-close]="secondFormGroup.value.email"
              class="main-btn btn-filled"
              mat-button mat-dialog-close
              (click)="reservation()">{{'offer-reservation-dialog.done' | translate}}
      </button>
    </div>
  </mat-step>

</mat-vertical-stepper>


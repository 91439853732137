<app-header4></app-header4>

<section style="margin-top: 150px; max-height: 300px; min-height: 200px">
  <div>
    <img src="../../../../../../assets/img/job/job-banner-new.jpg">
  </div>
</section>


<section style="margin-top: 50px; margin-left: 20px; margin-right: 20px; margin-bottom: 20px; max-width: 800px">
  <h1>{{'job.header' | translate}}</h1>
  <div *ngFor="let job of jobs">
    <mat-card style="margin-top: 10px; padding-bottom: 0px">
      <mat-card-header style="float: right; font-size: 12px">{{'job.since' | translate}} {{job?.createdDate | date:'dd-MM-yyyy'}}</mat-card-header>
      <!-- German -->
      <div *ngIf="currentLang == 'de'" [routerLink]="['/jobs-details/', {id: job?.postId}]">
        <mat-card-title style="cursor: pointer">{{job?.title}}</mat-card-title>
        <mat-card-subtitle class="job-content">{{job?.content}}</mat-card-subtitle>
        <span style="font-weight: bold; position: absolute; bottom: 13px; left: 13px">{{job?.jobType}}</span>
        <mat-card-actions align="end">
          <button [routerLink]="['/jobs-details/', {id: job?.postId}]" mat-button>Details</button>
        </mat-card-actions>
      </div>
      <!-- English -->
      <div *ngIf="currentLang == 'en'" [routerLink]="['/jobs-details/', {id: job?.postId}]">
        <mat-card-title style="cursor: pointer">{{job?.titleEN}}</mat-card-title>
        <mat-card-subtitle>{{job?.contentEN}}</mat-card-subtitle>
        <span style="float: left; font-weight: bold">{{job?.jobType}}</span>
        <mat-card-actions align="end">
          <button [routerLink]="['/jobs-details/', {id: job?.postId}]" mat-button>Details</button>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>

  <div *ngIf="jobs.length <= 0">
    Es tut uns leid, zurzeit haben wir leider keine offnen Stellen. Schau doch bald wieder vorbei!
  </div>
</section>

<app-footer2></app-footer2>

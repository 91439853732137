import { Component, OnInit } from '@angular/core';
import {AdminOfferUpdateComponent} from '../admin-offer-update/admin-offer-update.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-admin-offers-all',
  templateUrl: './admin-offers-all.component.html',
  styleUrls: ['./admin-offers-all.component.css']
})
export class AdminOffersAllComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialogAddOffer() {
    const dialogRef = this.dialog.open(AdminOfferUpdateComponent, {
      width: '600px',
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

<!--====== HEADER START ======-->
<header class="header-absolute sticky-header inner-page"
        (window:scroll)="windowScroll()"
        (window:resize)="setBreakPoint()"
        id="sticky-header">
    <div class=" container-custom-one">
        <div class="nav-container d-flex align-items-center justify-content-between">
            <!-- Main Menu -->
            <div class="nav-menu d-lg-flex align-items-center">
                <!-- Navbar Close Icon -->
                <div class="navbar-close">
                    <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
                </div>
                <!-- Off canvas Menu for web  -->
                <div class="toggle">
                    <a (click)="offCanvasBtn()"
                       id="offCanvasBtn header4btn">
                      <i class="fal fa-bars header4btn"></i>
                    </a>
                </div>
                <!-- Meneu Items -->
              <div class="menu-items">
                <ul>
                   <li>
                     <a routerLink='/'>{{'toolbar.home' | translate}}</a>
                   </li>
                   <li>
                     <a routerLink='/restaurant'>{{'toolbar.restaurant' | translate}}</a>
                   </li>
                   <li>
                     <a routerLink='/room-list'>{{'toolbar.rooms' | translate}}</a>
                     <ul class="submenu">
                       <div *ngFor="let room of rooms; index as i;">
                         <li><a [routerLink]="['/room-list']" [fragment]="'room'+i">{{room.title}}</a></li> <!-- change to room.title -->
                       </div>
                     </ul>
                   </li>
                  <li>
                    <a  (click)="openPackages()">{{'toolbar.arrangements' | translate}}</a>

<!--                    <ul *ngIf="currentLanguage == 'de'" class="submenu">-->
<!--                      <div *ngFor="let offer of offers; index as i;">-->
<!--                        <li><a [routerLink]="['/offers']" [fragment]="'offer'+i">{{offer.title}}</a></li>-->
<!--                      </div>-->
<!--                    </ul>-->

<!--                    <ul *ngIf="currentLanguage == 'en'" class="submenu">-->
<!--                      <div *ngFor="let offer of offers; index as i;">-->
<!--                        <li><a [routerLink]="['/offers']" [fragment]="'offer'+i">{{offer.titleEN}}</a></li>-->
<!--                      </div>-->
<!--                    </ul>-->

                  </li>
                  <li>
                    <a  [routerLink]="['/bike-tours']">{{'toolbar.bicycle' | translate}}</a>
                  </li>
                  <li>
                    <a  [routerLink]="['/jobs']">{{'sidenav.jobs' | translate}}</a>
                  </li>

                  <!--
                   <li>
                     <a [routerLink]="['/offers']">{{'toolbar.arrangements' | translate}}</a>
                     <ul class="submenu">
                       <li><a [routerLink]="['/offers']" [fragment]="'0'">{{'toolbar.weddings' | translate}}</a></li>
                       <li><a [routerLink]="['/offers']" [fragment]="'1'">{{'toolbar.bicycle' | translate}}</a></li>
                       <li><a [routerLink]="['/offers']" [fragment]="'2'">{{'toolbar.time-together' | translate}}</a></li>
                       <li><a [routerLink]="['/offers']" [fragment]="'wellness'">{{'toolbar.wellness' | translate}}</a></li>
                     </ul>
                   </li>
                   -->

                 </ul>
               </div>
                 <!-- from pushed-item -->
                <div class="nav-pushed-item"></div>
            </div>

            <!-- Site Logo -->
            <div class="site-logo logo">
              <a routerLink='/'><img src="../../../../../assets/img/logo/logo-schepers-antra.png"
                                     alt="Hotel Schepers Logo" title="Picture show's the Logo of Hotel and Restaurant Schepers"
                                     style="margin-right: 120px; max-height: 100px"></a>
            </div>
            <!-- Header Info Pussed To Menu Wrap -->
            <div class="nav-push-item" style="float: right">
                <!-- Header Info -->
                <div class="header-info d-lg-flex">
                    <div class="item">
                        <i class="fal fa-phone"></i>
                        <a href="tel:+492565 93320">
                            <h5 class="title header4btn">02565 93320</h5>
                        </a>
                    </div>
                  <div class="flag">
                    <img (click)="setLanguage()"
                         *ngIf="currentLanguage === 'en'"
                         src="../../../../../assets/img/flags/en-rect.png">
                    <img (click)="setLanguage()"
                         *ngIf="currentLanguage === 'de'"
                         src="../../../../../assets/img/flags/de-rect.png">
                    <img (click)="setLanguage()"
                         *ngIf="currentLanguage === 'nl'"
                         src="../../../../../assets/img/flags/nl-rect.png">
                  </div>
                </div>
            </div>
            <!-- Navbar Toggler -->
            <div class="navbar-toggler" id="toggler" (click)="offCanvasBtn()">
              <span></span><span></span><span></span>
            </div>

        </div>
    </div>
</header>
<!--====== HEADER PART END ======-->
<!--====== OFF CANVAS START ======-->
<div class="offcanvas-wrapper" id="offcanvas-wrapper" style="max-width: 350px">
    <div class="offcanvas-overly"
         id="offcanvas-overly"
         (click)="offCanvasOverlay()">
    </div>
    <div class="offcanvas-widget">
        <a class="offcanvas-close"
           (click)="offCanvasClose()">
          <i class="fal fa-times"></i>
        </a>
        <!-- Nav Widget -->
        <div *ngIf="breakpoint" class="widget nav-widget">
            <h5 class="widget-title">{{'sidenav.main' | translate}}</h5>
              <ul>
                <li><a routerLink='/'>{{'sidenav.home' | translate}}</a></li>
                <li><a routerLink='/restaurant'>{{'sidenav.restaurant' | translate}}</a></li>
                <li><a routerLink='/room-list'>{{'sidenav.rooms' | translate}}</a></li>
                <li><a style="cursor: pointer" (click)="openPackages()">{{'sidenav.arrangements' | translate}}</a></li>
                <li><a routerLink='/bike-tours' routerLinkActive="active">{{'sidenav.biketours' | translate}}</a></li>
              </ul>
        </div>
        <div class="widget nav-widget">
          <h5 class="widget-title">{{'sidenav.more' | translate}}</h5>
          <ul>
            <li><a routerLink='/offers-festivals' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Feste</a></li>
            <li><a routerLink='/offers-seminars' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'sidenav.seminars' | translate}}</a></li>
            <li><a routerLink='/wellness' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Wellness</a></li>
            <li><a routerLink='/about' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'sidenav.about-us' | translate}}</a></li>
            <li>
              <a routerLink='/places' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'sidenav.tourist-attractions' | translate}}</a>
              <!-- <ul class="submenu">
                  <li><a routerLink='/places-details' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Places Details</a></li>
              </ul> -->
            </li>
            <li><a routerLink='/jobs' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'sidenav.jobs' | translate}}</a></li>
            <li><a routerLink='/gallery' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'sidenav.gallery' | translate}}</a></li>

          </ul>
        </div>

        <!-- Social Link -->
        <div class="widget social-link">
            <h5 class="widget-title">{{'sidenav.contact-us' | translate}}</h5>
            <ul>
                <li><a href="https://de-de.facebook.com/hotel.schepers/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.instagram.com/explore/locations/1008973694/hotel-restaurant-schepers/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                <li><a href="https://www.youtube.com/watch?v=z9nT_a8HGI8" target="_blank"><i class="fab fa-youtube"></i></a></li>
              <div class="flag">
                <img (click)="setLanguage()"
                     *ngIf="currentLanguage === 'en'"
                     src="../../../../../assets/img/flags/en-rect.png">
                <img (click)="setLanguage()"
                     *ngIf="currentLanguage === 'de'"
                     src="../../../../../assets/img/flags/de-rect.png">
                <img (click)="setLanguage()"
                     *ngIf="currentLanguage === 'nl'"
                     src="../../../../../assets/img/flags/nl-rect.png">
              </div>
            </ul>
        </div>
    </div>
</div>
<!--====== OFF CANVAS END ======-->

import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgwWowModule } from 'ngx-wow';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/home/layouts/preloader/preloader.component';
import { Header4Component } from './components/home/layouts/header4/header4.component';
import { Footer2Component } from './components/home/layouts/footer2/footer2.component';
import { AboutComponent } from './components/home/pages/about/about.component';
import { GalleryComponent } from './components/home/pages/gallery/gallery.component';
import { HomeTwoComponent } from './components/home/pages/home-two/home-two.component';
import { PlacesComponent } from './components/home/pages/places/places.component';
import { PlacesDetailsComponent } from './components/home/pages/places-details/places-details.component';
import { RestaurantComponent } from './components/home/pages/restaurant/restaurant.component';
import { BookingDialogComponent } from './components/shared/bookingdialog/booking-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import { LoginComponent } from './components/admin/login/login.component';
import {environment} from '../environments/environment.prod';
import {AuthService} from './services/auth.service';
import {MatButtonModule} from '@angular/material/button';
import { RegisterComponent } from './components/admin/register/register.component';
import { AdminpanelComponent } from './components/admin/adminpanel/adminpanel.component';
import {AuthGuard} from './services/auth.guard';
import {MatTableModule} from '@angular/material/table';
import {CdkTableModule} from '@angular/cdk/table';
import { PostsComponent } from './components/admin/admin-posts/posts/posts.component';
import { PostComponent } from './components/admin/admin-posts/post/post.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ToolbarComponent} from './components/admin/admin-toolbar/toolbar.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import { NewsOutletComponent } from './components/home/modules/news/news-outlet/news-outlet.component';
import { NewOutletComponent } from './components/home/modules/news/new-outlet/new-outlet.component';
import { NewsOutletDetailComponent } from './components/home/modules/news/deatails/news-outlet-detail/news-outlet-detail.component';
import { NewpostComponent } from './components/admin/admin-posts/newpost/newpost.component';
import {NgxImageGalleryModule} from 'ngx-image-gallery';
import { AllpostsComponent } from './components/admin/admin-posts/allposts/allposts.component';
import { NewroomComponent } from './components/admin/admin-rooms/newroom/newroom.component';
import { RoomComponent } from './components/admin/admin-rooms/room/room.component';
import { RoomsComponent } from './components/admin/admin-rooms/rooms/rooms.component';
import { AllroomsComponent } from './components/admin/admin-rooms/allrooms/allrooms.component';
import { AdminFooterComponent } from './components/admin/admin-footer/admin-footer.component';
import {ViewRoomComponent} from './components/home/pages/room-list/elements/room/view-room.component';
import {ViewRoomsComponent} from './components/home/pages/room-list/elements/rooms/view-rooms.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DeleteDialogComponent } from './components/shared/delete-dialog/delete-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { TermsOfUseDialogComponent } from './components/shared/terms-of-use-dialog/terms-of-use-dialog.component';
import { UpdatepostComponent } from './components/admin/admin-posts/updatepost/updatepost.component';
import { UpdateroomComponent } from './components/admin/admin-rooms/updateroom/updateroom.component';
import {DatePipe} from '@angular/common';
import {RoomCarouselComponent} from './components/home/modules/rooms-carousel/room/room-carousel.component';
import {RoomsCarouselComponent} from './components/home/modules/rooms-carousel/rooms/rooms-carousel.component';
import { GalleryDialogComponent } from './components/shared/gallery-dialog/gallery-dialog.component';
import {RouterModule} from '@angular/router';
import {MatStepperModule} from '@angular/material/stepper';
import { AdminAllplacesComponent } from './components/admin/admin-places/admin-allplaces/admin-allplaces.component';
import { AdminNewplaceComponent } from './components/admin/admin-places/admin-newplace/admin-newplace.component';
import { AdminSingleplaceComponent } from './components/admin/admin-places/admin-singleplace/admin-singleplace.component';
import { AdminListplacesComponent } from './components/admin/admin-places/admin-listplaces/admin-listplaces.component';
import { AdminUpdateplaceDialogComponent } from './components/admin/admin-places/admin-updateplace-dialog/admin-updateplace-dialog.component';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import { OfferBookingDialogComponent } from './components/shared/offer-booking/offer-booking-dialog/offer-booking-dialog.component';
import { OfferBookingConfiramtionDialogComponent } from './components/shared/offer-booking/offer-booking-confiramtion-dialog/offer-booking-confiramtion-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {SettingsComponent} from './components/admin/settings/settings.component';
import { BookingButtonComponent } from './components/home/modules/booking-button/booking-button.component';
import { AllmenuComponent } from './components/admin/admin-menu/allmenu/allmenu.component';
import { NewmenuComponent } from './components/admin/admin-menu/newmenu/newmenu.component';
import { MenusComponent } from './components/admin/admin-menu/menus/menus.component';
import { UpdatemenuComponent } from './components/admin/admin-menu/updatemenu/updatemenu.component';
import { WellnessComponent } from './components/home/pages/wellness/wellness.component';
import { ReservationsComponent } from './components/admin/admin-reservations/reservations/reservations.component';
import { ReservationComponent } from './components/admin/admin-reservations/reservation/reservation.component';
import { AdminOfferComponent } from './components/admin/admin-offers-module/admin-offer/admin-offer.component';
import { AdminOffersComponent } from './components/admin/admin-offers-module/admin-offers/admin-offers.component';
import { AdminOfferUpdateComponent } from './components/admin/admin-offers-module/admin-offer-update/admin-offer-update.component';
import { AdminOffersAllComponent } from './components/admin/admin-offers-module/admin-offers-all/admin-offers-all.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { OffersNewComponent } from './components/home/pages/offers/offers-new/offers-new.component';
import { OfferNewComponent } from './components/home/pages/offers/offer-new/offer-new.component';
import { OffersFestivalsComponent } from './components/home/pages/offers-festivals/offers-festivals/offers-festivals.component';
import { OffersFestivalComponent } from './components/home/pages/offers-festivals/offers-festival/offers-festival.component';
import { OffersSeminarsComponent } from './components/home/pages/offers-seminars/offers-seminars/offers-seminars.component';
import { OffersSeminarComponent } from './components/home/pages/offers-seminars/offers-seminar/offers-seminar.component';
import {SeminarBookingConfiramtionComponent} from './components/home/pages/offers-seminars/seminar-booking-confiramtion/seminar-booking-confiramtion.component';
import {SeminarBookingDialogComponent} from './components/home/pages/offers-seminars/seminar-booking-dialog/seminar-booking-dialog.component';
import { ConfigComponent } from './components/admin/config/config.component';
import {MatListModule} from '@angular/material/list';
import { PrivatePolicyCookiesComponent } from './components/shared/cookies-dialog/private-policy-cookies/private-policy-cookies.component';
import { PrivatsphaereComponent } from './components/shared/cookies-dialog/privatsphaere/privatsphaere.component';
import { TestingComponent } from './components/admin/testing/testing.component';
import {TranslatorPipe} from './pipes/TranslatorPipe';
import { AdminHotelConfigHomeComponent } from './components/admin/admin-hotel-config/admin-hotel-config-home/admin-hotel-config-home.component';
import { RoomsCarouselV2Component } from './components/home/modules/rooms-carousel-v2/rooms-carousel-v2.component';
import { HomeBannerComponent } from './components/home/modules/home-banner/home-banner.component';
import { HomeBannerV2Component } from './components/home/modules/home-banner-v2/home-banner-v2.component';
import { VideoComponent } from './components/home/modules/video/video.component';
import { VideoDialogComponent } from './components/home/modules/video/video-dialog/video-dialog.component';
import { AdminConfigLayoutComponent } from './components/admin/admin-config-layout/admin-config-layout.component';
import {MatRadioModule} from '@angular/material/radio';
import { ConfirmDialogComponent } from './components/shared/confirm-dialog/confirm-dialog.component';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import { ContentStatusComponent } from './components/admin/shared/content-status/content-status.component';
import {MenuComponent} from './components/admin/admin-menu/menu/menu.component';
import { JobsComponent } from './components/home/pages/job-page/jobs/jobs.component';
import { JobsDetailsComponent } from './components/home/pages/job-page/jobs-details/jobs-details.component';
import { AlljobsComponent } from './components/admin/jobs/alljobs/alljobs.component';
import { NewJobComponent } from './components/admin/jobs/new-job/new-job.component';
import { UpdateJobComponent } from './components/admin/jobs/update-job/update-job.component';
import { JobComponent } from './components/admin/jobs/job/job.component';
import { AdminJobsComponent } from './components/admin/jobs/admin-jobs/admin-jobs.component';
import {AngularFireModule} from '@angular/fire/compat';
import {GoogleMapsModule} from '@angular/google-maps';
import { ImpressumComponent } from './components/home/pages/impressum/impressum.component';
import { MonitorComponent } from './components/shared/monitor/monitor.component';
import { PackagesHotelNetSolutionsComponent } from './components/shared/packages-hotel-net-solutions/packages-hotel-net-solutions.component';
import { AccordionPostsComponent } from './components/admin/admin-posts/accordion-posts/accordion-posts.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { ContentStatusOldComponent } from './components/admin/shared/content-status-old/content-status-old.component';
import { BikeTourPageComponent } from './components/home/pages/bike-tour-page/bike-tour-page.component';
import {AllBikeToursComponent} from "./components/admin/admin-bike-tours/allbiketours/all-bike-tours.component";
import {BikeTourComponent} from "./components/admin/admin-bike-tours/biketour/bike-tour.component";
import {BikeToursComponent} from "./components/admin/admin-bike-tours/biketours/bike-tours.component";
import {NewBiketourComponent} from "./components/admin/admin-bike-tours/newbiketour/new-biketour.component";
import {UpdatebiketourComponent} from "./components/admin/admin-bike-tours/updatemenu/updatebiketour.component";

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    Header4Component,
    Footer2Component,
    AboutComponent,
    GalleryComponent,
    HomeTwoComponent,
    PlacesComponent,
    PlacesDetailsComponent,
    RestaurantComponent,
    BookingDialogComponent,
    LoginComponent,
    RegisterComponent,
    AdminpanelComponent,
    PostsComponent,
    PostComponent,
    ToolbarComponent,
    NewsOutletComponent,
    NewOutletComponent,
    NewsOutletDetailComponent,
    NewpostComponent,
    AllpostsComponent,
    NewroomComponent,
    RoomComponent,
    RoomsComponent,
    AllroomsComponent,
    AdminFooterComponent,
    ViewRoomComponent,
    ViewRoomsComponent,
    DeleteDialogComponent,
    TermsOfUseDialogComponent,
    UpdatepostComponent,
    UpdateroomComponent,
    RoomCarouselComponent,
    RoomsCarouselComponent,
    GalleryDialogComponent,
    AdminAllplacesComponent,
    AdminNewplaceComponent,
    AdminSingleplaceComponent,
    AdminListplacesComponent,
    AdminUpdateplaceDialogComponent,
    OfferBookingDialogComponent,
    OfferBookingConfiramtionDialogComponent,
    SettingsComponent,
    BookingButtonComponent,
    AllmenuComponent,
    NewmenuComponent,
    MenusComponent,
    UpdatemenuComponent,
    MenuComponent,
    WellnessComponent,
    ReservationsComponent,
    ReservationComponent,
    AdminOfferComponent,
    AdminOffersComponent,
    AdminOfferUpdateComponent,
    AdminOffersAllComponent,
    OffersNewComponent,
    OfferNewComponent,
    OffersFestivalsComponent,
    OffersFestivalComponent,
    OffersSeminarsComponent,
    OffersSeminarComponent,
    SeminarBookingConfiramtionComponent,
    SeminarBookingDialogComponent,
    ConfigComponent,
    PrivatePolicyCookiesComponent,
    PrivatsphaereComponent,
    TestingComponent,
    TranslatorPipe,
    AdminHotelConfigHomeComponent,
    RoomsCarouselV2Component,
    HomeBannerComponent,
    HomeBannerV2Component,
    VideoComponent,
    VideoDialogComponent,
    AdminConfigLayoutComponent,
    ConfirmDialogComponent,
    AdminConfigLayoutComponent,
    ContentStatusComponent,
    JobsComponent,
    JobsDetailsComponent,
    AlljobsComponent,
    NewJobComponent,
    UpdateJobComponent,
    JobComponent,
    AdminJobsComponent,
    ImpressumComponent,
    MonitorComponent,
    PackagesHotelNetSolutionsComponent,
    AccordionPostsComponent,
    ContentStatusOldComponent,
    BikeTourPageComponent,
    AllBikeToursComponent,
    BikeTourComponent,
    BikeToursComponent,
    NewBiketourComponent,
    UpdatebiketourComponent
  ],
    imports: [
        FormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        SlickCarouselModule,
        BrowserAnimationsModule,
        NgwWowModule,
        MatDialogModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatTableModule,
        CdkTableModule,
        MatSlideToggleModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatSidenavModule,
        NgxImageGalleryModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatTooltipModule,
        RouterModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        MatStepperModule,
        MatExpansionModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatRadioModule,
        GoogleMapsModule,
        DragDropModule
    ],
  providers: [AuthService,
    AuthGuard,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http);
}

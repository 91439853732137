import {Component, Input, OnInit} from '@angular/core';
import {Menu} from '../../../../models/menu.model';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {UpdatebiketourComponent} from '../updatemenu/updatebiketour.component';
import {BiketourService} from "../../../../services/biketour.service";
import {BikeTour} from "../../../../models/biketour.model";

@Component({
  selector: 'app-biketour',
  templateUrl: './bike-tour.component.html',
  styleUrls: ['./bike-tour.component.css']
})
export class BikeTourComponent implements OnInit {
  @Input() menu: BikeTour;
  url: SafeResourceUrl;

  constructor(private bikeTourService: BiketourService,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // this.url = this.sanitize(this.biketour.menuUrl);
  }

  deleteMenu() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bikeTourService.deletebiketour(this.menu.biketourId, this.menu.biketourUrl, this.menu.photoUrl);
      }
    });
  }

  UpdateMenu() {
    const dialogRef = this.dialog.open(UpdatebiketourComponent, {
      width: '600px',
      height:'80%',
      data: this.menu
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  sanitize(url: string) {
   // return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

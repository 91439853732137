import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RoomService} from '../../../../services/room.service';
import {Room} from '../../../../models/room.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-newroom',
  templateUrl: './newroom.component.html',
  styleUrls: ['./newroom.component.css']
})
export class NewroomComponent implements OnInit {
  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  bulletPointCtrl = new UntypedFormControl();
  filteredBulletPoints: Observable<string[]>;
  bulletPoints: string[] = [];
  allBulletPoints: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

////////////////////////////////////////////////////// Check List ////////////////////////////////////////////////////////
  visible2 = true;
  selectable2 = true;
  removable2 = true;
  separatorKeysCodes2: number[] = [ENTER, COMMA];
  bulletPointCtrl2 = new UntypedFormControl();
  filteredBulletPoints2: Observable<string[]>;
  checkList: string[] = [];
  allBulletPoints2: string[] = ['Balcony', 'More stuff'];
  @ViewChild('fruitInput2') fruitInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2') matAutocomplete2: MatAutocomplete;
  uploadEvent1: Event;
  uploadEvent2: Event;
  uploadEvent3: Event;
  uploadEvent4: Event;
  uploadEvent5: Event;
  uploadEvent6: Event;
  uploadEvent7: Event;
  uploadEvent8: Event;
  uploadEvent9: Event;
  uploadEvent10: Event;
  roomForm: UntypedFormGroup;
  room: Room;
  hasPhoto1: boolean;
  hasPhoto2: boolean;
  hasPhoto3: boolean;
  hasPhoto4: boolean;
  hasPhoto5: boolean;
  hasPhoto6: boolean;
  hasPhoto7: boolean;
  hasPhoto8: boolean;
  hasPhoto9: boolean;
  hasPhoto10: boolean;
  startSave: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private roomService: RoomService,
              private dialogRef: MatDialogRef<NewroomComponent>) {
    this.roomForm = this.formBuilder.group({
      content: ['', [Validators.required]],
      title: ['', [Validators.required]],
      price: [''],
      roomType: ['', [Validators.required]],
      public: [false, ''],
      extras1: [''],
      extras2: [''],
      extras3: [''],
      extras4: [''],
      extras5: [''],
      extras6: [''],
      extras7: [''],
      extras8: [''],
      extras9: [''],
      extras10: ['']
    });
    this.filteredBulletPoints = this.bulletPointCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allBulletPoints.slice()));

    this.filteredBulletPoints2 = this.bulletPointCtrl2.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter2(fruit) : this.allBulletPoints2.slice()));
  }

  ngOnInit(): void {
    this.startSave = false;
  }

  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.bulletPoints.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.bulletPointCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.bulletPoints.indexOf(fruit);

    if (index >= 0) {
      this.bulletPoints.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.bulletPointCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBulletPoints.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points //////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////// Check List ////////////////////////////////////////////////////////
  add2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.checkList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.bulletPointCtrl2.setValue(null);
  }

  remove2(fruit: string): void {
    const index = this.checkList.indexOf(fruit);

    if (index >= 0) {
      this.checkList.splice(index, 1);
    }
  }

  selected2(event: MatAutocompleteSelectedEvent): void {
    this.checkList.push(event.option.viewValue);
    this.fruitInput2.nativeElement.value = '';
    this.bulletPointCtrl2.setValue(null);
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBulletPoints2.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////// End Check List ///////////////////////////////////////////////

  addRoom() {
    if (this.roomForm.invalid !== true && this.startSave) {
      this.room = new Room(this.roomForm.value);
      this.room.bulletPoints = this.bulletPoints;
      this.room.checkList = this.checkList;
      this.roomService.addRoom(
        this.uploadEvent1,
        this.uploadEvent2,
        this.uploadEvent3,
        this.uploadEvent4,
        this.uploadEvent5,
        this.uploadEvent6,
        this.uploadEvent7,
        this.uploadEvent8,
        this.uploadEvent9,
        this.uploadEvent10,
        this.room);
      this.dialogRef.close(true);
    }
  }

  pictureToUpload1($event: Event) {
    this.uploadEvent1 = $event;
    this.hasPhoto1 = true;
  }
  pictureToUpload2($event: Event) {
    this.uploadEvent2 = $event;
    this.hasPhoto2 = true;
  }
  pictureToUpload3($event: Event) {
    this.uploadEvent3 = $event;
    this.hasPhoto3 = true;
  }
  pictureToUpload4($event: Event) {
    this.uploadEvent4 = $event;
    this.hasPhoto4 = true;
  }

  pictureToUpload5($event: Event) {
    this.uploadEvent5 = $event;
    this.hasPhoto5 = true;
  }
  pictureToUpload6($event: Event) {
    this.uploadEvent6 = $event;
    this.hasPhoto6 = true;
  }
  pictureToUpload7($event: Event) {
    this.uploadEvent7 = $event;
    this.hasPhoto7 = true;
  }
  pictureToUpload8($event: Event) {
    this.uploadEvent8 = $event;
    this.hasPhoto8 = true;
  }
  pictureToUpload9($event: Event) {
    this.uploadEvent9 = $event;
    this.hasPhoto9 = true;
  }
  pictureToUpload10($event: Event) {
    this.uploadEvent10 = $event;
    this.hasPhoto10 = true;
  }

  startSaveing() {
    this.startSave = true;
  }
}

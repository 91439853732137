<app-header4></app-header4>
<!--====== BREADCRUMB PART START ======-->
<section class="breadcrumb-area"
         style="background-image: url(../../../../../assets/img/places/laga.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <!-- <span>{{'places.title' | translate}}</span> -->
            <h2 class="page-title">{{'places.tourist-attractions' | translate}}</h2>

            <ul class="breadcrumb-nav">
                <li><a routerLink='/'>Home</a></li>
                <li class="active">{{'places.tourist-attractions' | translate}}</li>
            </ul>
        </div>
    </div>
</section>
<!--====== BREADCRUMB PART END ======-->
<div style="margin-top: 30px; margin-left: 10px; margin-right: 10px" *ngIf="true">
  <google-map height="500px" width="100%" [zoom]="zoom" [center]="center" [options]="options" >
    <map-marker *ngFor="let marker of markers"
                [position]="marker.position"
                [options]="marker.options">
    </map-marker>
    <map-directions-renderer [directions]="directions"></map-directions-renderer>
  </google-map>
</div>

<div *ngIf="routeResponse" style="margin-top: 10px; margin-left: 10px">
  <p>Entfernung vom Hotel mit dem Fahrrad: <span style="font-weight: bold">{{routeResponse?.routes[0].legs[0].distance.text}}</span></p>
  <p>Zeit: <span style="font-weight: bold">{{routeResponse?.routes[0].legs[0].duration.text}}</span></p>



</div>
<!--====== PLACES CONTENT START ======-->
<section class="places-wrapper pt-25 pb-25">
    <div class="container">

        <div class="places-boxes">
            <!-- Title -->
            <div class="section-title text-center mb-10">
                <span class="title-tag">{{'places.popular' | translate}}</span>
                <!-- <h2>Sehenswürdigkeiten</h2> -->
            </div>
            <div class="row justify-content-center">
                <div *ngFor="let place of places" class="col-lg-3 col-md-4 col-sm-6 col-10">
                    <div class="place-box mt-30">
                        <div class="place-bg-wrap">
                            <div (click)="showonmaps(place.destinationLat, place.destinationLng)" class="place-bg"
                                 style="cursor: pointer; background-image: url('{{ place.photoUrl1 }}');">
                            </div>
                        </div>
                      <!-- ///////////////////////// Start german ///////////////////////////// -->
                        <div *ngIf="currentlang == 'de'" class="desc" >
                            <h4><a (click)="showonmaps(place.destinationLat, place.destinationLng)" class="place-titel" >{{ place?.title }}</a></h4>
                            <!-- <span class="time">{{ place.title }}</span> -->
                          <button [routerLink]="['/places-details', {id: place.placeId}]" style="margin: 8px" mat-stroked-button color="primary">Details</button>
                          <mat-icon (click)="showonmaps(place.destinationLat, place.destinationLng)" style="color: #bead8e; margin: 10px; cursor: pointer">location_on</mat-icon>
                        </div>
                      <!-- ///////////////////////// Start english ///////////////////////////// -->
                      <div *ngIf="currentlang == 'en'" class="desc" >
                        <h4><a (click)="showonmaps(place.destinationLat, place.destinationLng)" class="place-titel" >{{ place?.titleEN }}</a></h4>
                        <!-- <span class="time">{{ place.title }}</span> -->
                        <button [routerLink]="['/places-details', {id: place.placeId}]" style="margin: 8px" mat-stroked-button color="primary">Details</button>
                        <mat-icon (click)="showonmaps(place.destinationLat, place.destinationLng)" style="color: #bead8e; margin: 10px; cursor: pointer">location_on</mat-icon>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<hr>
<mat-card style="margin: 10px">
  <mat-card-title>Weitere Sehenswürdigkeiten</mat-card-title>
  <mat-card-subtitle>Viele Gronauer planen Ihren Urlaub aufgrund der aktuellen Situation in der eigenen Heimat.
    In der neuen Broschüre „Deine Zeit in Gronau“ erhalten sowohl Gronauer als auch Touristen viele Geheimtipps
    aus der Stadt mit vielen tollen Aktivitäten.
    <p>Die Broschüre kann hier heruntergeladen werden!!!</p>
  </mat-card-subtitle>
  <mat-card-actions align="end">
    <button (click)="openPdf()" mat-stroked-button color="primary">Zum PDF der Stadt Gronau</button>
  </mat-card-actions>
</mat-card>

<!--====== PLACES CONTENT END ======-->
<app-footer2></app-footer2>

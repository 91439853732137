import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PlaceService} from '../../../../services/place.service';
import {Place} from '../../../../models/place.model';
import {LanguageService} from '../../../../services/language.service';

@Component({
  selector: 'app-places-details',
  templateUrl: './places-details.component.html',
  styleUrls: ['./places-details.component.css']
})
export class PlacesDetailsComponent implements OnInit {
  place: Place;
  public lat = 52.177581;
  public lng = 7.0387851;
  public origin: any;
  public destination: any;
  currentLang: string;

  constructor(private router: ActivatedRoute,
              private placeService: PlaceService,
              private langService: LanguageService) {
    this.langService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
    this.origin = {lat: this.lat, lng: this.lng};
    this.router.params.subscribe(placeId => {
      this.placeService.getPlaceById(placeId.id).subscribe(place => {
        this.place = place;
        const numLat = Number(place.destinationLat);
        const numLng = Number(place.destinationLng);
        this.destination = {lat: numLat, lng: numLng};
      });
    });
  }

  ngOnInit(): void {
  }

  openWesiteInTab() {
      window.open(this.place.website, '_blank');
  }
}

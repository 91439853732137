<ngx-slick-carousel class="banner-area banner-style-two" id="bannerSlider" #slickModal="slick-carousel"
                    [config]="herosliderConfig">
  <div ngxSlickItem *ngFor="let slide of slides"
       class="single-banner d-flex align-items-center justify-content-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="banner-content text-center">

            <h1 class="title" data-animation="fadeInLeft" data-delay=".9s">{{'home.welcome' | translate}}</h1>
            <ul>

            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-bg" style="background-image: url('{{slide.img}}');"></div>
    <div class="banner-overly"></div>
  </div>
</ngx-slick-carousel>

<!--max-height: 800px for video to make it smaller-->

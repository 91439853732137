export class Post{
  index: number;
  postId: string;
  CreatorId: string;
  postType: string;
  public: boolean;
  title: string;
  titleEN: string;
  link: string;
  linkTitle: string;
  linkTitleEN: string;
  content: string;
  contentEN: string;
  views: string;
  likes: string;
  createdDate: any;
  editedDate: any;
  photoUrl: string;

  constructor(postData: any){
    if (postData.postId != null) {
      this.postId = postData.postId;
    } else {
      this.postId = '';
    }
    if (postData.CreatorId != null) {
      this.CreatorId = postData.CreatorId;
    } else {
      this.CreatorId = '';
    }
    if (postData.postType != null) {
      this.postType = postData.postType;
    } else {
      this.postType = '';
    }
    if (postData.public != null) {
      this.public = postData.public;
    } else {
      this.public = false;
    }
    if (postData.title != null) {
      this.title = postData.title;
    } else {
      this.title = '';
    }
    if (postData.titleEN != null) {
      this.titleEN = postData.titleEN;
    } else {
      this.titleEN = '';
    }
    if (postData.link != null) {
      this.link = postData.link;
    } else {
      this.link = '';
    }
    if (postData.linkTitle != null) {
      this.linkTitle = postData.linkTitle;
    } else {
      this.linkTitle = '';
    }
    if (postData.linkTitleEN != null) {
      this.linkTitleEN = postData.linkTitleEN;
    } else {
      this.linkTitleEN = '';
    }
    if (postData.content != null) {
      this.content = postData.content;
    } else {
      this.content = '';
    }
    if (postData.contentEN != null) {
      this.contentEN = postData.contentEN;
    } else {
      this.contentEN = '';
    }
    if (postData.views != null) {
      this.views = postData.views;
    } else {
      this.views = '0';
    }
    if (postData.likes) {
      this.likes  = postData.likes;
    } else {
      this.likes  = '';
    }
    if (postData.createdDate != null) {
      this.createdDate = postData.createdDate;
    } else {
      this.createdDate = Date.now();
    }
    if (postData.editedDate != null) {
      this.editedDate = postData.editedDate;
    } else {
      this.editedDate = Date.now();
    }
    if (postData.photoUrl != null) {
      this.photoUrl = postData.photoUrl;
    } else {
      this.photoUrl = '';
    }
  }
}

<app-header4></app-header4>
<!--====== Banner Start ======-->
<section class="breadcrumb-area restaurant-banner-pic" title="Picture show's a Table in the restaurant">
    <div class="container">
        <div class="breadcrumb-text">
            <h2 class="page-title">{{'restaurant.banner-title' | translate}}</h2>
            <ul class="breadcrumb-nav">
                <li><a routerLink='/'>{{'restaurant.banner-link-left' | translate}}</a></li>
                <li class="active">{{'restaurant.banner-link-right' | translate}}</li>
            </ul>
        </div>
    </div>
</section>
<!--====== Banner End ======-->

<!--====== Title Start ======-->
<section class="about-section pt-10 pb-30">
  <div class="container">
    <div class="section-title about-title text-center">
      <span class="title-tag">{{'restaurant.header-span' | translate}}<span>1898</span></span>
      <h2>{{'restaurant.header-h2' | translate}}</h2>
    </div>
  </div>
</section>
<!--====== Title End ======-->

<!--====== Menus Start ======-->
<div *ngFor="let menu of menus">
  <section class="about-section pt-10 pb-10">
    <!-- ///////////////////////////////////////////// German Start ///////////////////////////////////// -->
    <div *ngIf="currentLang == 'de'" class="container">
      <section class="about-section">
        <div class="container">
          <div class="section-title about-title text-center">
            <span class="title-tag">{{menu?.headerDescription}}</span>
          </div>
        </div>
      </section>
      <div class="about-text-box">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img">
              <img [src]="menu?.photoUrl"
                   alt="Picture show's Restaurant or a Dish"
                   title="Picture show's Restaurant or a Dish">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-text">
              <span>{{menu?.title}}</span>
              <p>
                {{menu?.content}}
              </p>
            </div>
            <a target="_blank" [attr.href]="menu?.menuUrl" class="main-btn1 btn-filled1 datepickerSize">Speisekarte<mat-icon class="arrowButton">arrow_right_alt</mat-icon></a>
          </div>
        </div>
      </div>
    </div>
    <!-- ///////////////////////////////////////////// English Start ///////////////////////////////////// -->
    <div *ngIf="currentLang == 'en'" class="container">
      <section class="about-section">
        <div class="container">
          <div class="section-title about-title text-center">
            <span class="title-tag">{{menu?.headerDescriptionEN}}</span>
          </div>
        </div>
      </section>
      <div class="about-text-box">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img">
              <img [src]="menu?.photoUrl"
                   alt="Picture show's Restaurant or a Dish"
                   title="Picture show's Restaurant or a Dish">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-text">
              <span>{{menu?.titleEN}}</span>
              <p>
                {{menu?.contentEN}}
              </p>
            </div>
            <a target="_blank" [attr.href]="menu?.menuUrl" class="main-btn1 btn-filled1 datepickerSize">Menu<mat-icon class="arrowButton">arrow_right_alt</mat-icon></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!--====== Menus End ======-->

<app-footer2></app-footer2>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'translator' })
export class TranslatorPipe implements PipeTransform {

  transform(text: string) {
    return text + ': Cool Translator';
  }

  constructor() {
  }

}


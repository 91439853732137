<div class="post-container">

  <div style="text-align: center; margin-top: 20px; margin-bottom: 10px">
    <mat-icon style="transform: scale(2)" (click)="openDialogAddRoom()"
              class="add-room-icon">add_box
    </mat-icon>
    <span style="margin-left: 20px">Add New Post</span>
  </div>

  <div style=" display: block;
     margin-bottom: 10px;
     margin-left: 5px;
     margin-right: 5px;
     border-color: #c8c8c8;
     border-width: 2px;
     border-style: solid;
     justify-content: center;
     align-items: center;">
    <mat-form-field style="margin: 5px">
      <mat-label>Online Filter</mat-label>
      <mat-select [(ngModel)]="selectedValue" name="Offer Type" >
        <mat-option *ngFor="let filter of onlineType" [value]="filter.value">
          {{filter.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div cdkDropList  (cdkDropListDropped)="drop($event)">
    <div style="margin-top: 10px;margin-bottom: 10px; margin-left: 5px; margin-right: 5px" *ngFor="let post of posts" cdkDrag>
      <div *ngIf="post.public === true && selectedValue ==='online' ||
      post.public === false && selectedValue ==='offline' ||
      selectedValue ==='all'">
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header style="display: flex; justify-content: space-between">
              <mat-panel-title >
                <p style="width: 35ch; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{post.title}}</p>
              </mat-panel-title>
              <mat-panel-description class="accordion-description">
                <p><b>created:</b> {{post?.createdDate | date:'dd-MM-yyyy'}}</p>
              </mat-panel-description>
              <app-content-status [isOnline]="post.public"></app-content-status>
              <mat-icon >arrow_drop_down</mat-icon>
            </mat-expansion-panel-header>

            <div style="float: left; max-width: 300px">
              <p >{{post.content}}</p>
              <button (click)="UpdatePost(post)" mat-stroked-button color="primary" style="margin-right: 5px">Edit</button>
              <button (click)="deletePost(post)" mat-stroked-button color="warn">Delete</button>
            </div>

            <div>
              <img class="accordion-img" [src]="post.photoUrl">
            </div>

          </mat-expansion-panel>

        </mat-accordion>
      </div>

    </div>
  </div>
</div>


import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgwWowService} from "ngx-wow";
import {MatDialog} from "@angular/material/dialog";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'app-home-banner-v2',
  templateUrl: './home-banner-v2.component.html',
  styleUrls: ['./home-banner-v2.component.css']
})
export class HomeBannerV2Component implements OnInit {
  bookingForm: UntypedFormGroup;
  isTouch: boolean;
  slides = [
    { img: 'assets/img/home/HotelBackView.jpg'},
    { img: 'assets/img/home/HotelEntrance-2.jpg'}
  ];

  herosliderConfig = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    fade: true,
    arrows: true,
    prevArrow:
      '<div class="slick-arrow prev-arrow"><i class="fal fa-arrow-left"></i></div>',
    nextArrow:
      '<div class="slick-arrow next-arrow"><i class="fal fa-arrow-right"></i></div>',
  };

  constructor(private wowService: NgwWowService,
              private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder) {
    this.wowService.init();
    this.bookingForm = this.formBuilder.group({
      checkInDate: [''],
      checkOutDate: [''],
      durationInDays: [''],
      persons: [1]
    });
  }

  @ViewChild('reviews') reviewsView: ElementRef;
  @ViewChild('video') video: ElementRef;

  ngOnInit(): void {
    this.isTouch = 'ontouchstart' in document.documentElement;
  }

}

import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {JobService} from '../../../../services/job.service';
import {JobModel} from '../../../../models/job.model';

@Component({
  selector: 'app-admin-jobs',
  templateUrl: './admin-jobs.component.html',
  styleUrls: ['./admin-jobs.component.css']
})
export class AdminJobsComponent implements OnInit {
  jobs: JobModel[];
  constructor(private authService: AuthService,
              private jobService: JobService) { }

  ngOnInit(): void {
   this.jobService.getAllJobs().subscribe(jobs => {
     this.jobs = jobs;
   });
  }

}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Room} from '../../../../../models/room.model';
import {RoomService} from '../../../../../services/room.service';
import {LanguageService} from '../../../../../services/language.service';

@Component({
  selector: 'app-carousel-rooms',
  templateUrl: './rooms-carousel.component.html',
  styleUrls: ['./rooms-carousel.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomsCarouselComponent implements OnInit {
  rooms: Room;
  featureroomsliderConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  constructor(private roomService: RoomService, private langService: LanguageService) {
    this.roomService.getPublicRooms().subscribe(rooms => {
      this.rooms = rooms;
    });
  }

  ngOnInit(): void {
    this.langService.getCurrentLanguage().subscribe(res => {
      this.roomService.getPublicRooms().subscribe(rooms => {
        this.rooms = rooms;
      });
    });
  }

}

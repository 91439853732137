import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {forkJoin, Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Menu} from '../models/menu.model';
import {finalize} from 'rxjs/operators';
import {GoogleTranslatorService} from './google-translator.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  private menuCollection: AngularFirestoreCollection<any>;
  private menuDocument: AngularFirestoreDocument<any>;
  menus: Observable<any>;
  menu: Observable<any>;
  userId: string;
  uploadPercent: Observable<number>;

  constructor(private authService: AuthService,
              private afs: AngularFirestore,
              private afStorage: AngularFireStorage,
              private translator: GoogleTranslatorService) {
      this.menuCollection = this.afs.collection<any>('menu', ref => ref
        .orderBy('editedDate', 'desc'));
      this.menus = this.menuCollection.valueChanges();
      this.authService.afAuth.authState.subscribe(authState => {
        if (authState) {
          this.userId = authState.uid;
      }
    });
  }

  getMenus() {
    return this.menus;
  }

  getMenuById(menuId: string) {
    this.menuDocument = this.afs.doc<any>('biketour/' + menuId);
    this.menu = this.menuDocument.valueChanges();
    return this.menu;
  }

  getPublicMenus() {
    this.menuCollection = this.afs.collection<any>('menu', ref => ref
      .where('public', '==', true).orderBy('editedDate', 'desc'));
    this.menus = this.menuCollection.valueChanges();
    return this.menus;
  }

  addMenu(uploadEvent,
          uploadEvent2,
          menu: Menu) {
    const resContentEN = this.translator.translate(menu.content);
    const resTitleEN = this.translator.translate(menu.title);
    const resTypeEN = this.translator.translate(menu.menuType);
    const resHeaderEN = this.translator.translate(menu.headerDescription);
    forkJoin(resContentEN, resTitleEN, resTypeEN, resHeaderEN).subscribe((res: any) => {
      menu.contentEN = res[0].data.translations[0].translatedText;
      menu.titleEN = res[1].data.translations[0].translatedText;
      menu.menuTypeEN = res[2].data.translations[0].translatedText;
      menu.headerDescriptionEN = res[3].data.translations[0].translatedText;
      this.afs.collection('menu').add(Object.assign({}, menu)).then(res => {
        this.afs.collection('menu').doc(res.id).update({menuId: res.id, CreatorId: this.userId});
        this.uploadMenuPictures(uploadEvent, 'menuUrl', res.id);
        this.uploadMenuPictures(uploadEvent2, 'photoUrl', res.id);
      });
    });
  }

  updateMenu(uploadEvent,
             uploadEvent2,
             menu: Menu) {
    const resContentEN = this.translator.translate(menu.content);
    const resTitleEN = this.translator.translate(menu.title);
    const resTypeEN = this.translator.translate(menu.menuType);
    const resHeaderEN = this.translator.translate(menu.headerDescription);
    forkJoin(resContentEN, resTitleEN, resTypeEN, resHeaderEN).subscribe((res: any) => {
      menu.contentEN = res[0].data.translations[0].translatedText;
      menu.titleEN = res[1].data.translations[0].translatedText;
      menu.menuTypeEN = res[2].data.translations[0].translatedText;
      menu.headerDescriptionEN = res[3].data.translations[0].translatedText;
      this.afs.collection('menu').doc(menu.menuId)
        .update({
          headerDescription: menu.headerDescription,
          headerDescriptionEN: menu.headerDescriptionEN,
          title: menu.title,
          titleEN: menu.titleEN,
          content: menu.content,
          contentEN: menu.contentEN,
          public: menu.public,
          menuType: menu.menuType,
          menuTypeEN: menu.menuTypeEN,
          editedDate: Date.now()
        });
    });
    if (uploadEvent != null) {
      this.uploadMenuPictures(uploadEvent, 'menuUrl', menu.menuId);
    }
    if (uploadEvent2 != null) {
      this.uploadMenuPictures(uploadEvent2, 'photoUrl', menu.menuId);
    }
  }

  uploadMenuPictures(event, field: string, menuId: string) {
    const file = event.target.files[0];
    const filePath = 'MenuPictures/' + this.userId + '/' + Date.now().toString();
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          this.afs.collection('menu').doc(menuId).update({[field]: res});
        });
      })
    ).subscribe();
  }

  deleteMenuPhoto(menuUrl: string, postId: string) {
    this.afStorage.refFromURL(menuUrl).delete();
    this.afs.collection('menu').doc(postId).update({menuUrl: ''});
  }

  deletePhoto(postPhotoUrl: string, postId: string) {
    this.afStorage.refFromURL(postPhotoUrl).delete();
    this.afs.collection('menu').doc(postId).update({photoUrl: ''});
  }

  deleteMenu(menuId: string, menuUrl: string, photoUrl: string) {
    if (menuUrl) {
      this.deleteMenuPhoto(menuUrl, menuId);
    }
    if (photoUrl) {
      this.deletePhoto(photoUrl, menuId);
    }
    this.afs.collection('menu').doc(menuId).delete().then();
  }



}

import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Offer} from '../../../../models/offer.model';
import {OfferService} from '../../../../services/offer.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-admin-offer-update',
  templateUrl: './admin-offer-update.component.html',
  styleUrls: ['./admin-offer-update.component.css']
})
export class AdminOfferUpdateComponent implements OnInit {
  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  bulletPointCtrl = new UntypedFormControl();
  filteredBulletPoints: Observable<string[]>;
  bulletPoints: string[] = [];
  allBulletPoints: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

////////////////////////////////////////////////////// Check List ////////////////////////////////////////////////////////
  visible2 = true;
  selectable2 = true;
  removable2 = true;
  separatorKeysCodes2: number[] = [ENTER, COMMA];
  bulletPointCtrl2 = new UntypedFormControl();
  filteredBulletPoints2: Observable<string[]>;
  checkList: string[] = [];
  allBulletPoints2: string[] = ['Balcony', 'More stuff'];

  @ViewChild('fruitInput2') fruitInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2') matAutocomplete2: MatAutocomplete;

  uploadEvent1: Event;
  uploadEvent2: Event;
  uploadEvent3: Event;
  uploadEvent4: Event;
  uploadEvent5: Event;
  uploadEvent6: Event;
  uploadEvent7: Event;
  uploadEvent8: Event;
  uploadEvent9: Event;
  uploadEvent10: Event;
  OfferForm: UntypedFormGroup;
  offer: Offer;
  hasPhoto1: boolean;
  hasPhoto2: boolean;
  hasPhoto3: boolean;
  hasPhoto4: boolean;
  hasPhoto5: boolean;
  hasPhoto6: boolean;
  hasPhoto7: boolean;
  hasPhoto8: boolean;
  hasPhoto9: boolean;
  hasPhoto10: boolean;
  timeLeft = 100;
  interval;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';

  private isUpdate: boolean;
  constructor(private formBuilder: UntypedFormBuilder,
              private offerService: OfferService,
              @Inject(MAT_DIALOG_DATA) public data: Offer,
              private dialog: MatDialog) {
    this.OfferForm = this.formBuilder.group({
      content: ['', [Validators.required]],
      title: ['', [Validators.required]],
      price: [''],
      offerType: ['', [Validators.required]],
      public: [false, ''],
      extras1: [''],
      extras2: [''],
      extras3: [''],
      extras4: [''],
      extras5: [''],
      extras6: [''],
      extras7: [''],
      extras8: [''],
      extras9: [''],
      extras10: ['']
    });
    this.filteredBulletPoints = this.bulletPointCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allBulletPoints.slice()));

    this.filteredBulletPoints2 = this.bulletPointCtrl2.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter2(fruit) : this.allBulletPoints2.slice()));
  }

  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.bulletPoints.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.bulletPointCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.bulletPoints.indexOf(fruit);
    if (index >= 0) {
      this.bulletPoints.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.bulletPointCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allBulletPoints.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points //////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////// Check List ////////////////////////////////////////////////////////
  add2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.checkList.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.bulletPointCtrl2.setValue(null);
  }

  remove2(fruit: string): void {
    const index = this.checkList.indexOf(fruit);
    if (index >= 0) {
      this.checkList.splice(index, 1);
    }
  }

  selected2(event: MatAutocompleteSelectedEvent): void {
    this.checkList.push(event.option.viewValue);
    this.fruitInput2.nativeElement.value = '';
    this.bulletPointCtrl2.setValue(null);
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allBulletPoints2.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////// End Check List ///////////////////////////////////////////////

  ngOnInit(): void {
    this.timeLeft = 0;
    if (this.data != null) {
      this.isUpdate = true;
      this.offer = this.data;
      this.OfferForm.controls.content.setValue(this.data.content);
      this.OfferForm.controls.title.setValue(this.data.title);
      this.OfferForm.controls.price.setValue(this.data.price);
      this.OfferForm.controls.offerType.setValue(this.data.offerType);
      this.OfferForm.controls.public.setValue(this.data.public);
      this.OfferForm.controls.extras1.setValue(this.data.extras1);
      this.OfferForm.controls.extras2.setValue(this.data.extras2);
      this.OfferForm.controls.extras3.setValue(this.data.extras3);
      this.OfferForm.controls.extras4.setValue(this.data.extras4);
      this.OfferForm.controls.extras5.setValue(this.data.extras5);
      this.OfferForm.controls.extras6.setValue(this.data.extras6);
      this.OfferForm.controls.extras7.setValue(this.data.extras7);
      this.OfferForm.controls.extras8.setValue(this.data.extras8);
      this.OfferForm.controls.extras9.setValue(this.data.extras9);
      this.OfferForm.controls.extras10.setValue(this.data.extras10);
      this.bulletPoints = this.data.bulletPoints;
      this.checkList = this.data.checkList;
    } else {
      this.isUpdate = false;
    }
  }

  addOffer() {
    if (this.isUpdate === false) {
      this.offer = new Offer(this.OfferForm.value);
      this.offer.bulletPoints = this.bulletPoints;
      this.offer.checkList = this.checkList;
      this.offerService.addOffer(
        this.uploadEvent1,
        this.uploadEvent2,
        this.uploadEvent3,
        this.uploadEvent4,
        this.uploadEvent5,
        this.uploadEvent6,
        this.uploadEvent7,
        this.uploadEvent8,
        this.uploadEvent9,
        this.uploadEvent10,
        this.offer);
    } else if (this.isUpdate) {
      this.offer = new Offer(this.OfferForm.value);
      this.offer.bulletPoints = this.bulletPoints;
      this.offer.checkList = this.checkList;
      this.offer.offerId = this.data.offerId;
      this.offerService.updateOffer(
        this.uploadEvent1,
        this.uploadEvent2,
        this.uploadEvent3,
        this.uploadEvent4,
        this.uploadEvent5,
        this.uploadEvent6,
        this.uploadEvent7,
        this.uploadEvent8,
        this.uploadEvent9,
        this.uploadEvent10,
        this.offer);
    }
  }

  pictureToUpload1($event: Event) {
    this.uploadEvent1 = $event;
    this.hasPhoto1 = true;
  }
  pictureToUpload2($event: Event) {
    this.uploadEvent2 = $event;
    this.hasPhoto2 = true;
  }
  pictureToUpload3($event: Event) {
    this.uploadEvent3 = $event;
    this.hasPhoto3 = true;
  }
  pictureToUpload4($event: Event) {
    this.uploadEvent4 = $event;
    this.hasPhoto4 = true;
  }

  pictureToUpload5($event: Event) {
    this.uploadEvent5 = $event;
    this.hasPhoto5 = true;
  }
  pictureToUpload6($event: Event) {
    this.uploadEvent6 = $event;
    this.hasPhoto6 = true;
  }
  pictureToUpload7($event: Event) {
    this.uploadEvent7 = $event;
    this.hasPhoto7 = true;
  }
  pictureToUpload8($event: Event) {
    this.uploadEvent8 = $event;
    this.hasPhoto8 = true;
  }
  pictureToUpload9($event: Event) {
    this.uploadEvent9 = $event;
    this.hasPhoto9 = true;
  }
  pictureToUpload10($event: Event) {
    this.uploadEvent10 = $event;
    this.hasPhoto10 = true;
  }

  deletePhoto1(photoUrl: string) {
    if (this.offer.photoUrl1) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl1');
          this.hasPhoto1 = false;
          this.offer.photoUrl1 = '';
        }
      });
    }
  }

  deletePhoto2(photoUrl: string) {
    if (this.offer.photoUrl2) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl2');
          this.hasPhoto2 = false;
          this.offer.photoUrl2 = '';
        }
      });
    }
  }

  deletePhoto3(photoUrl: string) {
    if (this.offer.photoUrl3) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl3');
          this.hasPhoto3 = false;
          this.offer.photoUrl3 = '';
        }
      });
    }
  }

  deletePhoto4(photoUrl: string) {
    if (this.offer.photoUrl4) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl4');
          this.hasPhoto4 = false;
          this.offer.photoUrl4 = '';
        }
      });
    }
  }

  deletePhoto5(photoUrl: string) {
    if (this.offer.photoUrl5) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl5');
          this.hasPhoto5 = false;
          this.offer.photoUrl5 = '';
        }
      });
    }
  }

  deletePhoto6(photoUrl: string) {
    if (this.offer.photoUrl6) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl6');
          this.hasPhoto6 = false;
          this.offer.photoUrl6 = '';
        }
      });
    }
  }

  deletePhoto7(photoUrl: string) {
    if (this.offer.photoUrl7) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl7');
          this.hasPhoto7 = false;
          this.offer.photoUrl7 = '';
        }
      });
    }
  }

  deletePhoto8(photoUrl: string) {
    if (this.offer.photoUrl8) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl8');
          this.hasPhoto8 = false;
          this.offer.photoUrl8 = '';
        }
      });
    }
  }

  deletePhoto9(photoUrl: string) {
    if (this.offer.photoUrl9) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl9');
          this.hasPhoto9 = false;
          this.offer.photoUrl9 = '';
        }
      });
    }
  }

  deletePhoto10(photoUrl: string) {
    if (this.offer.photoUrl10) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.offerService.deleteOfferPhoto(photoUrl, this.offer.offerId, 'photoUrl10');
          this.hasPhoto10 = false;
          this.offer.photoUrl10 = '';
        }
      });
    }
  }

}

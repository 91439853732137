import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { HomeTwoComponent } from './components/home/pages/home-two/home-two.component';
import { AboutComponent } from './components/home/pages/about/about.component';
import { GalleryComponent } from './components/home/pages/gallery/gallery.component';
import { PlacesComponent } from './components/home/pages/places/places.component';
import { PlacesDetailsComponent } from './components/home/pages/places-details/places-details.component';
import { RestaurantComponent } from './components/home/pages/restaurant/restaurant.component';
import {BookingDialogComponent} from './components/shared/bookingdialog/booking-dialog.component';
import {LoginComponent} from './components/admin/login/login.component';
import {RegisterComponent} from './components/admin/register/register.component';
import {AdminpanelComponent} from './components/admin/adminpanel/adminpanel.component';
import {AuthGuard} from './services/auth.guard';
import {AllpostsComponent} from './components/admin/admin-posts/allposts/allposts.component';
import {AllroomsComponent} from './components/admin/admin-rooms/allrooms/allrooms.component';
import {ViewRoomsComponent} from './components/home/pages/room-list/elements/rooms/view-rooms.component';
import {AdminAllplacesComponent} from './components/admin/admin-places/admin-allplaces/admin-allplaces.component';
import {SettingsComponent} from './components/admin/settings/settings.component';
import {AllmenuComponent} from './components/admin/admin-menu/allmenu/allmenu.component';
import {WellnessComponent} from './components/home/pages/wellness/wellness.component';
import {ReservationsComponent} from './components/admin/admin-reservations/reservations/reservations.component';
import {AdminOffersAllComponent} from './components/admin/admin-offers-module/admin-offers-all/admin-offers-all.component';
import {OffersNewComponent} from './components/home/pages/offers/offers-new/offers-new.component';
import {OffersFestivalsComponent} from './components/home/pages/offers-festivals/offers-festivals/offers-festivals.component';
import {OffersSeminarsComponent} from './components/home/pages/offers-seminars/offers-seminars/offers-seminars.component';
import {ConfigComponent} from './components/admin/config/config.component';
import {PrivatePolicyCookiesComponent} from './components/shared/cookies-dialog/private-policy-cookies/private-policy-cookies.component';
import {PrivatsphaereComponent} from './components/shared/cookies-dialog/privatsphaere/privatsphaere.component';
import {TestingComponent} from './components/admin/testing/testing.component';
import {AdminHotelConfigHomeComponent} from './components/admin/admin-hotel-config/admin-hotel-config-home/admin-hotel-config-home.component';
import {AdminConfigLayoutComponent} from './components/admin/admin-config-layout/admin-config-layout.component';
import {JobsComponent} from './components/home/pages/job-page/jobs/jobs.component';
import {JobsDetailsComponent} from './components/home/pages/job-page/jobs-details/jobs-details.component';
import {AlljobsComponent} from './components/admin/jobs/alljobs/alljobs.component';
import {BikeTourPageComponent} from "./components/home/pages/bike-tour-page/bike-tour-page.component";
import {AllBikeToursComponent} from "./components/admin/admin-bike-tours/allbiketours/all-bike-tours.component";

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {
    path: 'home', component: HomeTwoComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Zimmer Buchen | Wellness | Arragements | Öffnungszeiten ',
      description: 'Im Hotel Schepers in Gronau im Ortsteil Epe ist Tradition spürbar, die Ruhe und Ursprünglichkeit der Landschaft wirkt entspannend. ' +
        'Ein Hotel, um neue Energie zu schöpfen, zum Wohlfühlen, zum Entspannen, zum Arbeiten und Tagen oder einfach das Leben zu genießen. Wir möchten, ' +
        'dass Sie sich bei uns in unseren schönen Suiten und Hotelzimmern wohlfühlen. Am Flüsschen Dinkel wartet mit einem der schönsten Radwegenetze des ' +
        'Münsterlandes auf sie. Darüber hinaus erwartet Sie im Hotel Schepers eine außergewöhnliche Ausstattung, viel moderne Kunst und ein hervorragendes Restaurant.',
      ogUrl: 'www.hotel-schepers/home'
    }
   },
  {
    path: 'about', component: AboutComponent,
    data: {
      title: 'Hotel-Restaurant Schepers |  Über Uns ',
      description: 'Erfahren sie etwas über uns und die Region, sowie die Historie von Hotel Schepers',
      ogUrl: 'www.hotel-schepers/about'
    }
  },
  {
    path: 'gallery', component: GalleryComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Gallery ',
      description: 'Bilder vom Hotel innen und außen',
      ogUrl: 'www.hotel-schepers/gallery'
    }
  },
  {
    path: 'places', component: PlacesComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Sehenswürdigkeiten ',
      description: 'Sehenswürdigkeiten und Ausflugsziele in 48599 Gronau-Epe und Umgebung | Ausflugsziele für ' +
        'Radfahrer, Familien,  „Shopping“  und für Kulturbegeisterte',
      ogUrl: 'www.hotel-schepers/places'
    }
  },
  {
    path: 'places-details', component: PlacesDetailsComponent
  },
  // {
  //   path: 'offers', component: OffersNewComponent,
  //   data: {
  //     title: 'Hotel-Restaurant Schepers | Arrangements ',
  //     description: 'Genießen Sie ihre Auszeit im Hotel Schepers im Münsterland, egal ob Rad-wandern mit dem E-Bike oder Fahrrad | Romantische Auszeit oder ' +
  //       'Wellness Wochenende zu zweit wir haben das passende Arrangement für sie parat.',
  //     ogUrl: 'www.hotel-schepers/offers'
  //   }
  // },
  {
    path: 'offers-festivals', component: OffersFestivalsComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Feste ',
      description: 'Feste & Feiern in 48599 Gronau Epe | Hochzeiten | Betriebs und Geburtstagsfeiern | Im Trauzimmer in der alten Wassermühle, als auch in ' +
        'unserem voll ausgestatteten Veranstaltungsräumen lassen sich all ihre Wünsche erfüllen.',
      ogUrl: 'www.hotel-schepers/feste'
    }
  },
  {
    path: 'offers-seminars', component: OffersSeminarsComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Seminare ',
      description: '4* Hotel | Top ausgestattete Tagungsräume - Moderner Wellnessbereich. Das 4* Tagungshotel im West-Münsterland. Professionelle ' +
        'Eventberatung. 44 Zimmer + Suiten. Hervorragendes Restaurant und Catering.',
      ogUrl: 'www.hotel-schepers/seminars'
    }
  },
  {
    path: 'wellness', component: WellnessComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Wellness Massagen',
      description: 'Das Wellness- Hotel in 48599 Gronau-Epe | Tolle Angebote für Wellness, Sauna, Spa und ' +
        'Massageanwendungen in unseren modernen Wellnessbereich mit wunderbarer Panorama Terrasse',
      ogUrl: 'www.hotel-schepers/wellness'
    }
  },
  {
    path: 'restaurant', component: RestaurantComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Restaurant ',
      description: 'Spitzenküche in 48599 Gronau Epe, genießen Sie saisonale Produkte und beste Zutaten aus der Region | egal ob: Fisch, Steaks, ' +
        'vegetarisch, Firmenfeier oder Takeaway, Reservieren Sie gleich ihren Tisch | Küchenchef John Schepers lädt ein',
      ogUrl: 'www.hotel-schepers/restaurant'
    }
  },
  {
    path: 'bike-tours', component: BikeTourPageComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Radtouren',
      description: 'Erleben Sie die Faszination des Radfahrens in unserer einzigartigen Region, wo sanfte Hügel und malerische Landschaften Ihr Abenteuer begleiten. ' +
        'Das Hotel Schepers lädt Sie ein, die Schönheit der Umgebung auf entspannten Fahrradtouren zu entdecken. Von idyllischen Dorfwegen bis hin zu verträumten Uferpfaden ' +
        'bieten wir abwechslungsreiche Strecken für alle Altersgruppen und Erfahrungslevel. Unsere Guides, die als PDF zur Verfügung stehen, führen Sie durch charmante Dörfer, ' +
        'der Dinkel und vorbei an unseren Sehenswürdigkeiten. Erleben Sie unvergessliche Momente, tanken Sie frische Luft und genießen Sie die Freiheit des ' +
        'Radfahrens. Starten Sie Ihr Abenteuer mit unseren Fahrradtouren und lassen Sie sich von der Schönheit unserer Region verzaubern!',
      ogUrl: 'www.hotel-schepers/bike-tours'
    }
  },
  {
    path: 'room-list', component: ViewRoomsComponent,
    data: {
      title: 'Hotel-Restaurant Schepers | Zimmer',
      description: 'Stylisch & gemütlich in 48599 Gronau Epe übernachten | Ihre Unterkunft ist zentral gelegen und inmitten der Natur | ' +
        '24h-Check-In | vielfältiges Frühstück',
      ogUrl: 'www.hotel-schepers/room-list'
    }
  },
  {
    path: 'jobs', component: JobsComponent,
    data: {
      title: 'Hotel Restaurant Schepers | Jobs | Stellenangebote',
      description: 'Entdecken sie Job Angebote bei Hotel Schepers in Gronau Epe 48599 | Gastronomie | Hotelfachfrau | Hotelfachman | Koch ',
      ogUrl: 'www.hotel-schepers/jobs'
    }
  },
  {
    path: 'jobs-details', component: JobsDetailsComponent
  },
  {
    path: 'bookingdialog', component: BookingDialogComponent
  },
  {
    path: 'private-policy', component: PrivatePolicyCookiesComponent
  },
  {
    path: 'privatsphaere', component: PrivatsphaereComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'adminpanel', component: AdminpanelComponent , canActivate: [AuthGuard],
    children: [
      {
      path: 'allposts', component: AllpostsComponent, canActivate: [AuthGuard]
      },
      {
      path: 'allrooms', component: AllroomsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'allplaces', component: AdminAllplacesComponent, canActivate: [AuthGuard]
      },
      {
        path: 'allmenu', component: AllmenuComponent, canActivate: [AuthGuard]
      },
      {
        path: 'allbiketours', component: AllBikeToursComponent, canActivate: [AuthGuard]
      },
      {
        path: 'alloffers', component: AdminOffersAllComponent, canActivate: [AuthGuard]
      },
      {
        path: 'alljobs', component: AlljobsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'config', component: ConfigComponent, canActivate: [AuthGuard]
      },
      {
        path: 'register', component: RegisterComponent, canActivate: [AuthGuard]
      },
      {
        path: 'reservations', component: ReservationsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'hotel-config', component: AdminHotelConfigHomeComponent, canActivate: [AuthGuard]
      },
      {
        path: 'testing', component: TestingComponent, canActivate: [AuthGuard]
      },
      {
        path: 'layoutsettings', component: AdminConfigLayoutComponent, canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '**', component: HomeTwoComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslatorService {

  constructor(private http: HttpClient) { }

  translate(text: string): any {
    return this.http.post<any>('https://translation.googleapis.com/language/translate/v2?key=' + environment.firebase.apiKey, {
          q: text,
          target: 'en'
        });
  }

  translateList(text: string[]):any {
    return this.http.post<any>('https://translation.googleapis.com/language/translate/v2?key=' + environment.firebase.apiKey, {
      q: text,
      target: 'en'
    });
  }
}

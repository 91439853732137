import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import firebase from 'firebase/compat';
import User = firebase.User;


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  user: User;

  constructor(private authService: AuthService) {
    this.authService.getCurrentUser().then( user => {
      this.user = user;
    });
  }

  ngOnInit(): void {
  }

  SendVerifyEmail() {
    this.authService.sendEmailVerification();
  }
}

import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {Menu} from '../../../../models/menu.model';
import {RestaurantService} from '../../../../services/restaurant.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {BiketourService} from "../../../../services/biketour.service";
import {BikeTour} from "../../../../models/biketour.model";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs";
import {MatChipInputEvent} from "@angular/material/chips";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-updatebiketour',
  templateUrl: './updatebiketour.component.html',
  styleUrls: ['./updatebiketour.component.css']
})
export class UpdatebiketourComponent implements OnInit {
  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  bulletPointCtrl = new UntypedFormControl();
  filteredBulletPoints: Observable<string[]>;
  bulletPoints: string[] = [];
  allBulletPoints: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  uploadEvent: Event;
  uploadEvent2: Event;
  menuForm: UntypedFormGroup;
  bikeTour: BikeTour;
  currentDate: any;
  hasPhoto: boolean;
  hasPhoto2: boolean;
  menuUrl: SafeResourceUrl;

  pdfEvent: Event;
  hasPdf: boolean;
  // TODO add bullet points here too
  constructor(private authService: AuthService,
              private restaurantService: BiketourService,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: BikeTour) {
    this.menuForm = this.formBuilder.group({
      headerDescription: ['', Validators.required],
      content: ['', Validators.required],
      title: ['', Validators.required],
      public: [false, '']
    });
    this.bikeTour = data;
    this.bulletPoints = this.data.bulletPoints;
    this.filteredBulletPoints = this.bulletPointCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allBulletPoints.slice()));
  }

  ngOnInit(): void {
    // this.menuUrl = this.sanitize(this.biketour.menuUrl);

    // TODO: Make alle Entieies one Object and create automatic new pages with custom routes that dynamicly chnage according to the object types rooms, news etc.
    if (this.data != null) {
      this.menuForm.controls.title.setValue(this.data.title);
      this.menuForm.controls.headerDescription.setValue(this.data.headerDescription);
      this.menuForm.controls.content.setValue(this.data.content);
      this.menuForm.controls.bullet
      this.menuForm.controls.public.setValue(this.data.public);
    }
    this.hasPhoto = this.data.biketourUrl !== '';
    this.hasPhoto2 = this.data.photoUrl !== '';
    this.hasPdf = this.bikeTour.pdfUrl  !== '';
  }

  updateMenu() {
    this.bikeTour = new BikeTour(this.menuForm.value);
    this.bikeTour.biketourId = this.data.biketourId;
    this.bikeTour.bulletPoints = this.bulletPoints;
    this.restaurantService.updatebiketour(this.uploadEvent, this.uploadEvent2, this.pdfEvent, this.bikeTour);
  }

  gpxToUpload($event: Event) {
    this.uploadEvent = $event;
    this.hasPhoto = true;
    this.updateMenu();
    this.getUpdatedMenu();
    this.uploadEvent = null;
  }

  pdfToUpload($event: Event) {
    this.pdfEvent = $event;
    this.hasPdf = true;
    this.updateMenu();
    this.getUpdatedMenu();
    this.pdfEvent = null;
  }


  pictureToUpload2($event: Event) {
    this.uploadEvent2 = $event;
    this.hasPhoto2 = true;
    this.updateMenu();
    this.getUpdatedMenu();
    this.uploadEvent2 = null;
  }

  deletePdf() {
    if (this.bikeTour.biketourUrl) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.restaurantService.deletebiketour(this.bikeTour.biketourUrl, this.bikeTour.biketourId);
          this.hasPhoto2 = false;
          this.getUpdatedMenu();
        }
      });
    }
  }

  deleteMenuPhoto() {
    if (this.bikeTour.photoUrl) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.restaurantService.deletePhoto(this.bikeTour.photoUrl, this.bikeTour.biketourId);
          this.hasPhoto2 = false;
          this.getUpdatedMenu();
        }
      });
    }
  }

  getUpdatedMenu() {
    this.restaurantService.getbiketourById(this.bikeTour.biketourId).subscribe(menu => this.bikeTour = menu);
  }

  sanitize(url: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.bulletPoints.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.bulletPointCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.bulletPoints.indexOf(fruit);

    if (index >= 0) {
      this.bulletPoints.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.bulletPointCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBulletPoints.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points //////////////////////////////////////////////////////////

}

import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NewJobComponent} from '../new-job/new-job.component';

@Component({
  selector: 'app-alljobs',
  templateUrl: './alljobs.component.html',
  styleUrls: ['./alljobs.component.css']
})
export class AlljobsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialogAddRoom() {
    const dialogRef = this.dialog.open(NewJobComponent, {
      width: '600px',
      data: {}
    });
    dialogRef.afterClosed().subscribe();
  }

}

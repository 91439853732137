<form [formGroup]="menuForm" class="example-form">
  <mat-dialog-content>
    <mat-form-field class="example-full-width">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" placeholder="Title">
    </mat-form-field>
    <mat-form-field class="example-full-width" >
      <mat-label>HeaderDescription</mat-label>
      <textarea style="max-height: 100px" matInput formControlName="headerDescription" placeholder="Header Description"></textarea>
    </mat-form-field>
    <mat-form-field class="example-full-width" >
      <mat-label>Content</mat-label>
      <textarea style="max-height: 100px" matInput formControlName="content" placeholder="Content"></textarea>
    </mat-form-field>
    <mat-slide-toggle style="margin: 15px" formControlName="public">Public</mat-slide-toggle>

    <div class="row">
      <div class="col">
        <input #newMenuUpload hidden="true" type="file" (change)="pictureToUpload($event)" accept=".pdf,.jpg,.png"/>
        <button mat-flat-button color="primary" (click)="newMenuUpload.click()">Pdf Updload<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
      </div>
      <div class="col">
        <mat-icon *ngIf="hasMenu">check</mat-icon>
        <img [src]=menu?.menuUrl style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
      </div>
    </div>

    <div class="row">
      <div class="col">
        <input #newMenuUploadPicture hidden="true" type="file" (change)="pictureToUpload2($event)" accept=".jpg,.png"/>
        <button mat-flat-button color="primary" (click)="newMenuUploadPicture.click()">Picture Updload<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
      </div>
      <div class="col">
        <mat-icon *ngIf="hasPicture">check</mat-icon>
        <img [src]=menu?.photoUrl style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button style="margin: 10px" mat-raised-button color="primary" mat-button mat-dialog-close (click)="addPost()">Speichern</button>
    <button style="margin: 10px" mat-raised-button color="warn" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

</form>

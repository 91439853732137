<mat-card class="example-card" style="margin-left: 5px; margin-right: 5px">
  <mat-card-header>
    <div style="float: left">
      <mat-card-subtitle>
        <mat-icon style="font-size: 32px; color: #bead8e; cursor: pointer">work</mat-icon>
        <span style="position: relative; bottom: 10px; margin-left: 20px">{{job?.jobType}}</span>
      </mat-card-subtitle>
    </div>
  </mat-card-header>
  <hr style="margin-bottom: 5px; margin-top: 2px">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <mat-card-content style="text-align: left">
          <p> <b>Title:</b> {{job?.title}}</p>
          <p [hidden]="true" class="post-content"><b matTooltip={{job?.content}} matTooltipClass="tooltip-black" >Content:</b> {{job?.content}}</p>
          <p> <b>JobModel Type:</b> {{job?.jobType}}</p>
          <p><b>created:</b> {{job?.createdDate | date:'dd-MM-yyyy'}}</p>
          <p><b>changed:</b> {{job?.editedDate | date:'dd-MM-yyyy'}}</p>
          <p><b>Public:</b> {{job?.public}}</p>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <img mat-card-image [src]=job?.photoUrl
             style="
             width: 300px;
             height: 200px;
             margin-top: 10px;
             overflow: hidden">
      </div>
    </div>
  </div>

  <mat-card-actions>
    <button (click)="UpdatePost()" mat-stroked-button color="primary">Edit</button>
    <button (click)="deletePost()" mat-stroked-button color="warn">Delete</button>
    <app-content-status-old [isOnline]="job.public"></app-content-status-old>
  </mat-card-actions>

</mat-card>

<app-header4></app-header4>
<!--====== BREADCRUMB PART START ======1920 * 300-->
<section class="breadcrumb-area" style="background-image: url(../../../../../assets/img/gallery/banner.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
           <!-- <span>{{'gallery.title' | translate}}</span> -->
            <h2 class="page-title">{{'gallery.gallery' | translate}}</h2>
            <ul class="breadcrumb-nav">
                <li><a routerLink='/'>Home</a></li>
                <li class="active">{{'gallery.gallery' | translate}}</li>
            </ul>
        </div>
    </div>
</section>
<!--====== BREADCRUMB PART END ======-->
<!--====== GALLERY START ======-->
<section class="gallery-wrappper pt-20 pb-20">
    <div class="container">
        <div class="gallery-loop">
            <div class="single-gallery-image wow fadeInUp" data-wow-delay=".3s" *ngFor="let gallerypost of galleryposts">
                <a href="{{gallerypost.img}}" #img class="popup-image">
                    <img src="{{gallerypost.img}}" alt="Images">
                </a>
            </div>
        </div>
    </div>
</section>
<!--====== GALLERY END ======-->
<app-footer2></app-footer2>

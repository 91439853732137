export class Place{
  placeId: string;
  originLat: string;
  originLng: string;
  destinationLat: string;
  destinationLng: string;
  CreatorId: string;
  placeType: string;
  placeTypeEN: string;
  website: string;
  public: boolean;
  title: string;
  titleEN: string;
  content: string;
  contentEN: string;
  views: string;
  likes: string;
  createdDate: any;
  editedDate: any;
  photoUrl1: string;
  photoUrl2: string;
  photoUrl3: string;
  photoUrl4: string;

  constructor(placeData: any){
    if (placeData.postId != null) {
      this.placeId = placeData.postId;
    } else {
      this.placeId = '';
    }
    if (placeData.originLat != null) {
      this.originLat = placeData.originLat;
    } else {
      this.originLat = '52.177581';
    }
    if (placeData.originLng != null) {
      this.originLng = placeData.originLng;
    } else {
      this.originLng = '7.0387851';
    }
    if (placeData.destinationLat != null) {
      this.destinationLat = placeData.destinationLat;
    } else {
      this.destinationLat = '';
    }
    if (placeData.destinationLng != null) {
      this.destinationLng = placeData.destinationLng;
    } else {
      this.destinationLng = '';
    }
    if (placeData.CreatorId != null) {
      this.CreatorId = placeData.CreatorId;
    } else {
      this.CreatorId = '';
    }
    if (placeData.placeType != null) {
      this.placeType = placeData.placeType;
    } else {
      this.placeType = '';
    }
    if (placeData.placeTypeEN != null) {
      this.placeTypeEN = placeData.placeTypeEN;
    } else {
      this.placeTypeEN = '';
    }
    if (placeData.website != null) {
      this.website = placeData.website;
    } else {
      this.website = '';
    }
    if (placeData.public != null) {
      this.public = placeData.public;
    } else {
      this.public = false;
    }
    if (placeData.title != null) {
      this.title = placeData.title;
    } else {
      this.title = '';
    }
    if (placeData.titleEN != null) {
      this.titleEN = placeData.titleEN;
    } else {
      this.titleEN = '';
    }
    if (placeData.content != null) {
      this.content = placeData.content;
    } else {
      this.content = '';
    }
    if (placeData.contentEN != null) {
      this.contentEN = placeData.contentEN;
    } else {
      this.contentEN = '';
    }
    if (placeData.views != null) {
      this.views = placeData.views;
    } else {
      this.views = '0';
    }
    if (placeData.likes) {
      this.likes  = placeData.likes;
    } else {
      this.likes  = '';
    }
    if (placeData.createdDate != null) {
      this.createdDate = placeData.createdDate;
    } else {
      this.createdDate = Date.now();
    }
    if (placeData.editedDate != null) {
      this.editedDate = placeData.editedDate;
    } else {
      this.editedDate = Date.now();
    }
    if (placeData.photoUrl1 != null) {
      this.photoUrl1 = placeData.photoUrl1;
    } else {
      this.photoUrl1 = '';
    }
    if (placeData.photoUrl2 != null) {
      this.photoUrl2 = placeData.photoUrl2;
    } else {
      this.photoUrl2 = '';
    }
    if (placeData.photoUrl3 != null) {
      this.photoUrl3 = placeData.photoUrl3;
    } else {
      this.photoUrl3 = '';
    }
    if (placeData.photoUrl4 != null) {
      this.photoUrl4 = placeData.photoUrl4;
    } else {
      this.photoUrl4 = '';
    }
  }

}

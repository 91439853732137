<iframe [src]="baseUrl"
        style="position:fixed;
        top:0;
        left:0;
        bottom:0;
        right:0;
        width:90%;
        height:85%;
        border:none;
        margin: auto;
        background-color: #ffffff;
        padding:0;
        overflow:visible;
        z-index:999999;">
</iframe>

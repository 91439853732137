import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Post} from '../../../../../../models/post.model';
import {LanguageService} from '../../../../../../services/language.service';

@Component({
  selector: 'app-news-outlet-detail',
  templateUrl: './news-outlet-detail.component.html',
  styleUrls: ['./news-outlet-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewsOutletDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('imageWidth') imageWidth: ElementRef;
  widthExp: any;
  currentLang: any;
  constructor(public dialogRef: MatDialogRef<NewsOutletDetailComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Post,
              private langService: LanguageService) {
    this.langService.getCurrentLanguage().subscribe(lang => {
      this.currentLang = lang;
    });
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.widthExp = this.imageWidth.nativeElement.offsetWidth;
  }

  openLink() {
      window.open(this.data.link);
  }

  getSize() {
    this.widthExp = this.imageWidth.nativeElement.offsetWidth;
  }
}

import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from "@angular/fire/compat/firestore";
import {forkJoin, Observable} from "rxjs";
import {AuthService} from "./auth.service";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {GoogleTranslatorService} from "./google-translator.service";
import {finalize, map} from "rxjs/operators";
import {BikeTour} from "../models/biketour.model";
import {object} from "@angular/fire/database";

@Injectable({
  providedIn: 'root'
})
export class BiketourService {

  private biketourCollection: AngularFirestoreCollection<any>;
  private biketourDocument: AngularFirestoreDocument<any>;
  biketours: Observable<any>;
  biketour: Observable<any>;
  userId: string;
  uploadPercent: Observable<number>;

  constructor(private authService: AuthService,
              private afs: AngularFirestore,
              private afStorage: AngularFireStorage,
              private translator: GoogleTranslatorService) {
    this.biketourCollection = this.afs.collection<any>('biketour', ref => ref
      .orderBy('editedDate', 'desc'));
    this.biketours = this.biketourCollection.valueChanges();
    this.authService.afAuth.authState.subscribe(authState => {
      if (authState) {
        this.userId = authState.uid;
      }
    });
  }

  getbiketours() {
    return this.biketours;
  }

  getbiketourById(biketourId: string) {
    this.biketourDocument = this.afs.doc<any>('biketour/' + biketourId);
    this.biketour = this.biketourDocument.valueChanges();
    return this.biketour;
  }

  getPublicbiketours() {
    this.biketourCollection = this.afs.collection<any>('biketour', ref => ref
      .where('public', '==', true).orderBy('editedDate', 'desc'));
    this.biketours = this.biketourCollection.valueChanges();
    return this.biketours;
  }

  addbiketour(uploadEvent,
              uploadEvent2, pdfEvent,
              biketour: BikeTour) {
    const resContentEN = this.translator.translate(biketour.content);
    const resTitleEN = this.translator.translate(biketour.title);
    const resTypeEN = this.translator.translate(biketour.bikeTourType);
    const resHeaderEN = this.translator.translate(biketour.headerDescription);
    const bulletEn = this.translator.translateList(biketour.bulletPoints);
    forkJoin(resContentEN, resTitleEN, resTypeEN, resHeaderEN, bulletEn).subscribe((res: any) => {
      biketour.contentEN = res[0].data.translations[0].translatedText;
      biketour.titleEN = res[1].data.translations[0].translatedText;
      biketour.biketourTypeEN = res[2].data.translations[0].translatedText;
      biketour.headerDescriptionEN = res[3].data.translations[0].translatedText;
      res[4].data.translations.forEach(translation => {
        biketour.bulletPointsEN.push(translation.translatedText);
      });
      this.afs.collection('biketour').add(Object.assign({}, biketour)).then(res => {
        this.afs.collection('biketour').doc(res.id).update({biketourId: res.id, CreatorId: this.userId});
        this.uploadbiketourPictures(uploadEvent, 'biketourUrl', res.id);
        this.uploadbiketourPdf(pdfEvent, 'pdfUrl', res.id);
        this.uploadbiketourPictures(uploadEvent2, 'photoUrl', res.id);
      });
    });
  }

  updatebiketour(uploadEvent,
             uploadEvent2, pdfEvent,
             biketour: BikeTour) {
    const resContentEN = this.translator.translate(biketour.content);
    const resTitleEN = this.translator.translate(biketour.title);
    const resTypeEN = this.translator.translate(biketour.bikeTourType);
    const resHeaderEN = this.translator.translate(biketour.headerDescription);
    const bulletEn = this.translator.translateList(biketour.bulletPoints);
    forkJoin(resContentEN, resTitleEN, resTypeEN, resHeaderEN, bulletEn).subscribe((res: any) => {
      biketour.contentEN = res[0].data.translations[0].translatedText;
      biketour.titleEN = res[1].data.translations[0].translatedText;
      biketour.biketourTypeEN = res[2].data.translations[0].translatedText;
      biketour.headerDescriptionEN = res[3].data.translations[0].translatedText;

      res[4].data.translations.forEach(translation => {
        biketour.bulletPointsEN.push(translation.translatedText);
      });

      this.afs.collection('biketour').doc(biketour.biketourId)
        .update({
          headerDescription: biketour.headerDescription,
          headerDescriptionEN: biketour.headerDescriptionEN,
          title: biketour.title,
          titleEN: biketour.titleEN,
          content: biketour.content,
          contentEN: biketour.contentEN,
          bulletPoints: biketour.bulletPoints,
          public: biketour.public,
          biketourType: biketour.bikeTourType,
          biketourTypeEN: biketour.biketourTypeEN,
          bulletPointsEN: biketour.bulletPointsEN,
          editedDate: Date.now()
        });
    });
    console.log(biketour.bulletPoints);
    this.translator.translateList(biketour.bulletPoints).subscribe(trans => {
      console.log(trans)
    });

    if (uploadEvent != null) {
      this.uploadbiketourPictures(uploadEvent, 'biketourUrl', biketour.biketourId);
    }
    if (uploadEvent2 != null) {
      this.uploadbiketourPictures(uploadEvent2, 'photoUrl', biketour.biketourId);
    }
    if (pdfEvent != null) {
      this.uploadbiketourPdf(pdfEvent, 'pdfUrl', biketour.biketourId);
    }
  }

  uploadbiketourPictures(event, field: string, biketourId: string) {
    const file = event.target.files[0];
    const filePath = 'BiketourPictures/' + this.userId + '/' + Date.now().toString() + `${file.name}`;
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          this.afs.collection('biketour').doc(biketourId).update({[field]: res});
        });
      })
    ).subscribe();
  }

  uploadbiketourPdf(event, field: string, biketourId: string) {
    const file = event.target.files[0];
    const filePath = 'BiketourPictures/' + this.userId + '/' + Date.now().toString() + '.pdf';
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          this.afs.collection('biketour').doc(biketourId).update({[field]: res});
        });
      })
    ).subscribe();
  }

  deletebiketourPhoto(biketourUrl: string, postId: string) {
    this.afStorage.refFromURL(biketourUrl).delete();
    this.afs.collection('biketour').doc(postId).update({biketourUrl: ''});
  }

  deletePhoto(postPhotoUrl: string, postId: string) {
    this.afStorage.refFromURL(postPhotoUrl).delete();
    this.afs.collection('biketour').doc(postId).update({photoUrl: ''});
  }

  deletebiketour(biketourId: string, biketourUrl: string, photoUrl?: string) {
    if (biketourUrl) {
      this.deletebiketourPhoto(biketourUrl, biketourId);
    }
    if (photoUrl) {
      this.deletePhoto(photoUrl, biketourId);
    }
    this.afs.collection('biketour').doc(biketourId).delete().then();
  }
}

import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AdminNewplaceComponent} from '../admin-newplace/admin-newplace.component';

@Component({
  selector: 'app-admin-allplaces',
  templateUrl: './admin-allplaces.component.html',
  styleUrls: ['./admin-allplaces.component.css']
})
export class AdminAllplacesComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  openDialogAddPlace() {
    const dialogRef = this.dialog.open(AdminNewplaceComponent, {
      width: '600px',
      data: ''
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

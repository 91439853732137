import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import {RestaurantService} from '../../../../services/restaurant.service';
import {LanguageService} from '../../../../services/language.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RestaurantComponent implements OnInit {
  menus: any;
  currentLang: string;

  constructor(private wowService: NgwWowService,
              private restaurantService: RestaurantService,
              private langService: LanguageService) {
    this.langService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
    this.restaurantService.getPublicMenus().subscribe(menus => {
      this.menus = menus;
    });
    this.wowService.init();
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import {MoviesService} from '../../../../services/movies.service';
import {PostService} from '../../../../services/post.service';
import {Post} from '../../../../models/post.model';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {UpdatepostComponent} from '../updatepost/updatepost.component';
import {MatDialog} from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {NewpostComponent} from '../newpost/newpost.component';

interface FilterTypes {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-accordion-posts',
  templateUrl: './accordion-posts.component.html',
  styleUrls: ['./accordion-posts.component.css']
})
export class AccordionPostsComponent implements OnInit {
  posts: Post[];
  selectedValue: string;
  lastIndex = 0;
  onlineType: FilterTypes[] = [
    {value: 'all', viewValue: 'All'},
    {value: 'online', viewValue: 'Online'},
    {value: 'offline', viewValue: 'Offline'}
  ];
  constructor(private moviesService: MoviesService,
              private postService: PostService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.selectedValue = this.onlineType[1].value;
    this.postService.getPosts().subscribe(posts => {
      this.posts = posts;
      this.updateLastIndex();
    });
  }

  updateLastIndex() {
    this.posts.forEach(post => {
      if (post.index >= this.lastIndex) {
        this.lastIndex = post.index;
      }
    });
  }

  openDialogAddRoom() {
    const dialogRef = this.dialog.open(NewpostComponent, {
      width: '600px',
      data: {lastIndex: this.lastIndex}
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.lastIndex ++;
      }
    });
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.posts, event.previousIndex, event.currentIndex);
    this.changeIndex(this.posts);
    this.posts.forEach(post => {
      this.postService.updateIndex(post.postId, post.index);
    });
  }

  changeIndex(posts: any[]) {
    posts.forEach((post, index) => {
      this.posts[index].index = index;
    });
  }

  deletePost(post: Post) {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postService.deletePost(post.postId, post.photoUrl);
        this.changeIndex(this.posts);
        this.lastIndex --;
      }
    });
  }

  UpdatePost(post: Post) {
    const dialogRef = this.dialog.open(UpdatepostComponent, {
      width: '600px',
      data: post
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }


}

export class BikeTour {
  biketourId: string;
  CreatorId: string;
  bikeTourType: string;
  biketourTypeEN: string;
  public: boolean;
  headerDescription: string;
  headerDescriptionEN: string;
  title: string;
  titleEN: string;
  content: string;
  contentEN: string;
  views: string;
  likes: string;
  createdDate: any;
  editedDate: any;
  biketourUrl: string;
  pdfUrl: string;
  photoUrl: string;
  bulletPoints: string[];
  bulletPointsEN: string[];

  constructor(biketourData: any){
    if (biketourData.biketourId != null) {
      this.biketourId = biketourData.biketourId;
    } else {
      this.biketourId = '';
    }
    if (biketourData.CreatorId != null) {
      this.CreatorId = biketourData.CreatorId;
    } else {
      this.CreatorId = '';
    }
    if (biketourData.biketourType != null) {
      this.bikeTourType = biketourData.biketourType;
    } else {
      this.bikeTourType = '';
    }
    if (biketourData.public != null) {
      this.public = biketourData.public;
    } else {
      this.public = false;
    }
    if (biketourData.headerDescription != null) {
      this.headerDescription = biketourData.headerDescription;
    } else {
      this.headerDescription = '';
    }
    if (biketourData.title != null) {
      this.title = biketourData.title;
    } else {
      this.title = '';
    }
    if (biketourData.content != null) {
      this.content = biketourData.content;
    } else {
      this.content = '';
    }
    if (biketourData.views != null) {
      this.views = biketourData.views;
    } else {
      this.views = '0';
    }
    if (biketourData.likes) {
      this.likes  = biketourData.likes;
    } else {
      this.likes  = '';
    }
    if (biketourData.createdDate != null) {
      this.createdDate = biketourData.createdDate;
    } else {
      this.createdDate = Date.now();
    }
    if (biketourData.editedDate != null) {
      this.editedDate = biketourData.editedDate;
    } else {
      this.editedDate = Date.now();
    }
    if (biketourData.biketourUrl != null) {
      this.biketourUrl = biketourData.biketourUrl;
    } else {
      this.biketourUrl = '';
    }
    if (biketourData.pdfUrl != null) {
      this.pdfUrl = biketourData.pdfUrl;
    } else {
      this.pdfUrl = '';
    }
    if (biketourData.photoUrl != null) {
      this.photoUrl = biketourData.photoUrl;
    } else {
      this.photoUrl = '';
    }

    if (biketourData.bulletPoints != null) {
      this.bulletPoints = biketourData.bulletPoints;
    } else {
      this.bulletPoints = [];
    }
    if (biketourData.bulletPointsEN != null) {
      this.bulletPointsEN = biketourData.bulletPointsEN;
    } else {
      this.bulletPointsEN = [];
    }
  }

}

import { Component, OnInit } from '@angular/core';
import {PostService} from '../../../../../services/post.service';
import {Post} from '../../../../../models/post.model';

@Component({
  selector: 'app-news-outlet',
  templateUrl: './news-outlet.component.html',
  styleUrls: ['./news-outlet.component.css']
})
export class NewsOutletComponent implements OnInit {
  posts: Post;
  blogsliderConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  constructor(private postService: PostService) { }

  ngOnInit(): void {
    this.postService.getPublicPosts().subscribe(res => {
      this.posts = res;
    });
  }

  swipeLeft() {
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { take, map, tap } from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService,
              public router: Router,
              private afAuth: AngularFireAuth){}

canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): Observable<boolean> {
  return this.afAuth.authState.pipe(
    take(1),
    map(user => !!user),
    tap(loggedIn => {
      if (!loggedIn) {
        this.router.navigate(['login']);
      }
    })
  );
}


}

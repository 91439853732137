import {Component, Input, OnInit} from '@angular/core';
import {BookingDialogComponent} from '../../../../shared/bookingdialog/booking-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {GalleryDialogComponent} from '../../../../shared/gallery-dialog/gallery-dialog.component';
import {LanguageService} from '../../../../../services/language.service';
import {GoogleTranslatorService} from '../../../../../services/google-translator.service';

@Component({
  selector: 'app-carousel-room',
  templateUrl: './room-carousel.component.html',
  styleUrls: ['./room-carousel.component.css']
})
export class RoomCarouselComponent implements OnInit {
  @Input() room: any;
  @Input() index: any;
  currentLang: string;

  constructor(private dialog: MatDialog,
              private languageService: LanguageService,
              private translator: GoogleTranslatorService) {
  }

  ngOnInit(): void {
    this.languageService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
    // this.languageService.getCurrentLanguage().subscribe(lang => {
    //   if (lang === 'en') {
    //     console.log('its english');
    //
    //     this.translator.translate(this.room.content).subscribe(res => {
    //       this.room.content = res.data.translations[0].translatedText;
    //     });
    //
    //     this.translator.translate(this.room.title).subscribe(res => {
    //       this.room.title = res.data.translations[0].translatedText;
    //     });
    //
    //   } else {
    //     console.log('its german');
    //   }
    // });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(GalleryDialogComponent, {
      data: this.room
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }

  openBookingDialog() {
    // this.dialog.open(BookingDialogComponent);
    window.open('https://onepagebooking.com/hotel-schepers', '_blank');
  }

}

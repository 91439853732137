import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BookingDialogComponent} from '../../../shared/bookingdialog/booking-dialog.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {UrlSerializer} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {LanguageService} from "../../../../services/language.service";

@Component({
  selector: 'app-booking-button',
  templateUrl: './booking-button.component.html',
  styleUrls: ['./booking-button.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BookingButtonComponent implements OnInit {
  selected = 1;
  bookingForm: UntypedFormGroup;
  baseUrl: SafeResourceUrl;
  checkInDate = 'arrival=';
  checkOutDate = 'departure=';
  personsAdults = 'adults=';
  persons: string;
  currentLang: string;

  constructor(private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private urlSerializer: UrlSerializer,
              private datePipe: DatePipe,
              private dom: DomSanitizer,
              private langService: LanguageService) {
    this.bookingForm = this.formBuilder.group({
    checkInDate: [''],
    checkOutDate: [''],
    persons: [1]
  }); }

  ngOnInit(): void {
  }

  openBookingDialog() {
    /*
    console.log(this.bookingForm);
    const dialogRef = this.dialog.open(BookingDialogComponent, {
      data: this.bookingForm.value
    });
    dialogRef.afterClosed().subscribe(result => {
    });

     */
    this.langService.getCurrentLanguage().subscribe(lang => {
      this.currentLang = lang;
      this.persons = this.personsAdults + this.bookingForm.controls.persons.value;
      if (this.bookingForm.controls.checkInDate.value !== '' && this.bookingForm.controls.checkOutDate.value !== '') {
        this.checkInDate = this.checkInDate + this.datePipe.transform(this.bookingForm.controls.checkInDate.value, 'dd.MM.yyyy');
        this.checkOutDate = this.checkOutDate + this.datePipe.transform(this.bookingForm.controls.checkOutDate.value, 'dd.MM.yyyy');
        window.open('https://onepagebooking.com/hotel-schepers?module=public&lang=' + this.currentLang + '&' + this.checkInDate + '&' + this.checkOutDate + '&' + this.persons, '_blank');
      } else {
        window.open('https://onepagebooking.com/hotel-schepers', '_blank');
      }
    });
  }
}

<div class="accordion reservation-box">
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>

      <mat-expansion-panel-header style="min-height: 120px">
        <mat-panel-title>
          <div>
            <p style="font-weight: bold; width: 200px">Name: </p> <p>{{reservation.customerName}} {{reservation.customerSureName}}</p>
          </div>
        </mat-panel-title>
        <mat-panel-description >
          <p style="margin-right: 10px"><b>Requested on: </b> {{reservation.created | date:'dd-MM-yyyy'}}</p>
          <p style="margin-left: 10px"><b>  for Date: </b> {{reservation.dateIn.toDate() | date: 'dd-MM-yyyy'}}</p>
          <mat-icon matTooltip="Replie message was sent to customer"
                    *ngIf="reservation?.replied"
                    style="position: absolute; right: 60px">question_answer
          </mat-icon>
          <mat-icon matTooltip="Confirmation was sent to customer"
                    *ngIf="reservation?.confirmed"
                    style="position: absolute; right: 110px">calendar_today
          </mat-icon>
          <!-- <mat-icon>account_circle</mat-icon> -->

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p style="margin-right: 10px"><b>Type: </b> {{reservation?.type}}</p>
      <p style="margin-right: 10px"><b>Title: </b> {{reservation?.title}}</p>
      <p style="margin-right: 10px"><b>Name: </b> {{reservation?.customerName}}</p>
      <p style="margin-right: 10px"><b>Sure Name: </b> {{reservation?.customerSureName}}</p>
      <p style="margin-right: 10px"><b>Amount of Guests: </b> {{reservation?.amountCustomers}}</p>
      <p style="margin-right: 10px"><b>Phone: </b> {{reservation?.customerTelephone}}</p>
      <p style="margin-right: 10px"><b>Email: </b> {{reservation?.customerEmail}}</p>

      <button (click)="setReplied()" [disabled]="reservation?.replied" style="margin: 10px" mat-raised-button color="primary">
        Set Replied
      </button>

      <button (click)="setConfirmed()" [disabled]="reservation?.confirmed" style="margin: 10px" mat-raised-button color="primary">
        Set Confirmed
      </button>

      <button (click)="delete(reservation)"  style="margin: 10px" mat-raised-button color="warn">
        Delete
      </button>

    </mat-expansion-panel>
  </mat-accordion>
</div>

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import firebase from 'firebase/compat';
import User = firebase.User;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  authenticate: UntypedFormGroup;

  @Output() user: EventEmitter<User> = new EventEmitter();

  constructor(private authService: AuthService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.authenticate = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
      // recaptcha: ['', Validators.required]
    });
  }

  login(){
    this.authService.login(this.authenticate.value.email, this.authenticate.value.password).subscribe(res => {
      this.user.emit(res);
    });
  }

  logout(){
    this.authService.logout();
  }

}

import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {JobService} from '../../../../services/job.service';
import {JobModel} from '../../../../models/job.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-update-job',
  templateUrl: './update-job.component.html',
  styleUrls: ['./update-job.component.css']
})
export class UpdateJobComponent implements OnInit {
  uploadEvent: Event;
  jobForm: UntypedFormGroup;
  job: JobModel;
  currentDate: any;
  hasPhoto: boolean;

  ////////////////////////////////////////////////////// Start Bullet Points 1 //////////////////////////////////////////////////////////
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  bulletPointCtrl = new UntypedFormControl();
  filteredBulletPoints: Observable<string[]>;
  bulletPoints: string[] = [];
  allBulletPoints: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  ////////////////////////////////////////////////////// End Bullet Points 1 //////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////// Start Bullet Points 1 //////////////////////////////////////////////////////////
  visible2 = true;
  selectable2 = true;
  removable2 = true;
  separatorKeysCodes2: number[] = [ENTER, COMMA];
  bulletPointCtrl2 = new UntypedFormControl();
  filteredBulletPoints2: Observable<string[]>;
  bulletPoints2: string[] = [];
  allBulletPoints2: string[] = ['Beamer mit 50K lumen', 'ClipBoard mit Zeichen Material'];
  @ViewChild('fruitInput2') fruitInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2') matAutocomplete2: MatAutocomplete;
  ////////////////////////////////////////////////////// End Bullet Points 1 //////////////////////////////////////////////////////////

  constructor(private authService: AuthService,
              private jobService: JobService,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: JobModel) {
    this.jobForm = this.formBuilder.group({
      content: [''],
      title: ['', Validators.maxLength(60)],
      public: [false, '']
    });
    this.job = data;
  }

  ngOnInit(): void {
    if (this.data != null) {
      this.jobForm.controls.title.setValue(this.job.title);
      this.jobForm.controls.content.setValue(this.job.content);
      this.jobForm.controls.public.setValue(this.job.public);
      this.bulletPoints = this.data.bulletPoints;
      this.bulletPoints2 = this.data.bulletPoints2;
    }
    this.hasPhoto = this.job.photoUrl !== '';
  }

  updatePost() {
    this.job = Object.assign(this.job, this.jobForm.value);
    this.job.bulletPoints = this.bulletPoints;
    this.job.bulletPoints2 = this.bulletPoints2;
    this.jobService.updatePost(this.uploadEvent, this.job);
  }

  pictureToUpload($event: Event) {
    this.uploadEvent = $event;
    this.hasPhoto = true;
    this.updatePost();
    this.getUpdatedPost();
    this.uploadEvent = null;
  }

  deleteRoomPhoto() {
    if (this.job.photoUrl) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.jobService.deleteRoomPhoto(this.job.photoUrl, this.job.postId, 'photoUrl');
          this.hasPhoto = false;
          this.getUpdatedPost();
        }
      });
    }
  }

  getUpdatedPost() {
    this.jobService.getPostById(this.job.postId).subscribe(post => this.job = post);
  }

  ////////////////////////////////////////////////////// Bullet Points //////////////////////////////////////////////////////////
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.bulletPoints.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.bulletPointCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.bulletPoints.indexOf(fruit);
    if (index >= 0) {
      this.bulletPoints.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.bulletPointCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allBulletPoints.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points //////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////// Bullet Points 2 //////////////////////////////////////////////////////////
  add2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.bulletPoints2.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.bulletPointCtrl2.setValue(null);
  }

  remove2(fruit: string): void {
    const index = this.bulletPoints2.indexOf(fruit);
    if (index >= 0) {
      this.bulletPoints2.splice(index, 1);
    }
  }

  selected2(event: MatAutocompleteSelectedEvent): void {
    this.bulletPoints2.push(event.option.viewValue);
    this.fruitInput2.nativeElement.value = '';
    this.bulletPointCtrl2.setValue(null);
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allBulletPoints2.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  ////////////////////////////////////////////////////// End Bullet Points 2 //////////////////////////////////////////////////////////

}

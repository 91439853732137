<app-header4></app-header4>

<section class="places-wrapper pt-150">

  <!-- //////////////////////////////////////////////// German Start //////////////////////////////////// -->
  <div *ngIf="currentLang == 'de'" class="container">
    <div class="places-details">
      <div class="title-wrap mb-50 d-flex align-items-center justify-content-between">
        <div class="title">
          <span class="place-cat ">Job | {{job?.jobType}}</span>
          <h2>{{job?.title}}</h2>
        </div>
      </div>
      <p class="mb-25">{{job?.content}}</p>

      <!-- Bullets 1 Start-->
      <div *ngIf="job?.bulletPoints.length >= 1">
        <hr>
        <div class="places-details title-wrap mb-10 d-flex align-items-center justify-content-between title">
          <span class="place-cat ">Was wir Dir bieten</span>
        </div>
        <p *ngFor="let bullet of job?.bulletPoints">
          <span *ngIf="bullet != ''"><b>-</b> {{bullet}}</span>
        </p>
      </div>
      <!-- Bullets 1 End-->

      <!-- Bullets 2 Start-->
      <div *ngIf="job?.bulletPoints2.length >= 1">
        <hr>
        <div class="places-details title-wrap mb-10 d-flex align-items-center justify-content-between title">
          <span class="place-cat ">Was Du mitbringst</span>
        </div>
        <p *ngFor="let bullet of job?.bulletPoints2">
          <span *ngIf="bullet != ''"><b>-</b> {{bullet}}</span>
        </p>
      </div>
      <!-- Bullets 2 End-->

      <hr>
      <div class="places-details title-wrap mb-10 d-flex align-items-center justify-content-between title">
        <span class="place-cat ">Bewerbungen an</span>
      </div>
      <p><span style="font-weight: bold">Name:</span> Frau Petra Schepers</p>
      <p><span style="font-weight: bold">Email:</span> info@hotel-schepers.de</p>

      <div class="gallery mt-30 mb-60">
        <div class="row">
          <div class="col-sm-6 mt-30">
            <img [src]="job?.photoUrl" alt="Img">
          </div>
        </div>
      </div>
      <p class="mb-25"> </p>
    </div>
  </div>
  <!-- //////////////////////////////////////////////// German End //////////////////////////////////// -->

  <!-- //////////////////////////////////////////////// English start //////////////////////////////////// -->
  <div *ngIf="currentLang == 'en'" class="container">
    <div class="places-details">

      <div class="title-wrap mb-50 d-flex align-items-center justify-content-between">
        <div class="title">
          <span class="place-cat">Job | {{job?.jobType}}</span>
          <h2>{{job?.titleEN}}</h2>
        </div>
      </div>
      <p class="mb-25">{{job?.contentEN}}</p>

      <!-- Bullets 1 Start-->
      <hr>
      <div class="places-details title-wrap mb-10 d-flex align-items-center justify-content-between title">
        <span class="place-cat ">what we Offer</span>
      </div>
      <p *ngFor="let bullet of job?.bulletPointsEN">
        <span *ngIf="bullet != ''"><b>-</b> {{bullet}}</span>
      </p>
      <!-- Bullets 1 End-->

      <!-- Bullets 2 Start-->
      <hr>
      <div class="places-details title-wrap mb-10 d-flex align-items-center justify-content-between title">
        <span class="place-cat ">what we Need</span>
      </div>
      <p *ngFor="let bullet of job?.bulletPointsEN2">
        <span *ngIf="bullet != ''"><b>-</b> {{bullet}}</span>
      </p>
      <!-- Bullets 2 End-->

      <hr>
      <div class="places-details title-wrap mb-10 d-flex align-items-center justify-content-between title">
        <span class="place-cat ">Applications to</span>
      </div>
      <p><span style="font-weight: bold">Name:</span> Frau Petra Schepers</p>
      <p><span style="font-weight: bold">Email:</span> info@hotel-schepers.de</p>

      <div class="gallery mt-30 mb-30">
        <div class="row">
          <div class="col-sm-6 mt-30">
            <img [src]="job?.photoUrl" alt="Img">
          </div>
        </div>
      </div>
      <p class="mb-25"> </p>
    </div>
  </div>
  <!-- //////////////////////////////////////////////// English End //////////////////////////////////// -->

</section>
<app-footer2></app-footer2>

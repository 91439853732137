import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {forkJoin, Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {finalize} from 'rxjs/operators';
import {Place} from '../models/place.model';
import {GoogleTranslatorService} from './google-translator.service';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
  private placesCollection: AngularFirestoreCollection<any>;
  private placeCollection: AngularFirestoreDocument<any>;
  places: Observable<any>;
  place: Observable<any>;
  userId: string;
  uploadPercent: Observable<number>;

  constructor(private authService: AuthService,
              private afs: AngularFirestore,
              private afStorage: AngularFireStorage,
              private translator: GoogleTranslatorService) {
    this.placesCollection = this.afs.collection<any>('places', ref => ref
      .orderBy('editedDate', 'desc'));
    this.places = this.placesCollection.valueChanges();

    this.authService.afAuth.authState.subscribe(authState => {
      if (authState) {
        this.userId = authState.uid;
      }
    });
  }

  getPlaces() {
    return this.places;
  }

  getPlaceById(placeId: string) {
    this.placeCollection = this.afs.doc<any>('places/' + placeId);
    this.place = this.placeCollection.valueChanges();
    return this.place;
  }

  getPublicPlaces() {
    this.placesCollection = this.afs.collection<any>('places', ref => ref
      .where('public', '==', true).orderBy('editedDate', 'desc'));
    this.places = this.placesCollection.valueChanges();
    return this.places;
  }

  addPlace(uploadEvent1,
           uploadEvent2,
           uploadEvent3,
           uploadEvent4,
           place: Place) {
    const resContentEN = this.translator.translate(place.content);
    const resTitleEN = this.translator.translate(place.title);
    const resPlaceTypeEN = this.translator.translate(place.placeType);
    forkJoin(resContentEN, resTitleEN, resPlaceTypeEN).subscribe((res: any) => {
      console.log(res);
      place.contentEN = res[0].data.translations[0].translatedText;
      place.titleEN = res[1].data.translations[0].translatedText;
      place.placeTypeEN = res[2].data.translations[0].translatedText;
      console.log(place);
      this.afs.collection('places').add(Object.assign({}, place)).then(res => {
        this.afs.collection('places').doc(res.id).update({placeId: res.id, CreatorId: this.userId});
        this.uploadPlacePictures(uploadEvent1, 'photoUrl1' , res.id);
        this.uploadPlacePictures(uploadEvent2, 'photoUrl2', res.id);
        this.uploadPlacePictures(uploadEvent3, 'photoUrl3', res.id);
        this.uploadPlacePictures(uploadEvent4, 'photoUrl4', res.id);
      });
    });

  }

  updatePlace(uploadEvent1,
              uploadEvent2,
              uploadEvent3,
              uploadEvent4,
              place: Place) {
    const resContentEN = this.translator.translate(place.content);
    const resTitleEN = this.translator.translate(place.title);
    const resPlaceTypeEN = this.translator.translate(place.placeType);
    forkJoin(resContentEN, resTitleEN, resPlaceTypeEN).subscribe((res: any) => {
      console.log(res);
      place.contentEN = res[0].data.translations[0].translatedText;
      place.titleEN = res[1].data.translations[0].translatedText;
      place.placeTypeEN = res[2].data.translations[0].translatedText;
      console.log(place);
      this.afs.collection('places').doc(place.placeId)
        .update({
          title: place.title,
          titleEN: place.titleEN,
          content: place.content,
          contentEN: place.contentEN,
          destinationLat: place.destinationLat,
          destinationLng: place.destinationLng,
          website: place.website,
          public: place.public,
          placeType: place.placeType,
          placeTypeEN: place.placeTypeEN,
          editedDate: Date.now()
        });
    });

    if (uploadEvent1 != null) {
      this.uploadPlacePictures(uploadEvent1, 'photoUrl1', place.placeId);
    }
    if (uploadEvent2 != null) {
      this.uploadPlacePictures(uploadEvent2, 'photoUrl2', place.placeId);
    }
    if (uploadEvent3 != null) {
      this.uploadPlacePictures(uploadEvent3, 'photoUrl3', place.placeId);
    }
    if (uploadEvent4 != null) {
      this.uploadPlacePictures(uploadEvent4, 'photoUrl4', place.placeId);
    }
  }

  deletePlace(placeId: string) {
    this.afs.collection('places').doc(placeId).delete().then(res => {
    });
  }

  uploadPlacePictures(event, field: string, postId: string) {
    const file = event.target.files[0];
    const filePath = 'PlacesPictures/' + this.userId + '/' + Date.now().toString();
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          this.afs.collection('places').doc(postId).update({[field]: res});
        });
      })
    ).subscribe();
  }

  deletePlacePhoto(placePhotoUrl: string, placeId: string, photoNumber: string) {
    this.afStorage.refFromURL(placePhotoUrl).delete();
    this.afs.collection('places').doc(placeId).update({[photoNumber]: ''});
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import IdTokenResult = firebase.auth.IdTokenResult;
import firebase from 'firebase/compat';
import User = firebase.User;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user: User;
  currentUserUid: Subject<string> = new BehaviorSubject<string>(null);
  timeToAutoLogout: number;

  constructor(public  afAuth: AngularFireAuth,
              private analytics: AngularFireAnalytics,
              public  router: Router,
              private firestore: AngularFirestore) {
                        this.afAuth.authState.subscribe(userData => {
                          if (userData) {
                            this.user = userData;
                            this.updateCurrentUserUid(userData.uid);
                          }
                        });
                        this.afAuth.idTokenResult.subscribe(tokenInfo => {
                          if (tokenInfo) {
                            this.setLogoutTime(tokenInfo);
                          }
                        });
              }

  updateCurrentUserUid(userUid: string): void {
    this.currentUserUid.next(userUid);
  }

  getCurrentUserUid(): Observable<string> {
    return this.currentUserUid;
  }

  register(email: string, password: string, name: string, surname: string, zipcode: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.firestore.collection('alerts').doc(result.user.uid).set({});
        this.firestore.collection('users').doc(result.user.uid).set({
          userid: result.user.uid,
          useremail: result.user.email,
          username: name,
          usersurname: surname,
          userzipcode: zipcode,
          userisadmin: false,
      }).then(() => {
        localStorage.setItem('uid', result.user.uid);
        this.router.navigate(['login']);
        this.user.updateProfile({displayName: name});
      });
        window.alert('You have been successfully registered! Please Verify your Email now');
        return result;
      }).catch((error) => {
        window.alert(error.message);
      });
  }

  login(email: string, password: string){
      this.afAuth.signInWithEmailAndPassword(email, password).then(res => {
        localStorage.setItem('uid', res.user.uid);
        this.analytics.logEvent('Login', {user: res.user.uid});
        this.router.navigate(['adminpanel']);
      }).catch(err => {
        window.alert(err);
      });
      return this.afAuth.user;
  }

  setLogoutTime(tokenInfo: IdTokenResult) {
        const nowTime = new Date().getTime();
        const tokenExpTime = new Date(tokenInfo.expirationTime).getTime();
        this.timeToAutoLogout = tokenExpTime - nowTime;
        console.log(this.timeToAutoLogout);
        this.autoLogout(this.timeToAutoLogout);
  }

  autoLogout(timeToLogout: number) {
    if (timeToLogout > 1000) {
      setTimeout(() => {
        if (this.isAdminRoute(this.router.url)) {}
        this.logout();
      }, timeToLogout);
    } else {
      this.logout();
    }
  }

  isAdminRoute(value: string): boolean {
    return /^\/adminpanel(\/|$)/.test(value);
  }

  logout(){
    return this.afAuth.signOut().then(() => {
      localStorage.setItem('uid', null);
      this.user = null;
      this.router.navigate(['login']);
    }).catch((error) => {
      window.alert(error.message);
    });
  }

  ResetPassword(email){
      this.afAuth.sendPasswordResetEmail(email).then(() => {
        window.alert('We send you an email to reset your password');
      }).catch(err => {
        window.alert(err);
      });
  }

  getCurrentUser() {
    return this.afAuth.currentUser;
  }

  sendEmailVerification() {
    this.user.sendEmailVerification().then();
  }

  getTimeToAutoLogout(): number {
    return this.timeToAutoLogout;
  }

}

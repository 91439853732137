import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {UrlSerializer} from "@angular/router";

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.css']
})
export class VideoDialogComponent implements OnInit {
  baseUrl: SafeResourceUrl;

  constructor(private urlSerializer: UrlSerializer, private dom: DomSanitizer){
    this.baseUrl = this.dom.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/gJjBoC7G9rg?vq=hd2160');
  }

  ngOnInit(): void {
  }

}

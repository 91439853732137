import { Component, OnInit } from '@angular/core';
import {NgwWowService} from "ngx-wow";
import {RestaurantService} from "../../../../services/restaurant.service";
import {LanguageService} from "../../../../services/language.service";
import {BiketourService} from "../../../../services/biketour.service";
import {BikeTour} from "../../../../models/biketour.model";

@Component({
  selector: 'app-bike-tour-page',
  templateUrl: './bike-tour-page.component.html',
  styleUrls: ['./bike-tour-page.component.css']
})
export class BikeTourPageComponent implements OnInit {

  menus: BikeTour[];
  currentLang: string;

  constructor(private wowService: NgwWowService,
              private restaurantService: BiketourService,
              private langService: LanguageService) {
    this.langService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
    this.restaurantService.getPublicbiketours().subscribe((menus: BikeTour[])=> {
      this.menus = menus;
      console.log(this.menus);
    });
    this.wowService.init();
  }

  ngOnInit(): void {
  }

  openBookingPackages() {
    window.open('https://onepagebooking.com/hotel-schepers/packages', '_blank')
  }
}

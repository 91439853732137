import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UrlSerializer} from '@angular/router';
import {Booking} from '../../../models/booking.model';
import {DatePipe} from '@angular/common';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {LanguageService} from '../../../services/language.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking-dialog.component.html',
  styleUrls: ['./booking-dialog.component.css']
})

export class BookingDialogComponent implements OnInit {
  baseUrl: SafeResourceUrl;
  checkInDate = 'arrival=';
  checkOutDate = 'departure=';
  personsAdults = 'adults=';
  persons: string;
  currentLang: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Booking,
              private urlSerializer: UrlSerializer,
              private datePipe: DatePipe,
              private dom: DomSanitizer,
              private langService: LanguageService) {
  }

  ngOnInit(): void {
    this.langService.getCurrentLanguage().subscribe(lang => {
      this.currentLang = lang;
    });
    if (this.data !== null ) {
      this.persons = this.personsAdults + this.data.persons;
      if (this.data.checkInDate !== '' && this.data.checkOutDate !== '') {
        this.checkInDate = this.checkInDate + this.datePipe.transform(this.data.checkInDate, 'dd.MM.yyyy');
        this.checkOutDate = this.checkOutDate + this.datePipe.transform(this.data.checkOutDate, 'dd.MM.yyyy');
        this.baseUrl = this.dom.bypassSecurityTrustResourceUrl('https://onepagebooking.com/hotel-schepers?module=public&lang=' + this.currentLang + '&' + this.checkInDate + '&' + this.checkOutDate + '&' + this.persons);
      } else {
        this.baseUrl = this.dom.bypassSecurityTrustResourceUrl('https://onepagebooking.com/hotel-schepers');
      }
    } else {
      this.baseUrl = this.dom.bypassSecurityTrustResourceUrl('https://onepagebooking.com/hotel-schepers');
    }
  }
}

<div class="container"
     style=" display: block;
     margin-bottom: 10px;
     border-color: #c8c8c8;
     border-width: 2px;
     border-style: solid;
      justify-content: center;
      align-items: center;">
  <mat-form-field style="margin: 5px">
    <mat-label>Filter By</mat-label>
    <mat-select [(ngModel)]="selectedValue" name="Offer Type" (selectionChange)="filterOffers()">
      <mat-option *ngFor="let filter of offertypes" [value]="filter.value">
        {{filter.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<app-admin-offer *ngFor="let offer of offers" [offer]="offer"></app-admin-offer>

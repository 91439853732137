export class ReservationModel{
  reservationId: string;
  creatorId: string;
  customerName: string;
  customerSureName: string;
  customerTelephone: string;
  type: string;
  title: string;
  amountCustomers: string;
  customerEmail: string;
  dateIn: any;
  dateOut: any;
  confirmedByUserId: string;
  created: string;
  replied: boolean;
  confirmed: boolean;

  // TODO Add Constructor fallback values
  constructor(ReservationData: any){
    if (ReservationData.created == null) {
      this.created = Date.now().toString();
    }else {
      this.created = ReservationData.created;
    }

    if (ReservationData.reservationId == null) {
      this.reservationId = '';
    }else {
      this.reservationId = ReservationData.reservationId;
    }

    this.reservationId = ReservationData.reservationId;
    this.customerName = ReservationData.customerName;
    this.customerSureName = ReservationData.customerSureName;
    this.customerTelephone = ReservationData.customerTelephone;
    this.type = ReservationData.type;
    this.title = ReservationData.title;
    this.amountCustomers = ReservationData.amountCustomers;
    this.customerEmail = ReservationData.customerEmail;
    this.dateIn = ReservationData.dateIn;
    this.dateOut = ReservationData.dateOut;
    this.confirmed = ReservationData.confirmed;
    this.confirmedByUserId = ReservationData.confirmedByUserId;

    if (ReservationData.replied == null) {
      this.replied = false;
    }else {
      this.replied = ReservationData.replied;
    }

    this.confirmed = ReservationData.confirmed;

  }
}

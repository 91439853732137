
<div>
  <mat-dialog-content>

    <div (click)="onNoClick()" >
      <img alt="Picture of current News Post" class="news-img"  #imageWidth [src]=data.photoUrl (load)="getSize()">

      <div *ngIf="currentLang == 'de'">
        <h4 style="margin-top: 10px; margin-bottom: 10px">{{data.title}}</h4>
        <span style="font-size: 15px; color: #6d6d6d" [ngStyle]="{'max-width.px': widthExp}">{{data.content}}</span>
      </div>

      <div *ngIf="currentLang == 'en'">
        <h4 style="margin-top: 10px; margin-bottom: 10px">{{data.titleEN}}</h4>
        <span style="font-size: 15px; color: #6d6d6d" [ngStyle]="{'max-width.px': widthExp}">{{data.contentEN}}</span>
      </div>

    </div>

    <a *ngIf="data.link != ''" (click)="openLink()"
       class="main-btn1 btn-filled1" style="margin-left: 0; margin-top: 20px">
      <div *ngIf="currentLang == 'de'">
        {{data.linkTitle}}
      </div>
      <div *ngIf="currentLang == 'en'">
        {{data.linkTitleEN}}
      </div>

    </a>

  </mat-dialog-content>
</div>





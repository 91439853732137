<app-header4></app-header4>
<!--====== FEATURE ROOM START ======-->
<section id="wellness" class="feature-room-section with-bg pt-155 pb-115"
         style="background-image: url(../../../../../assets/img/arrangements/wellness.jpg);">
  <div class="container">
    <div class="section-title white-color text-center">
      <!-- <span class="title-tag">popular list</span> -->
      <h2>{{'arrangements.wellness' | translate}}</h2>
    </div>
    <!--Start Wellness Loop -->
    <div class="row room-gird-loop justify-content-center mt-30">
      <div *ngFor="let offer of offers" class="col-lg-4 col-sm-6">
        <!-- ////////////////////////// German Start ///////////////////////////////// -->
        <div *ngIf="currentLang == 'de'" class="room-box mt-30">
          <div class="room-img-wrap">
            <div class="room-img"
                 style="background-image: url('{{ offer.photoUrl1}}');"></div>
          </div>
          <div class="room-desc">
            <h4 class="title"><a>{{ offer.title }}</a></h4>
            <p>{{offer.content}}</p>
            <span class="price">{{offer.price}}<span> €</span></span>
            <a (click)="openOfferBookingDialog(offer.offerType, offer.title, true)"
               class="book-btn"
               style="cursor: pointer">Anfragen</a>
          </div>
        </div>
        <!-- ////////////////////////// English Start ///////////////////////////////// -->
        <div *ngIf="currentLang == 'en'" class="room-box mt-30">
          <div class="room-img-wrap">
            <div class="room-img"
                 style="background-image: url('{{ offer.photoUrl1}}');"></div>
          </div>
          <div class="room-desc">
            <h4 class="title"><a>{{ offer.titleEN }}</a></h4>
            <p>{{offer.contentEN}}</p>
            <span class="price">{{offer.price}}<span> €</span></span>
            <a (click)="openOfferBookingDialog(offer.offerType, offer.title, true)"
               class="book-btn"
               style="cursor: pointer">Request</a>
          </div>
        </div>

      </div>
    </div>
    <!--====== End Wellness ======-->
  </div>
</section>
<!--====== FEATURE ROOM END ======-->
<app-footer2></app-footer2>

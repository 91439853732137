import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import {LanguageService} from '../../../../services/language.service';
import {RoomService} from '../../../../services/room.service';
import {OfferService} from '../../../../services/offer.service';
import {BookingDialogComponent} from '../../../shared/bookingdialog/booking-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PackagesHotelNetSolutionsComponent} from '../../../shared/packages-hotel-net-solutions/packages-hotel-net-solutions.component';

@Component({
  selector: 'app-header4',
  templateUrl: './header4.component.html',
  styleUrls: ['./header4.component.css']
})
export class Header4Component implements OnInit {

  public currentLanguage: string;
  private langCounter = 0;
  private langList: string[];
  public breakpoint: boolean;
  rooms: any;
  offers: any;

  constructor(private languageService: LanguageService,
              private roomService: RoomService,
              private offerService: OfferService,
              private dialog: MatDialog) {
    this.setBreakPoint();
    this.languageService.getCurrentLanguage().subscribe(lang => {
      this.currentLanguage = lang;
      if (this.currentLanguage === 'de'){
        this.langCounter = 0;
      }else if (this.currentLanguage === 'en') {
        this.langCounter = 1;
      } // else if (this.currentLanguage === 'nl') {
       // this.langCounter = 2;
      // }
    });
    // this.langList = ['de', 'en', 'nl'];
    this.langList = ['de', 'en'];
    this.roomService.getRooms().subscribe(rooms => {
      this.rooms = rooms;
    });
    this.offerService.getPublicOffersByType('Arrangement').subscribe(offers => {
      this.offers = offers;
    });
  }

  ngOnInit(): void {
    var var_window = $(window),
      navContainer = $('.nav-container'),
      pushedWrap = $('.nav-pushed-item'),
      pushItem = $('.nav-push-item'),
      pushedHtml = pushItem.html(),
      pushBlank = '',
      navMenu = $('.nav-menu')

    // expands the dropdown menu on each click
    navMenu.find('li .dd-trigger').on('click', function(e) {
      e.preventDefault();
      $(this)
        .parent('li')
        .children('ul')
        .stop(true, true)
        .slideToggle(350);
      $(this).parent('li').toggleClass('active');
    });

    function breakpointCheck() {
      let windoWidth = window.innerWidth;
      if (windoWidth <= 1219) {
        navContainer.addClass('breakpoint-on');
        pushedWrap.html(pushedHtml);
        pushItem.hide();
      } else {
        navContainer.removeClass('breakpoint-on');
        pushedWrap.html(pushBlank);
        pushItem.show();
      }
    }

    breakpointCheck();
    var_window.on('resize', function () {
      breakpointCheck();
    });
  }

  setBreakPoint(){
    this.breakpoint = window.innerWidth <= 1150;
  }

  setLanguage(): void {
    this.langCounter = this.langCounter + 1;
    // if (this.langCounter <= 2) {
    if (this.langCounter <= 1) {
      this.languageService.switchLang(this.langList[this.langCounter]);
    } else {
      this.langCounter = 0;
      this.languageService.switchLang(this.langList[this.langCounter]);
    }
  }

  // Sticky header
  windowScroll() {
    const navbar = document.getElementById('sticky-header');
    if (document.body.scrollTop >= 150 || document.documentElement.scrollTop > 400000) { // 400
      navbar.classList.add('sticky-active');
    } else {
      navbar.classList.remove('sticky-active');
    }
  }

  // offCanvasBtn
  // called when clicking burger menu
  offCanvasBtn() {
    document.getElementById('toggler').classList.add('active');
    document.getElementById('offcanvas-wrapper').classList.add('show-offcanvas');
    document.getElementById('offcanvas-overly').classList.add('show-overly');
  }
  // called when clicking X
  offCanvasClose() {
    document.getElementById('toggler').classList.remove('active');
    document.getElementById('offcanvas-wrapper').classList.remove('show-offcanvas');
    document.getElementById('offcanvas-overly').classList.remove('show-overly');
  }
  // Called when clicking in window
  offCanvasOverlay() {
    document.getElementById('toggler').classList.remove('active');
    document.getElementById('offcanvas-wrapper').classList.remove('show-offcanvas');
    document.getElementById('offcanvas-overly').classList.remove('show-overly');
  }

  openPackages() {
    //this.offCanvasClose();
    //const dialogRef = this.dialog.open(PackagesHotelNetSolutionsComponent, {
    //});
    //dialogRef.afterClosed().subscribe(result => {
    //});
    window.open('https://onepagebooking.com/hotel-schepers/packages', '_blank')
  }
}

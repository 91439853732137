
<form [formGroup]="formGroup" (ngSubmit)="saveOpeningHours(formGroup.value)">
  <div class="time-box">
    <h2 style="margin-top: 10px; margin-bottom: 10px"> Restaurant Opening Hours</h2>
    <div>
      <mat-form-field style="margin-right: 10px">
        <input matInput type="time"  placeholder="Monday Start"  formControlName="mondayStart">
      </mat-form-field>
      <mat-form-field >
        <input matInput type="time" placeholder="Monday End"  formControlName="mondayEnd">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="margin-right: 10px">
        <input matInput type="time" placeholder="Tuesday Start"  formControlName="tuesdayStart">
      </mat-form-field>
      <mat-form-field >
        <input matInput type="time" placeholder="Tuesday End"  formControlName="tuesdayEnd">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="margin-right: 10px">
        <input matInput type="time" placeholder="Wednesday Start"  formControlName="wednesdayStart">
      </mat-form-field>
      <mat-form-field >
        <input matInput type="time" placeholder="Wednesday End"  formControlName="wednesdayEnd">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="margin-right: 10px">
        <input matInput type="time" placeholder="Thursday Start"  formControlName="thursdayStart">
      </mat-form-field>
      <mat-form-field >
        <input matInput type="time" placeholder="Thursday End"  formControlName="thursdayEnd">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="margin-right: 10px">
        <input matInput type="time" placeholder="Friday Start"  formControlName="fridayStart">
      </mat-form-field>
      <mat-form-field >
        <input matInput type="time" placeholder="Friday End"  formControlName="fridayEnd">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="margin-right: 10px">
        <input matInput type="time" placeholder="Saturday Start"  formControlName="saturdayStart">
      </mat-form-field>
      <mat-form-field >
        <input matInput type="time" placeholder="Saturday End"  formControlName="saturdayEnd">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="margin-right: 10px">
        <input matInput type="time" placeholder="Sunday Start"  formControlName="sundayStart">
      </mat-form-field>
      <mat-form-field >
        <input matInput type="time" placeholder="Sunday End"  formControlName="sundayEnd">
      </mat-form-field>
    </div>
    <div>
      <button mat-raised-button color="primary" type="submit">Save</button>
    </div>
  </div>

</form>


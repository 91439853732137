import { Component, OnInit } from '@angular/core';
import {OfferService} from '../../../../../services/offer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-offers-seminars',
  templateUrl: './offers-seminars.component.html',
  styleUrls: ['./offers-seminars.component.css']
})
export class OffersSeminarsComponent implements OnInit {
  offers: any;
  constructor(private offerService: OfferService,
              private route: ActivatedRoute,
              private viewController: ViewportScroller) { }

  ngOnInit(): void {
    this.offerService.getOffersByType('Seminar').subscribe(offers => {
      this.offers = offers;
    });
    this.route.fragment.subscribe(fragment => {
      this.delay(300).then(() => this.viewController.scrollToAnchor(fragment));
    });
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}

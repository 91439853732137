import { Component, OnInit } from '@angular/core';
import {CookieService} from '../../../../services/cookie.service';

@Component({
  selector: 'app-private-policy-cookies',
  templateUrl: './private-policy-cookies.component.html',
  styleUrls: ['./private-policy-cookies.component.css']
})
export class PrivatePolicyCookiesComponent implements OnInit {
  checked: any;

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    this.checked = this.cookieService.getCookieConfig();
  }

  changeCookieSetting($event) {
    this.checked = $event.checked;
    this.cookieService.setCookieConfig($event.checked);
  }
}

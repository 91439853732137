import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  email: string;
}
@Component({
  selector: 'app-offer-booking-confiramtion-dialog',
  templateUrl: './offer-booking-confiramtion-dialog.component.html',
  styleUrls: ['./offer-booking-confiramtion-dialog.component.css']
})
export class OfferBookingConfiramtionDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OfferBookingConfiramtionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

}

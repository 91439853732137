import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {AuthService} from './auth.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Observable} from 'rxjs';
import {ReservationModel} from '../models/reservation.model';
import {EmailService} from './email.service';

@Injectable({
  providedIn: 'root'
})

export class ReservationService {
  private reservationCollection: AngularFirestoreCollection<any>;
  userId: string;
  reservations: Observable<any>;

  constructor(private authService: AuthService,
              private afs: AngularFirestore,
              private afStorage: AngularFireStorage,
              private mailService: EmailService) {
    this.reservationCollection = this.afs.collection<any>('reservations', ref => ref
      .orderBy('created', 'desc'));

    this.reservations = this.reservationCollection.valueChanges();

    this.authService.afAuth.authState.subscribe(authState => {
      if (authState) {
        this.userId = authState.uid;
      }else {
        this.userId = '';
      }
    });
  }

  getAllReservations() {
    return this.reservations;
  }

  addReservation(reservation: ReservationModel) {
    console.log(reservation);
    this.afs.collection('reservations').add(Object.assign({}, reservation)).then(res => {
      this.afs.collection('reservations').doc(res.id).update({reservationId: res.id});
    }).catch((err) => {
      console.log('Cant Create Reservation', err);
    });
    this.mailService.sendTestEmail(reservation);
  }

  setReplied(reservation: ReservationModel) {
    this.afs.collection('reservations').doc(reservation.reservationId).update({replied: true});
  }

  serConfirmed(reservation: ReservationModel) {
    this.afs.collection('reservations').doc(reservation.reservationId).update({confirmed: true});
  }

  delete(reservation: ReservationModel) {
    this.afs.collection('reservations').doc(reservation.reservationId).delete().then(res => console.log(res));
  }
}

 import {Component, OnInit} from '@angular/core';
 import {RoomService} from '../../../../services/room.service';
 import {Room} from '../../../../models/room.model';
 import {GalleryDialogComponent} from '../../../shared/gallery-dialog/gallery-dialog.component';
 import {MatDialog} from '@angular/material/dialog';
 import {LanguageService} from '../../../../services/language.service';
 import {BookingDialogComponent} from '../../../shared/bookingdialog/booking-dialog.component';

 @Component({
  selector: 'app-rooms-carousel-v2',
  templateUrl: './rooms-carousel-v2.component.html',
  styleUrls: ['./rooms-carousel-v2.component.css']
})

export class RoomsCarouselV2Component implements OnInit {

  settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    dots: false,
    centerMode: true,
    centerPadding: '28%',
    prevArrow:
      '<div class="slick-arrow prev-arrow"><i class="fal fa-arrow-left"></i></div>',
    nextArrow:
      '<div class="slick-arrow next-arrow"><i class="fal fa-arrow-right"></i></div>',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: '20%',
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '10%',
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: '5%',
        },
      },
    ]
  };

  public rooms = [

    {
      id: 1,
      title: 'Amber Lastir',
      price: 365,
      period: 'Night',
      category: [
        1
      ],
      gridimage: 'assets/img/room/01.jpg',
      listimage: 'assets/img/room/list-1.jpg',
      sliderimage: 'assets/img/room-slider/01.jpg',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      longdesc: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>',
      authors: [
        1,
        2
      ],
      amenities: [
        {
          title: 'Air conditioner',
          icon: 'fal fa-bath'
        },
        {
          title: 'High speed WiFi',
          icon: 'fal fa-wifi'
        },
        {
          title: 'Strong Locker',
          icon: 'fal fa-key'
        },
        {
          title: 'Breakfast',
          icon: 'fal fa-cut'
        },
        {
          title: 'Kitchen',
          icon: 'fal fa-guitar'
        },
        {
          title: 'Smart Security',
          icon: 'fal fa-lock'
        },
        {
          title: 'Cleaning',
          icon: 'fal fa-broom'
        },
        {
          title: 'Shower',
          icon: 'fal fa-shower'
        },
        {
          title: '24/7 Online Support',
          icon: 'fal fa-headphones-alt'
        },
        {
          title: 'Grocery',
          icon: 'fal fa-shopping-basket'
        },
        {
          title: 'Single bed',
          icon: 'fal fa-bed'
        },
        {
          title: 'Expert Team',
          icon: 'fal fa-users'
        },
        {
          title: 'shop near',
          icon: 'fal fa-shopping-cart'
        },
        {
          title: 'Towels',
          icon: 'fal fa-bus'
        }
      ],
      rules: [
        'No smoking, parties or events.',
        'Check-in time from 2 PM, check-out by 10 AM.',
        'Time to time car parking',
        'Download Our minimal app',
        'Browse regular our website'
      ],
      cancellation: 'Phasellus volutpat neque a tellus venenatis, a euismod augue facilisis. Fusce ut metus mattis, consequat metus nec, luctus lectus. Pellentesque orci quis hendrerit sed eu dolor. <strong>Cancel up</strong> to <strong>14 days</strong> to get a full refund.',
      location: [
        31.53912,
        -89.29163
      ],
      offer: true
    }
  ];

  public roomsOrg: Room[];
  currentLang: string;
  constructor(private roomService: RoomService,
              private dialog: MatDialog,
              private languageService: LanguageService) {
    this.languageService.getCurrentLanguage().subscribe(lang => this.currentLang = lang);
  }

   ngOnInit(): void {
         this.roomService.getPublicRooms().subscribe((rooms: Room[]) => {
           this.roomsOrg = rooms;
           console.log(this.roomsOrg);
         });
     }

   openDialog(roomOrg: Room) {
     const dialogRef = this.dialog.open(GalleryDialogComponent, {
       data: roomOrg
     });
     dialogRef.afterClosed().subscribe(result => {
     });
   }

   openBookingDialog() {
     this.dialog.open(BookingDialogComponent);
   }
 }

<mat-card class="example-card">
  <mat-card-header>
    <div class="container">
      <div class="row">
        <div class="col" style="float: left">
          <mat-card-subtitle><mat-icon style="font-size: 32px; color: #bead8e; margin: 10px; cursor: pointer">restaurant</mat-icon>{{menu?.menuType}}</mat-card-subtitle>
        </div>
      </div>
    </div>
  </mat-card-header>
  <hr style="margin-bottom: 5px; margin-top: 2px">
  <div class="container">
    <div class="row">
      <div class="col">
        <mat-card-content>
          <p> <b>Title:</b> {{menu?.title}}</p>
          <p [hidden]="true" > <b>Content:</b> {{menu?.content}}</p>
          <p><b>created:</b> {{menu?.createdDate | date:'d-MM-y'}}</p>
          <p><b>changed:</b> {{menu?.editedDate | date:'d-MM-y'}}</p>
          <p><b>Public:</b> {{menu?.public}}</p>
        </mat-card-content>
      </div>
      <div class="col">
        <img [src]="menu?.photoUrl" style="height: 200px; width: 250px">
<!--        <iframe [hidden]="true" style="-->
<!--             width: 300px;-->
<!--             height: 200px;-->
<!--             margin-top: 10px;-->
<!--             overflow: hidden"-->
<!--                [src]="url">-->
<!--        </iframe>-->
      </div>
    </div>
  </div>

  <mat-card-actions>
    <button (click)="UpdateMenu()" mat-stroked-button color="primary">Edit</button>
    <button (click)="deleteMenu()" mat-stroked-button color="warn">Delete</button>
    <app-content-status-old [isOnline]="menu.public"></app-content-status-old>
  </mat-card-actions>

</mat-card>

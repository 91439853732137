import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';
import {Menu} from '../../../../models/menu.model';
import {RestaurantService} from '../../../../services/restaurant.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-updatemenu',
  templateUrl: './updatemenu.component.html',
  styleUrls: ['./updatemenu.component.css']
})
export class UpdatemenuComponent implements OnInit {
  uploadEvent: Event;
  uploadEvent2: Event;
  menuForm: UntypedFormGroup;
  menu: Menu;
  currentDate: any;
  hasPhoto: boolean;
  hasPhoto2: boolean;
  menuUrl: SafeResourceUrl;
  constructor(private authService: AuthService,
              private restaurantService: RestaurantService,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: Menu) {
    this.menuForm = this.formBuilder.group({
      headerDescription: ['', Validators.required],
      content: ['', Validators.required],
      title: ['', Validators.required],
      public: [false, '']
    });
    this.menu = data;
  }

  ngOnInit(): void {
    // this.menuUrl = this.sanitize(this.menu.menuUrl);

    if (this.data != null) {
      this.menuForm.controls.title.setValue(this.data.title);
      this.menuForm.controls.headerDescription.setValue(this.data.headerDescription);
      this.menuForm.controls.content.setValue(this.data.content);
      this.menuForm.controls.public.setValue(this.data.public);
    }
    this.hasPhoto = this.data.menuUrl !== '';
    this.hasPhoto2 = this.data.photoUrl !== '';
  }

  updateMenu() {
    this.menu = new Menu(this.menuForm.value);
    this.menu.menuId = this.data.menuId;
    this.restaurantService.updateMenu(this.uploadEvent, this.uploadEvent2, this.menu);
  }

  pdfToUpload($event: Event) {
    this.uploadEvent = $event;
    this.hasPhoto = true;
    this.updateMenu();
    this.getUpdatedMenu();
    this.uploadEvent = null;
  }

  pictureToUpload2($event: Event) {
    this.uploadEvent2 = $event;
    this.hasPhoto2 = true;
    this.updateMenu();
    this.getUpdatedMenu();
    this.uploadEvent2 = null;
  }

  deleteMenuPdf() {
    if (this.menu.menuUrl) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.restaurantService.deleteMenuPhoto(this.menu.menuUrl, this.menu.menuId);
          this.hasPhoto2 = false;
          this.getUpdatedMenu();
        }
      });
    }
  }

  deleteMenuPhoto() {
    if (this.menu.photoUrl) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.restaurantService.deletePhoto(this.menu.photoUrl, this.menu.menuId);
          this.hasPhoto2 = false;
          this.getUpdatedMenu();
        }
      });
    }
  }

  getUpdatedMenu() {
    this.restaurantService.getMenuById(this.menu.menuId).subscribe(menu => this.menu = menu);
  }

  sanitize(url: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

<form [formGroup]="placeForm" class="example-form">

  <div class="container">
    <mat-dialog-content>
      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Place Type</mat-label>
          <mat-select formControlName="placeType">
            <mat-option value="Sehenswürdigkeit">Sehenswürdigkeit</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" placeholder="place Type">
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Lat</mat-label>
          <input style="max-height: 120px" matInput formControlName="destinationLat" placeholder="Lat">
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Lng</mat-label>
          <input style="max-height: 120px" matInput formControlName="destinationLng" placeholder="Lng">
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Website</mat-label>
          <input style="max-height: 120px" matInput formControlName="website" placeholder="Website">
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Content</mat-label>
          <textarea style="max-height: 120px" matInput formControlName="content" placeholder="Content"></textarea>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-slide-toggle style="margin: 15px" formControlName="public">Public</mat-slide-toggle>
      </div>
      <!--Size 1170x570-->
      <div class="row">
        <div class="col">
          <input #newPostUpload1 hidden="true" type="file" (change)="pictureToUpload1($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload1.click()">Upload 1<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
          <span>Upload 1 = max-height 600px</span>
          <a target="_blank" href="https://www.photopea.com/">Change height here</a>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto1">check</mat-icon>
          <img [src]=place?.photoUrl1 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload2 hidden="true" type="file" (change)="pictureToUpload2($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload2.click()">Upload 2<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto2">check</mat-icon>
          <img [src]=place?.photoUrl2 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload3 hidden="true" type="file" (change)="pictureToUpload3($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload3.click()">Upload 3<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto3">check</mat-icon>
          <img [src]=place?.photoUrl3 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <input #newPostUpload4 hidden="true" type="file" (change)="pictureToUpload4($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="newPostUpload4.click()">Upload 4<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto4">check</mat-icon>
          <img [src]=place?.photoUrl4 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions>
      <button style="margin: 10px" mat-raised-button color="primary" mat-button mat-dialog-close (click)="addPlace()">Speichern</button>
      <button style="margin: 10px" mat-raised-button color="warn" mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>

  </div>
</form>

import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Place} from '../../../../models/place.model';
import {PlaceService} from '../../../../services/place.service';

@Component({
  selector: 'app-admin-newplace',
  templateUrl: './admin-newplace.component.html',
  styleUrls: ['./admin-newplace.component.css']
})
export class AdminNewplaceComponent implements OnInit {
  uploadEvent1: Event;
  uploadEvent2: Event;
  uploadEvent3: Event;
  uploadEvent4: Event;
  placeForm: UntypedFormGroup;
  place: Place;
  hasPhoto1: boolean;
  hasPhoto2: boolean;
  hasPhoto3: boolean;
  hasPhoto4: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private placeService: PlaceService) {
    this.placeForm = this.formBuilder.group({
      content: ['', Validators.required],
      title: ['', Validators.required],
      placeType: ['', Validators.required],
      destinationLat: [''],
      destinationLng: [''],
      website: [''],
      public: [false, ''],
    });
  }

  ngOnInit(): void {
  }

  addPlace() {
    this.place = new Place(this.placeForm.value);
    this.placeService.addPlace(this.uploadEvent1,
      this.uploadEvent2,
      this.uploadEvent3,
      this.uploadEvent4,
      this.place);
  }

  pictureToUpload1($event: Event) {
    this.uploadEvent1 = $event;
    this.hasPhoto1 = true;
  }
  pictureToUpload2($event: Event) {
    this.uploadEvent2 = $event;
    this.hasPhoto2 = true;
  }
  pictureToUpload3($event: Event) {
    this.uploadEvent3 = $event;
    this.hasPhoto3 = true;
  }
  pictureToUpload4($event: Event) {
    this.uploadEvent4 = $event;
    this.hasPhoto4 = true;
  }
}

<app-header4></app-header4>
<!--====== BANNER PART START ======-->
<app-home-banner *ngIf="layoutConfig?.banner == 'Home-banner-1'"></app-home-banner>
<app-home-banner-v2 *ngIf="layoutConfig?.banner == 'Home-banner-2'"></app-home-banner-v2>

<app-monitor></app-monitor>

<app-booking-button></app-booking-button>

<!--====== FEATURE ROOM START ======-->
<app-carousel-rooms *ngIf="layoutConfig?.roomSlider == 'Room-Slider-1'"></app-carousel-rooms>
<app-rooms-carousel-v2 *ngIf="layoutConfig?.roomSlider == 'Room-Slider-2'"></app-rooms-carousel-v2>

<!--====== Video START ======-->
<app-video *ngIf="layoutConfig?.videoDesign == 'video-1'"></app-video>

<!--====== About START ======-->
<section class="text-block pt-50 pb-50">
    <div class="container">
        <div class="row align-items-center justify-content-center wow fadeInLeft" data-wow-delay=".3s">
            <div class="col-lg-7">
                <div class="text-img text-center text-lg-left mb-small">
                    <img src="../../../../../assets/img/home/HotelBackView.jpg"
                         alt="Picture from the Hotel Back"
                         title="Picture show's the Hotels Back with the Garden">
                </div>
            </div>
            <div class="col-lg-5 col-md-8 col-sm-10 wow fadeInRight" data-wow-delay=".5s">
                <div class="block-text">
                    <div class="section-title mb-20">
                        <span class="title-tag">{{'home.aboutus-header' | translate}}</span>
                      <p style="margin-bottom: 10px">{{'home.aboutus-sub' | translate}}</p>
                      <div style="display: flex; align-items: center;"   >
                        <h3 class="collapsible">
                          <a href="https://www.google.com/maps/@52.1776041,7.0389108,3a,90y,19.44h,60.11t/data=!3m7!1e1!3m5!1sAF1QipOnthjyop80U-k24U9jp3J-Ly1wFPEoFXWXAMvt!2e10!3e12!7i13744!8i6872?entry=ttu" target="_blank">
                            {{'home.aboutus-tour-button' | translate}}<span style="margin-left: 10px"><mat-icon>keyboard_arrow_right</mat-icon></span>
                          </a>
                        </h3>
                      </div>
                    </div>
                  <hr>
                  <p style="margin-bottom: 10px">{{'home.aboutus' | translate}}</p>
                  <a href="https://hotel-schepers.de/about" routerLink='/about' class="main-btn btn-filled mt-20">{{'home.aboutus-button' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== About END ======-->

<!--====== CORE FEATURES START ======-->
<section class="core-feature-section bg-white pt-5 pb-5">
    <div class="container">
        <div class="section-title text-center mb-50">
      </div>
      <!-- Feature Loop -->
        <div class="row features-loop">
            <div class="col-lg-4 col-sm-6 order-1">
                <div class="feature-box with-hover-img wow fadeInLeft" data-wow-delay="0.5s">
                    <div routerLink="/gallery" class="icon"><i class="flaticon-arrow"></i></div>
                    <h3><a href="https://hotel-schepers.de/gallery" routerLink="/gallery" class="feature-title">{{'home.feature-gallery-title' | translate}}</a></h3>
                    <p>{{'home.feature-gallery-sub' | translate}}</p>
                    <span class="count">01</span>
                    <div *ngIf="!isTouch" class="hover-img hover-pic1" title="Picture show's Hotel Entrance"></div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-2">
              <div class="feature-box wow fadeInDown" data-wow-delay="0.5s" routerLink="/offers-seminars">
                <div  class="icon"><i class="flaticon-teamwork"></i></div>
                <h3><a class="feature-title" >{{'home.feature-seminars-title' | translate}}</a></h3>
                <p>{{'home.feature-seminars-sub' | translate}}</p>
                <span class="count">02</span>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-3 ">
                <div class="feature-box with-hover-img wow fadeInRight" data-wow-delay="0.5s">
                    <div routerLink="/places" class="icon"><i class="flaticon-location-pin"></i></div>
                    <h3><a href="https://hotel-schepers.de/places" routerLink="/places">{{'home.feature-places-title' | translate}}</a></h3>
                    <p>{{'home.feature-places-sub' | translate}}</p>
                    <span class="count">03</span>
                    <div *ngIf="!isTouch" class="hover-img hover-pic2" title="Picture show's Hotel Lobby">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== CORE FEATURES END ======-->

<!--====== News Outlet Start ======-->
<section class="latest-news pt-5 pb-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-8 col-sm-7">
        <div class="section-title">
          <h2>{{'home.news' | translate}}</h2>
        </div>
      </div>
      <div class="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
        <div class="latest-post-arrow arrow-style text-right">
        </div>
      </div>
    </div>
    <!-- Latest post loop -->
    <app-news-outlet></app-news-outlet>
  </div>
</section>
<!--====== News Outlet End ======-->

<app-footer2></app-footer2>

<app-header4></app-header4>
<!--====== Banner Start ======-->
<section class="breadcrumb-area restaurant-banner-pic" title="Picture show's a Bicycle">
  <div class="container">
    <div class="breadcrumb-text">
      <h2 class="page-title">{{'biketours.banner-title' | translate}}</h2>
      <a (click)="openBookingPackages()" class="main-btn1 btn-filled1 datepickerSize" type="submit">{{'home.book' | translate}}</a>
      <ul class="breadcrumb-nav">
        <li><a routerLink='/'>{{'biketours.banner-link-left' | translate}}</a></li>
        <li class="active">{{'biketours.banner-link-right' | translate}}</li>
      </ul>
    </div>
  </div>
</section>
<!--====== Banner End ======-->


<!--====== Menus Start ======-->
<div *ngFor="let menu of menus">
  <section class="about-section pt-10 pb-10">
    <!-- ///////////////////////////////////////////// German Start ///////////////////////////////////// -->
    <div *ngIf="currentLang == 'de'" class="container">
      <section class="about-section">
        <div class="container">
          <div class="section-title about-title text-center" style="margin-top: 30px">
            <span class="title-tag">{{menu?.headerDescription}}</span>
          </div>
        </div>
      </section>
      <div class="about-text-box">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img">
              <img [src]="menu?.photoUrl"
                   alt="Picture show's Restaurant or a Dish"
                   title="Picture show's Restaurant or a Dish">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-text">
              <span>{{menu?.title}}</span>
              <p>
                {{menu?.content}}
              </p>
              <hr>
              <ul *ngFor="let bullet of menu.bulletPoints">
                <li>
                  <span style="font-weight: bold; font-size: 12px">{{bullet.toString().split(':')[0]}}: &nbsp;</span><span style="font-weight: normal; font-size: 12px">{{bullet.toString().split(':')[1]}}</span>
                </li>
              </ul>
            </div>
            <a *ngIf="menu?.biketourUrl" target="_blank" [attr.href]="menu?.biketourUrl" class="main-btn1 btn-filled1 datepickerSize">
              Download Radtour als .GPX
            </a>
            <a *ngIf="menu?.pdfUrl" target="_blank" [attr.href]="menu?.pdfUrl" class="main-btn1 btn-filled1 datepickerSize">
              Download Radtour als .PDF
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ///////////////////////////////////////////// English Start ///////////////////////////////////// -->
    <div *ngIf="currentLang == 'en'" class="container">
      <section class="about-section">
        <div class="container">
          <div class="section-title about-title text-center" style="margin-top: 30px">
            <span class="title-tag">{{menu?.headerDescriptionEN}}</span>
          </div>
        </div>
      </section>
      <div class="about-text-box">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img">
              <img [src]="menu?.photoUrl"
                   alt="Foto zeigt Fahrradtouren"
                   title="Foto zeigt Fahrradtouren">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-text">
              <span>{{menu?.titleEN}}</span>
              <p>
                {{menu?.contentEN}}
              </p>
              <hr>
              <ul *ngFor="let bullet of menu.bulletPointsEN">
                <li>
                  <span style="font-weight: bold; font-size: 12px">{{bullet.toString().split(':')[0]}}: &nbsp;</span><span style="font-weight: normal; font-size: 12px">{{bullet.toString().split(':')[1]}}</span>
                </li>
              </ul>
            </div>
            <a *ngIf="menu?.biketourUrl" target="_blank" [attr.href]="menu?.biketourUrl" class="main-btn1 btn-filled1 datepickerSize">
              Download Radtour as .GPX
            </a>
            <a *ngIf="menu?.pdfUrl" target="_blank" [attr.href]="menu?.pdfUrl" class="main-btn1 btn-filled1 datepickerSize">
              Download Radtour as .PDF
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<span style="display: flex; justify-content: center; justify-items: center">Quelle: &nbsp;<a target="_blank" href="https://gronau-inside.de/erleben-entdecken/radtouren/radfahren">https://gronau-inside.de/erleben-entdecken/radtouren/radfahren</a></span>
<!--====== Menus End ======-->


<app-footer2></app-footer2>

<form [formGroup]="roomForm" (ngSubmit)="updateRoom()" class="example-form">

  <div class="container">
    <mat-dialog-content>
      <div class="row">
        <mat-form-field style="width: 100%">
          <mat-label>Room Type</mat-label>
          <mat-select formControlName="roomType">
            <mat-option value="Einzelzimmmer">Einzelzimmmer</mat-option>
            <mat-option value="Doppelzimmer">Doppelzimmer</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" placeholder="Title">
        </mat-form-field>
      </div>


      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Content</mat-label>
          <textarea style="max-height: 120px" matInput formControlName="content" placeholder="Content"></textarea>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="example-full-width">
          <mat-label>Price</mat-label>
          <input matInput formControlName="price" placeholder="Price">
        </mat-form-field>
      </div>


      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 1 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput1 hidden="true" type="file" (change)="pictureToUpload1($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput1.click()">Upload 1<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
          <span>Upload 1 = max-height 600px</span>
          <a target="_blank" href="https://www.photopea.com/">Change height here</a>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto1">check</mat-icon>
          <img (click)="deleteRoomPhoto1()" [src]=roomFromServer?.photoUrl1 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 2 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput2 hidden="true" type="file" (change)="pictureToUpload2($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput2.click()">Upload 2<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto2">check</mat-icon>
          <img (click)="deleteRoomPhoto2()" [src]=roomFromServer?.photoUrl2 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 3 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput3 hidden="true" type="file" (change)="pictureToUpload3($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput3.click()">Upload 3<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto3">check</mat-icon>
          <img (click)="deleteRoomPhoto3()" [src]=roomFromServer?.photoUrl3 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 4 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput4 hidden="true" type="file" (change)="pictureToUpload4($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput4.click()">Upload 4<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto4">check</mat-icon>
          <img (click)="deleteRoomPhoto4()" [src]=roomFromServer?.photoUrl4 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 5 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput5 hidden="true" type="file" (change)="pictureToUpload5($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput5.click()">Upload 5<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto5">check</mat-icon>
          <img (click)="deleteRoomPhoto5()" [src]=roomFromServer?.photoUrl5 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 6 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput6 hidden="true" type="file" (change)="pictureToUpload6($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput6.click()">Upload 6<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto6">check</mat-icon>
          <img (click)="deleteRoomPhoto6()" [src]=roomFromServer?.photoUrl6 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 7 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput7 hidden="true" type="file" (change)="pictureToUpload7($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput7.click()">Upload 7<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto7">check</mat-icon>
          <img (click)="deleteRoomPhoto7()" [src]=roomFromServer?.photoUrl7 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 8 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput8 hidden="true" type="file" (change)="pictureToUpload8($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput8.click()">Upload 8<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto8">check</mat-icon>
          <img (click)="deleteRoomPhoto8()" [src]=roomFromServer?.photoUrl8 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 9 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput9 hidden="true" type="file" (change)="pictureToUpload9($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput9.click()">Upload 9<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto9">check</mat-icon>
          <img (click)="deleteRoomPhoto9()" [src]=roomFromServer?.photoUrl9 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 10 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      <div class="row">
        <div class="col">
          <input #csvInput10 hidden="true" type="file" (change)="pictureToUpload10($event)" accept=".jpg,.png"/>
          <button mat-flat-button color="primary" (click)="csvInput10.click()">Upload 10<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
        </div>
        <div class="col">
          <mat-icon *ngIf="hasPhoto10">check</mat-icon>
          <img (click)="deleteRoomPhoto10()" [src]=roomFromServer?.photoUrl10 style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
        </div>
      </div>

      <div class="row">
        <mat-slide-toggle style="margin-bottom: 5px" formControlName="public">Public</mat-slide-toggle>
      </div>

      <div class="row">
        <div class="col-4">
          <mat-checkbox formControlName="extras1" class="example-margin">Wifi 200Mbit</mat-checkbox>
          <mat-checkbox formControlName="extras2" class="example-margin">Frühstuck</mat-checkbox>
          <mat-checkbox formControlName="extras3" class="example-margin">Dusche</mat-checkbox>
          <mat-checkbox formControlName="extras4" class="example-margin">Badewanne</mat-checkbox>
          <mat-checkbox formControlName="extras5" class="example-margin">Balkon</mat-checkbox>
        </div>
        <div class="col-4">
          <mat-checkbox formControlName="extras6" class="example-margin">Doppel Bett</mat-checkbox>
          <mat-checkbox formControlName="extras7" class="example-margin">Schlafsofa</mat-checkbox>
          <mat-checkbox formControlName="extras8" class="example-margin">Schreibtisch</mat-checkbox>
          <mat-checkbox formControlName="extras9" class="example-margin">Kaffeemaschine</mat-checkbox>
        </div>

      </div>

      <!-----------------------Start Bullet Points ------------------>
      <mat-form-field class="example-chip-list" style="width: 100%; margin-top: 10px; margin-bottom: 10px">
        <mat-chip-list #chipList aria-label="Fruit selection">
          <mat-chip
            *ngFor="let bulletPoint of bulletPoints"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(bulletPoint)">
            {{bulletPoint}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Bulletpoint List for Text"
            #fruitInput
            [formControl]="bulletPointCtrl"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let fruit of filteredBulletPoints | async" [value]="fruit">
            {{fruit}}
          </mat-option>
        </mat-autocomplete> -->
      </mat-form-field>

      <!-----------------------Start Check List ------------------>
      <mat-form-field *ngIf="false" class="example-chip-list" style="width: 100%; margin-top: 10px; margin-bottom: 10px">
        <mat-chip-list #chipList2 aria-label="Fruit selection2">
          <mat-chip
            *ngFor="let bulletPoint2 of checkList"
            [selectable]="selectable2"
            [removable]="removable2"
            (removed)="remove2(bulletPoint2)">
            {{bulletPoint2}}
            <mat-icon matChipRemove *ngIf="removable2">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Checkbox List for key words"
            #fruitInput2
            [formControl]="bulletPointCtrl2"
            [matAutocomplete]=""
            [matChipInputFor]="chipList2"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes2"
            (matChipInputTokenEnd)="add2($event)">
        </mat-chip-list>
       <!--  <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selected2($event)">
           <mat-option *ngFor="let CheckPoint of filteredBulletPoints2 | async" [value]="CheckPoint">
             {{CheckPoint}}
           </mat-option>
         </mat-autocomplete> -->
      </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions>
      <button  style="margin: 10px" mat-raised-button color="primary" mat-button type="submit" (click)="startSaving()">Speichern</button>
      <button style="margin: 10px" mat-raised-button color="warn" mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>

  </div>
</form>

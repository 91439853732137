<form [formGroup]="menuForm" (ngSubmit)="updateMenu()" class="example-form">
  <mat-dialog-content>
    <mat-form-field class="example-full-width">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" placeholder="Title">
    </mat-form-field>
    <mat-form-field class="example-full-width" >
      <mat-label>HeaderDescription</mat-label>
      <textarea style="max-height: 100px" matInput formControlName="headerDescription" placeholder="Header Description"></textarea>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Content</mat-label>
      <textarea style="max-height: 100px" matInput formControlName="content" placeholder="Content"></textarea>
    </mat-form-field>
    <mat-slide-toggle style="margin-bottom: 20px" formControlName="public">Public</mat-slide-toggle>
    <div class="container">
      <div class="row">
        <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 1 PDF !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
          <div class="col">
            <input #csvInput1 hidden="true" type="file" (change)="pdfToUpload($event)" accept=".pdf,.jpg,.png"/>
            <button mat-flat-button color="primary" (click)="csvInput1.click()">PDF Uplaod<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
          </div>
          <div class="col">
            <mat-icon *ngIf="hasPhoto">check</mat-icon>
            <iframe
              style="
              width: 110px;
              height: 70px;"
                     [src]="menuUrl">
            </iframe>
            <mat-icon style="cursor: pointer" (click)="deleteMenuPdf()">delete</mat-icon>
          </div>
        </div>
        <!-- !!!!!!!!!!!!!!!!!!!!!! End Upload 1 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

        <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 2 PIC !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
        <div class="row">
          <div class="col">
            <input #csvInput2 hidden="true" type="file" (change)="pictureToUpload2($event)" accept=".jpg,.png"/>
            <button mat-flat-button color="primary" (click)="csvInput2.click()">PIC Uplaod<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
          </div>
          <div class="col">
            <mat-icon *ngIf="hasPhoto2">check</mat-icon>
            <img (click)="deleteMenuPhoto()" [src]=menu?.photoUrl style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
          </div>
        </div>
        <!-- !!!!!!!!!!!!!!!!!!!!!! End Upload 2 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button style="margin: 10px" mat-raised-button color="primary" mat-button mat-dialog-close type="submit">Update</button>
    <button style="margin: 10px" mat-raised-button color="warn" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

</form>

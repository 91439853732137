<div class="container"   style="text-align: center;margin-top: 10px">
  <mat-icon (click)="openDialogAddRoom()"
            class="add-room-icon"
            style="color: #bead8e; transform: scale(2)">add_box
  </mat-icon>
  <span style="margin: 40px">Add New Job</span>
</div>
<div style="display: flex;
  justify-content: center;
  align-items: center;">
  <app-admin-jobs></app-admin-jobs>
</div>

<app-preloader></app-preloader>
<router-outlet></router-outlet>
<div class="cookie-box" *ngIf="!cookiesSettingsSet">
  <div class="cookie-text-field">
    Wir verwenden Cookies und andere Tracking-Technologien, um Ihr Surferlebnis auf unserer Website zu verbessern, um unseren Website-Verkehr zu analysieren und um zu verstehen, woher unsere Besucher kommen.
    <a (click)="openPrivacyDialog()" style="color: #BEAD8EFF; cursor: pointer">Weitere Informationen</a>
  </div>
  <div class="cookie-buttons">
    <div class="main-btn-cookies btn-border" (click)="setCookieConfig(true)">Okay</div>
    <a class="cookie-deny-btn" (click)="setCookieConfig(false)">Nein Danke!</a>
  </div>
</div>

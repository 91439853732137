export class Room{
  roomId: string;
  CreatorId: string;
  roomType: string;
  roomTypeEN: string;
  title: string;
  titleEN: string;
  content: string;
  contentEN: string;
  price: string;
  public: boolean;
  views: string;
  likes: string;
  altText: string;
  thumbnailUrl: string;
  createdDate: any;
  editedDate: any;
  photoUrl1: string;
  photoUrl2: string;
  photoUrl3: string;
  photoUrl4: string;
  photoUrl5: string;
  photoUrl6: string;
  photoUrl7: string;
  photoUrl8: string;
  photoUrl9: string;
  photoUrl10: string;
  extras1: boolean;
  extras2: boolean;
  extras3: boolean;
  extras4: boolean;
  extras5: boolean;
  extras6: boolean;
  extras7: boolean;
  extras8: boolean;
  extras9: boolean;
  extras10: boolean;
  bulletPoints: string[];
  checkList: string[];
  bulletPointsEN: string[];

  constructor(roomData: any){
    if (roomData.postId != null) {
      this.roomId = roomData.postId;
    } else {
      this.roomId = '';
    }
    if (roomData.CreatorId != null) {
      this.CreatorId = roomData.CreatorId;
    } else {
      this.CreatorId = '';
    }
    if (roomData.roomType != null) {
      this.roomType = roomData.roomType;
    } else {
      this.roomType = '';
    }
    if (roomData.roomTypeEN != null) {
      this.roomTypeEN = roomData.roomTypeEN;
    } else {
      this.roomTypeEN = '';
    }
    if (roomData.price != null) {
      this.price = roomData.price;
    } else {
      this.price = '';
    }
    if (roomData.public != null) {
      this.public = roomData.public;
    } else {
      this.public = false;
    }
    if (roomData.title != null) {
      this.title = roomData.title;
    } else {
      this.title = '';
    }
    if (roomData.titleEN != null) {
      this.titleEN = roomData.titleEN;
    } else {
      this.titleEN = '';
    }
    if (roomData.content != null) {
      this.content = roomData.content;
    } else {
      this.content = '';
    }
    if (roomData.contentEN != null) {
      this.contentEN = roomData.contentEN;
    } else {
      this.contentEN = '';
    }
    if (roomData.views != null) {
      this.views = roomData.views;
    } else {
      this.views = '';
    }
    if (roomData.altText != null) {
      this.altText = roomData.altText;
    } else {
      this.altText = '';
    }
    if (roomData.thumbnailUrl != null) {
      this.thumbnailUrl = roomData.thumbnailUrl;
    } else {
      this.thumbnailUrl = '';
    }
    if (roomData.likes) {
      this.likes  = roomData.likes;
    } else {
      this.likes  = '';
    }
    if (roomData.createdDate != null) {
      this.createdDate = roomData.createdDate;
    } else {
      this.createdDate = Date.now();
    }
    if (roomData.editedDate != null) {
      this.editedDate = roomData.editedDate;
    } else {
      this.editedDate = Date.now();
    }
    if (roomData.photoUrl1 != null) {
      this.photoUrl1 = roomData.photoUrl1;
    } else {
      this.photoUrl1 = '';
    }
    if (roomData.photoUrl2 != null) {
      this.photoUrl2 = roomData.photoUrl2;
    } else {
      this.photoUrl2 = '';
    }
    if (roomData.photoUrl3 != null) {
      this.photoUrl3 = roomData.photoUrl3;
    } else {
      this.photoUrl3 = '';
    }
    if (roomData.photoUrl4 != null) {
      this.photoUrl4 = roomData.photoUrl4;
    } else {
      this.photoUrl4 = '';
    }
    if (roomData.photoUrl5 != null) {
      this.photoUrl5 = roomData.photoUrl5;
    } else {
      this.photoUrl5 = '';
    }
    if (roomData.photoUrl6 != null) {
      this.photoUrl6 = roomData.photoUrl6;
    } else {
      this.photoUrl6 = '';
    }
    if (roomData.photoUrl7 != null) {
      this.photoUrl7 = roomData.photoUrl7;
    } else {
      this.photoUrl7 = '';
    }
    if (roomData.photoUrl8 != null) {
      this.photoUrl8 = roomData.photoUrl8;
    } else {
      this.photoUrl8 = '';
    }
    if (roomData.photoUrl9 != null) {
      this.photoUrl9 = roomData.photoUrl9;
    } else {
      this.photoUrl9 = '';
    }
    if (roomData.photoUrl10 != null) {
      this.photoUrl10 = roomData.photoUrl10;
    } else {
      this.photoUrl10 = '';
    }

    if (roomData.extras1 != null) {
      this.extras1 = roomData.extras1;
    } else {
      this.extras1 = false;
    }

    if (roomData.extras2 != null) {
      this.extras2 = roomData.extras2;
    } else {
      this.extras2 = false;
    }

    if (roomData.extras3 != null) {
      this.extras3 = roomData.extras3;
    } else {
      this.extras3 = false;
    }

    if (roomData.extras4 != null) {
      this.extras4 = roomData.extras4;
    } else {
      this.extras4 = false;
    }

    if (roomData.extras5 != null) {
      this.extras5 = roomData.extras5;
    } else {
      this.extras5 = false;
    }

    if (roomData.extras6 != null) {
      this.extras6 = roomData.extras6;
    } else {
      this.extras6 = false;
    }

    if (roomData.extras7 != null) {
      this.extras7 = roomData.extras7;
    } else {
      this.extras7 = false;
    }

    if (roomData.extras8 != null) {
      this.extras8 = roomData.extras8;
    } else {
      this.extras8 = false;
    }

    if (roomData.extras9 != null) {
      this.extras9 = roomData.extras9;
    } else {
      this.extras9 = false;
    }

    if (roomData.extras10 != null) {
      this.extras10 = roomData.extras10;
    } else {
      this.extras10 = false;
    }

    if (roomData.bulletPoints != null) {
      this.bulletPoints = roomData.bulletPoints;
    } else {
      this.bulletPoints = [];
    }
    if (roomData.bulletPointsEN != null) {
      this.bulletPointsEN = roomData.bulletPointsEN;
    } else {
      this.bulletPointsEN = [];
    }
    if (roomData.checkList != null) {
      this.checkList = roomData.checkList;
    } else {
      this.checkList = [];
    }
  }
}

import { Injectable } from '@angular/core';
import {AuthService} from './auth.service';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {forkJoin, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Post} from '../models/post.model';
import {GoogleTranslatorService} from './google-translator.service';

@Injectable({
  providedIn: 'root'
})

export class PostService {
  private postCollection: AngularFirestoreCollection<any>;
  private postDocument: AngularFirestoreDocument<any>;
  posts: Observable<any>;
  userId: string;
  uploadPercent: Observable<number>;
  post: Observable<any>;

  constructor(private authService: AuthService,
              private afs: AngularFirestore,
              private afStorage: AngularFireStorage,
              private translator: GoogleTranslatorService) {
    this.getPosts();
    this.authService.afAuth.authState.subscribe(authState => {
      if (authState) {
        this.userId = authState.uid;
      }
    });
  }

  getPosts() {
    this.postCollection = this.afs.collection<any>('posts', ref => ref
      .orderBy('index', 'asc'));
    this.posts = this.postCollection.valueChanges();
    return this.posts;
  }

  getPostById(postId: string) {
    this.postDocument = this.afs.doc<any>('posts/' + postId);
    this.post = this.postDocument.valueChanges();
    return this.post;
  }

  getPublicPosts() {
    this.postCollection = this.afs.collection<any>('posts', ref => ref
      .where('public', '==', true).orderBy('index', 'asc'));
    this.posts = this.postCollection.valueChanges();
    return this.posts;
  }

  getOfflinePosts() {
    this.postCollection = this.afs.collection<any>('posts', ref => ref
      .where('public', '==', false).orderBy('index', 'asc'));
    this.posts = this.postCollection.valueChanges();
    return this.posts;
  }

  addPost(uploadEvent, post: Post) {
    this.afs.collection('posts').add(Object.assign({}, post)).then(res => {
      this.afs.collection('posts').doc(res.id).update({postId: res.id, CreatorId: this.userId}).then();
      if (uploadEvent != null) {
        this.uploadPostPictures(uploadEvent, res.id);
      }

    });
  }

  updatePost(uploadEvent, post: Post) {
    const resContentEN = this.translator.translate(post.content);
    const resContentTitleEN = this.translator.translate(post.title);
    const resLinkTitleEN = this.translator.translate(post.linkTitle);
    forkJoin([resContentEN, resContentTitleEN, resLinkTitleEN]).subscribe((res: any) => {
      post.contentEN = res[0].data.translations[0].translatedText;
      post.titleEN = res[1].data.translations[0].translatedText;
      post.linkTitleEN = res[2].data.translations[0].translatedText;
      this.afs.collection('posts').doc(post.postId)
        .update({
          title: post.title,
          titleEN: post.titleEN,
          content: post.content,
          contentEN: post.contentEN,
          link: post.link,
          linkTitle: post.linkTitle,
          linkTitleEN: post.linkTitleEN,
          public: post.public,
          editedDate: Date.now()
        });
      if (uploadEvent != null) {
        this.uploadPostPictures(uploadEvent, post.postId);
      }
    });
  }

  deletePost(postId: string, photoUrl: string) {
    if (photoUrl) {
      this.deletePostPhoto(photoUrl);
    }
    this.afs.collection('posts').doc(postId).delete().then();
  }

  deletePostPhoto(photoUrl: string) {
    this.afStorage.refFromURL(photoUrl).delete();
  }

  uploadPostPictures(event, postId: string) {
    const file = event.target.files[0];
    const filePath = 'PostPictures/' + this.userId + '/' + Date.now().toString();
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          this.afs.collection('posts').doc(postId).update({photoUrl: res});
        });
      } )
    ).subscribe();
  }

  deleteRoomPhoto(postPhotoUrl: string, postId: string, photoNumber: string) {
    this.afStorage.refFromURL(postPhotoUrl).delete();
    this.afs.collection('post').doc(postId).update({[photoNumber]: ''});
  }

  updateIndex(postId: string, index: number) {
    this.afs.collection('posts').doc(postId).update({index}).then();
  }



}

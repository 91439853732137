import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {LayoutService} from '../../../../services/layout.service';
import {LayoutConfigModel} from '../../../../models/LayoutConfig.model';
import {SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HomeTwoComponent implements OnInit {
  bookingForm: UntypedFormGroup;
  isTouch: boolean;
  layoutConfig: LayoutConfigModel;
  constructor(private wowService: NgwWowService,
              private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private layoutService: LayoutService) {
    this.bookingForm = this.formBuilder.group({
      checkInDate: [''],
      checkOutDate: [''],
      durationInDays: [''],
      persons: [1]
    });
  }

  ngOnInit(): void {
    this.layoutService.getLayoutsConfig().subscribe((layoutConfig: LayoutConfigModel) => this.layoutConfig = layoutConfig);
    this.isTouch = 'ontouchstart' in document.documentElement;
  }

}

import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public language: Subject<string> = new BehaviorSubject<string>(null);

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'de', 'nl']);
    translate.setDefaultLang(navigator.language);

    if (sessionStorage.getItem('usedLang') !== null) {
      this.language.next(sessionStorage.getItem('usedLang'));
    } else {
      this.language.next('de');
    }
    this.language.asObservable().subscribe(data => {
      sessionStorage.setItem('usedLang', data);
      this.translate.use(data);
    });
  }

  switchLang(lang: string): void {
    this.language.next(lang);
    sessionStorage.setItem('usedLang', lang);
    this.translate.use(lang);
  }

  getCurrentLanguage(): Observable<string>{
    return this.language.asObservable();
  }

  setLanguage(language: string): any{
    this.language.next(language);
  }
}

import { Component, OnInit } from '@angular/core';
import {Place} from '../../../../models/place.model';
import {PlaceService} from '../../../../services/place.service';

@Component({
  selector: 'app-admin-listplaces',
  templateUrl: './admin-listplaces.component.html',
  styleUrls: ['./admin-listplaces.component.css']
})
export class AdminListplacesComponent implements OnInit {
  places: Place;

  constructor(private placesService: PlaceService) { }

  ngOnInit(): void {
    this.placesService.getPlaces().subscribe(places => {
      this.places = places;
    });
  }

}

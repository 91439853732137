import {Component, Inject, Input, OnInit} from '@angular/core';
import {Post} from '../../../../models/post.model';
import {AuthService} from '../../../../services/auth.service';
import {PostService} from '../../../../services/post.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-newpost',
  templateUrl: './newpost.component.html',
  styleUrls: ['./newpost.component.css']
})
export class NewpostComponent implements OnInit {
  uploadEvent: Event;
  postForm: UntypedFormGroup;
  post: Post;
  hasPhoto1: boolean;
  data: any;
  constructor(private authService: AuthService,
              private postService: PostService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
    this.postForm = new UntypedFormGroup({
      content: new UntypedFormControl(null, {validators: [Validators.required]}),
      title: new UntypedFormControl(null, {validators: [Validators.required, Validators.maxLength(30)]}),
      link: new UntypedFormControl(null, {validators: [Validators.maxLength(500)]}),
      linkTitle: new UntypedFormControl(null, {validators: [Validators.maxLength(12)]}),
      public: new UntypedFormControl(false, {validators: [Validators.required]}),
    });

  }

  ngOnInit(): void {
    console.log(this.data.lastIndex);
  }

  addPost() {
    console.log('Save Post');
    this.post = new Post(this.postForm.value);
    this.post.postType = 'News';
    this.post.index = this.data.lastIndex + 1 ;
    this.postService.addPost(this.uploadEvent, this.post);
  }

  pictureToUpload($event: Event) {
    this.uploadEvent = $event;
    this.hasPhoto1 = true;
  }
}

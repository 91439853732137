<div>

<!--  <div style="text-align: center; margin-top: 20px; margin-bottom: 10px">-->
<!--    <mat-icon style="transform: scale(2)" (click)="openDialogAddRoom()"-->
<!--              class="add-room-icon">add_box-->
<!--    </mat-icon>-->
<!--    <span style="margin-left: 20px">Add New Post</span>-->
<!--  </div>-->

<!--  <div style="-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    align-items: center;">-->
<!--    <app-posts></app-posts>-->
<!--  </div>-->

  <app-accordion-posts></app-accordion-posts>
</div>

import { Component, OnInit } from '@angular/core';
import {OfferService} from '../../../../../services/offer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {Offer} from '../../../../../models/offer.model';
import {SEOServiceService} from '../../../../../services/seoservice.service';

@Component({
  selector: 'app-offers-new',
  templateUrl: './offers-new.component.html',
  styleUrls: ['./offers-new.component.css']
})
export class OffersNewComponent implements OnInit {
  offers: Offer;
  constructor(private offerService: OfferService,
              private route: ActivatedRoute,
              private viewController: ViewportScroller) { }

  ngOnInit(): void {
    this.offerService.getPublicOffersByType('Arrangement').subscribe(offers => {
      this.offers = offers;
    });
    this.route.fragment.subscribe(fragment => {
      this.delay(300).then(() => this.viewController.scrollToAnchor(fragment));
    });
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}

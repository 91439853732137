import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Post} from '../../../../models/post.model';
import {AuthService} from '../../../../services/auth.service';
import {PostService} from '../../../../services/post.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../../shared/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-updatepost',
  templateUrl: './updatepost.component.html',
  styleUrls: ['./updatepost.component.css']
})
export class UpdatepostComponent implements OnInit {
  uploadEvent: Event;
  postForm: UntypedFormGroup;
  post: Post;
  currentDate: any;
  hasPhoto: boolean;
  constructor(private authService: AuthService,
              private postService: PostService,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: Post) {
    this.postForm = this.formBuilder.group({
      content: [''],
      title: ['', Validators.maxLength(30)],
      link: ['', Validators.maxLength(500)],
      linkTitle: ['', Validators.maxLength(20)],
      public: [false, '']
    });
    this.post = data;
  }

  ngOnInit(): void {
    if (this.data != null) {
      this.postForm.controls.title.setValue(this.post.title);
      this.postForm.controls.content.setValue(this.post.content);
      this.postForm.controls.public.setValue(this.post.public);
      this.postForm.controls.link.setValue(this.post.link);
      this.postForm.controls.linkTitle.setValue(this.post.linkTitle);
    }
    this.hasPhoto = this.post.photoUrl !== '';
  }

  updatePost() {
    // this.post = new Post(this.postForm.value);
    this.post = Object.assign(this.post, this.postForm.value);
    this.postService.updatePost(this.uploadEvent, this.post);
  }

  pictureToUpload($event: Event) {
    this.uploadEvent = $event;
    this.hasPhoto = true;
    this.updatePost();
    this.getUpdatedPost();
    this.uploadEvent = null;
  }

  deleteRoomPhoto() {
    if (this.post.photoUrl) {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.postService.deleteRoomPhoto(this.post.photoUrl, this.post.postId, 'photoUrl');
          this.hasPhoto = false;
          this.getUpdatedPost();
        }
      });
    }
  }

  getUpdatedPost() {
    this.postService.getPostById(this.post.postId).subscribe(post => this.post = post);
  }

}

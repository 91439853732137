<app-header4></app-header4>
<!--====== BREADCRUMB PART START ======-->
<section class="breadcrumb-area" style="background-image: url(../../../../../assets/img/arrangements/feste.jpg);">
  <div class="container">
    <div class="breadcrumb-text">
      <!-- <span>The ultimate luxury</span> -->
      <h2 class="page-title">Feste</h2>


      <ul class="breadcrumb-nav">
        <li><a routerLink='/'>Home</a></li>
        <li class="active">Feste</li>
      </ul>
    </div>
  </div>
</section>

<app-offers-festival  id="{{'offer' + i}}" *ngFor="let offer of offers; index as i;" [offer]="offer"></app-offers-festival>

<app-footer2></app-footer2>

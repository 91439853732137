<div class="room-box">
  <div class="room-img-wrap courser">
    <div (click)="openDialog()" class="room-img room-pic" [ngStyle]="{'background-image': 'url(' + room?.photoUrl1 + ')'}"></div>
  </div>
  <!-- ////////////////////////////////////// German Start ////////////////////////////////////// -->
  <div *ngIf="currentLang == 'de'" class="room-desc">
    <h4 class="title"><a href="https://hotel-schepers.de/room-list" [routerLink]="['/room-list']" [fragment]="'room'+ index">{{ room?.title }}</a></h4>
    <div  [routerLink]="['/room-list']" [fragment]="'room'+ index" >
      <p style="font-size: 16px; overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;">{{room?.content}}</p>
    </div>
    <span class="price">ab {{ room?.price }}€ /Nacht</span>
    <a class="book-btn courser" (click)="openBookingDialog()">{{'rooms.book' | translate}}</a>
  </div>
  <!-- ////////////////////////////////////// English Start ////////////////////////////////////// -->
  <div *ngIf="currentLang == 'en'" class="room-desc">
    <h4 class="title"><a href="https://hotel-schepers.de/room-list" [routerLink]="['/room-list']" [fragment]="'room'+ index">{{ room?.titleEN }}</a></h4>
    <div  [routerLink]="['/room-list']" [fragment]="'room'+ index" >
      <p style="font-size: 16px; overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;">{{room?.contentEN}}</p>
    </div>
    <span class="price">ab {{ room?.price }}€ /Night</span>
    <a class="book-btn courser" (click)="openBookingDialog()">{{'rooms.book' | translate}}</a>
  </div>

</div>

<form [formGroup]="jobForm" class="example-form">
  <mat-dialog-content>
    <mat-form-field class="example-full-width">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" placeholder="Title">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Content</mat-label>
      <textarea style="max-height: 120px" matInput formControlName="content" placeholder="Content"></textarea>
    </mat-form-field>

    <!-----------------------Start Bullet Points 1 ------------------>
    <mat-form-field class="example-chip-list" style="width: 100%; margin-top: 10px; margin-bottom: 10px">
      <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip
          *ngFor="let bulletPoint of bulletPoints"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(bulletPoint)">
          {{bulletPoint}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Bulletpoint List for Text"
          #fruitInput
          [formControl]="bulletPointCtrl"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
    </mat-form-field>
    <!-----------------------End Bullet Points 1 ------------------>

    <!-----------------------Start Bullet Points 2 ------------------>
    <mat-form-field class="example-chip-list" style="width: 100%; margin-top: 10px; margin-bottom: 10px">
      <mat-chip-list #chipList2 aria-label="Fruit selection">
        <mat-chip
          *ngFor="let bulletPoint2 of bulletPoints2"
          [selectable]="selectable2"
          [removable]="removable2"
          (removed)="remove2(bulletPoint2)">
          {{bulletPoint2}}
          <mat-icon matChipRemove *ngIf="removable2">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Bulletpoint List for Text"
          #fruitInput
          [formControl]="bulletPointCtrl2"
          [matChipInputFor]="chipList2"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes2"
          (matChipInputTokenEnd)="add2($event)">
      </mat-chip-list>
    </mat-form-field>
    <!-----------------------End Bullet Points 2 ------------------>

    <mat-slide-toggle formControlName="public">Public</mat-slide-toggle>
    <div class="container">
      <div class="row">
        <!-- !!!!!!!!!!!!!!!!!!!!!! Upload 1 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
        <div class="row">
          <div class="col">
            <input #csvInput1 hidden="true" type="file" (change)="pictureToUpload($event)" accept=".pdf, .jpg,.png"/>
            <button mat-flat-button color="primary" (click)="csvInput1.click()">Upload 1<mat-icon style="margin: 5px">cloud_upload</mat-icon></button>
          </div>
          <div class="col">
            <mat-icon *ngIf="hasPhoto">check</mat-icon>
            <img (click)="deleteRoomPhoto()" [src]=job?.photoUrl style="width: 80px; height: 50px; margin: 5px; cursor: pointer">
          </div>
        </div>
        <!-- !!!!!!!!!!!!!!!!!!!!!! End Upload 1 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button (click)="updatePost()" [disabled]="jobForm.invalid" style="margin: 10px" mat-raised-button color="primary" mat-button mat-dialog-close type="submit">Update</button>
    <button style="margin: 10px" mat-raised-button color="warn" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

</form>

<div class="footer-box">
  <div class="container">
    <div class="row">
      <div class="col-3" style="margin-top: 10px;" >
        <h5 style="color: #a0a0a0">Email:</h5>
        <span style="color: white">Lukas@linuxpoint.net</span>
      </div>
      <!-- <div class="col-3" style="margin-top: 10px; color: white">
        <h5><b>City:</b></h5>
        <span>Tübingen</span>
      </div> -->
      <!-- <div class="col-3" style="margin-top: 10px; color: white">
        <h5><b>Tel:</b></h5>
        <span>+491752507994</span>
      </div> -->
      <div class="col">
      <img src="/assets/admin-logo/logo-long.png"
           style="
           height: 70px;
           width: 200px;
           float: right;
           margin: 5px">
      </div>
    </div>
  </div>
</div>

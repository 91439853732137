import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {forkJoin, Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {finalize} from 'rxjs/operators';
import {Room} from '../models/room.model';
import {GoogleTranslatorService} from './google-translator.service';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  private RoomsCollection: AngularFirestoreCollection<any>;
  private RoomCollection: AngularFirestoreDocument<any>;
  rooms: Observable<any>;
  room: Observable<any>;
  userId: string;
  uploadPercent: Observable<number>;

  constructor(private authService: AuthService,
              private afs: AngularFirestore,
              private afStorage: AngularFireStorage,
              private translator: GoogleTranslatorService) {
    this.RoomsCollection = this.afs.collection<any>('rooms', ref => ref
      .orderBy('editedDate', 'desc'));
    this.rooms = this.RoomsCollection.valueChanges();

    this.authService.afAuth.authState.subscribe(authState => {
      if (authState) {
        this.userId = authState.uid;
      }
    });
  }

  getRooms() {
    return this.rooms;
  }

  getRoomById(roomId: string) {
    this.RoomCollection = this.afs.doc<any>('rooms/' + roomId);
    this.room = this.RoomCollection.valueChanges();
    return this.room;
  }

  getPublicRooms() {
    this.RoomsCollection = this.afs.collection<any>('rooms', ref => ref
      .where('public', '==', true).orderBy('editedDate', 'desc'));
    this.rooms = this.RoomsCollection.valueChanges();
    return this.rooms;
  }

  addRoom(uploadEvent1,
          uploadEvent2,
          uploadEvent3,
          uploadEvent4,
          uploadEvent5,
          uploadEvent6,
          uploadEvent7,
          uploadEvent8,
          uploadEvent9,
          uploadEvent10,
          room: Room) {
    const obsList = [];
    const bulletPointsEN = [];
    const obsRoomTypeEN = this.translator.translate(room.roomType);
    obsList.push(obsRoomTypeEN);
    const obsRoomTitleEN = this.translator.translate(room.title);
    obsList.push(obsRoomTitleEN);
    const obsRoomContentEN = this.translator.translate(room.content);
    obsList.push(obsRoomContentEN);
    if (room.bulletPoints.length >= 1) {
      const obsRoomBulletPointsEN = this.translator.translateList(room.bulletPoints);
      obsList.push(obsRoomBulletPointsEN);
    }

    forkJoin(obsList).subscribe((translations: any) => {
      room.roomTypeEN = translations[0].data.translations[0].translatedText;
      room.titleEN = translations[1].data.translations[0].translatedText;
      room.contentEN = translations[2].data.translations[0].translatedText;
      if (translations[3] != null) {
        translations[3].data.translations.forEach(translated => {
          bulletPointsEN.push(translated.translatedText);
        });
        room.bulletPointsEN = bulletPointsEN;
      }
      this.afs.collection('rooms').add(Object.assign({}, room)).then(res => {
        this.afs.collection('rooms').doc(res.id).update({roomId: res.id, CreatorId: this.userId});
        this.uploadPostPictures(uploadEvent1, 'photoUrl1' , res.id);
        this.uploadPostPictures(uploadEvent2, 'photoUrl2', res.id);
        this.uploadPostPictures(uploadEvent3, 'photoUrl3', res.id);
        this.uploadPostPictures(uploadEvent4, 'photoUrl4', res.id);
        this.uploadPostPictures(uploadEvent5, 'photoUrl5', res.id);
        this.uploadPostPictures(uploadEvent6, 'photoUrl6', res.id);
        this.uploadPostPictures(uploadEvent7, 'photoUrl7', res.id);
        this.uploadPostPictures(uploadEvent8, 'photoUrl8', res.id);
        this.uploadPostPictures(uploadEvent9, 'photoUrl9', res.id);
        this.uploadPostPictures(uploadEvent10, 'photoUrl10', res.id);
      });
    });
  }

  updateRoom(uploadEvent1,
             uploadEvent2,
             uploadEvent3,
             uploadEvent4,
             uploadEvent5,
             uploadEvent6,
             uploadEvent7,
             uploadEvent8,
             uploadEvent9,
             uploadEvent10,
             room: Room) {
    const obsList = [];
    const bulletPointsEN = [];
    const obsRoomTypeEN = this.translator.translate(room.roomType);
    obsList.push(obsRoomTypeEN);
    const obsRoomTitleEN = this.translator.translate(room.title);
    obsList.push(obsRoomTitleEN);
    const obsRoomContentEN = this.translator.translate(room.content);
    obsList.push(obsRoomContentEN);
    if (room.bulletPoints.length >= 1) {
      const obsRoomBulletPointsEN = this.translator.translateList(room.bulletPoints);
      obsList.push(obsRoomBulletPointsEN);
    }

    forkJoin(obsList).subscribe((translations: any) => {
      room.roomTypeEN = translations[0].data.translations[0].translatedText;
      room.titleEN = translations[1].data.translations[0].translatedText;
      room.contentEN = translations[2].data.translations[0].translatedText;
      if (translations[3] != null) {
        translations[3].data.translations.forEach(translated => {
          bulletPointsEN.push(translated.translatedText);
        });
        room.bulletPointsEN = bulletPointsEN;
      }
      this.afs.collection('rooms').doc(room.roomId)
      .update({
        title: room.title,
        titleEN: room.titleEN,
        content: room.content,
        contentEN: room.contentEN,
        public: room.public,
        roomType: room.roomType,
        roomTypeEN: room.roomTypeEN,
        price: room.price,
        extras1: room.extras1,
        extras2: room.extras2,
        extras3: room.extras3,
        extras4: room.extras4,
        extras5: room.extras5,
        extras6: room.extras6,
        extras7: room.extras7,
        extras8: room.extras8,
        extras9: room.extras9,
        extras10: room.extras10,
        bulletPoints: room.bulletPoints,
        bulletPointsEN: room.bulletPointsEN,
        checkList: room.checkList,
        editedDate: Date.now(),
      }).then();
    });
    if (uploadEvent1 != null) {
      this.uploadPostPictures(uploadEvent1, 'photoUrl1', room.roomId);
    }
    if (uploadEvent2 != null) {
      this.uploadPostPictures(uploadEvent2, 'photoUrl2', room.roomId);
    }
    if (uploadEvent3 != null) {
      this.uploadPostPictures(uploadEvent3, 'photoUrl3', room.roomId);
    }
    if (uploadEvent4 != null) {
      this.uploadPostPictures(uploadEvent4, 'photoUrl4', room.roomId);
    }
    if (uploadEvent5 != null) {
      this.uploadPostPictures(uploadEvent5, 'photoUrl5', room.roomId);
    }
    if (uploadEvent6 != null) {
      this.uploadPostPictures(uploadEvent6, 'photoUrl6', room.roomId);
    }
    if (uploadEvent7 != null) {
      this.uploadPostPictures(uploadEvent7, 'photoUrl7', room.roomId);
    }
    if (uploadEvent8 != null) {
      this.uploadPostPictures(uploadEvent8, 'photoUrl8', room.roomId);
    }
    if (uploadEvent9 != null) {
      this.uploadPostPictures(uploadEvent9, 'photoUrl9', room.roomId);
    }
    if (uploadEvent10 != null) {
      this.uploadPostPictures(uploadEvent10, 'photoUrl10', room.roomId);
    }
  }

  deleteRoom(postId: string) {
    this.afs.collection('rooms').doc(postId).delete().then();
  }

  deleteRoomPhoto(roomPhotoUrl: string, roomId: string, photoNumber: string) {
    this.afStorage.refFromURL(roomPhotoUrl).delete();
    this.afs.collection('rooms').doc(roomId).update({[photoNumber]: ''});
  }

  uploadPostPictures(event, field: string, postId: string) {
    const file = event.target.files[0];
    const filePath = 'RoomPictures/' + this.userId + '/' + Date.now().toString();
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          this.afs.collection('rooms').doc(postId).update({[field]: res});
        });
      })
    ).subscribe();
  }

}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LanguageService} from '../../../services/language.service';
import {SidenavService} from '../../../services/sidenav.service';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {AppUser} from '../../../models/AppUser.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit {
  appUser: AppUser;
  public currentLanguage: string;

  constructor(private authenticationService: AuthService,
              private languageService: LanguageService,
              private sidenavService: SidenavService,
              private userService: UserService) {
                this.languageService.getCurrentLanguage().subscribe(lang => {
                  this.currentLanguage = lang;
                });
              }

  ngOnInit(): void {
    this.setLanguage('de');
    this.userService.getUser().subscribe(user => {
      if (user) {
        this.appUser = user;
      }
    });
  }

  logoutUser(): void {
    this.authenticationService.logout();
  }

  setLanguage(lang: string): void {
    this.languageService.switchLang(lang);
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-content-status',
  templateUrl: './content-status.component.html',
  styleUrls: ['./content-status.component.css']
})
export class ContentStatusComponent implements OnInit {
  @Input() isOnline: boolean;

  constructor() { }

  ngOnInit(): void {

  }

}

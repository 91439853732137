
<!--====== BREADCRUMB PART START ======-->
<!--Image Size is: 1920 x 885-->


<section class="offers-area pt-30 pb-30">
  <div class="container">
    <div class="offer-boxes-loop">
      <div class="offer-box">
        <div (click)="openGallery()" class="thumb room-picture">
          <img [src]=room?.photoUrl1 alt="images" class="room-img">
          <span (click)="openGallery()" class="top-right"><mat-icon class="icon">photo</mat-icon></span>
        </div>
        <div class="offer-desc">
          <!--/////////////////////////////////// German Start //////////////////////////////////////-->
            <div *ngIf="currentLang == 'de'" class="title-wrap">
              <div class="title">
                <span class="room-cat">{{room?.roomType}}</span>
                <h2>{{room?.title}}</h2>
              </div>
              <div class="price">
                <span>ab </span> {{room?.price }}<span> €</span>
              </div>
            </div>
            <!--/////////////////////////////////// German End //////////////////////////////////////-->

            <!--/////////////////////////////////// English Start //////////////////////////////////////-->
            <div *ngIf="currentLang == 'en'" class="title-wrap">
              <div class="title">
                <span class="room-cat">{{room?.roomTypeEN}}</span>
                <h2>{{room?.titleEN}}</h2>
              </div>
              <div class="price">
                <span>ab </span> {{room?.price }}<span> €</span>
              </div>
            </div>
              <!--/////////////////////////////////// English End //////////////////////////////////////-->
            <div class="row justify-content-between">
              <!--/////////////////////////////////// German Start //////////////////////////////////////-->
              <div *ngIf="currentLang == 'de'" class="col-lg-5">
                <div class="offer-text">
                  <p>
                    {{room?.content}}
                  </p>
                  <hr>
                  <p *ngFor="let bullet of room?.bulletPoints">
                    <span *ngIf="bullet != ''"><b>-</b> {{bullet}}</span>
                  </p>
                </div>
              </div>
              <!--/////////////////////////////////// German End ////////////////////////////////////// -->

              <!--/////////////////////////////////// English Start //////////////////////////////////////-->
              <div *ngIf="currentLang == 'en'" class="col-lg-5">
                <div class="offer-text">
                  <p>
                    {{room?.contentEN}}
                  </p>
                  <hr>
                  <p *ngFor="let bullet of room?.bulletPointsEN">
                    <span *ngIf="bullet != ''"><b>-</b> {{bullet}}</span>
                  </p>
                </div>
              </div>
              <!--/////////////////////////////////// English End ////////////////////////////////////// -->
            <div class="col-lg-5">
              <div class="offer-feature">
                <ul>
                  <li *ngIf="room?.extras1"><i class="fal fa-wifi"></i><span style="margin-left: 10px">{{"rooms.wifi" | translate}}</span></li>
                  <li *ngIf="room?.extras2"><i class="fal fa-egg-fried"></i><span style="margin-left: 10px">{{"rooms.breakfast" | translate}}</span></li>
                  <li *ngIf="room?.extras3"><i class="fal fa-shower"></i><span style="margin-left: 10px">{{"rooms.shower" | translate}}</span></li>
                  <li *ngIf="room?.extras4"><i class="fal fa-bath"></i><span style="margin-left: 10px">{{"rooms.bathtub" | translate}}</span></li>
                  <li *ngIf="room?.extras5"><i class="fal fa-street-view"></i><span style="margin-left: 10px">{{"rooms.balcony" | translate}}</span></li>
                  <li *ngIf="room?.extras6"><i class="fal fa-bed"></i><span style="margin-left: 10px">{{"rooms.double-bed" | translate}}</span></li>
                  <li *ngIf="room?.extras7"><i class="fal fa-couch"></i><span style="margin-left: 10px">{{"rooms.sofa" | translate}}</span></li>
                  <li *ngIf="room?.extras8"><i class="fal fa-desktop"></i><span style="margin-left: 10px">{{"rooms.desk" | translate}}</span></li>
                  <li *ngIf="room?.extras9"><i class="fal fa-coffee"></i><span style="margin-left: 10px">{{"rooms.coffee-maker" | translate}}</span></li>
                </ul>
              </div>
              <a (click)="openBookingDialog()" class="main-btn btn-filled">{{"rooms.book" | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  <ngx-image-gallery
    [images]="images"
    [conf]="conf"
    (onOpen)="galleryOpened($event)"
    (onClose)="galleryClosed()"
    (onImageClicked)="galleryImageClicked($event)"
    (onImageChange)="galleryImageChanged($event)"
    (onDelete)="deleteImage($event)"
  ></ngx-image-gallery>

<form [formGroup]="bookingForm" (ngSubmit)="openBookingDialog()">
  <div class="booking-button-box">
    <div class="datepicker-box">
      <div class="group">
        <div class="input">
          <mat-form-field appearance="fill">
            <mat-label>{{'home.arrival' | translate}}</mat-label>
            <input (click)="picker1.open()" formControlName="checkInDate" matInput [matDatepicker]="picker1">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="input">
          <mat-form-field appearance="fill">
            <mat-label>{{'home.departure' | translate}}</mat-label>
            <input (click)="picker2.open()" formControlName="checkOutDate" matInput [matDatepicker]="picker2">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="group">
        <div class="input">
          <mat-form-field class="datepickerSize" appearance="fill">
            <mat-label>{{'home.guests' | translate}}</mat-label>
            <mat-select formControlName="persons">
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <a (click)="openBookingDialog()"class="main-btn1 btn-filled1 datepickerSize" type="submit">{{'home.book' | translate}}</a>
      </div>
      </div>
  </div>
</form>

<form [formGroup]="authenticate" (ngSubmit)="login()">
  <div class="login-container">
    <mat-card class="login-card">
      <mat-card-title>Login Admin-Dashboard</mat-card-title>
      <mat-form-field appearance="outline">
        <input matInput placeholder="E-Mail" formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Password" formControlName="password" type="password">
      </mat-form-field>
      <mat-card-actions>
        <button mat-stroked-button color="primary" type="submit">Login</button>
      </mat-card-actions>
    </mat-card>
   <!-- <ngx-recaptcha2 #captchaElem
                    [siteKey]=siteKey
                    useGlobalDomain="false"
                    size='normal'
                    hl='en'
                    [theme]=theme
                    type='image'
                    formControlName="recaptcha"
                    (reset)="handleReset()"
                    (expire)="handleExpire()"
                    (error)="handleError()"
                    (load)="handleLoad()"
                    (success)="handleSuccess($event)"
                    class="recaptcha">
    </ngx-recaptcha2>
    <mat-error *ngIf="authenticate.controls.password.touched && authenticate.controls.recaptcha.hasError('required') || loginFailed">
      <mat-icon>error</mat-icon>
    </mat-error> -->
  </div>
</form>

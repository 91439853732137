import {Component, Input, OnInit, ViewEncapsulation,} from '@angular/core';
import {Post} from '../../../../../models/post.model';
import {MatDialog} from '@angular/material/dialog';
import {NewsOutletDetailComponent} from '../deatails/news-outlet-detail/news-outlet-detail.component';
import {LanguageService} from '../../../../../services/language.service';

@Component({
  selector: 'app-new-outlet',
  templateUrl: './new-outlet.component.html',
  styleUrls: ['./new-outlet.component.css']
})

export class NewOutletComponent implements OnInit {
  currentLang: any;

  @Input() post: Post;
  mousePositionDown: any;
  mousePositionUp: any;

  constructor(private dialog: MatDialog,
              private language: LanguageService) {
    this.language.getCurrentLanguage().subscribe(lang => {
      this.currentLang = lang;
    });
  }

  ngOnInit(): void {}

  openNews() {
    if (this.mousePositionUp >= this.mousePositionDown + 10 || this.mousePositionUp <= this.mousePositionDown - 10) {
    } else {
      this.dialog.open(NewsOutletDetailComponent, {
        data : this.post
      });
    }
  }

  mouseDown(event) {
    this.mousePositionDown = event.pageX;
  }

  mouseUp(event) {
    this.mousePositionUp = event.pageX;
  }
}
